// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import {
  DateFunctions,
  Information,
  ToastGenerator,
} from "../../utils/Functions";
import { useParams } from "react-router-dom";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import Colors from "../../utils/Colors";

const requestDetailsInit = {
  entityDetails: {
    advertiser: { fullName: "" },
    creator: {
      fullName: "",
    },
    content: {
      contentDetails: {
        name: "",
      },
    },
  },
  adSlotDetails: {
    selectedCountryCode: "",
    selectedAdSlot: "",
    selectedAdFile: {
      adFileName: "",
    },
  },
  contractTerms: {
    typeOfContract: "",
    duration: 0,
    rateOramount: 0,
  },
  status: "",
  createdAt: "",
};

interface AdvertiserRequestDetailsProps {}

const AdvertiserRequestDetails: FC<AdvertiserRequestDetailsProps> = (props) => {
  const { id } = useParams();

  const [dataLoading, setDataLoading] = useState(false);
  const [requestDetails, setRequestDetails] = useState(requestDetailsInit);

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  useEffect(() => {
    refreshDataCall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshDataCall = () => {
    setDataLoading(true);
    API.getRequestById(id).then((response) => {
      setDataLoading(false);
      setRequestDetails(response.requestData);
    });
  };

  const confirmModalCall = () => {
    setModal({
      open: true,
      apiLoading: true,
    });

    API.withdrawContractRequest(id).then((response) => {
      if (
        response.message === Constants.API_RESPONSES.REQUEST_WITHDRAWN_SUCCESS
      ) {
        ToastGenerator.success(response.message);
        setModal({
          open: false,
          apiLoading: false,
        });
        refreshDataCall();
      }
    });
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Request Details" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Request Details" />

          <Box sx={{ ml: 1 }}>
            <CUIPresets.LabelValueHorizontal
              sx={{ mt: 1 }}
              label={"Creator Name"}
              value={requestDetails.entityDetails?.creator?.fullName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Content Name"}
              value={
                requestDetails.entityDetails?.content?.contentDetails?.name
              }
            />
            <CUIPresets.LabelValueHorizontal
              label={"Advertiser Name"}
              value={requestDetails.entityDetails?.advertiser?.fullName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Raised Date"}
              value={DateFunctions.format(requestDetails.createdAt)}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Ad Slot Details"
            />

            <CUIPresets.LabelValueHorizontal
              label={"Country"}
              value={Information.retrieveCountry(
                requestDetails.adSlotDetails?.selectedCountryCode
              )}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Ad Slot"}
              value={requestDetails.adSlotDetails?.selectedAdSlot}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Ad File"}
              value={requestDetails.adSlotDetails?.selectedAdFile?.adFileName}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Contract Terms"
            />
            <CUIPresets.LabelValueHorizontal
              label={"Type Of Contract"}
              value={requestDetails.contractTerms?.typeOfContract}
            />
            <CUIPresets.LabelValueHorizontal
              label={
                requestDetails.contractTerms?.typeOfContract === "FIX"
                  ? "Amount"
                  : "Rate"
              }
              value={requestDetails.contractTerms?.rateOramount}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Contract Duration"}
              value={requestDetails.contractTerms.duration + " Days"}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Response"
            />

            <CUIPresets.LabelStatus
              chipSelect={"contractRequest"}
              status={requestDetails.status}
            />

            {requestDetails.status ===
              Constants.STATUS_FLAGS.CONTRACT_REQUEST.RAISED && (
              <CUIPresets.OutlinedButton
                onClick={() => {
                  setModal({
                    open: true,
                    apiLoading: false,
                  });
                }}
                title="Withdraw"
                sx={{ my: 2 }}
                color={Colors.red}
              />
            )}
          </Box>

          <CUIPresets.CustomDialog
            title="Contract Request"
            content={["Are you sure about withdrawing this request?"]}
            confirmText={"Confirm"}
            cancelText="Cancel"
            confirmCall={confirmModalCall}
            modalOpen={modal.open}
            cancelCall={() =>
              setModal({
                open: false,
                apiLoading: false,
              })
            }
            apiLoading={modal.apiLoading}
          />
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdvertiserRequestDetails;
