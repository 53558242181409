// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import { ArrangeHorizontal, DocumentText1 } from "iconsax-react";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import Colors from "../../utils/Colors";

interface AdvertiserContractsProps {}

const AdvertiserContracts: FC<AdvertiserContractsProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Contracts" disabled />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<DocumentText1 size="60" color={Colors.blueBackground} />}
            title="Contracts"
            onClick={() => navigate(PATHS.ADVERTISER_CONTRACT_TYPES)}
          />
          <CUIPresets.SelectFlow
            Icon={<ArrangeHorizontal size="60" color={Colors.blueBackground} />}
            title="Transactions"
            onClick={() => navigate(PATHS.ADVERTISER_TRANSACTIONS)}
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdvertiserContracts;
