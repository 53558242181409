// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { DateFunctions, Information } from "../../utils/Functions";
import { useParams } from "react-router-dom";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";

const requestDetailsInit = {
  entityDetails: {
    advertiser: { fullName: "" },
    creator: {
      fullName: "",
    },
    content: {
      contentDetails: {
        name: "",
      },
      masterFile: {
        bunnyStreamLink: "",
      },
    },
  },
  adSlotDetails: {
    selectedCountryCode: "",
    selectedAdSlot: "",
    selectedAdFile: {
      adFileName: "",
      bunnyStreamLink: "",
    },
  },
  contractTerms: {
    typeOfContract: "",
    duration: 0,
    rateOramount: 0,
  },
  status: "",
  createdAt: "",
};

interface CreatorRequestDetailsProps {}

const CreatorRequestDetails: FC<CreatorRequestDetailsProps> = (props) => {
  const { id } = useParams();

  const [dataLoading, setDataLoading] = useState(false);
  const [requestDetails, setRequestDetails] = useState(requestDetailsInit);

  useEffect(() => {
    refreshDataCall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshDataCall = () => {
    setDataLoading(true);
    API.getContractRequestByIdAdmin(id).then((response) => {
      setDataLoading(false);
      setRequestDetails(response.request);
    });
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Contract Request Details" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Contract Request Details" />

          <Box sx={{ ml: 1 }}>
            <CUIPresets.LabelValueHorizontal
              sx={{ mt: 1 }}
              label={"Creator Name"}
              value={requestDetails.entityDetails?.creator?.fullName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Content Name"}
              value={
                requestDetails.entityDetails?.content?.contentDetails?.name
              }
            />

            <CUIPresets.LabelValueHorizontal
              label={"Advertiser Name"}
              value={requestDetails.entityDetails?.advertiser?.fullName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Raised Date"}
              value={DateFunctions.format(requestDetails.createdAt)}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Ad Slot Details"
            />
            <CUIPresets.LabelValueHorizontal
              label={"Country"}
              value={Information.retrieveCountry(
                requestDetails.adSlotDetails?.selectedCountryCode
              )}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Ad Slot"}
              value={requestDetails.adSlotDetails?.selectedAdSlot}
            />
            <CUIPresets.LabelIframeHorizontal
              label={"Ad Preview"}
              iframe={
                requestDetails.adSlotDetails?.selectedAdFile?.bunnyStreamLink
              }
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Contract Terms"
            />
            <CUIPresets.LabelValueHorizontal
              label={"Type Of Contract"}
              value={requestDetails.contractTerms?.typeOfContract}
            />
            <CUIPresets.LabelValueHorizontal
              label={
                requestDetails.contractTerms?.typeOfContract === "FIX"
                  ? "Amount"
                  : "Rate"
              }
              value={requestDetails.contractTerms?.rateOramount}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Contract Duration"}
              value={requestDetails.contractTerms.duration + " Days"}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Response"
            />

            <CUIPresets.LabelValueHorizontal
              label={"Status"}
              value={requestDetails.status}
            />
          </Box>
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default CreatorRequestDetails;
