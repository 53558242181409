// React Imports
import React, { FC } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";

interface AdvertiserHomeProps {}

const AdvertiserHome: FC<AdvertiserHomeProps> = (props) => {
  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 16,
          }}
          text="Creators"
        />

        <CUIPresets.TitleDescription
          title="Search Creators"
          description="You can search the creators and send them contract requests using this feature."
        />

        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 32,
          }}
          text="Ad Catalogue"
        />
        <CUIPresets.TitleDescription
          title="Upload New Ad"
          description={
            "You can upload new video file which can be used as an ad."
          }
        />
        <CUIPresets.TitleDescription
          title="Manage Ad Files"
          description={
            "List of all the ad files uploaded and ability to delete the ad file"
          }
        />

        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 32,
          }}
          text="Funds Management"
        />
        <CUIPresets.TitleDescription
          title="Funds Overview"
          description={
            "List of all changes in funds from adding and withdrawing funds, also ability to add or withdraw funds"
          }
        />

        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 32,
          }}
          text="Request Management"
        />
        <CUIPresets.TitleDescription
          title="Open Requests"
          description={
            "List of all the requests which you've raised and yet to responded by content creators"
          }
        />
        <CUIPresets.TitleDescription
          title="Other Requests"
          description={
            "List of all the expired and rejected contract requests."
          }
        />

        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 32,
          }}
          text="Contracts"
        />
        <CUIPresets.TitleDescription
          title="Contracts"
          description={
            "Ability to view all the contracts by creator, date and status."
          }
        />
        <CUIPresets.TitleDescription
          title="Transactions"
          description={"List of all transactions from advertiser to creators from your account."}
        />

        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 32,
          }}
          text="Profile"
        />
        <CUIPresets.TitleDescription
          title="Profile Info"
          description={"To view profile info and ability to update fullname."}
        />
        <CUIPresets.TitleDescription
          title="Change Password"
          description={
            "Ability to change password of your account using your old account password."
          }
        />
        <CUIPresets.TitleDescription
          title="Logout"
          description={"Ability to log out of the current accout."}
        />
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdvertiserHome;
