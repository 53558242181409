// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import {
  DateFunctions,
  Information,
  ToastGenerator,
} from "../../utils/Functions";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../api/API";
import Constants from "../../utils/Constants";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";

const requestDetailsInit = {
  entityDetails: {
    advertiser: { fullName: "" },
    creator: {
      fullName: "",
    },
    content: {
      contentDetails: {
        name: "",
      },
      masterFile: {
        bunnyStreamLink: "",
      },
    },
  },
  adSlotDetails: {
    selectedCountryCode: "",
    selectedAdSlot: "",
    selectedAdFile: {
      adFileName: "",
      bunnyStreamLink: "",
    },
  },
  contractTerms: {
    typeOfContract: "",
    duration: 0,
    rateOramount: 0,
  },
  status: "",
  createdAt: "",
};

interface CreatorRequestDetailsProps {}

const CreatorRequestDetails: FC<CreatorRequestDetailsProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState(false);
  const [requestDetails, setRequestDetails] = useState(requestDetailsInit);

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
    response: "",
  });

  useEffect(() => {
    refreshDataCall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshDataCall = () => {
    setDataLoading(true);
    API.getRequestByIdCreator(id).then((response) => {
      setDataLoading(false);
      setRequestDetails(response.requestData);
    });
  };

  const confirmModalCall = () => {
    const payload = {
      response:
        modal.response === "accept"
          ? Constants.STATUS_FLAGS.CONTRACT_REQUEST.ACCEPTED
          : Constants.STATUS_FLAGS.CONTRACT_REQUEST.REJECTED,
    };

    setModal({
      open: true,
      apiLoading: true,
      response: "",
    });

    API.creatorResponseRequest(id, payload).then((response) => {
      if (
        payload.response === "accepted" &&
        response.message === Constants.API_RESPONSES.CONTRACT_CREATE
      ) {
        ToastGenerator.success(response.message);
        setModal({
          open: false,
          apiLoading: false,
          response: "",
        });
        navigate(-1);
      } else if (
        payload.response === "rejected" &&
        response.message === Constants.API_RESPONSES.REQUEST_REJECT
      ) {
        ToastGenerator.success(response.message);
        setModal({
          open: false,
          apiLoading: false,
          response: "",
        });
        navigate(-1);
      }
    });
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Request Details" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Request Details" />

          <Box sx={{ ml: 1 }}>
            <CUIPresets.LabelValueHorizontal
              sx={{ mt: 1 }}
              label={"Creator Name"}
              value={requestDetails.entityDetails?.creator?.fullName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Content Name"}
              value={
                requestDetails.entityDetails?.content?.contentDetails?.name
              }
            />
            <CUIPresets.LabelIframeHorizontal
              label={"Content Preview"}
              iframe={
                requestDetails.entityDetails?.content?.masterFile
                  ?.bunnyStreamLink
              }
            />

            <CUIPresets.LabelValueHorizontal
              label={"Advertiser Name"}
              value={requestDetails.entityDetails?.advertiser?.fullName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Raised Date"}
              value={DateFunctions.format(requestDetails.createdAt)}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Ad Slot Details"
            />
            <CUIPresets.LabelValueHorizontal
              label={"Country"}
              value={Information.retrieveCountry(
                requestDetails.adSlotDetails?.selectedCountryCode
              )}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Ad Slot"}
              value={requestDetails.adSlotDetails?.selectedAdSlot}
            />
            <CUIPresets.LabelIframeHorizontal
              label={"Ad Preview"}
              iframe={
                requestDetails.adSlotDetails?.selectedAdFile?.bunnyStreamLink
              }
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Contract Terms"
            />
            <CUIPresets.LabelValueHorizontal
              label={"Type Of Contract"}
              value={requestDetails.contractTerms?.typeOfContract}
            />
            <CUIPresets.LabelValueHorizontal
              label={
                requestDetails.contractTerms?.typeOfContract === "FIX"
                  ? "Amount"
                  : "Rate"
              }
              value={requestDetails.contractTerms?.rateOramount}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Contract Duration"}
              value={requestDetails.contractTerms.duration + " Days"}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Response"
            />

            {requestDetails.status !==
              Constants.STATUS_FLAGS.CONTRACT_REQUEST.RAISED && (
              <CUIPresets.LabelStatus
                chipSelect={"contractRequest"}
                status={requestDetails.status}
              />
            )}

            {requestDetails.status ===
              Constants.STATUS_FLAGS.CONTRACT_REQUEST.RAISED && (
              <>
                <CUIPresets.FilledButton
                  color={Colors.red}
                  sx={{
                    width: "180px",
                    marginRight: 4,
                    marginBottom: 6,
                  }}
                  title="Reject"
                  onClick={() =>
                    setModal({
                      open: true,
                      apiLoading: false,
                      response: "reject",
                    })
                  }
                />
                <CUIPresets.FilledButton
                  color={Colors.green}
                  sx={{
                    width: "180px",
                    marginBottom: 6,
                  }}
                  title="Accept"
                  onClick={() =>
                    setModal({
                      open: true,
                      apiLoading: false,
                      response: "accept",
                    })
                  }
                />
              </>
            )}
          </Box>

          <CUIPresets.CustomDialog
            title="Contract Request"
            content={[
              "Are you sure about " +
                (modal.response === "accept" ? "accepting" : "rejecting") +
                " this request?",
            ]}
            confirmText={"Confirm"}
            cancelText="Cancel"
            confirmCall={confirmModalCall}
            modalOpen={modal.open}
            cancelCall={() =>
              setModal({
                open: false,
                apiLoading: false,
                response: "",
              })
            }
            apiLoading={modal.apiLoading}
          />
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default CreatorRequestDetails;
