// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box, Grid } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import RoutePath from "../../routes/RoutePath";

// Local Imports
import SideBar from "./SideBar";
import TopBar from "./TopBar";

const HolderStyles = {
  height: "80vh",
  width: "91%",
  mt: 4,
  ml: 4,
  pb: 2,
  pl: 4,
  boxShadow: Styles.SStyles.elevation,
  backgroundColor: Colors.white,
  borderRadius: 3,
  overflowY: "scroll",
};

interface DashboardProps {}

const Creator: FC<DashboardProps> = (props) => {
  return (
    <Grid container height="100vh">
      <Grid item xs={3}>
        <SideBar.Creator />
      </Grid>
      <Grid item xs={9} sx={{ backgroundColor: Colors.lightBlueBackground2 }}>
        <TopBar />

        <Box sx={HolderStyles}>
          <RoutePath.Creator />
        </Box>
      </Grid>
    </Grid>
  );
};

const Advertiser: FC<DashboardProps> = (props) => {
  return (
    <Grid container height="100vh">
      <Grid item xs={3}>
        <SideBar.Advertiser />
      </Grid>
      <Grid item xs={9} sx={{ backgroundColor: Colors.lightBlueBackground2 }}>
        <TopBar />

        <Box sx={HolderStyles}>
          <RoutePath.Advertiser />
        </Box>
      </Grid>
    </Grid>
  );
};

const Admin: FC<DashboardProps> = (props) => {
  return (
    <Grid container height="100vh">
      <Grid item xs={3}>
        <SideBar.Admin />
      </Grid>
      <Grid item xs={9} sx={{ backgroundColor: Colors.lightBlueBackground2 }}>
        <TopBar />

        <Box sx={HolderStyles}>
          <RoutePath.Admin />
        </Box>
      </Grid>
    </Grid>
  );
};

const Dashboard = {
  Creator,
  Advertiser,
  Admin,
};

export default Dashboard;
