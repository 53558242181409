// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import Images from "../../utils/Images";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTools from "../../atoms/CTools";
import ChangePassword from "./ChangePassword";
import ProfileInfo from "./ProfileInfo";

interface ViewerProfileProps {}

const ViewerProfile: FC<ViewerProfileProps> = (props) => {
  const [profileInfoOrChangePassword, setProfileInfoOrChangePassword] =
    useState(true);

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          backgroundColor: Colors.blackBackground,
          height: "100vh",
          width: "100%",
        }}
      >
        <CUIPresets.ViewerTopBar />

        <Box
          sx={{
            width: "100%",
            height: window.innerHeight - 80,
            position: "relative",
            overflow: "hidden",
            ...Styles.SStyles.RowCenterCenter,
          }}
        >
          {profileInfoOrChangePassword && (
            <ProfileInfo
              setProfileInfoOrChangePassword={setProfileInfoOrChangePassword}
            />
          )}
          {!profileInfoOrChangePassword && (
            <ChangePassword
              setProfileInfoOrChangePassword={setProfileInfoOrChangePassword}
            />
          )}
          <CTools.ImageContainer
            sx={{
              position: "absolute",
              top: -100,
              left: -100,
            }}
            imageBgColor={null}
            src={Images.BlueEllipse}
          />
          <CTools.ImageContainer
            sx={{
              position: "absolute",
              bottom: -100,
              right: -100,
              overflow: "hidden",
            }}
            imageBgColor={null}
            src={Images.PurpleEllipse}
          />
        </Box>
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default ViewerProfile;
