// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";
import {} from "iconsax-react";

// Functional Imports
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import Constants from "../../utils/Constants";
import ACTION_TYPES from "../../redux/actions/actionTypes";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CComposite from "../../atoms/CComposite";
import TrendingContent from "./TrendingContent";
import TrendingSpaces from "./TrendingSpaces";

interface ViewerTrendingProps {}

const ViewerTrending: FC<ViewerTrendingProps> = (props) => {
  const dispatch = useDispatch();

  const selectedOption = useSelector(
    (state: any) => state.appState.subscriptionsOptionSelected
  );
  const filter = useSelector((state: any) => state.appState.trendingFilter);

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          backgroundColor: Colors.blackBackground2,
          height: "100vh",
          width: "100vw",
        }}
      >
        <CUIPresets.ViewerTopBar
          options={[
            Constants.TYPES.TRENDING_SUBSCRIPTION.CONTENT,
            Constants.TYPES.TRENDING_SUBSCRIPTION.SPACES,
          ]}
          selected={selectedOption}
          setSelected={(e: any) => {
            dispatch({
              type: ACTION_TYPES.SUBSCRIPTIONS_OPTION_SELECTED,
              payload: e,
            });
          }}
        />

        <Box
          sx={{
            height: 56,
            width: "100vw",
            backgroundColor: Colors.blackBackground4,
            ...Styles.SStyles.RowCenterSpace,
          }}
        >
          <CComposite.FilterSettings
            options={[
              Constants.TYPES.TRENDING_FILTER.DAY,
              Constants.TYPES.TRENDING_FILTER.WEEK,
              Constants.TYPES.TRENDING_FILTER.MONTH,
            ]}
            filter={filter}
            setFilter={(e: any) => {
              dispatch({ type: ACTION_TYPES.TRENDING_FILTER, payload: e });
            }}
            sx={{
              ml: 2,
            }}
          />
          <CUIPresets.GridSelection />
        </Box>

        <Box
          sx={{
            ml: 4,
            overflowY: "scroll",
            height: window.innerHeight - 140,
          }}
        >
          {selectedOption === "Content" && <TrendingContent filter={filter} />}
          {selectedOption === "Spaces" && <TrendingSpaces filter={filter} />}
        </Box>
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default ViewerTrending;
