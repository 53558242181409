// React Imports
import React, { FC, useEffect } from "react";

// MUI Imports
import { Grid, Box } from "@mui/material";

// Functional Imports
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import Colors from "../../utils/Colors";
import Arrays from "../../utils/Arrays";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";
import CText from "../../atoms/CText";

const initValues = {
  fullName: "",
  userName: "",
  email: "",
  password: "",
  type: "",
};

interface RegisterProps {
  setLoginOrRegister?: any;
}

const Register: FC<RegisterProps> = (props) => {
  const { type } = useParams();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({
      fullName: yup.string().required("Name is required"),
      userName: yup.string().required("Username is required"),
      email: yup
        .string()
        .required("Email is required")
        .email("Enter Valid Email"),
      password: yup.string().required("Enter password"),
      type: yup.string().required("Select Type"),
    }),
    onSubmit: (values: any) => {
      API.registerClientAccount(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue("type", type);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      item
      xs={12}
      md={5}
      paddingLeft={5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        height: "100vh",
        overflowY: "scroll",
        backgroundColor: Colors.lightBlueBackground,
      }}
    >
      <CUIPresets.AnimationWrapper>
        <Box width={470}>
          <CText.Jakarta.Size32.Bold.Black
            style={{
              marginTop: 24,
              width: 420,
            }}
            text="Register"
          />

          <CInputOutput.LabelInputFilled
            sx={{ width: 420, mt: 4 }}
            label="Name"
            textFieldProps={{
              placeholder: "Enter your full name",
              helperText: formik.touched.fullName && formik.errors.fullName,
              error: Boolean(formik.touched.fullName && formik.errors.fullName),
              onBlur: formik.handleBlur,
              onChange: formik.handleChange,
              value: formik.values.fullName,
              name: "fullName",
            }}
          />

          <CInputOutput.LabelInputFilled
            sx={{ width: 420, mt: 4 }}
            label="Email"
            textFieldProps={{
              placeholder: "Enter your email",
              helperText: formik.touched.email && formik.errors.email,
              error: Boolean(formik.touched.email && formik.errors.email),
              onBlur: formik.handleBlur,
              onChange: formik.handleChange,
              value: formik.values.email,
              name: "email",
            }}
          />

          <CInputOutput.LabelInputFilled
            sx={{ width: 420, mt: 4 }}
            label="Username"
            textFieldProps={{
              placeholder: "Enter your user name",
              helperText: formik.touched.userName && formik.errors.userName,
              error: Boolean(formik.touched.userName && formik.errors.userName),
              onBlur: formik.handleBlur,
              onChange: formik.handleChange,
              value: formik.values.userName,
              name: "userName",
            }}
          />

          <CInputOutput.LabelInputFilledPassword
            sx={{ width: 420, mt: 4 }}
            label="Password"
            textFieldProps={{
              placeholder: "Enter your password",
              helperText: formik.touched.password && formik.errors.password,
              error: Boolean(formik.touched.password && formik.errors.password),
              onBlur: formik.handleBlur,
              onChange: formik.handleChange,
              value: formik.values.password,
              name: "password",              
            }}
          />

          <CInputOutput.SelectBoxFilled
            label="Type of User"
            placeholder="Select Type of User"
            sx={{
              width: 430,
              mt: 3,
              height: 70,
              marginBottom: 2.5,
            }}
            error={formik.touched.type && formik.errors.type}
            options={Arrays.TypeOfUser}
            xsKey="typeId"
            xsValue="type"
            selected={formik.values.type}
            setValue={(e: any) => {
              formik.setFieldValue("type", e.target.value);
            }}
          />

          <CUIPresets.FilledButton
            onClick={formik.handleSubmit}
            title="Register"
            sx={{
              width: 430,
              mt: 3,
            }}
          />

          <CUIPresets.OrBox sx={{ mt: 3, width: 430 }} />

          <CUIPresets.OutlinedButton
            title="Login"
            onClick={() => props.setLoginOrRegister("login")}
            sx={{
              width: 430,
              mt: 3,
              mb: 5,
            }}
          />
        </Box>
      </CUIPresets.AnimationWrapper>
    </Grid>
  );
};

export default Register;
