// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useParams } from "react-router-dom";
import API from "../../api/API";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";

interface AdminCreatorSubspaceDetailsProps {}

const AdminCreatorSubspaceDetails: FC<AdminCreatorSubspaceDetailsProps> = (
  props
) => {
  const { id } = useParams();

  const [dataLoading, setDataLoading] = useState(false);
  const [subspaceDetails, setSubspaceDetails]: any = useState([]);

  useEffect(() => {
    setDataLoading(true);
    API.getSubspaceByIdAdmin(id).then((response) => {
      setSubspaceDetails(response.subspace);
      setDataLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Subspace Details" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <Box
            sx={{
              width: 800,
              ...Styles.SStyles.RowCenterSpace,
            }}
          >
            <CUIPresets.PageHeader title="Subspace Details" />
            <Box
              sx={{
                ...Styles.SStyles.RowCenterCenter,
              }}
            ></Box>
          </Box>

          <CUIPresets.LabelValueHorizontal
            label={"Name of Subspace"}
            value={subspaceDetails.subSpaceName}
          />
          <CUIPresets.LabelValueHorizontal
            label={"Description"}
            value={subspaceDetails.description}
          />
          {subspaceDetails.contents?.map((item: any, index: number) => (
            <CTableRow.SubspaceContentRow
              index={index + 1}
              title={item.contentDetails?.name}
              image={item.contentDetails?.coverPhoto}
            />
          ))}
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdminCreatorSubspaceDetails;
