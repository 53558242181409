import CustomDialog from "./CustomDialog";
import FooterActions from "./FooterActions";
import FilterSettings from "./FilterSettings";
import SpacesList from "./SpacesList";
import ContentListSubspace from "./ContentListSubspace";
import ContentList from "./ContentList";
import MySpaceTile from "./MySpaceTile";
import SearchDateBar from "./SearchDateBar";
import DateModal from "./DateModal";
import DatePicker from "./DatePicker";

const CComposite = {
  CustomDialog,
  FooterActions,
  FilterSettings,
  SpacesList,
  ContentListSubspace,
  ContentList,
  MySpaceTile,
  SearchDateBar,
  DateModal,
  DatePicker,
};

export default CComposite;
