// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box } from "@mui/material";
import {} from "iconsax-react";

// Functional Imports
import { DateFunctions } from "../../utils/Functions";
import { useParams } from "react-router-dom";
import API from "../../api/API";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import RightSection from "./RightSection";

interface ViewerPlayerProps {}

const ViewerPlayer: FC<ViewerPlayerProps> = (props) => {
  const { contentid = "", subspaceid = "" } = useParams();

  const [dataLoading, setDataLoading] = useState(false);

  const [contentDetails, setContentDetails] = useState({
    contentId: "",
    contentName: "",
    streamLink: "",
    savedContent: false,
    watchLater: false,
    duration: 0,
  });
  const [spaceDetails, setSpaceDetails] = useState({
    backgroundImage: "",
    profileImage: "",
    description: "",
    spaceId: "",
  });
  const [suggestions, setSuggestions] = useState([]);
  const [contentActions, setContentActions] = useState({
    previous: "",
    next: "",
    subspaceId: "",
  });

  useEffect(() => {
    setDataLoading(true);
    API.getContentForViewer(contentid).then((response) => {
      setDataLoading(false);
      setContentDetails({
        contentId: contentid,
        contentName: response.contentViewerDetails?.contentTitle,
        streamLink: response.contentViewerDetails?.streamLink,
        watchLater: response.contentViewerDetails?.contentFlags?.watchLater,
        savedContent: response.contentViewerDetails?.contentFlags?.saved,
        duration: DateFunctions.duratonInSeconds(
          response.contentViewerDetails?.duration
        ),
      });
      setSpaceDetails({
        spaceId: response.contentViewerDetails?.spaceDetails?._id,
        backgroundImage:
          response.contentViewerDetails?.spaceDetails?.configuration
            ?.backgroundImage,
        profileImage:
          response.contentViewerDetails?.spaceDetails?.configuration
            ?.profileImage,
        description:
          response.contentViewerDetails?.spaceDetails?.configuration
            ?.description,
      });
      setSuggestions(response.contentViewerDetails?.suggestions);
    });
    if (subspaceid !== "0") {
      API.getSubspaceForViewer(subspaceid).then((response) => {
        const contentIdArr = response.subSpaceDetails?.contents?.map(
          (item: any) => item._id
        );

        const indexOfCurrentContent = contentIdArr.indexOf(contentid);

        let previousContentId = "";
        let nextContentId = "";

        if (indexOfCurrentContent !== 0) {
          previousContentId = contentIdArr[indexOfCurrentContent - 1];
        }

        if (indexOfCurrentContent !== contentIdArr.length - 1) {
          nextContentId = contentIdArr[indexOfCurrentContent + 1];
        }

        setContentActions({
          previous: previousContentId,
          next: nextContentId,
          subspaceId: subspaceid,
        });
      });
    } else {
      setContentActions({
        previous: "",
        next: "",
        subspaceId: subspaceid,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentid, subspaceid]);

  useEffect(() => {
    const startTime = new Date();

    return () => {
      const endTime = new Date();

      const watchtimeInSeconds =
        (endTime.getTime() - startTime.getTime()) / 1000;

      if (watchtimeInSeconds > 10) {
        let tempWatchTime = 0;

        if (watchtimeInSeconds > contentDetails.duration * 1.1) {
          tempWatchTime = contentDetails.duration * 1.1;
        } else {
          tempWatchTime = watchtimeInSeconds;
        }

        const payload = {
          id: contentid,
          watchTime: Math.ceil(Number(tempWatchTime)),
        };

        API.manageViewsAndWatchtime(payload).then((response) => {
          // REMOVE AFTER DEV
          console.dir(response);
        });

        if (subspaceid !== "0") {
          const continueWatchingPayload = {
            contentId: contentid,
            subSpaceId: subspaceid,
          };

          API.updateContinueWatching(continueWatchingPayload).then(
            (response) => {
              // REMOVE AFTER DEV
              console.dir(response);
            }
          );
        }
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentDetails]);

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          backgroundColor: Colors.blackBackground2,
          height: "100vh",
          width: "100%",
        }}
      >
        <CUIPresets.ViewerTopBar confirmAction={true} />
        {dataLoading && <CUIPresets.ViewerLoading sx={{ mt: 18 }} />}
        {!dataLoading && (
          <Box
            sx={{
              width: "95%",
              height: "80%",
              m: 4,
              ...Styles.SStyles.RowCenterSpace,
            }}
          >
            <Box
              sx={{
                width: "60%",
                height: "95%",
                m: 4,
              }}
            >
              <CUIPresets.ViewerContentPlayer
                streamLink={contentDetails.streamLink}
                style={{
                  marginLeft: 24,
                  maxHeight: 500,
                }}
              />

              <CText.Jakarta.Size24.Mid.Grey1
                style={{
                  marginTop: 16,
                  marginLeft: 24,
                }}
                text={contentDetails.contentName}
              />
            </Box>

            <Box
              sx={{
                width: "35%",
                height: "95%",
                m: 4,
              }}
            >
              <RightSection
                contentDetails={contentDetails}
                spaceDetails={spaceDetails}
                suggestions={suggestions}
                contentActions={contentActions}
                setContentDetails={setContentDetails}
              />
            </Box>
          </Box>
        )}
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default ViewerPlayer;
