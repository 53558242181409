// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import * as yup from "yup";
import { useFormik } from "formik";
import { ToastGenerator } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";
import CComposite from "../../atoms/CComposite";
import CText from "../../atoms/CText";

const initValues = {
  txnId: "",
  senderAddress: "",
  image: {
    name: "",
    file: null,
    size: "",
  },
  dateOfTransaction: null,
};

interface RaisedStatusProps {
  requestDetails?: any;
}

const RaisedStatus: FC<RaisedStatusProps> = (props) => {
  const navigate = useNavigate();

  const [modeOfPayment, setModeOfPayment] = useState(
    Constants.TYPES.MODE_OF_PAYMENTS.BANK
  );
  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  useEffect(() => {
    setModeOfPayment(props.requestDetails?.requestDetails?.modeOfPayment);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({
      txnId: yup.string().required("Txn ID is required"),
      senderAddress: yup.string().required("Address is required"),
      dateOfTransaction: yup
        .string()
        .required("Date of Transaction is required"),
    }),
    onSubmit: (values: any) => {
      if (values.image.file === null) {
        ToastGenerator.info("Upload Proof");
        return;
      }

      setModal({
        open: true,
        apiLoading: false,
      });
    },
  });

  const confirmModalCall = () => {
    setModal({
      open: true,
      apiLoading: true,
    });
    API.uploadProofAddFunds(props.requestDetails?._id, formik.values).then(
      (response) => {
        if (response.message === Constants.API_RESPONSES.UPLOAD_PROOF_SUCCESS) {
          ToastGenerator.success(response.message);
          setModal({
            open: false,
            apiLoading: false,
          });
          navigate(-1);
        }
      }
    );
  };

  return (
    <Box>
      <CUIPresets.LabelValueHorizontal
        label={"Amount"}
        value={props.requestDetails?.requestDetails?.amountInSSC + " SSC"}
      />

      <CUIPresets.LabelValueHorizontal
        label={"Currency"}
        value={props.requestDetails?.requestDetails?.currency}
      />

      <CUIPresets.LabelValueHorizontal
        label={"Amount in " + props.requestDetails?.requestDetails?.currency}
        value={props.requestDetails?.requestDetails?.amountInCurrency}
      />

      <CUIPresets.LabelValueHorizontal
        label={"Mode of Payment"}
        value={props.requestDetails?.requestDetails?.modeOfPayment}
      />

      <CText.Roboto.Size28.Light.BlackUnderline
        style={{
          marginBottom: 16,
          marginTop: 32,
        }}
        text="Payment"
      />

      {modeOfPayment === Constants.TYPES.MODE_OF_PAYMENTS.UPI && (
        <>
          <CText.Jakarta.Size18.Light.Grey1
            text="Make your payment to the following UPI address"
            style={{
              marginTop: 16,
              marginBottom: 16,
            }}
          />

          <CUIPresets.LabelValueHorizontal
            label={"UPI ID"}
            value="7795180292@ybl"
          />
        </>
      )}

      {modeOfPayment === Constants.TYPES.MODE_OF_PAYMENTS.BANK && (
        <>
          <CText.Jakarta.Size18.Light.Grey1
            text="Make your payment to the following Bank Account"
            style={{
              marginTop: 16,
              marginBottom: 16,
            }}
          />

          <CUIPresets.LabelValueHorizontal
            label={"Account Holder Name"}
            value="Basavachetan Mathapati"
          />

          <CUIPresets.LabelValueHorizontal
            label={"Account Number"}
            value="847276487434"
          />

          <CUIPresets.LabelValueHorizontal
            label={"IFSC Code"}
            value="SBIN0001729"
          />
        </>
      )}

      {modeOfPayment === Constants.TYPES.MODE_OF_PAYMENTS.PAYPAL && (
        <>
          <CText.Jakarta.Size18.Light.Grey1
            text="Make your payment to the following paypal address"
            style={{
              marginTop: 16,
              marginBottom: 16,
            }}
          />

          <CUIPresets.LabelValueHorizontal
            label={"Paypal Address"}
            value="paypal@username"
          />
        </>
      )}

      <CText.Roboto.Size28.Light.BlackUnderline
        style={{
          marginBottom: 16,
          marginTop: 32,
        }}
        text="Proof of Payment"
      />

      <CText.Jakarta.Size18.Light.Grey1
        text="Upload a screenshot of the payment."
        style={{
          marginTop: 16,
          marginBottom: 16,
        }}
      />

      <CInputOutput.SelectImage
        title={"Upload Proof"}
        setImage={(ev: any) => {
          formik.setFieldValue("image", {
            file: ev,
            name: ev.name,
            size: Number(ev.size) / (1024 * 1024),
          });
        }}
      />

      <CUIPresets.SelectedFile
        imageExists={formik.values.image.file}
        fileName={formik.values.image.name}
        fileSize={formik.values.image.size}
      />

      <CText.Roboto.Size28.Light.BlackUnderline
        style={{
          marginBottom: 16,
          marginTop: 32,
        }}
        text="Transaction Details"
      />

      <CInputOutput.LabelInputOutlined
        sx={{ width: 400, mt: 1 }}
        label="Transaction ID"
        textFieldProps={{
          placeholder: "Enter Txn ID",
          helperText: formik.touched.txnId && formik.errors.txnId,
          error: Boolean(formik.touched.txnId && formik.errors.txnId),
          onBlur: formik.handleBlur,
          onChange: formik.handleChange,
          value: formik.values.txnId,
          name: "txnId",
        }}
      />

      <CInputOutput.LabelInputOutlined
        sx={{ width: 400, mt: 2 }}
        label={
          modeOfPayment === Constants.TYPES.MODE_OF_PAYMENTS.BANK
            ? "Sender's Account Number"
            : modeOfPayment === Constants.TYPES.MODE_OF_PAYMENTS.PAYPAL
            ? "Sender's Paypal ID"
            : "Sender's UPI ID"
        }
        textFieldProps={{
          placeholder: "Enter Address",
          helperText:
            formik.touched.senderAddress && formik.errors.senderAddress,
          error: Boolean(
            formik.touched.senderAddress && formik.errors.senderAddress
          ),
          onBlur: formik.handleBlur,
          onChange: formik.handleChange,
          value: formik.values.senderAddress,
          name: "senderAddress",
        }}
      />

      <CInputOutput.DatePicker.Vertical
        label="Date of Transaction"
        value={formik.values.dateOfTransaction}
        touched={Boolean(formik.touched.dateOfTransaction)}
        setValue={(newDate: any) =>
          formik.setFieldValue("dateOfTransaction", newDate)
        }
        sx={{ mr: 1 }}
        error={formik.errors.dateOfTransaction}
      />

      <CComposite.FooterActions.Filled
        onClick={() => formik.handleSubmit()}
        sx={{
          mt: 4,
          mb: 4,
          width: 760,
        }}
        confirmText={"Upload Proof"}
      />

      <CUIPresets.CustomDialog
        title="Upload Proof"
        content={["Are you sure about uploading this proof?"]}
        confirmText={"Confirm"}
        cancelText="Cancel"
        confirmCall={confirmModalCall}
        modalOpen={modal.open}
        cancelCall={() =>
          setModal({
            open: false,
            apiLoading: false,
          })
        }
        apiLoading={modal.apiLoading}
      />
    </Box>
  );
};

export default RaisedStatus;
