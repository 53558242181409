// React Imports
import React, { FC } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";

interface CreatorHomeProps {}

const CreatorHome: FC<CreatorHomeProps> = (props) => {
  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 16,
          }}
          text="Advertisements"
        />

        <CUIPresets.TitleDescription
          title="Open Requests"
          description="List of all the requests sent by advertisers on your content. You can either accept or reject these requests."
        />
        <CUIPresets.TitleDescription
          title="Other Requests"
          description={
            "List of all the requests sent by advertisers which have been expired or rejected."
          }
        />

        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 32,
          }}
          text="Content Management"
        />
        <CUIPresets.TitleDescription
          title="Create New Content"
          description={
            "To create new content from the existing unassigned master files."
          }
        />
        <CUIPresets.TitleDescription
          title="Manage Content"
          description={
            "List of all the contents created and other details such as contracts and suggestions."
          }
        />

        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 32,
          }}
          text="Master Files"
        />
        <CUIPresets.TitleDescription
          title="Upload New Video"
          description={
            "To upload a video from the computer to platform as a master file."
          }
        />
        <CUIPresets.TitleDescription
          title="Manage Master Files"
          description={
            "List of all the master files uploaded to platform and ability to delete unassigned master files."
          }
        />

        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 32,
          }}
          text="Creator Space"
        />
        <CUIPresets.TitleDescription
          title="Create New Subspace"
          description={
            "To create a new subspace with a name, description , list of content and the order of the content."
          }
        />
        <CUIPresets.TitleDescription
          title="Manage Subspaces"
          description={
            "List of all subspaces and ability to update or delete subspaces."
          }
        />
        <CUIPresets.TitleDescription
          title="Space Configuration"
          description={
            "To create space name, description, profile image and background image."
          }
        />

        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 32,
          }}
          text="Funds Management"
        />
        <CUIPresets.TitleDescription
          title="Transactions"
          description={
            "List of the transactions from advertisers to creators based on date and content."
          }
        />
        <CUIPresets.TitleDescription
          title="Contracts"
          description={"List of all contracts created with advertisers."}
        />

        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 32,
          }}
          text="Overview"
        />
        <CUIPresets.TitleDescription
          title="Overview"
          description={"To view total subscribers and last 30 days views."}
        />
        <CUIPresets.TitleDescription
          title="Content Overview"
          description={
            "List of content with views accumulated over last day, last week, last month and alltime. To view watchtime details accumulated over the same time."
          }
        />
        <CUIPresets.TitleDescription
          title="Space Overview"
          description={
            "List of views accumulated by your space in various countries over last day, last week, last month and alltime."
          }
        />

        <CText.Jakarta.Size20.Mid.Blue2
          style={{
            marginTop: 32,
          }}
          text="Profile"
        />
        <CUIPresets.TitleDescription
          title="Profile Info"
          description={"To view profile info and ability to update fullname."}
        />
        <CUIPresets.TitleDescription
          title="Change Password"
          description={
            "Ability to change password of your account using your old account password."
          }
        />
        <CUIPresets.TitleDescription
          title="Logout"
          description={"Ability to log out of the current accout."}
        />
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorHome;
