// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import { Eye, EyeSlash } from "iconsax-react";

// Functional Imports
import { LabelInputProps } from "./CInputOutput";

// Local Imports
import CText from "../CText";
import Colors from "../../utils/Colors";
import CTools from "../CTools";

const LabelInputLight: FC<LabelInputProps> = (props) => {
  return (
    <Box sx={{ mt: 2, width: "90%", ...props.sx }}>
      <CText.Jakarta.Size18.Mid.Grey1 text={props.label} />
      <TextField
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        onWheel={(event) => {
          event.preventDefault();
        }}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: Colors.white,
          },
          width: "100%",
          height: "50px",
          justifyContent: "center",
          paddingLeft: 1,
          borderRadius: "3px",
          borderWidth: 0,
          backgroundColor: Colors.greyBackground2,
          mt: 1,
          input: { color: Colors.white },
          ...props.textFieldSx,
        }}
        FormHelperTextProps={{ component: CTools.HelperText.Light }}
        {...props.textFieldProps}
      />
    </Box>
  );
};

const LabelInputLightPassword: FC<LabelInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box sx={{ mt: 2, width: "90%", ...props.sx }}>
      <CText.Jakarta.Size18.Mid.Grey1 text={props.label} />
      <TextField
        variant="standard"
        type={showPassword ? "text" : "password"}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                sx={{
                  mr: 1,
                }}
              >
                {!showPassword && <Eye color={Colors.greyText5} />}
                {showPassword && <EyeSlash color={Colors.greyText5} />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onWheel={(event) => {
          event.preventDefault();
        }}
        sx={{
          width: "100%",
          height: "50px",
          justifyContent: "center",
          paddingLeft: 1,
          borderRadius: "3px",
          borderWidth: 0,
          backgroundColor: Colors.greyBackground2,
          mt: 1,
          input: { color: Colors.white },
          ...props.textFieldSx,
        }}
        FormHelperTextProps={{ component: CTools.HelperText.Light }}
        {...props.textFieldProps}
      />
    </Box>
  );
};

const ViewerCInputOutput = {
  LabelInputLight,
  LabelInputLightPassword,
};

export default ViewerCInputOutput;
