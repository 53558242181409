// Library Imports
import { combineReducers } from "redux";

// Local Imports
import applicationReducer from "./applicationReducer";

const appReducer = combineReducers({
  appState: applicationReducer,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;
