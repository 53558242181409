// React Imports
import React, { FC } from "react";

// MUI Imports
import { ButtonBase } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import CText from "../CText";

// Local Imports

interface HorizontalOptionsTileProps {
  selected?: any;
  title?: any;
  setValue?: any;
}

const HorizontalOptionsTile: FC<HorizontalOptionsTileProps> = (props) => {
  return (
    <ButtonBase
      onClick={() => props.setValue(props.title)}
      sx={{
        flex: 1,
        height: 35,
        mx: 1,
        borderRadius: 1,
        backgroundColor: props.selected ? Colors.white : null,
        ...Styles.SStyles.RowCenterCenter,
      }}
    >
      {props.selected ? (
        <CText.Jakarta.Size16.SemiBold.Black text={props.title} />
      ) : (
        <CText.Jakarta.Size16.Light.Black text={props.title} />
      )}      
    </ButtonBase>
  );
};

const CUIRaw = {
  HorizontalOptionsTile,
};

export default CUIRaw;
