// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Grid } from "@mui/material";

// Functional Imports
import { DateFunctions } from "../../utils/Functions";
import { useParams } from "react-router-dom";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";

interface AdminViewerContentListProps {}

const AdminViewerContentList: FC<AdminViewerContentListProps> = (props) => {
  const { id, type } = useParams();

  const [dataLoading, setDataLoading] = useState(false);
  const [contentList, setContentList] = useState<any>([]);

  useEffect(() => {
    setDataLoading(true);
    if (type === "saved-content") {
      API.getSavedContentViewerAdmin(id).then((response) => {
        setDataLoading(false);
        const tempContentList: any = [];

        response.savedContent?.map((item: any) => {
          tempContentList.push({
            date: DateFunctions.format(item.createdAt),
            contentName: item.contentDetails?.contentDetails?.name,
            spaceId: item.contentDetails?.spaceId,
          });

          return 0;
        });

        setContentList([...tempContentList]);
      });
    } else if (type === "watch-later") {
      API.getWatchLaterViewerAdmin(id).then((response) => {
        setDataLoading(false);
        const tempContentList: any = [];

        response.watchLater?.map((item: any) => {
          tempContentList.push({
            date: DateFunctions.format(item.createdAt),
            contentName: item.watchLaterContent?.contentDetails?.name,
            spaceId: item.watchLaterContent?.spaceId,
          });

          return 0;
        });

        setContentList([...tempContentList]);
      });
    } else if (type === "history") {
      API.getHistoryViewerAdmin(id).then((response) => {
        setDataLoading(false);
        const tempContentList: any = [];

        response.history?.map((item: any) => {
          tempContentList.push({
            date: DateFunctions.format(item.createdAt),
            contentName: item.contentDetails?.contentDetails?.name,
            spaceId: item.contentDetails?.spaceId,
          });

          return 0;
        });

        setContentList([...tempContentList]);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let pageTitle = "";
  if (type === "saved-content") {
    pageTitle = "Saved Content";
  } else if (type === "history") {
    pageTitle = "History";
  } else if (type === "watch-later") {
    pageTitle = "Watch Later";
  }

  return (
    <>
      <CUIPresets.PageHeader title={pageTitle} />

      <Grid container>
        {dataLoading && <CUIPresets.LoadingSpinner />}
        {!dataLoading &&
          contentList.map((item: any) => (
            <CTableRow.ViewerAdminContentTile
              date={item.date}
              contentName={item.contentName}
              spaceId={item.spaceId}
            />
          ))}
        {!dataLoading && contentList.length === 0 && (
          <CTableRow.FlexTableRow
            sx={{ width: 320 }}
            contentArray={["No Data"]}
          />
        )}
      </Grid>
    </>
  );
};

export default AdminViewerContentList;
