// React Imports
import React, { FC } from "react";

// MUI Imports
import {  } from "@mui/material";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../CUIPresets";

interface SpacesListProps {
  grid?: any;
  spacesList?: any;
}

const SpacesList: FC<SpacesListProps> = (props) => {
  const navigate = useNavigate();

  if (props.grid === Constants.TYPES.VIEWER_DISPLAY.COMPACT) {
    return (
      <>
        {props.spacesList?.map((item: any) => (
          <CUIPresets.SmallSpaceTile
            displayImageSource={item.configuration?.profileImage}
            backgroundImageSource={item.configuration?.backgroundImage}
            creatorName={item.configuration?.name}
            onClick={() => navigate(PATHS.VIEWER_SPACE + "/" + item._id)}
          />
        ))}
      </>
    );
  } else if (props.grid === Constants.TYPES.VIEWER_DISPLAY.COMFORTABLE) {
    return (
      <>
        {props.spacesList?.map((item: any) => (
          <CUIPresets.LargeSpaceTile
            displayImageSource={item.configuration?.profileImage}
            backgroundImageSource={item.configuration?.backgroundImage}
            creatorName={item.configuration?.name}
            creatorDescription={item.configuration?.description}
            onClick={() => navigate(PATHS.VIEWER_SPACE + "/" + item._id)}
            sx={{ mt: 2 }}
          />
        ))}
      </>
    );
  } else return null;
};

export default SpacesList;
