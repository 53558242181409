// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";
import { ArchiveAdd, BoxTime, Eye } from "iconsax-react";

// Functional Imports
import Styles from "../../utils/Styles";
import Colors from "../../utils/Colors";
import Constants from "../../utils/Constants";

// Local Imports
import CText from "../../atoms/CText";
import CUIPresets from "../../atoms/CUIPresets";
import CTools from "../../atoms/CTools";

interface MySpaceTileProps {
  type?: any;
  image?: any;
  contentName?: any;
  spaceName?: any;
  date?: any;
  onClick?: any;
  contentId?: any;
  deleteSavedContent?: any;
}

const MySpaceTile: FC<MySpaceTileProps> = (props) => {
  const [loading, setLoading] = useState(false);

  let tileIcon = <></>;

  if (props.type === Constants.TYPES.MY_SPACE.HISTORY) {
    tileIcon = <Eye size="20" color={Colors.greyText7} />;
  } else if (props.type === Constants.TYPES.MY_SPACE.SAVED_CONTENT) {
    tileIcon = <ArchiveAdd size="20" color={Colors.greyText7} />;
  } else if (props.type === Constants.TYPES.MY_SPACE.WATCH_LATER) {
    tileIcon = <BoxTime size="20" color={Colors.greyText7} />;
  }

  return (
    <Box
      sx={{
        width: '40vw',
        height: '10vw',
        mt: 2,
        mr: '2vw',
        borderRadius: 2,
        backgroundColor: Colors.blackBackground4,
        ...Styles.SStyles.RowCenterSpace,
        px: 4,
      }}
    >
      <CTools.ImageContainer
        height={120}
        width={180}
        src={props.image}
        sx={{
          mr: 2,
        }}
      />
      <Box
        sx={{
          width:
            props.type === Constants.TYPES.MY_SPACE.SAVED_CONTENT
              ? "60%"
              : "70%",
          ml: 2,
        }}
      >
        <>
          <CText.Jakarta.Size18.SemiBold.White text={props.contentName} />
          <CText.Jakarta.Size16.Light.Cyan
            style={{
              marginTop: 16,
            }}
            text={props.spaceName}
          />

          <Box
            sx={{
              ...Styles.SStyles.RowCenterStart,
              mt: 2,
            }}
          >
            {tileIcon}
            <CText.Jakarta.Size14.Light.Grey7
              style={{
                marginLeft: 8,
              }}
              text={props.date}
            />
          </Box>
        </>
      </Box>

      {props.type === Constants.TYPES.MY_SPACE.SAVED_CONTENT && (
        <>
          {!loading && (
            <CUIPresets.RightArrowAvatarDelete
              sx={{
                mr: 2,
              }}
              onClick={() => {
                setLoading(true);
                props
                  .deleteSavedContent(props.contentId)
                  .then((response: any) => {
                    if (response) {
                      setLoading(false);
                    }
                  });
              }}
              iconSize={42}
            />
          )}
          {loading && (
            <CUIPresets.LoadingAction
              sx={{
                mr: 2,
              }}
              iconSize={42}
            />
          )}

          <CUIPresets.RightArrowAvatarLight
            onClick={props.onClick}
            iconSize={42}
          />
        </>
      )}
      {props.type !== Constants.TYPES.MY_SPACE.SAVED_CONTENT && (
        <CUIPresets.RightArrowAvatarLight
          onClick={props.onClick}
          iconSize={42}
        />
      )}
    </Box>
  );
};

export default MySpaceTile;
