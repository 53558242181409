// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box } from "@mui/material";
import {} from "iconsax-react";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { DateFunctions } from "../../utils/Functions";
import { PATHS } from "../../routes/Paths";
import moment from "moment";
import Constants from "../../utils/Constants";
import Arrays from "../../utils/Arrays";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";
import CInputOutput from "../../atoms/CInputOutput";

interface AdminPaymentRequestsListProps {}

const AdminPaymentRequestsList: FC<AdminPaymentRequestsListProps> = (props) => {
  const { type, id } = useParams();
  const navigate = useNavigate();

  const [typeOfDisplay, setTypeOfDisplay]: any = useState(
    Constants.TYPES.PAYMENT_REQUEST.DATE
  );
  const [dataLoading, setDataLoading] = useState(false);
  const [requestsList, setRequestsList]: any = useState([]);
  const [fullName, setFullName] = useState("-");
  const [dateValue, setDateValue] = useState(new Date());
  const [statusValue, setStatusValue] = useState(
    Constants.STATUS_FLAGS.CREATOR_PAYMENT_REQUESTS.RAISED
  );

  useEffect(() => {
    setTypeOfDisplay(type);
    setDataLoading(true);

    if (type === Constants.TYPES.PAYMENT_REQUEST.CREATOR) {
      API.getClientAccountById(id).then((response) => {
        setFullName(response.client.fullName);
      });

      API.getPaymentRequestsAdmin("client", id).then((response) => {
        populateData(response.paymentRequests);
        setDataLoading(false);
      });
    } else if (type === Constants.TYPES.PAYMENT_REQUEST.DATE) {
      API.getPaymentRequestsAdmin(
        "date",
        DateFunctions.format(new Date(), "YYYY-MM-DD")
      ).then((response) => {
        populateData(response.paymentRequests);
        setDataLoading(false);
      });
    } else if (type === Constants.TYPES.PAYMENT_REQUEST.FILTER) {
      API.getPaymentRequestsAdmin(
        "filter",
        Constants.STATUS_FLAGS.WITHDRAW_FUNDS_REQUEST.RAISED
      ).then((response) => {
        populateData(response.paymentRequests);
        setDataLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateData = (dataArray: any) => {
    let pushData: any = [];
    dataArray.map((item: any) => {
      pushData.push([
        type === Constants.TYPES.PAYMENT_REQUEST.CREATOR
          ? DateFunctions.format(item.requestDetails?.date)
          : item.creatorDetails?.fullName,
        item.requestDetails?.amountInSSC?.toString(),
        item.requestDetails?.currency,
        <CUIPresets.ChipSelect.AdvertiserFunds status={item.status} />,
        <CUIPresets.RightArrowAvatarWhite
          sx={{ ml: 2 }}
          onClick={() =>
            navigate(PATHS.ADMIN_PAYMENT_REQUEST_DETAILS + "/" + item._id)
          }
        />,
      ]);

      return 0;
    });
    setRequestsList(pushData);
  };

  const dateUpdate = (newDate: any) => {
    setDateValue(newDate);
    setDataLoading(true);
    API.getPaymentRequestsAdmin(
      "date",
      DateFunctions.format(newDate, "YYYY-MM-DD")
    ).then((response) => {
      populateData(response.paymentRequests);
      setDataLoading(false);
    });
  };

  const statusUpdate = (newStatus: any) => {
    setStatusValue(newStatus);
    setDataLoading(true);
    API.getPaymentRequestsAdmin("filter", newStatus).then((response) => {
      populateData(response.paymentRequests);
      setDataLoading(false);
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Payment Requests" />

        <Box
          sx={{
            mt: 2,
            width: 800,
            mb: 1,
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          {typeOfDisplay === Constants.TYPES.PAYMENT_REQUEST.DATE && (
            <>
              <CUIPresets.LabelValueHorizontal
                labelSx={{ width: 90 }}
                label={"Date :"}
                value={DateFunctions.format(dateValue)}
              />

              <CInputOutput.DatePicker.Horizontal
                label=""
                value={moment(dateValue)}
                setValue={(newDate: any) => dateUpdate(newDate)}
                sx={{ mr: 1, mt: 0, mb: 2 }}
                innerSx={{ width: 200 }}
              />
            </>
          )}

          {typeOfDisplay === Constants.TYPES.PAYMENT_REQUEST.FILTER && (
            <>
              <CUIPresets.LabelValueHorizontal
                labelSx={{ width: 90 }}
                label={"Status :"}
                value={
                  statusValue ===
                  Constants.STATUS_FLAGS.CREATOR_PAYMENT_REQUESTS.RAISED
                    ? "Raised"
                    : "Approved"
                }
              />
              <CUIPresets.SortBox
                sx={{ mt: -3 }}
                label="Filter By"
                options={Arrays.Filters.PAYMENT_REQUESTS}
                xsKey="typeId"
                xsValue="type"
                selected={statusValue}
                setValue={(e: any) => statusUpdate(e.target.value)}
              />
            </>
          )}

          {typeOfDisplay === Constants.TYPES.PAYMENT_REQUEST.CREATOR && (
            <>
              <CUIPresets.LabelValueHorizontal
                labelSx={{ width: 90 }}
                label={"Advertiser :"}
                value={fullName}
              />
            </>
          )}
        </Box>

        <CTableRow.FlexTableTop
          titleArray={
            type === Constants.TYPES.PAYMENT_REQUEST.CREATOR
              ? Arrays.Titles.PaymentRequestsCreator.Date
              : Arrays.Titles.PaymentRequestsCreator.Creator
          }
        />
        {!dataLoading && (
          <>
            {requestsList.map((item: any) => (
              <CTableRow.FlexTableRow contentArray={item} />
            ))}
            {requestsList.length === 0 && (
              <CTableRow.FlexTableRow contentArray={["No Data"]} />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminPaymentRequestsList;
