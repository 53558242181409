const ACTION_TYPES = {
  GRID: "GRID",
  SEARCHED_TEXT: "SEARCHED_TEXT",
  SEARCHED: "SEARCHED",
  SPACES_LIST: "SPACES_LIST",
  SUBSCRIPTIONS_OPTION_SELECTED: "SUBSCRIPTIONS_OPTION_SELECTED",
  MYSPACE_OPTION_SELECTED: "MYSPACE_OPTION_SELECTED",
  SAVED_CONTENT_LIST: "SAVED_CONTENT_LIST",
  SAVED_CONTENT_LOADING: "SAVED_CONTENT_LOADING",
  HISTORY_LIST: "HISTORY_LIST",
  HISTORY_LOADING: "HISTORY_LOADING",
  TRENDING_OPTION_SELECTED: "TRENDING_OPTION_SELECTED",
  TRENDING_FILTER: 'TRENDING_FILTER'
};

export default ACTION_TYPES;
