// React Imports
import React, { FC } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import Constants from "../../utils/Constants";

interface RaisedStatusProps {
  requestDetails?: any;
}

const RaisedStatus: FC<RaisedStatusProps> = (props) => {
  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.LabelValueHorizontal
          label={"Amount"}
          value={props.requestDetails?.requestDetails?.amountInSSC + " SSC"}
        />

        <CUIPresets.LabelValueHorizontal
          label={"Currency"}
          value={props.requestDetails?.requestDetails?.currency}
        />

        <CUIPresets.LabelValueHorizontal
          label={"Amount in " + props.requestDetails?.requestDetails?.currency}
          value={props.requestDetails?.requestDetails?.amountInCurrency}
        />

        <CUIPresets.LabelValueHorizontal
          label={"Mode of Payment"}
          value={props.requestDetails?.requestDetails?.modeOfPayment}
        />

        <CText.Roboto.Size28.Light.BlackUnderline
          text="Reciever's Details"
          style={{ marginBottom: 16 }}
        />

        {props.requestDetails?.requestDetails?.modeOfPayment ===
          Constants.TYPES.MODE_OF_PAYMENTS.PAYPAL && (
          <CUIPresets.LabelValueHorizontal
            label={"Paypal ID"}
            value={
              props.requestDetails?.requestDetails?.receiverDetails?.paypalId
            }
          />
        )}

        {props.requestDetails?.requestDetails?.modeOfPayment ===
          Constants.TYPES.MODE_OF_PAYMENTS.UPI && (
          <CUIPresets.LabelValueHorizontal
            label={"UPI ID"}
            value={props.requestDetails?.requestDetails?.receiverDetails?.upiId}
          />
        )}

        {props.requestDetails?.requestDetails?.modeOfPayment ===
          Constants.TYPES.MODE_OF_PAYMENTS.BANK && (
          <>
            <CUIPresets.LabelValueHorizontal
              label={"Account Holder Name"}
              value={
                props.requestDetails?.requestDetails?.receiverDetails
                  ?.accountHolderName
              }
            />

            <CUIPresets.LabelValueHorizontal
              label={"Account Number"}
              value={
                props.requestDetails?.requestDetails?.receiverDetails
                  ?.accountNumber
              }
            />

            <CUIPresets.LabelValueHorizontal
              label={"IFSC Code"}
              value={
                props.requestDetails?.requestDetails?.receiverDetails?.ifscCode
              }
            />
          </>
        )}

        <CText.Roboto.Size28.Light.BlackUnderline text="Platform Response" />

        <CUIPresets.LabelValueHorizontal
          label={"Status"}
          value={
            props.requestDetails?.status ===
            Constants.STATUS_FLAGS.WITHDRAW_FUNDS_REQUEST.RAISED
              ? "Pending"
              : "Paid"
          }
          sx={{ mt: 2 }}
        />

        {props.requestDetails?.status ===
          Constants.STATUS_FLAGS.WITHDRAW_FUNDS_REQUEST.APPROVED && (
          <>
            <CUIPresets.LabelImageHorizontal
              label={"Proof of Payment"}
              src={props.requestDetails?.adminResponse?.photoReceipt}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Made By"}
              value={props.requestDetails?.adminResponse?.actionBy}
            />
          </>
        )}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default RaisedStatus;
