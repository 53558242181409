import moment from "moment";
import { Flip, ToastOptions, toast } from "react-toastify";
import Arrays from "./Arrays";
import Constants from "./Constants";

export const objectMap = (obj: any, fn: any) => {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
  );
};

const ToastConfigColored: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
  transition: Flip,
};

const ToastConfigDark: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  transition: Flip,
};

const successToast = (msg: any) => {
  toast.success(msg, ToastConfigColored);
};

const failToast = (msg: any) => {
  toast.error(msg, ToastConfigColored);
};

const infoToast = (msg: any) => {
  toast.info(msg, ToastConfigColored);
};

const warningToast = (msg: any) => {
  toast.warn(msg, ToastConfigColored);
};

const successDark = (msg: any) => {
  toast.success(msg, ToastConfigDark);
};

const failDarkToast = (msg: any) => {
  toast.error(msg, ToastConfigDark);
};

const infoDark = (msg: any) => {
  toast.info(msg, ToastConfigDark);
};

export const ToastGenerator = {
  success: successToast,
  fail: failToast,
  info: infoToast,
  warn: warningToast,

  successDark: successDark,
  infoDark: infoDark,
  failDark: failDarkToast,
};

const setString = (label: any, value: any) => {
  localStorage.setItem(label, value);
};

const getString = (label: any): string => {
  const value = localStorage.getItem(label);
  if (value === null) {
    return "";
  } else {
    return value;
  }
};

export const LocalStorage = {
  getString,
  setString,
};

export const errorLog = (error: any) => {
  console.log(error);
};

export const NumberTypeInputProps = {
  type: "number",
  onWheel: (e: any) => e.target.blur(),
};

const masterFileTitle = (title: any) => {
  return title
    .trim()
    .replace(/ /g, "_")
    .replace(/'/g, "")
    .replace(/\W/g, "")
    .toLowerCase();
};

const convertToTimeStringArray = (timeObjectArray: any) => {
  return timeObjectArray.map((item: any) => {
    let timeString = "00:";

    if (item.minutes.length === 1) {
      timeString = timeString + "0" + item.minutes + ":";
    } else if (item.minutes.length === 2) {
      timeString = timeString + item.minutes + ":";
    }

    if (item.seconds.length === 1) {
      timeString = timeString + "0" + item.seconds;
    } else if (item.seconds.length === 2) {
      timeString = timeString + item.seconds;
    }

    return timeString;
  });
};

const convertToTimeDisplay = (adSlots: any) => {
  let timeDisplay = "";

  if (adSlots.length > 1) {
    adSlots?.map((item: any, index: any) => {
      if (index === adSlots.length - 1) {
        timeDisplay = timeDisplay + " and " + item.substring(3, 8);
      } else if (index === 0) {
        timeDisplay = timeDisplay + " " + item.substring(3, 8);
      } else {
        timeDisplay = timeDisplay + ", " + item.substring(3, 8);
      }

      return 0;
    });
  } else {
    timeDisplay = adSlots[0];
  }

  return timeDisplay;
};

const stringLimiter = (string: any, limit: any = 10) => {
  if (string?.length > limit) {
    return string.substring(0, limit) + "...";
  } else {
    return string;
  }
};

const adSlotDisplay = (adSlots: any) => {
  let str = "";

  adSlots?.map((item: any, index: any) => {
    if (index === 0) {
      str = item;
    } else {
      str = str + " | " + item;
    }

    return 0;
  });

  return str;
};

export const StringManipulation = {
  masterFileTitle,
  convertToTimeStringArray,
  convertToTimeDisplay,
  stringLimiter,
  adSlotDisplay,
};

const rounding = (number: any, round: any = 2) => {
  const roundedNumber =
    Math.round(Number(number) * Math.pow(10, Number(round))) /
    Math.pow(10, Number(round));
  return roundedNumber;
};

export const Calculations = {
  rounding,
};

const showDateInfo = (date: any, format: string = "DD-MM-YYYY") => {
  const isValid = moment(date).isValid();
  if (isValid) {
    return moment(date).format(format);
  } else {
    return "-";
  }
};

const duratonInSeconds = (duration: any) => {
  const durationArr = duration.split(":"); // split it at the colons

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  const seconds =
    +durationArr[0] * 60 * 60 + +durationArr[1] * 60 + +durationArr[2];

  return seconds;
};

const differenceInDays = (dateFirst: any, dateSecond: any) => {
  const date1 = new Date(dateFirst);
  const date2 = new Date(dateSecond);

  const diffTime = Math.abs(date2.valueOf() - date1.valueOf());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const DateFunctions = {
  format: showDateInfo,
  duratonInSeconds,
  differenceInDays,
};

const retrieveCountry = (countryCode: any) => {
  let countryName = "";

  if (countryCode === "ALL") {
    countryName = "All";
  } else {
    Arrays.Constants.ListOfCountries.map((item) => {
      if (item.code === countryCode) {
        countryName = item.name;
      }

      return 0;
    });
  }

  return countryName;
};

export const Information = {
  retrieveCountry,
};

const validateEmail = (email: any) => {
  const emailArr: any = String(email)
    .toLowerCase()
    .match(Constants.REGEX.EMAIL);

  return !!emailArr;
};

export const Validate = {
  email: validateEmail,
};

const getMTOnSuggestions = (index: any, suggestions: any) => {
  if (index === 0) {
    if (suggestions.length === 3) {
      return 68;
    } else if (suggestions.length === 2) {
      return 36;
    } else if (suggestions.length === 1) {
      return 0;
    }
  } else {
    return 2;
  }
};

export const UI = {
  getMTOnSuggestions,
};

const numberRound = (number: number, position: number) => {
  const multiplier = 10 ^ position;
  const roundedNumber = Math.round(number * multiplier) / multiplier;

  return roundedNumber;
};

export const NumberFunctions = {
  round: numberRound,
};
