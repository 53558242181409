// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import { Activity, ArrangeHorizontal, DocumentText1, VideoTime } from "iconsax-react";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";

interface CreatorFundManagementProps {}

const CreatorFundManagement: FC<CreatorFundManagementProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Fund Management" disabled />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<ArrangeHorizontal size="60" color={Colors.blueBackground} />}
            title="Transactions"
            onClick={() => navigate(PATHS.CREATOR_TRANSACTIONS)}
          />
          <CUIPresets.SelectFlow
            Icon={<DocumentText1 size="60" color={Colors.blueBackground} />}
            title="Contracts"
            onClick={() => navigate(PATHS.CREATOR_CONTRACTS)}
          />
          <CUIPresets.SelectFlow
            onClick={() => navigate(PATHS.CREATOR_FUNDS_OVERVIEW)}
            Icon={<Activity size="60" color={Colors.blueBackground} />}
            title="Funds Overview"
          />
          <CUIPresets.SelectFlow
            onClick={() => navigate(PATHS.CREATOR_WATCHTIME_BILLS)}
            Icon={<VideoTime size="60" color={Colors.blueBackground} />}
            title="Watchtime Bills"
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorFundManagement;
