// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import Styles from "../../utils/Styles";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";
import Colors from "../../utils/Colors";
import { ToastGenerator } from "../../utils/Functions";
import Constants from "../../utils/Constants";
import { PATHS } from "../../routes/Paths";

interface CreatorViewSubspaceProps {}

const CreatorViewSubspace: FC<CreatorViewSubspaceProps> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [dataLoading, setDataLoading] = useState(false);
  const [subspaceDetails, setSubspaceDetails]: any = useState([]);

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  useEffect(() => {
    setDataLoading(true);
    API.getSubspaceById(id).then((response) => {
      setSubspaceDetails(response.subSpace);
      setDataLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmModalCall = () => {
    setModal({
      open: true,
      apiLoading: true,
    });

    API.deleteSubspaceById(id).then((response) => {
      if (
        response.message === Constants.API_RESPONSES.SUBSPACE_DELETE_SUCCESS
      ) {
        ToastGenerator.success(response.message);
        setModal({
          open: false,
          apiLoading: false,
        });
        navigate(-1);
      }
    });
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="View Subspace" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <Box
            sx={{
              width: 800,
              ...Styles.SStyles.RowCenterSpace,
            }}
          >
            <CUIPresets.PageHeader title="View Subspace" />
            <Box
              sx={{
                ...Styles.SStyles.RowCenterCenter,
              }}
            >
              <CUIPresets.OutlinedButton
                onClick={() =>
                  navigate(PATHS.CREATOR_UPDATE_SUBSPACE + "/" + id)
                }
                sx={{
                  mt: 1.5,
                  width: 120,
                }}
                title="Update"
              />
              <CUIPresets.OutlinedButton
                color={Colors.red}
                onClick={() =>
                  setModal({
                    open: true,
                    apiLoading: false,
                  })
                }
                sx={{
                  mt: 1.5,
                  width: 120,
                  ml: 2,
                }}
                title="Delete"
              />
            </Box>
          </Box>

          <CUIPresets.LabelValueHorizontal
            label={"Name of Subspace"}
            value={subspaceDetails.subSpaceName}
          />
          <CUIPresets.LabelValueHorizontal
            label={"Description"}
            value={subspaceDetails.description}
          />
          {subspaceDetails.contents?.map((item: any, index: number) => (
            <CTableRow.SubspaceContentRow
              index={index + 1}
              title={item.contentDetails?.name}
              image={item.contentDetails?.coverPhoto}
            />
          ))}
          <CUIPresets.CustomDialog
            title="Delete Subspace"
            content={["Are you sure about deleting this subspace?"]}
            confirmText={"Confirm"}
            cancelText="Cancel"
            confirmCall={confirmModalCall}
            modalOpen={modal.open}
            cancelCall={() =>
              setModal({
                open: false,
                apiLoading: false,
              })
            }
            apiLoading={modal.apiLoading}
          />
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default CreatorViewSubspace;
