// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Avatar,
  ButtonBase,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  Radio,
  Checkbox,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { SearchNormal1, Back } from "iconsax-react";

// Functional Imports
import { animated, useSpring } from "@react-spring/web";
import { Blocks, ThreeDots } from "react-loader-spinner";
import { Calculations } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import Images from "../../utils/Images";

// Local Imports
import CUIRaw from "./CUIRaw";
import StaticBits from "./StaticBits";
import CButton from "./CButton";
import CText from "../CText";
import ViewerCUIPresets from "./ViewerCUIPresets";
import CTools from "../CTools";
import ChipSelect from "./ChipSelect";

interface LabelValueVerticalProps {
  label?: any;
  value?: any;
  sx?: any;
}

const LabelValueVertical: FC<LabelValueVerticalProps> = (props) => {
  return (
    <Box
      sx={{
        ...props.sx,
        mx: 2,
        mb: 1,
        minWidth: 160,
      }}
    >
      <CText.Jakarta.Size16.SemiBold.Grey3
        style={{
          marginLeft: 20,
          paddingTop: 16,
          textAlign: "start",
        }}
        text={props.label}
      />

      <CText.Jakarta.Size18.SemiBold.Black
        style={{
          marginLeft: 20,
          paddingTop: 16,
          textAlign: "start",
        }}
        text={props.value}
      />
    </Box>
  );
};

const LabelValueVertical2: FC<LabelValueVerticalProps> = (props) => {
  return (
    <Box
      sx={{
        ...props.sx,
        mt: 3,
        mb: 1,
        minWidth: 160,
      }}
    >
      <CText.Jakarta.Size16.Mid.Black text={props.label} />

      <CText.Jakarta.Size16.Mid.Grey1
        style={{ marginTop: 8, marginLeft: 8 }}
        text={props.value}
      />
    </Box>
  );
};

interface LabelValueHorizontalProps {
  label?: any;
  value?: any;
  sx?: any;
  labelSx?: any;
  valueSx?: any;
  labelStyle?: any;
  valueStyle?: any;
}

const LabelValueHorizontal: FC<LabelValueHorizontalProps> = (props) => {
  return (
    <Box
      display="flex"
      sx={{
        mb: 3,
        ...props.sx,
      }}
    >
      <Box width={320} sx={props.labelSx}>
        <CText.Jakarta.Size16.Mid.Grey3
          style={{
            ...props.labelStyle,
          }}
          text={props.label}
        />
      </Box>
      <Box maxWidth={500} sx={props.valueSx}>
        <CText.Jakarta.Size16.Mid.Black
          style={{
            ...props.valueStyle,
          }}
          text={props.value}
        />
      </Box>
    </Box>
  );
};

interface LabelImageHorizontalProps {
  label?: any;
  src?: any;
  sx?: any;
  labelSx?: any;
  valueSx?: any;
  labelStyle?: any;
  valueStyle?: any;
}

const LabelImageHorizontal: FC<LabelImageHorizontalProps> = (props) => {
  const [imageSize, setImageSize] = useState("small");

  const imageToggle = () => {
    if (imageSize === "small") {
      setImageSize("large");
    } else {
      setImageSize("small");
    }
  };

  return (
    <Box
      display="flex"
      sx={{
        mb: 3,
        ...props.sx,
      }}
    >
      <Box width={320} sx={props.labelSx}>
        <CText.Jakarta.Size16.Mid.Grey3
          style={{
            ...props.labelStyle,
          }}
          text={props.label}
        />
      </Box>
      {/*  */}
      <Box maxWidth={500} onClick={imageToggle} sx={props.valueSx}>
        <CTools.ImageContainer
          height={imageSize === "small" ? 200 : 400}
          width={imageSize === "small" ? 300 : 600}
          src={props.src}
        />
      </Box>
    </Box>
  );
};

interface LabelIframeHorizontalProps {
  label?: any;
  iframe?: any;
  sx?: any;
  labelSx?: any;
  valueSx?: any;
  labelStyle?: any;
  valueStyle?: any;
}

const LabelIframeHorizontal: FC<LabelIframeHorizontalProps> = (props) => {
  return (
    <Box
      display="flex"
      sx={{
        mb: 3,
        ...props.sx,
      }}
    >
      <Box width={320} sx={props.labelSx}>
        <CText.Jakarta.Size16.Mid.Grey3
          style={{
            ...props.labelStyle,
          }}
          text={props.label}
        />
      </Box>
      <Box maxWidth={500} sx={props.valueSx}>
        <CTools.IFrameContainer height={200} width={300} src={props.iframe} />
      </Box>
    </Box>
  );
};

interface LabelStatusProps {
  status?: any;
  chipSelect?: any;
}

const LabelStatus: FC<LabelStatusProps> = (props) => {
  return (
    <Box
      display="flex"
      sx={{
        mb: 3,
      }}
    >
      <Box width={320}>
        <CText.Jakarta.Size16.Mid.Grey3
          style={{ marginTop: 8 }}
          text={"Status"}
        />
      </Box>
      <Box maxWidth={500}>
        {props.chipSelect === "masterFile" && (
          <CUIPresets.ChipSelect.MasterFile status={props.status} />
        )}
        {props.chipSelect === "adFile" && (
          <CUIPresets.ChipSelect.AdFile status={props.status} />
        )}
        {props.chipSelect === "advertiserFunds" && (
          <CUIPresets.ChipSelect.AdvertiserFunds status={props.status} />
        )}
        {props.chipSelect === "contractRequest" && (
          <CUIPresets.ChipSelect.ContractRequest status={props.status} />
        )}
        {props.chipSelect === "contracts" && (
          <CUIPresets.ChipSelect.Contract status={props.status} />
        )}        
        {props.chipSelect === "watchtimeBill" && (
          <CUIPresets.ChipSelect.WatchtimeBills status={props.status} />
        )}
        {props.chipSelect === "watchtimeBillProof" && (
          <CUIPresets.ChipSelect.WatchtimeBillProof status={props.status} />
        )}        
      </Box>
    </Box>
  );
};

interface LogowTextProps {
  title?: any;
}

const LogowText: FC<LogowTextProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        pt: 3,
        pl: 4,
      }}
    >
      <Box
        component={"img"}
        src={Images.LogoWhite}
        sx={{
          height: 35,
        }}
      />
      <CText.Outfit.Size20.Light.White
        style={{
          marginLeft: 16,
        }}
        text={props.title}
      />
    </Box>
  );
};

interface HorizontalOptionsBarProps {
  options?: any;
  sx?: any;
  value?: any;
  setValue?: any;
}

const HorizontalOptionsBar: FC<HorizontalOptionsBarProps> = (props) => {
  return (
    <Box
      sx={{
        width: 700,
        height: 40,

        borderRadius: 1,
        backgroundColor: Colors.lightGreyTextfield,
        ...Styles.SStyles.elevation,
        ...Styles.SStyles.RowCenterSpace,
        ...props.sx,
      }}
    >
      {props.options?.map((item: any, index: number) => (
        <CUIRaw.HorizontalOptionsTile
          title={item}
          selected={item === props.value}
          setValue={props.setValue}
        />
      ))}
    </Box>
  );
};

interface OrBoxProps {
  sx?: any;
}

const OrBox: FC<OrBoxProps> = (props) => {
  return (
    <Box display={"flex"} alignItems={"center"} sx={{ ...props.sx }}>
      <Box sx={{ height: 1.2, width: 200, backgroundColor: Colors.greyText }} />
      <CText.Jakarta.Size18.Light.Grey1
        style={{
          marginRight: 16,
          marginLeft: 16,
        }}
        text="or"
      />
      <Box sx={{ height: 1.2, width: 200, backgroundColor: Colors.greyText }} />
    </Box>
  );
};

interface SearchBarProps {
  placeholder?: any;
  sx?: any;
  textFieldProps?: any;
}

const SearchBar: FC<SearchBarProps> = (props) => {
  return (
    <TextField
      variant="standard"
      placeholder={props.placeholder}
      InputProps={{
        disableUnderline: true,
        endAdornment: <SearchNormal1 size="22" color={Colors.greyText2} />,
      }}
      sx={{
        width: 350,
        height: "40px",
        justifyContent: "center",
        paddingLeft: 1,
        borderRadius: "6px",
        borderWidth: 0,
        backgroundColor: Colors.lightGreyTextfield,
        mt: 1,
        px: 2,
        ...props.sx,
      }}
      {...props.textFieldProps}
    />
  );
};

interface ShowSelectedItemProps {
  value?: any;
  label?: any;
  sx?: any;
  xsKey?: any;
  xsEnable?: any;
}

const ShowSelectedItem: FC<ShowSelectedItemProps> = (props) => {
  return (
    <Box sx={{ ...props.sx }}>
      <CText.Jakarta.Size16.Mid.Black text={props.label} />

      <Box
        sx={{
          width: "100%",
          maxHeight: "160px",
          overflowY: "scroll",
        }}
      >
        {props.value?.map(
          (item: any) =>
            item[props.xsEnable] && (
              <Box
                sx={{
                  width: "98%",
                  height: "40px",
                  mt: 1,
                  backgroundColor: Colors.lightGreyBackground,
                  borderRadius: 1,
                  ...Styles.SStyles.RowCenterCenter,
                }}
              >
                <Typography>{item[props.xsKey]}</Typography>
              </Box>
            )
        )}
      </Box>
    </Box>
  );
};

interface SortBoxProps {
  selected?: any;
  setValue?: any;
  options?: any;
  xsKey?: any;
  xsValue?: any;
  sx?: any;
  SortBoxProps?: any;
  label?: any;
}

const SortBox: FC<SortBoxProps> = (props) => {
  return (
    <FormControl
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        ...props.sx,
      }}
    >
      <CText.Jakarta.Size18.Mid.Grey1
        style={{
          marginRight: 8,
        }}
        text={props.label}
      />
      <Select
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
        }}
        style={{
          backgroundColor: Colors.lightGreyTextfield,
          width: 180,
          height: 40,
        }}
        value={props.selected}
        onChange={props.setValue}
        displayEmpty
        {...props.SortBoxProps}
      >
        {props.options.map((item: any, index: any) => {
          return (
            <MenuItem key={index} value={item[props.xsKey]}>
              <CText.Jakarta.Size16.Mid.Black text={item[props.xsValue]} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

SortBox.defaultProps = {
  label: "Sort By",
};

interface SelectFlowProps {
  Icon: JSX.Element;
  title: string;
  onClick?: any;
}

const SelectFlow: FC<SelectFlowProps> = (props) => {
  return (
    <Grid item>
      <ButtonBase
        onClick={() => {
          setTimeout(() => {
            if (props.onClick) {
              props.onClick();
            }
          }, 250);
        }}
        sx={{
          height: 280,
          width: 240,
          border: "3px solid" + Colors.blueBackground,
          borderRadius: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mr: 6,
          mb: 6,
          ":hover": {
            backgroundColor: Colors.lightBlueBackground,
          },
        }}
      >
        <Avatar sx={{ backgroundColor: Colors.lightBlueBackground2, p: 3 }}>
          {props.Icon}
        </Avatar>

        <CText.Jakarta.Size18.Mid.Blue4
          style={{
            marginTop: 64,
          }}
          text={props.title}
        />
      </ButtonBase>
    </Grid>
  );
};

interface SideBarTileProps {
  title?: any;
  Icon: React.ReactElement;
  selected?: any;
  setSelected?: any;
}

const SideBarTile: FC<SideBarTileProps> = ({
  selected,
  Icon,
  title,
  setSelected,
}) => {
  return (
    <ButtonBase
      onClick={() => setSelected(title)}
      sx={{
        height: "60px",
        width: "95%",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        pl: 2,
        backgroundColor: selected === title ? Colors.white : null,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        ":hover": {
          border: "2px dashed " + Colors.white,
          borderLeft: 0,
        },
      }}
    >
      {React.cloneElement(Icon, {
        color: selected === title ? Colors.blueBackground : Colors.white,
      })}
      {selected === title ? (
        <CText.Jakarta.Size16.Light.Blue4
          style={{
            marginLeft: 16,
          }}
          text={title}
        />
      ) : (
        <CText.Jakarta.Size16.Light.White
          style={{
            marginLeft: 16,
          }}
          text={title}
        />
      )}
    </ButtonBase>
  );
};

interface PageHeaderProps {
  title?: any;
  disabled?: any;
  onClick?: any;
  sx?: any;
}

const PageHeader: FC<PageHeaderProps> = (props) => {
  const [showBack, setShowBack] = useState(false);
  const navigate = useNavigate();

  const backAction = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <Box
      onMouseEnter={() => setShowBack(true)}
      onMouseLeave={() => setShowBack(false)}
      display="flex"
      alignItems="center"
      justifyContent="start"
      sx={{
        paddingTop: 2,
        paddingBottom: 1,
        ...props.sx,
      }}
    >
      {!props.disabled && showBack && (
        <IconButton
          onClick={backAction}
          sx={{ height: 60, width: 60, marginRight: 1, marginLeft: -2 }}
        >
          <Back size={40} color={Colors.black} />
        </IconButton>
      )}
      <CText.Roboto.Size48.SemiBold.Black text={props.title} />
    </Box>
  );
};

const PageHeaderWhite: FC<PageHeaderProps> = (props) => {
  const [showBack, setShowBack] = useState(false);
  const navigate = useNavigate();

  const backAction = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <Box
      onMouseEnter={() => setShowBack(true)}
      onMouseLeave={() => setShowBack(false)}
      display="flex"
      alignItems="center"
      justifyContent="start"
      sx={{
        paddingTop: 1,
        paddingBottom: 1,
        ...props.sx,
      }}
    >
      {!props.disabled && showBack && (
        <IconButton
          onClick={backAction}
          sx={{ height: 60, width: 60, marginRight: 1, marginLeft: -2 }}
        >
          <Back size={40} color={Colors.white} />
        </IconButton>
      )}
      <CText.Roboto.Size48.SemiBold.WhiteShadow text={props.title} />
    </Box>
  );
};

interface AnimationWrapperProps {
  children: React.ReactNode;
}

const AnimationWrapper: FC<AnimationWrapperProps> = (props) => {
  const animation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return <animated.div style={animation}>{props.children}</animated.div>;
};

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

interface CustomDialogProps {
  title?: any;
  content?: any;
  cancelCall?: any;
  cancelText?: any;
  confirmCall?: any;
  confirmText?: any;
  modalOpen?: any;
  apiLoading?: any;
}

const CustomDialog: FC<CustomDialogProps> = (props) => {
  return (
    <Dialog
      open={props.modalOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.cancelCall}
    >
      <DialogTitle
        sx={{
          backgroundColor: Colors.blueBackground,
          color: "white",
          height: "25px",
        }}
      >
        <CText.Jakarta.Size18.SemiBold.White text={props.title} />
      </DialogTitle>
      <DialogContent sx={{ minWidth: "320px", mt: 2 }}>
        <DialogContentText
          sx={{
            fontWeight: "500",
            color: "black",
          }}
        >
          {props.content.map((item: any) => (
            <CText.Jakarta.Size16.Mid.Black text={item} />
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#EEE",
        }}
      >
        {props.cancelText.length > 0 && (
          <ButtonBase
            sx={{
              px: 1.5,
              py: 1,
              br: 1,
              ":hover": {
                backgroundColor: Colors.greyBackground,
              },
            }}
            onClick={props.cancelCall}
          >
            <CText.Outfit.Size18.SemiBold.Blue3 text={props.cancelText} />
          </ButtonBase>
        )}

        {!props.apiLoading && (
          <CButton.Filled
            title={props.confirmText}
            onClick={props.confirmCall}
            sx={{
              width: "100px",
              marginRight: 1,
            }}
          />
        )}

        {props.apiLoading && (
          <Box
            sx={{
              height: "40px",
              width: "100px",
              backgroundColor: Colors.blueBackground,
              borderRadius: 1,
              marginRight: 1,
              ...Styles.SStyles.lowElevation,
              ...Styles.SStyles.ColumnCenterCenter,
            }}
          >
            <ThreeDots
              height="40"
              width="60"
              radius="9"
              color="white"
              wrapperStyle={{}}
              visible={true}
            />
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface LoadingSpinnerProps {}

const LoadingSpinner: FC<LoadingSpinnerProps> = (props) => {
  return (
    <Box
      sx={{
        width: 800,
        ...Styles.SStyles.RowCenterCenter,
        mt: 10,
      }}
    >
      <Blocks
        visible={true}
        height="180"
        width="180"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
      />
    </Box>
  );
};

interface SelectedFileProps {
  imageExists?: any;
  fileName?: any;
  fileSize?: any;
  sx?: any;
}

const SelectedFile: FC<SelectedFileProps> = (props) => {
  if (props.imageExists !== null) {
    return (
      <Box sx={props.sx}>
        <CText.Jakarta.Size14.SemiBold.Black
          text={"Selected File: " + props.fileName?.substring(0, 30) + "..."}
          style={{
            marginTop: 12,
          }}
        />
        <CText.Jakarta.Size14.SemiBold.Black
          text={"Size in MB: " + Calculations.rounding(props.fileSize, 1)}
          style={{
            marginTop: 8,
          }}
        />
      </Box>
    );
  } else return null;
};

interface ChooseMasterFileRowProps {
  sx?: any;
  masterFileName?: any;
  selected?: any;
  onSelected?: any;
}

const ChooseMasterFileRow: FC<ChooseMasterFileRowProps> = (props) => {
  return (
    <Box
      sx={{
        width: 765,
        minHeight: 48,
        py: 0.5,
        px: 2,
        mt: 1,
        ...Styles.SStyles.RowCenterSpace,
        backgroundColor: Colors.lightBlueBackground2,
        ...props.sx,
      }}
    >
      <CText.Jakarta.Size16.SemiBold.Black
        style={{
          marginRight: 8,
          marginLeft: 8,
        }}
        text={props.masterFileName}
      />

      <Radio
        checked={props.selected === props.masterFileName}
        sx={{ ml: 12 }}
        onChange={props.onSelected}
        value="a"
      />
    </Box>
  );
};

interface CreatorContentCheckRowProps {
  image?: any;
  title?: any;
  sx?: any;
  checked?: any;
  onClick?: any;
}

const CreatorContentCheckRow: FC<CreatorContentCheckRowProps> = (props) => {
  return (
    <Box
      sx={{
        width: 770,
        borderColor: Colors.lightBlueBackground2,
        borderTop: "3px solid " + Colors.lightBlueBackground3,
        borderLeft: "3px solid " + Colors.lightBlueBackground3,
        height: 70,
        px: 2,
        borderRadius: 2,
        mb: 1,
        ...Styles.SStyles.RowCenterSpace,
        ...props.sx,
      }}
    >
      <CTools.ImageContainer src={props.image} height={60} width={100} />

      <CText.Jakarta.Size16.Mid.Black
        style={{
          width: "75%",
        }}
        text={props.title}
      />

      <Checkbox
        sx={{ color: Colors.blueBackground }}
        checked={props.checked}
        onClick={props.onClick}
      />
    </Box>
  );
};

interface TitleDescriptionProps {
  title?: any;
  description?: any;
}

const TitleDescription: FC<TitleDescriptionProps> = (props) => {
  return (
    <>
      <CText.Jakarta.Size18.Mid.Black
        style={{
          marginTop: 12,
        }}
        text={props.title}
      />
      <CText.Jakarta.Size16.Light.Grey2
        style={{
          marginTop: 4,
        }}
        text={props.description}
      />
    </>
  );
};

const CUIPresets = {
  LabelValueVertical,
  LabelValueVertical2,
  LabelValueHorizontal,
  LabelStatus,
  LogowText,
  HorizontalOptionsBar,
  LabelImageHorizontal,
  LabelIframeHorizontal,
  OrBox,
  SearchBar,
  ShowSelectedItem,
  SortBox,
  SelectFlow,
  SideBarTile,
  PageHeader,
  PageHeaderWhite,
  AnimationWrapper,
  CustomDialog,
  ChipSelect,
  LoadingSpinner,
  SelectedFile,
  ChooseMasterFileRow,
  CreatorContentCheckRow,
  TitleDescription,

  BlueChip: StaticBits.BlueChip,
  RedChip: StaticBits.RedChip,
  GreenChip: StaticBits.GreenChip,
  OrangeChip: StaticBits.OrangeChip,

  OutlinedButton: CButton.Outlined,
  FilledButton: CButton.Filled,
  ViewerFilled: CButton.ViewerFilled,
  ViewerOutlined: CButton.ViewerOutlined,
  ViewerOutlined2: CButton.ViewerOutlined2,
  ChipWhite: CButton.ChipWhite,
  ChipCyan: CButton.ChipCyan,
  Filled2: CButton.Filled2,
  ChangeContent: CButton.ChangeContent,

  RightArrowAvatarWhite: StaticBits.RightArrowAvatarWhite,
  MaximiseAvatarWhite: StaticBits.MaximiseAvatarWhite,
  RightArrowAvatarBlue: StaticBits.RightArrowAvatarBlue,
  RightArrowAvatarLight: StaticBits.RightArrowAvatarLight,
  RightArrowAvatarDelete: StaticBits.RightArrowAvatarDelete,
  LoadingAction: StaticBits.LoadingAction,

  // Viewer CUI
  ViewerSelectFlow: ViewerCUIPresets.ViewerSelectFlow,
  GridSelection: ViewerCUIPresets.GridSelection,
  LargeContentTile: ViewerCUIPresets.LargeContentTile,
  SmallContentTile: ViewerCUIPresets.SmallContentTile,
  LargeSpaceTile: ViewerCUIPresets.LargeSpaceTile,
  SmallSpaceTile: ViewerCUIPresets.SmallSpaceTile,
  ViewerTopBar: ViewerCUIPresets.ViewerTopBar,
  ViewerLoading: ViewerCUIPresets.ViewerLoading,
  ViewerStatusText: ViewerCUIPresets.ViewerStatusText,
  SubscribeButton: ViewerCUIPresets.SubscribeButton,
  SubspaceTile: ViewerCUIPresets.SubspaceTile,
  ViewerDialog: ViewerCUIPresets.ViewerDialog,
  LargeContentWithoutTitle: ViewerCUIPresets.LargeContentWithoutTitle,
  SmallContentWithoutTitle: ViewerCUIPresets.SmallContentWithoutTitle,
  ContentActionTile: ViewerCUIPresets.ContentActionTile,
  ViewerAction: ViewerCUIPresets.ViewerAction,
  ViewerContentPlayer: ViewerCUIPresets.ViewerContentPlayer,
};

export default CUIPresets;
