// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { DateFunctions } from "../../utils/Functions";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";

interface ProofStatusProps {
  requestDetails?: any;
}

const ProofStatus: FC<ProofStatusProps> = (props) => {
  return (
    <Box>
      <CUIPresets.LabelValueHorizontal
        label={"Amount"}
        value={props.requestDetails?.requestDetails?.amountInSSC + " SSC"}
      />

      <CUIPresets.LabelValueHorizontal
        label={"Currency"}
        value={props.requestDetails?.requestDetails?.currency}
      />

      <CUIPresets.LabelValueHorizontal
        label={"Amount in INR"}
        value={props.requestDetails?.requestDetails?.amountInCurrency}
      />

      <CUIPresets.LabelValueHorizontal
        label={"Mode of Payment"}
        value={props.requestDetails?.requestDetails?.modeOfPayment}
      />

      <CUIPresets.LabelValueHorizontal
        label={"To UPI ID"}
        value="7795180292@ybl"
      />

      <CText.Roboto.Size28.Light.BlackUnderline
        style={{
          marginBottom: 16,
          marginTop: 32,
        }}
        text="Proof"
      />

      <CUIPresets.LabelImageHorizontal
        label={"Proof of Payment"}
        src={props.requestDetails?.proofDetails?.photoProof}
      />

      <CUIPresets.LabelValueHorizontal
        label={"Transaction ID"}
        value={props.requestDetails?.proofDetails?.txnId}
      />

      <CUIPresets.LabelValueHorizontal
        label={"Sender Address"}
        value={props.requestDetails?.proofDetails?.senderAddress}
      />

      <CUIPresets.LabelValueHorizontal
        label={"Date of Transaction"}
        value={DateFunctions.format(
          props.requestDetails?.proofDetails?.dateOfTransaction
        )}
      />

      <CText.Roboto.Size28.Light.BlackUnderline
        style={{
          marginBottom: 16,
          marginTop: 32,
        }}
        text="Platform Response"
      />

      <CUIPresets.LabelValueHorizontal
        label={"Status"}
        value={
          props.requestDetails?.status ===
          Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.UPLOADED
            ? "Pending"
            : props.requestDetails?.status ===
              Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.APPROVED
            ? "Approved"
            : "Rejected"
        }
      />

      {props.requestDetails?.status !==
        Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.UPLOADED && (
        <CUIPresets.LabelValueHorizontal
          label={"Made By"}
          value={props.requestDetails?.adminResponse?.actionBy}
        />
      )}

      {props.requestDetails?.status ===
        Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.REJECTED && (
        <CUIPresets.LabelValueHorizontal
          label={"Made By"}
          value={props.requestDetails?.adminResponse?.actionBy}
        />
      )}
    </Box>
  );
};

export default ProofStatus;
