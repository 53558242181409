// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import { Money } from "iconsax-react";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";

interface AdminAdvertiserAccessProps {}

const AdminAdvertiserAccess: FC<AdminAdvertiserAccessProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Advertiser Access" />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<Money size="60" color={Colors.blueBackground} />}
            title="Ad Files"
            onClick={() =>
              navigate(PATHS.ADMIN_ADVERTISER_VIEW_AD_FILES + "/" + id)
            }
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminAdvertiserAccess;
