// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { DateFunctions } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Constants from "../../utils/Constants";
import API from "../../api/API";
import moment from "moment";

// Local Imports
import CComposite from "../../atoms/CComposite";
import CText from "../../atoms/CText";

interface ListGeneratorSavedContentProps {
  contentList?: any;
  setContentList?: any;
}

const ListGeneratorSavedContent: FC<ListGeneratorSavedContentProps> = (
  props
) => {
  const navigate = useNavigate();

  const [list, setList] = useState([]);

  useEffect(() => {
    let currentMonth = moment().month();
    let currentYear = moment().year();

    let listTemp: any = [];

    props.contentList.map((item: any, index: number) => {
      if (
        moment(item.createdAt).month() === currentMonth &&
        moment(item.createdAt).year() === currentYear
      ) {
        listTemp.push(
          <CComposite.MySpaceTile
            contentId={item.contentDetails?._id}
            contentName={item.contentDetails?.contentDetails?.name}
            spaceName={item.contentDetails?.spaceDetails?.name}
            image={item.contentDetails?.contentDetails?.coverPhoto}
            date={DateFunctions.format(item.createdAt)}
            type={Constants.TYPES.MY_SPACE.SAVED_CONTENT}
            deleteSavedContent={deleteSavedContent}
            onClick={() => {
              navigate(
                PATHS.VIEWER_PLAYER + "/" + item.contentDetails?._id + "/0"
              );
            }}
          />
        );
      } else {
        listTemp.push(
          <CText.Jakarta.Size16.Mid.White
            text={
              moment(item.createdAt).format("MMM") +
              " " +
              moment(item.createdAt).year()
            }
            style={{ marginBottom: 0, marginTop: 16, width: "100%" }}
          />
        );
        listTemp.push(
          <CComposite.MySpaceTile
            contentId={item.contentDetails?._id}
            contentName={item.contentDetails?.contentDetails?.name}
            spaceName={item.contentDetails?.spaceDetails?.name}
            image={item.contentDetails?.contentDetails?.coverPhoto}
            date={DateFunctions.format(item.createdAt)}
            type={Constants.TYPES.MY_SPACE.SAVED_CONTENT}
            deleteSavedContent={deleteSavedContent}
            onClick={() => {
              navigate(
                PATHS.VIEWER_PLAYER + "/" + item.contentDetails?._id + "/0"
              );
            }}
          />
        );
        currentMonth = moment(item.createdAt).month();
        currentYear = moment(item.createdAt).year();
      }

      return 0;
    });

    setList(listTemp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contentList]);

  const deleteSavedContent = async (contentId: any) => {
    const payload = {
      contentId: contentId,
      action: "remove",
    };

    return API.manageSavedContent(payload).then((response) => {
      if (response.actionStatus) {
        const newContentList = props.contentList?.filter(
          (item: any) => item.contentDetails?._id !== contentId
        );

        props.setContentList([...newContentList]);
        return true;
      }
    });
  };

  return <>{list}</>;
};

export default ListGeneratorSavedContent;
