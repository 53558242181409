// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box, IconButton } from "@mui/material";
import { Edit } from "iconsax-react";

// Functional Imports
import {
  Information,
  LocalStorage,
  ToastGenerator,
} from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";

// Local Imports
import CText from "../../atoms/CText";
import CInputOutput from "../../atoms/CInputOutput";
import CUIPresets from "../../atoms/CUIPresets";
import API from "../../api/API";

interface ProfileInfoProps {
  setProfileInfoOrChangePassword?: any;
}

const ProfileInfo: FC<ProfileInfoProps> = (props) => {
  const navigate = useNavigate();

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });
  const [profileInfo, setProfileInfo] = useState({
    fullName: "",
    email: "",
    countryCode: "",
  });

  useEffect(() => {
    API.getViewerProfile().then((response) => {
      setProfileInfo({
        fullName: response.viewer?.fullName,
        email: response.viewer?.email,
        countryCode: response.viewer?.countryCode,
      });
    });
  }, []);

  const onLogout = () => {
    setModal({
      open: true,
      apiLoading: true,
    });
    ToastGenerator.successDark("Logging you out!");
    LocalStorage.setString("JWT", "");
    LocalStorage.setString("Type", "");
    setTimeout(() => {
      navigate(PATHS.NOAUTH_AUTH);
      window.location.reload();
      setModal({
        open: false,
        apiLoading: false,
      });
    }, 3000);
  };

  return (
    <Box
      sx={{
        backgroundColor: Colors.blackBackground2,
        width: 480,
        height: 500,
        overflowY: "scroll",
      }}
    >
      <CText.Jakarta.Size24.SemiBold.White
        style={{ marginLeft: 32, marginTop: 16 }}
        text="Profile"
      />
      {false && (
        <Box
          sx={{
            ...Styles.SStyles.RowCenterSpace,
            mt: 2,
          }}
        >
          <IconButton style={{ marginRight: 32, marginTop: 8 }}>
            <Edit size={28} color={Colors.white} variant="TwoTone" />
          </IconButton>
        </Box>
      )}

      <CInputOutput.LabelInputLight
        sx={{ width: 400, mt: 2, ml: 4 }}
        label="Full Name"
        textFieldProps={{
          placeholder: "",
          disabled: true,
          value: profileInfo.fullName,
        }}
      />

      <CInputOutput.LabelInputLight
        sx={{ width: 400, mt: 2, ml: 4 }}
        label="Email"
        textFieldProps={{
          placeholder: "",
          disabled: true,
          value: profileInfo.email,
        }}
      />

      <CInputOutput.LabelInputLight
        sx={{ width: 400, mt: 2, ml: 4 }}
        label="Country"
        textFieldProps={{
          placeholder: "",
          disabled: true,
          value: Information.retrieveCountry(profileInfo.countryCode),
        }}
      />

      <CUIPresets.ViewerFilled
        title={"Change Password"}
        onClick={() => props.setProfileInfoOrChangePassword(false)}
        sx={{ width: 400, height: 40, ml: 4, mt: 4 }}
      />

      <CUIPresets.ViewerOutlined
        title={"Logout"}
        sx={{ width: 400, height: 40, mt: 3, mb: 4, ml: 4 }}
        onClick={() =>
          setModal({
            open: true,
            apiLoading: false,
          })
        }
      />

      <CUIPresets.ViewerDialog
        title="Logout"
        content={["Are you sure about logging out?"]}
        confirmText={"Confirm"}
        cancelText="Cancel"
        confirmCall={onLogout}
        modalOpen={modal.open}
        cancelCall={() =>
          setModal({
            open: false,
            apiLoading: false,
          })
        }
        apiLoading={modal.apiLoading}
      />
    </Box>
  );
};

export default ProfileInfo;
