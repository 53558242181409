// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { Information } from "../../utils/Functions";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";
import Arrays from "../../utils/Arrays";
import Styles from "../../utils/Styles";

interface CreatorDetailedOverviewProps {}

const CreatorDetailedOverview: FC<CreatorDetailedOverviewProps> = (props) => {
  const navigate = useNavigate()
  const { id } = useParams();

  const [tableData, setTableData] = useState<any>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [contentName, setContentName] = useState("");

  useEffect(() => {
    setDataLoading(true);
    API.getContentViewDetailsById(id).then((response) => {
      setDataLoading(false);
      let detailArray = [];
      detailArray = [...response.contentViewDetails?.contentByCountry];
      detailArray.push(response.contentViewDetails?.content);
      setContentName(response.contentViewDetails?.content?.contentName);
      setDataLoading(false);
      populateData(detailArray);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateData = (dataArray: any) => {
    const apiData: any = [];

    dataArray?.map((item: any) => {
      apiData.push([
        Information.retrieveCountry(item.countryCode),
        String(item.d1Views),
        String(item.d7Views),
        String(item.d30Views),
        String(item.allTimeViews),
      ]);

      return 0;
    });

    setTableData(apiData);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <Box
          sx={{
            ...Styles.SStyles.RowCenterSpace,
            width: 900,            
          }}
        >
          <CUIPresets.PageHeader title="Detailed Overview" />

          <CUIPresets.OutlinedButton
            sx={{
              width: 200,
              mt: 1
            }}
            title="Watchtime Details"
            onClick={() => navigate(PATHS.CREATOR_WATCHTIME_DETAILS + "/" + id)}
          />
        </Box>

        {!dataLoading && (
          <>
            <CUIPresets.LabelValueHorizontal
              label={"Content Name"}
              value={contentName}
            />
            <CTableRow.FlexTableTopCustom
              flexArray={[1, 0.5, 0.5, 0.5, 0.5, 0.3]}
              sx={{
                width: 900,
              }}
              titleArray={Arrays.Titles.DetailedOverview}
            />
            {tableData.map((item: any) => (
              <CTableRow.FlexTableRowCustom
                flexArray={[1, 0.5, 0.5, 0.5, 0.5, 0.3]}
                sx={{
                  width: 900,
                }}
                contentArray={item}
              />
            ))}
            {tableData.length === 0 && (
              <CTableRow.FlexTableRow
                sx={{
                  width: 900,
                }}
                contentArray={["No Data"]}
              />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorDetailedOverview;
