// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";
import {} from "iconsax-react";

// Functional Imports
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import ACTION_TYPES from "../../redux/actions/actionTypes";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import SubscriptionContent from "./SubscriptionContent";
import SubscriptionSpaces from "./SubscriptionSpaces";

interface ViewerSubscriptionsProps {}

const ViewerSubscriptions: FC<ViewerSubscriptionsProps> = (props) => {
  const dispatch = useDispatch();

  const selectedOption = useSelector(
    (state: any) => state.appState.subscriptionsOptionSelected
  );

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          backgroundColor: Colors.blackBackground2,
          height: "100vh",
          width: "100vw",
        }}
      >
        <CUIPresets.ViewerTopBar
          options={["Content", "Spaces"]}
          selected={selectedOption}
          setSelected={(e: any) => {
            dispatch({
              type: ACTION_TYPES.SUBSCRIPTIONS_OPTION_SELECTED,
              payload: e,
            });
          }}
        />

        <Box
          sx={{
            height: 56,
            width: "100vw",
            backgroundColor: Colors.blackBackground4,
            ...Styles.SStyles.RowCenterEnd,
          }}
        >
          <CUIPresets.GridSelection />
        </Box>

        <Box
          sx={{
            ml: 4,
            overflowY: "scroll",
            height: window.innerHeight - 140,
          }}
        >
          {selectedOption === "Content" && <SubscriptionContent />}
          {selectedOption === "Spaces" && <SubscriptionSpaces />}
        </Box>
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default ViewerSubscriptions;
