// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";
import { ToastGenerator } from "../../utils/Functions";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";
import CComposite from "../../atoms/CComposite";

interface UploadNewVideoProps {
  onConfirm?: any;
}

const UploadNewVideo: FC<UploadNewVideoProps> = (props) => {
  const [videoFile, setVideoFile]: any = useState({
    file: null,
    name: "",
    size: "",
  });
  const [masterFileName, setMasterFileName] = useState("");
  const [adSlots, setAdSlots] = useState([
    {
      minutes: "",
      seconds: "",
    },
  ]);
  const [showModal, setShowModal] = useState(false);

  const handleServerUpload = () => {
    if (videoFile.file === null) {
      ToastGenerator.info("Select video file");
    } else if (Number(videoFile.size) > 1024) {
      ToastGenerator.info("Video File has to be less than 1 GB");
    } else if (masterFileName === "") {
      ToastGenerator.info("Video Reference name cannot be empty");
    } else if (!checkForTime()) {
      ToastGenerator.info("Minutes and Seconds should be between 0 and 59");
    } else {
      setShowModal(true);
    }
  };

  const checkForTime = () => {
    const result = adSlots?.map(
      (item) =>
        Number(item.minutes) >= 0 &&
        Number(item.minutes) < 60 &&
        Number(item.seconds) >= 0 &&
        Number(item.seconds) < 60 &&
        item.minutes !== "" &&
        item.seconds !== ""
    );

    let count = 0;
    result.map((item) => {
      if (item) {
        count++;
      }

      return 0;
    });

    return count === result.length;
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Upload New Video" />

        <Box display={"flex"}>
          <Box>
            <CInputOutput.SelectVideo
              setVideo={(ev: any) => {
                setVideoFile({
                  file: ev,
                  name: ev.name,
                  size: Number(ev.size) / (1024 * 1024),
                });
              }}
            />

            <CUIPresets.SelectedFile
              imageExists={videoFile.file}
              fileName={videoFile.name}
              fileSize={videoFile.size}
            />
          </Box>

          <Box sx={{ ml: 8, width: 500 }}>
            <CInputOutput.LabelInputOutlined
              sx={{ width: "80%", mt: 0 }}
              label="Video Reference Name"
              textFieldProps={{
                placeholder: "Enter Video Reference Name",
                value: masterFileName,
                onChange: (e: any) => setMasterFileName(e.target.value),
              }}
            />

            {adSlots?.map((item: any, index: any) => (
              <CInputOutput.TimeInput
                index={index}
                label={"Ad Slot " + (index + 1)}
                time={adSlots[index]}
                setTime={(ev: any) => {
                  let modifiedArray = adSlots;
                  modifiedArray[index] = ev;
                  setAdSlots([...modifiedArray]);
                }}
                onDelete={() => {
                  let modifiedArray = adSlots.filter(
                    (itemLocal: any, indexLocal: any) => indexLocal !== index
                  );
                  setAdSlots([...modifiedArray]);
                }}
              />
            ))}

            <CUIPresets.OutlinedButton
              color={Colors.blueBackground}
              onClick={() => {
                if (adSlots.length < 3) {
                  setAdSlots([
                    ...adSlots,
                    {
                      minutes: "",
                      seconds: "",
                    },
                  ]);
                } else {
                  ToastGenerator.info("Maximum allowed advertisements are 3");
                }
              }}
              sx={{
                textTransform: "none",
                width: "170px",
                mt: 1,
              }}
              title="Add Slot"
            />

            <CComposite.FooterActions.Filled
              onClick={handleServerUpload}
              sx={{
                mt: 12,
                mb: 4,
                width: 400,
              }}
              confirmText={"Upload To Server"}
            />
          </Box>
        </Box>

        <CUIPresets.CustomDialog
          title="Upload To Server"
          content={[
            "Are you sure about uploading this video to server?",
            "____",
            "NOTE: ",
            "Advertisements Slots should be less than the duration ",
            "of the content otherwise will lead to processing errors.",
          ]}
          confirmText={"Confirm"}
          cancelText="Cancel"
          confirmCall={() => {
            const payload = {
              adSlots,
              masterFileName,
              videoFile,
            };
            props.onConfirm(payload);
            setShowModal(false);
          }}
          modalOpen={showModal}
          cancelCall={() => setShowModal(false)}
        />
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default UploadNewVideo;
