// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { ToastGenerator } from "../../utils/Functions";
import Colors from "../../utils/Colors";

// Local Imports
import CText from "../../atoms/CText";
import CInputOutput from "../../atoms/CInputOutput";
import CUIPresets from "../../atoms/CUIPresets";
import API from "../../api/API";

interface ChangePasswordProps {
  setProfileInfoOrChangePassword?: any;
}

const ChangePassword: FC<ChangePasswordProps> = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onConfirm = () => {
    if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
      ToastGenerator.infoDark("Missing fields!");
      return;
    }

    if (newPassword !== confirmPassword) {
      ToastGenerator.infoDark("Passwords do not match");
      return;
    }

    if (newPassword.length < 8) {
      ToastGenerator.infoDark("Password too short!");
      return;
    }

    const payload = {
      oldPassword: oldPassword,
      newPassword: confirmPassword,
    };

    API.viewerChangePassword(payload).then((response) => {      
      if (response.actionSuccess) {
        ToastGenerator.successDark("Password changed successfully!");
        props.setProfileInfoOrChangePassword(true)
      }
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: Colors.blackBackground2,
        width: 480,
        height: 500,
        overflowY: "scroll",
      }}
    >
      <CText.Jakarta.Size24.SemiBold.White
        style={{ marginLeft: 32, marginTop: 32 }}
        text="Change Password"
      />
      <CInputOutput.LabelInputLightPassword
        sx={{ width: 400, mt: 2, ml: 4 }}
        label="Old Password"
        textFieldProps={{
          placeholder: "Enter old password",
          value: oldPassword,
          onChange: (e: any) => setOldPassword(e.target.value),
        }}
      />

      <CInputOutput.LabelInputLightPassword
        sx={{ width: 400, mt: 2, ml: 4 }}
        label="New Password"
        textFieldProps={{
          placeholder: "Enter new password",
          value: newPassword,
          onChange: (e: any) => setNewPassword(e.target.value),
        }}
      />

      <CInputOutput.LabelInputLightPassword
        sx={{ width: 400, mt: 2, ml: 4 }}
        label="Confirm Password"
        textFieldProps={{
          placeholder: "Confirm new password",
          value: confirmPassword,
          onChange: (e: any) => setConfirmPassword(e.target.value),
        }}
      />

      <CUIPresets.ViewerFilled
        title={"Confirm"}
        onClick={onConfirm}
        sx={{ width: 400, height: 40, ml: 4, mt: 4 }}
      />

      <CUIPresets.ViewerOutlined
        onClick={() => props.setProfileInfoOrChangePassword(true)}
        title={"Back"}
        sx={{ width: 400, height: 40, mt: 3, mb: 4, ml: 4 }}
      />
    </Box>
  );
};

export default ChangePassword;
