// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { ToastGenerator } from "../../utils/Functions";
import { useFormik } from "formik";
import * as yup from "yup";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";
import CComposite from "../../atoms/CComposite";

const initValues = {
  id: "",
  name: "",
  description: "",
  backgroundImage: {
    name: "",
    file: null,
    size: 0,
  },
  profileImage: {
    name: "",
    file: null,
    size: 0,
  },
};

interface CreatorSpaceConfigurationProps {}

const CreatorSpaceConfiguration: FC<CreatorSpaceConfigurationProps> = (
  props
) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: (values: any) => {
      setModal({
        open: true,
        apiLoading: false,
      });
    },
  });

  useEffect(() => {
    getDisplayData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDisplayData = () => {
    setDataLoading(true);
    API.getSpaceConfiguration().then((response) => {

      if ( response.entryFound ) {
        formik.setValues({
          id: response.space._id,
          name: response.space?.configuration?.name,
          description: response.space?.configuration?.description,
          profileImage: {
            name: "",
            file: response.space?.configuration?.profileImage,
            size: 0,
          },
          backgroundImage: {
            name: "",
            file: response.space?.configuration?.backgroundImage,
            size: 0,
          },
        });
      }
      setDataLoading(false);

    });
  };

  const confirmModalCall = () => {
    setModal({
      open: true,
      apiLoading: true,
    });

    const payload = {
      name: formik.values.name,
      description: formik.values.description,
      backgroundImage: formik.values.backgroundImage.file,
      profileImage: formik.values.profileImage.file,
    };

    API.postSpaceConfiguration(payload).then((response) => {
      if (response.message === Constants.API_RESPONSES.SPACE_CREATE_SUCCESS) {
        ToastGenerator.success(response.message);
        setModal({
          open: false,
          apiLoading: false,
        });
        getDisplayData();
      }
    });
  };

  const updateConfiguration = () => {
    if (formik.values.name === "") {
      formik.setFieldError("name", "Name is required");
      return;
    }

    if (formik.values.description === "") {
      formik.setFieldError("description", "Description is required");
      return;
    }

    const payload = {
      id: formik.values.id,
      name: formik.values.name,
      description: formik.values.description,
    };

    API.putSpaceConfiguration(payload).then((response) => {
      if (
        response.message === Constants.API_RESPONSES.SPACE_CONFIG_UPDATE_SUCCESS
      ) {
        ToastGenerator.success(response.message);
        getDisplayData();
      }
    });
  };

  const updateImages = (type: any, image: any) => {
    const payload = {
      id: formik.values.id,
      type: type,
      image: image,
    };

    API.putSpaceConfigurationImages(payload).then((response) => {
      if (
        response.message === Constants.API_RESPONSES.SPACE_IMAGES_UPDATE_SUCCESS
      ) {
        ToastGenerator.success(response.message);
        getDisplayData();
      }
    });
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Space Configuration" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Space Configuration" />

          <CInputOutput.LabelInputOutlined
            sx={{ width: 400 }}
            label="Name"
            textFieldProps={{
              placeholder: "Enter Name",
              helperText: formik.touched.name && formik.errors.name,
              error: Boolean(formik.touched.name && formik.errors.name),
              onBlur: formik.handleBlur,
              onChange: formik.handleChange,
              value: formik.values.name,
              name: "name",
            }}
          />

          <CInputOutput.LabelInputOutlined
            label="Description"
            textFieldProps={{
              placeholder: "Enter description",
              multiline: true,
              rows: 2,
              sx: { mt: 1, width: 400 },
              helperText:
                formik.touched.description && formik.errors.description,
              error: Boolean(
                formik.touched.description && formik.errors.description
              ),
              onBlur: formik.handleBlur,
              onChange: formik.handleChange,
              value: formik.values.description,
              name: "description",
            }}
          />

          {formik.values.id !== "" && (
            <CUIPresets.OutlinedButton
              title={"Update"}
              sx={{
                marginTop: 2,
                width: 400,
              }}
              onClick={() => updateConfiguration()}
            />
          )}

          {formik.values.id === "" && (
            <>
              <CInputOutput.SelectImage
                label={"Profile Image"}
                title={"Upload Profile Image"}
                sx={{ mt: 2 }}
                setImage={(ev: any) => {
                  formik.setFieldValue("profileImage", {
                    file: ev,
                    name: ev.name,
                    size: Number(ev.size) / (1024 * 1024),
                  });
                }}
              />

              <CUIPresets.SelectedFile
                imageExists={formik.values.profileImage.file}
                fileName={formik.values.profileImage.name}
                fileSize={formik.values.profileImage.size}
              />

              <CInputOutput.SelectImage
                label={"Background Image"}
                title={"Upload Background Image"}
                sx={{ mt: 2 }}
                setImage={(ev: any) => {
                  formik.setFieldValue("backgroundImage", {
                    file: ev,
                    name: ev.name,
                    size: Number(ev.size) / (1024 * 1024),
                  });
                }}
              />

              <CUIPresets.SelectedFile
                imageExists={formik.values.backgroundImage.file}
                fileName={formik.values.backgroundImage.name}
                fileSize={formik.values.backgroundImage.size}
              />
            </>
          )}

          {formik.values.id !== "" &&
            formik.values.profileImage.file !== null && (
              <CInputOutput.UpdateImage
                label={"Profile Image"}
                image={formik.values.profileImage.file}
                sx={{ mt: 2 }}
                setImage={(ev: any) => {
                  updateImages("profile", ev);
                }}
              />
            )}

          {formik.values.id !== "" &&
            formik.values.backgroundImage.file !== null && (
              <CInputOutput.UpdateImage
                label={"Background Image"}
                image={formik.values.backgroundImage.file}
                sx={{ mt: 2 }}
                setImage={(ev: any) => {
                  updateImages("background", ev);
                }}
              />
            )}

          {formik.values.id === "" && (
            <CComposite.FooterActions.Filled
              sx={{
                mt: 4,
                mb: 4,
                width: 760,
              }}
              confirmText={"Update"}
              onClick={() => formik.handleSubmit()}
            />
          )}
          <CUIPresets.CustomDialog
            title="Space Configuration"
            content={["Are you sure about updating space configuration?"]}
            confirmText={"Confirm"}
            cancelText="Cancel"
            confirmCall={confirmModalCall}
            modalOpen={modal.open}
            cancelCall={() =>
              setModal({
                open: false,
                apiLoading: false,
              })
            }
            apiLoading={modal.apiLoading}
          />
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default CreatorSpaceConfiguration;
