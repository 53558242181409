// React Imports
import React, { FC } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Local Imports
import RouteSetter from "./routes/RouteSetter";

interface AppProps {}

const App: FC<AppProps> = (props) => {
  return (
    <>
      <RouteSetter />
      <ToastContainer />
    </>
  );
};

export default App;

// TEST IFRAME "https://video.bunnycdn.com/embed/82169/ead1c1db-eb45-4c70-92f1-dfb403ef4b52"