// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Checkbox, Grid } from "@mui/material";

// Functional Imports
import { ToastGenerator } from "../../utils/Functions";
import Styles from "../../utils/Styles";
import Colors from "../../utils/Colors";

// Local Imports
import CText from "../../atoms/CText";
import CUIPresets from "../../atoms/CUIPresets";
import CComposite from "../../atoms/CComposite";
import Arrays from "../../utils/Arrays";

const CountryBoxStyle = {
  height: 60,
  width: 260,
  ...Styles.SStyles.RowCenterSpace,
  px: 2,
  mr: 2,
  my: 1,
  backgroundColor: Colors.blueTableColor,
  borderBottom: "2px solid " + Colors.blueBorderColor,
  borderRadius: 1,
};

interface ChooseCountriesProps {
  setSelectCountry?: any;
  setCountries?: any;
  countries?: any;
  selectedCountries?: any;
  setSelectedCountries?: any;
}

const ChooseCountries: FC<ChooseCountriesProps> = (props) => {
  const [noCountries, setNoCountries] = useState(true);

  const handleToggle = (index: any) => {
    const tempArray = props.countries;

    tempArray[index].enabled = !tempArray[index].enabled;

    props.setCountries([...tempArray]);
  };

  const interceptToggle = (index: any) => {
    if (!props.countries[index].enabled) {
      if (props.selectedCountries > 4) {
        ToastGenerator.info("Maximum limit reached!");
      } else {
        handleToggle(index);
      }
    } else {
      handleToggle(index);
    }
  };

  useEffect(() => {
    let count = 0;

    props.countries?.map((item: any) => {
      if (item.enabled) {
        count++;
      }

      return 0;
    });

    props.setSelectedCountries(count);
    setNoCountries(count === 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.countries]);

  const closeCall = () => {
    const tempRef = Arrays.Constants.ListOfCountries
    props.setCountries([...tempRef]);
    props.setSelectCountry(false);
    props.setSelectedCountries(0)
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader
          onClick={() => closeCall()}
          title="Choose Countries"
        />

        <Grid container sx={{ width: 900 }}>
          {props.countries?.map((item: any, index: any) => (
            <Grid item sx={CountryBoxStyle}>
              <CText.Jakarta.Size16.Mid.Black text={item.name} />
              <Checkbox
                checked={item.enabled}
                onChange={(ev) => interceptToggle(index)}
              />
            </Grid>
          ))}
        </Grid>

        <CText.Roboto.Size28.Light.BlackUnderline
          style={{ marginTop: 24 }}
          text={"Selected Countries"}
        />
        <Grid container sx={{ width: 900 }}>
          {props.countries.map(
            (item: any) =>
              item.enabled && (
                <Grid item sx={CountryBoxStyle}>
                  <CText.Jakarta.Size16.Mid.Black text={item.name} />
                </Grid>
              )
          )}
          {noCountries && (
            <CText.Jakarta.Size16.Mid.Black
              style={{ margin: 8 }}
              text={"No Countries Selected"}
            />
          )}
        </Grid>

        {props.selectedCountries > 0 && (
          <CComposite.FooterActions.Outlined
            onClick={() => props.setSelectCountry(false)}
            onCancel={() => closeCall()}
            sx={{
              mt: 4,
              mb: 4,
              width: 820,
            }}
            confirmText={"Confirm"}
          />
        )}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default ChooseCountries;
