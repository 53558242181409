// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { ToastGenerator } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import API from "../../api/API";

// Local Imports
import CInputOutput from "../../atoms/CInputOutput";
import CUIPresets from "../../atoms/CUIPresets";
import CComposite from "../../atoms/CComposite";

interface AdminChangePasswordProps {}

const AdminChangePassword: FC<AdminChangePasswordProps> = (props) => {
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChangePassword = () => {
    if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
      ToastGenerator.info("Missing fields!");
      return;
    }

    if (newPassword !== confirmPassword) {
      ToastGenerator.info("Passwords do not match");
      return;
    }

    if (newPassword.length < 8) {
      ToastGenerator.info("Password too short!");
      return;
    }

    const payload = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    API.changeClientPassword(payload).then((response) => {
      if (response.actionSuccess) {
        ToastGenerator.success("Password changed successfully!");
        navigate(-1);
      }
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Change Password" />

        <CInputOutput.LabelInputOutlined
          sx={{ width: 360, mt: 0, ml: 1 }}
          label="Old Password"
          textFieldProps={{
            placeholder: "Enter old password",
            type: "password",
            value: oldPassword,
            onChange: (e: any) => setOldPassword(e.target.value),
          }}
        />

        <CInputOutput.LabelInputOutlined
          sx={{ width: 360, ml: 1 }}
          label="New Password"
          textFieldProps={{
            placeholder: "Enter new password",
            type: "password",
            value: newPassword,
            onChange: (e: any) => setNewPassword(e.target.value),
          }}
        />

        <CInputOutput.LabelInputOutlined
          sx={{ width: 360, ml: 1 }}
          label="Confirm New Password"
          textFieldProps={{
            placeholder: "Enter confirm new password",
            type: "password",
            value: confirmPassword,
            onChange: (e: any) => setConfirmPassword(e.target.value),
          }}
        />
        <CComposite.FooterActions.Filled
          onClick={handleChangePassword}
          sx={{
            mt: 4,
          }}
          confirmText={"Confirm"}
        />
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminChangePassword;
