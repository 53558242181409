// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import { DocumentFilter, Flash, FlashSlash } from "iconsax-react";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";

interface CreatorContractsProps {}

const CreatorContracts: FC<CreatorContractsProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Contracts" />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<DocumentFilter size="60" color={Colors.blueBackground} />}
            title="By Content"
            onClick={() => navigate(PATHS.CREATOR_CONTENT_LIST + "/contracts")}
          />
          <CUIPresets.SelectFlow
            Icon={<Flash size="60" color={Colors.blueBackground} />}
            title="Active Contracts"
            onClick={() => navigate(PATHS.CREATOR_CONTRACT_LIST + "/0")}
          />
          <CUIPresets.SelectFlow
            Icon={<FlashSlash size="60" color={Colors.blueBackground} />}
            title="Other Contracts"
            onClick={() => navigate(PATHS.CREATOR_CONTRACT_LIST + "/1")}
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorContracts;
