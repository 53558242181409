import Colors from "./Colors";

const SStyles = {
  elevation: {
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
  },

  lowElevation: {
    boxShadow: "0 1px 2px rgb(0 0 0 / 0.2)",
  },

  midElevation: {
    boxShadow: "0 3px 5px rgb(0 0 0 / 0.2)",
  },

  RowCenterCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  RowCenterStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },

  RowCenterEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },

  RowCenterSpace: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  RowStartSpace: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
  },

  RowStartStart: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
  },

  ColumnCenterCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },  

  ColumnStartStart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
  },
  
  ColumnStartCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
  },
  
  ColumnEndCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    justifyContent: "center",
  },

  ColumnStartSpace: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
  },

  FullHeightFullWidth: {
    height: "100%",
    width: "100%",
  },
};

const CStyles = {
  viewerHoverEffect: {
    p: 1,
    px: 2,
    borderRadius: 1,
    cursor: "pointer",
    ":hover": {
      backgroundColor: Colors.blackBackground2,
      ...SStyles.midElevation,
    },
  },
};

const Styles = {
  SStyles,
  CStyles,
};

export default Styles;
