// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Grid } from "@mui/material";

// Functional Imports
import { useSelector } from "react-redux";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CComposite from "../../atoms/CComposite";

interface SubscriptionSpacesProps {
  grid?: any;
}

const SubscriptionSpaces: FC<SubscriptionSpacesProps> = (props) => {
  const grid = useSelector((state: any) => state.appState.grid);

  const [dataLoading, setDataLoading] = useState(false);
  const [spacesList, setSpacesList] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    API.getAllSubscribedSpaces().then((response) => {
      setDataLoading(false);
      const tempSubscribedSpaces = response.subscribedSpaces?.map(
        (item: any) => item.space
      );
      setSpacesList(tempSubscribedSpaces);
    });
  }, []);

  return (
    <>
      <Grid container sx={{ mt: 2, mb: 4 }} >
        {dataLoading && <CUIPresets.ViewerLoading sx={{ mt: 12 }} />}
        {!dataLoading && spacesList.length === 0 && (
          <CUIPresets.ViewerStatusText text="No Spaces" />
        )}        
        {!dataLoading && spacesList.length > 0 && (
          <Grid container sx={{ width: "100%" }}>
            <CComposite.SpacesList grid={grid} spacesList={spacesList} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SubscriptionSpaces;
