// React Imports
import { FC, useEffect, useState } from "react";

// MUI Imports

// Functional Imports
import {
  Calculations,
  DateFunctions,
  StringManipulation,
} from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";

interface CreatorWatchtimeBillsProps {}

const CreatorWatchtimeBills: FC<CreatorWatchtimeBillsProps> = (props) => {
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState(false);
  const [watchtimeBills, setWatchtimeBills] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    API.getWatchtimeBillsForCreator().then((response) => {
      setDataLoading(false);
      populateData(response.watchtimeBills);
      console.log("response");
      console.log(JSON.stringify(response, null, 4));
    });
  }, []);

  const populateData = (dataArray: any) => {
    const apiData: any = [];

    dataArray?.map((item: any) => {
      apiData.push([
        DateFunctions.format(item.month, "MMMM - YY"),
        String(Calculations.rounding(item.totalAmountInSSC)),
        <CUIPresets.ChipSelect.WatchtimeBills status={item.status} />,
        <CUIPresets.RightArrowAvatarWhite
          sx={{ ml: 2 }}
          onClick={() =>
            navigate(PATHS.CREATOR_WATCHTIME_BILL + "/" + item._id)
          }
        />,
      ]);

      return 0;
    });

    setWatchtimeBills(apiData);
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Watchtime Bills" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Watchtime Bills" />
          <CTableRow.FlexTableTop
            titleArray={["Month", "Amount", "Status", "Actions"]}
          />
          {watchtimeBills.map((item: any) => (
            <CTableRow.FlexTableRow contentArray={item} />
          ))}
          {watchtimeBills.length === 0 && (
            <CTableRow.FlexTableRow contentArray={["No Data"]} />
          )}
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default CreatorWatchtimeBills;
