export const PATHS = {
  // Creator
  ROOT: "/",
  DASHBOARD: "/dashboard",
  CREATOR_ADVERTISEMENTS: "/advertisements",
  CREATOR_MASTER_FILES: "/master-files",
  CREATOR_UPLOAD_MASTER_FILE: "/upload-master-file",
  CREATOR_MANAGE_MASTER_FILES: "/manage-master-files",
  CREATOR_MASTER_FILE_DETAILS: "/master-file-details",
  CREATOR_CREATOR_SPACE: "/creator-space",
  CREATOR_SPACE_CONFIGURATION: "/space-configuration",
  CREATOR_CONTENT_MANAGEMENT: "/content-management",
  CREATOR_CHOOSE_MASTER_FILE: "/choose-master-file",
  CREATOR_CREATE_CONTENT: "/create-content",
  CREATOR_CHOOSE_COUNTRIES: "/choose-countries",
  CREATOR_CONTENT_LIST: "/content-list",
  CREATOR_CONTENT_DETAILS: "/content-details",
  CREATOR_CREQUESTS: "/crequests",
  CREATOR_CREQUEST_DETAILS: "/crequest-details",
  CREATOR_CREATE_NEW_SUBSPACE: "/create-subspace",
  CREATOR_UPDATE_SUBSPACE: "/update-subspace",
  CREATOR_MANAGE_SUBSPACES: "/manage-subspaces",
  CREATOR_OVERVIEW: "/overview",
  CREATOR_OVERVIEWS: "/overviews",
  CREATOR_CONTENT_OVERVIEW: "/content-overview",
  CREATOR_DETAILED_OVERVIEW: "/detailed-overview",
  CREATOR_SPACE_OVERVIEW: "/space-overview",
  CREATOR_WATCHTIME_DETAILS: "/watchtime-details",
  CREATOR_FUND_MANAGEMENT: "/fund-management",
  CREATOR_TRANSACTIONS: "/transactions",
  CREATOR_CONTRACTS: "/contracts",
  CREATOR_CONTRACT_LIST: "/contract-list",
  CREATOR_CONTRACT_DETAILS: "/contract-details",
  CREATOR_PROFILE: "/profile",
  CREATOR_PROFILE_INFO: "/profile-info",
  CREATOR_CHANGE_PASSWORD: "/change-password",
  CREATOR_HOME: "/home",
  CREATOR_VIEW_SUBSPACE: "/view-subspace",
  CREATOR_FUNDS_OVERVIEW: "/cfunds-overview",
  CREATOR_PAYMENT_REQUEST: "/payment-request",
  CREATOR_WATCHTIME_BILLS: "/watchtime-bills",
  CREATOR_WATCHTIME_BILL: "/watchtime-bill",
  CREATOR_WATCHTIME_BILL_PROOF_UPLOAD: "/watchtime-bill-proof-upload",

  // Advertisers
  ADVERTISER_HOME: "a-home",
  ADVERTISER_CREATORS: "/creators",
  ADVERTISER_AD_CATALOGUE: "/ad-catalogue",
  ADVERTISER_FUNDS_MANAGEMENT: "/funds-management-ad",
  ADVERTISER_SEARCH_CREATOR: "/search-creator",
  ADVERTISER_SELECT_CONTENT: "/select-content",
  ADVERTISER_CONTRACT_REQUEST: "/contract-request",
  ADVERTISER_REQUEST_MANAGEMENT: "/request-management",
  ADVERTISER_ADD_FUNDS_REQUEST: "/add-funds-request",
  ADVERTISER_UPLOAD_NEW_AD: "/upload-new-ad",
  ADVERTISER_MANAGE_AD_FILES: "/manage-ad-files",
  ADVERTISER_VIEW_AD_DETAILS: "/view-ad-details",
  ADVERTISER_FUNDS_OVERVIEW: "/funds-overview",
  ADVERTISER_WITHDRAW_FUNDS_REQUEST: "/withdraw-funds-request",
  ADVERTISER_REQUEST_DETAILS: "/request-details",
  ADVERTISER_REQUESTS: "/requests",
  ADVERTISER_CONTRACTS: "/acontracts",
  ADVERTISER_TRANSACTIONS: "/atransactions",
  ADVERTISER_CONTRACT_TYPES: "/contract-types",
  ADVERTISER_CONTRACT_LIST: "/acontract-list",
  ADVERTISER_CREATORS_WITH_CONTRACTS: "/creator-with-contracts",
  ADVERTISER_PROFILE: "/aprofile",
  ADVERTISER_CHANGE_PASSWORD: "/achange-password",
  ADVERTISER_PROFILE_INFO: "/aprofile-info",
  ADVERTISER_CONTRACT_DETAILS: "/contract-details-ad",

  // ADMIN
  ADMIN_CREATOR_SEARCH: "/creator-search-an",
  ADMIN_CREATOR_ACCESS: "/creator-access",
  ADMIN_CREATOR_VIEW_MASTER_FILES: "/creator-view-master-files",
  ADMIN_CREATOR_MASTER_FILE_DETAILS: "/creator-master-file-details",
  ADMIN_CREATOR_REQUESTS: "/creator-requests",
  ADMIN_CONTRACT_REQUEST_DETAILS: "/contract-request-details",
  ADMIN_CREATOR_VIEW_SUBSPACES: "/creator-view-subspaces",
  ADMIN_CREATOR_SUBSPACE_DETAILS: "/creator-subspace-details",
  ADMIN_CREATOR_VIEW_CONTENT: "/creator-view-content",
  ADMIN_CREATOR_CONTENT_DETAILS: "/creator-content-details",
  ADMIN_VIEWER_SEARCH: "/viewer-search",
  ADMIN_VIEWER_ACCESS: "/viewer-access",
  ADMIN_VIEWER_CONTENT_LIST: "/viewer-content-list",
  ADMIN_ADVERTISER_VIEW_AD_FILES: "/advertiser-view-ad-files",
  ADMIN_ADVERTISER_AD_FILE_DETAILS: "/advertiser-ad-file-details",
  ADMIN_WATCHTIME_RATES: "/watchtime-rates",
  ADMIN_CURRENCY_CONVERSION: "/currency-conversion",
  ADMIN_CREATOR_ADVERTISEMENTS: "/creator-advertisements",
  ADMIN_CREATOR_MASTER_FILES: "/creator-master-files",
  ADMIN_CREATOR_CREATOR_SPACE: "/creator-creator-space",
  ADMIN_CREATOR_SUBSPACES: "/creator-subspaces",
  ADMIN_CREATOR_SUBSPACE: "/creator-subspace",
  ADMIN_CREATOR_FLOW: "/creator-flow",
  ADMIN_ADVERTISER_SEARCH: "/advertiser-search",
  ADMIN_ADVERTISER_ACCESS: "/advertiser-access",
  ADMIN_ADVERTISER_AD_CATALOGUE: "/advertiser-ad-catalogue",
  ADMIN_ADVERTISER_VIEW_REQUESTS: "/advertiser-view-requests",
  ADMIN_ADVERTISER_VIEW_CONTRACTS: "/advertiser-view-contracts",
  ADMIN_VIEWER_VIEW_SUBSCRIPTIONS: "/viewer-view-subscriptions",
  ADMIN_VIEWER_VIEW_HISTORY: "/viewer-view-history",
  ADMIN_VIEWER_VIEW_ACCOUNT_INFO: "/viewer-view-account-info",
  ADMIN_PAYMENTS: "/payments",
  ADMIN_ADD_FUNDS_REQUESTS: "/add-funds-requests",
  ADMIN_ADD_FUNDS_REQUESTS_DATE: "/add-funds-requests-date",
  ADMIN_ADD_FUNDS_REQUESTS_LIST: "/add-funds-requests-list",
  ADMIN_ADD_FUNDS_REQUESTS_DETAILS: "/add-funds-requests-details",
  ADMIN_WITHDRAW_FUNDS_REQUESTS: "/withdraw-funds-requests",
  ADMIN_WITHDRAW_FUNDS_REQUESTS_LIST: "/withdraw-funds-requests-list",
  ADMIN_WITHDRAW_FUNDS_REQUESTS_DETAILS: "/withdraw-funds-requests-details",
  ADMIN_CREATOR_PAYMENT_REQUESTS: "/creator-payment-request",
  ADMIN_CREATOR_PAYMENT_REQUEST_LIST: "/creator-payment-request-list",
  ADMIN_PAYMENT_REQUEST_DETAILS: "/payment-request-details",
  ADMIN_PROFILE: "/profile-admin",
  ADMIN_CHANGE_PASSWORD: "/change-password-admin",
  ADMIN_CREATOR_WATCHTIME_BILLS: "/creator-watchtime-bills",
  ADMIN_CREATOR_WATCHTIME_BILLS_LIST: "/creator-watchtime-bills-list",
  ADMIN_CREATOR_WATCHTIME_BILL_DETAILS: "/creator-watchtime-bill-details",
  ADMIN_CREATOR_WATCHTIME_BILL_PROOF_DETAILS:
    "/creator-watchtime-bill-proof-details",
  ADMIN_CREATOR_WATCHTIME_BILL_PROOF_LIST: "/creator-watchtime-bill-proof-list",

  // VIEWER
  VIEWER_NAVIGATE_TO: "/navigate-to",
  VIEWER_SUBSCRIPTIONS: "/subscriptions",
  VIEWER_TRENDING: "/trending",
  VIEWER_SEARCH: "/search",
  VIEWER_MY_SPACE: "/my-space",
  VIEWER_SPACE: "/space",
  VIEWER_SUBSPACE: "/subspace",
  VIEWER_PLAYER: "/player",
  VIEWER_RECENTLY_UPLOADED: "/recently-uploaded",
  VIEWER_MOST_POPULAR: "/most-popular",
  VIEWER_PROFILE: "/profile",

  // NO AUTHENTICATION
  NOAUTH_AUTH: "/cauth",
  VIEWER_AUTH: "/auth",

  // COMMON
  HOME: "/home",
};
