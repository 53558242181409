// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box, Grid } from "@mui/material";
import {} from "iconsax-react";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import Constants from "../../utils/Constants";
import Colors from "../../utils/Colors";
import API from "../../api/API";

// Local Imports
import CTools from "../../atoms/CTools";
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import { PATHS } from "../../routes/Paths";

interface ViewerSpaceProps {}

const ViewerSpace: FC<ViewerSpaceProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });
  const [dataLoading, setDataLoading] = useState(false);
  const [spaceDetails, setSpaceDetails] = useState({
    spaceName: "",
    backgroundImage: "",
  });
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    subscriptionStatus: Constants.TYPES.SUBSCRIPTION_TYPES.SUBSCRIBE,
    subscribedDate: null,
  });
  const [subspacesList, setSubspacesList] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    API.getSpaceAndSubspaces(id).then((response) => {
      setDataLoading(false);
      setSpaceDetails({
        spaceName: response.spaceDetails?.configuration?.name,
        backgroundImage: response.spaceDetails?.configuration?.backgroundImage,
      });
      setSubscriptionDetails({
        subscriptionStatus: response.subscriptionDetails?.subscribed
          ? Constants.TYPES.SUBSCRIPTION_TYPES.SUBSCRIBED
          : Constants.TYPES.SUBSCRIPTION_TYPES.SUBSCRIBE,
        subscribedDate: response.subscriptionDetails?.subscriptionDate,
      });
      setSubspacesList(response.subSpaces);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshSubscriptionDetails = () => {
    setSubscriptionDetails({
      subscriptionStatus: Constants.TYPES.SUBSCRIPTION_TYPES.LOADING,
      subscribedDate: null,
    });
    API.getSpaceAndSubspaces(id).then((response) => {
      setSubscriptionDetails({
        subscriptionStatus: response.subscriptionDetails?.subscribed
          ? Constants.TYPES.SUBSCRIPTION_TYPES.SUBSCRIBED
          : Constants.TYPES.SUBSCRIPTION_TYPES.SUBSCRIBE,
        subscribedDate: response.subscriptionDetails?.subscriptionDate,
      });
    });
  };

  const handleSubscribe = () => {
    let action = "";

    if (
      subscriptionDetails.subscriptionStatus ===
      Constants.TYPES.SUBSCRIPTION_TYPES.SUBSCRIBE
    ) {
      action = "subscribe";
    } else {
      action = "unsubscribe";
    }

    const payload = { spaceId: id, action: action };

    API.manageSubscriptions(payload).then((response) => {
      refreshSubscriptionDetails();
    });
  };

  const interceptSubscribe = () => {
    if (
      subscriptionDetails.subscriptionStatus ===
      Constants.TYPES.SUBSCRIPTION_TYPES.SUBSCRIBE
    ) {
      handleSubscribe();
    } else {
      setModal({
        open: true,
        apiLoading: false,
      });
    }
  };

  const confirmModalCall = () => {
    handleSubscribe();
    setModal({
      open: false,
      apiLoading: false,
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          backgroundColor: Colors.blackBackground2,
          height: "100vh",
          width: "100%",
        }}
      >
        <CUIPresets.ViewerTopBar />
        {dataLoading && <CUIPresets.ViewerLoading sx={{ mt: 20 }} />}
        {!dataLoading && (
          <Box
            sx={{
              overflowY: "scroll",
              height: window.innerHeight - 80,
            }}
          >
            <Box sx={{ position: "relative" }}>
              <CTools.ImageContainer
                imageBgColor={null}
                src={spaceDetails.backgroundImage}
                sx={{
                  opacity: 0.75,
                }}
                height={window.innerHeight - 80}
                width="100%"
              />
              <CText.Jakarta.Size48.SemiBold.WhiteShadow
                style={{
                  position: "absolute",
                  bottom: 108,
                  left: 64,
                }}
                text={spaceDetails.spaceName}
              />
              <CUIPresets.SubscribeButton
                type={subscriptionDetails.subscriptionStatus}
                subscriptionDate={subscriptionDetails.subscribedDate}
                onClick={interceptSubscribe}
                sx={{
                  position: "absolute",
                  bottom: 64,
                  left: 64,
                }}
              />
            </Box>

            <Grid
              container
              sx={{
                pl: "6vw",
                pt: 2,
                pb: 4,
              }}
            >
              <CUIPresets.SubspaceTile
                onClick={() =>
                  navigate(PATHS.VIEWER_RECENTLY_UPLOADED + "/" + id)
                }
                label={"Recently Uploaded"}
              />
              <CUIPresets.SubspaceTile
                onClick={() => navigate(PATHS.VIEWER_MOST_POPULAR + "/" + id)}
                label={"Most Popular"}
              />
              {subspacesList.map((item: any) => (
                <CUIPresets.SubspaceTile
                  label={item.subSpaceName}
                  onClick={() =>
                    navigate(PATHS.VIEWER_SUBSPACE + "/" + item._id)
                  }
                />
              ))}
            </Grid>
          </Box>
        )}
      </Box>
      <CUIPresets.ViewerDialog
        title="Unsubscribe"
        content={[
          "Are you sure about unsubscribing " + spaceDetails.spaceName + "?",
        ]}
        confirmText={"Confirm"}
        cancelText="Cancel"
        confirmCall={confirmModalCall}
        modalOpen={modal.open}
        cancelCall={() =>
          setModal({
            open: false,
            apiLoading: false,
          })
        }
        apiLoading={modal.apiLoading}
      />
    </CUIPresets.AnimationWrapper>
  );
};

export default ViewerSpace;
