// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Arrays from "../../utils/Arrays";
import Colors from "../../utils/Colors";
import API from "../../api/API";

// Local Imports
import CTableRow from "../../atoms/CTableRow";
import CUIPresets from "../../atoms/CUIPresets";

interface CreatorManageSubspacesProps {}

const CreatorManageSubspaces: FC<CreatorManageSubspacesProps> = (props) => {
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState(false);
  const [subspaceList, setSubspaceList]: any = useState([]);

  useEffect(() => {
    setDataLoading(true);
    API.getAllSubspaces().then((response) => {
      populateData(response.subSpaces);
      setDataLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateData = (apiData: any) => {
    let subspaceArr: any = [];

    apiData.map((item: any) => {
      subspaceArr.push([
        item.subSpaceName,
        String(item.contents?.length),
        <CUIPresets.RightArrowAvatarWhite
          onClick={() => navigate(PATHS.CREATOR_VIEW_SUBSPACE + "/" + item._id)}
          sx={{
            ml: 2,
          }}
        />,
      ]);

      return 0;
    });

    setSubspaceList([...subspaceArr]);
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="All Subspaces" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="All Subspaces" />

          <CTableRow.FlexTableTop
            titleArray={Arrays.Titles.AllSubspaces}
            textStyle={{ color: Colors.greyText4 }}
            lastFlex={0.4}
          />
          {subspaceList.map((item: any) => (
            <CTableRow.FlexTableRow contentArray={item} lastFlex={0.4} />
          ))}
          {subspaceList.length === 0 && (
            <CTableRow.FlexTableRow contentArray={["No Subspaces"]} />
          )}
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default CreatorManageSubspaces;
