// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Arrays from "../../utils/Arrays";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";

interface CreatorContentListProps {}

const CreatorContentList: FC<CreatorContentListProps> = (props) => {
  const { type } = useParams();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [sortValue, setSortValue] = useState("upload_date");

  const [dataLoading, setDataLoading] = useState(false);
  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    API.getContentList("", "").then((response: any) => {
      setDataLoading(false);
      setContentList(response.contentList);
    });
  }, []);

  const sortAction = (sortPass: any) => {
    setSortValue(sortPass);
    setDataLoading(true);
    API.getContentList("", sortPass).then((response: any) => {
      setDataLoading(false);
      setContentList(response.contentList);
    });
  };

  const searchAction = (searchPass: any) => {
    setSearchText(searchPass);
    setDataLoading(true);
    API.getContentList(searchPass, "").then((response: any) => {
      setDataLoading(false);
      setContentList(response.contentList);
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Content List" />
        <Box
          sx={{
            width: 800,

            marginBottom: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CUIPresets.SearchBar
            placeholder={"Search Content"}
            textFieldProps={{
              onChange: (e: any) => searchAction(e.target.value),
              value: searchText,
            }}
          />

          <CUIPresets.SortBox
            options={Arrays.Sorting.ContentList}
            xsKey="typeId"
            xsValue="type"
            selected={sortValue}
            setValue={(e: any) => sortAction(e.target.value)}
          />
        </Box>

        {!dataLoading &&
          contentList?.map((item: any) => (
            <CTableRow.CreatorContentRow
              image={item.contentDetails?.coverPhoto}
              title={item.contentDetails?.name}
              onClick={() => {
                if (type === "details") {
                  navigate(PATHS.CREATOR_CONTENT_DETAILS + "/" + item._id);
                } else if (type === "contracts") {
                  navigate(PATHS.CREATOR_CONTRACT_LIST + "/" + item._id);
                }
              }}
            />
          ))}
        {!dataLoading && contentList?.length === 0 && (
          <CTableRow.FlexTableRow contentArray={["No Content"]} />
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorContentList;
