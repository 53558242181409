// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Local Imports
import Styles from "../../utils/Styles";

interface TopBarProps {}

const TopBar: FC<TopBarProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "60px",
        backgroundColor: Colors.white,
        ...Styles.SStyles.elevation,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    />
  );
};

export default TopBar;
