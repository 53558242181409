// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box, ButtonBase, Grid } from "@mui/material";
import { ArrowLeft } from "iconsax-react";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import CComposite from "../../atoms/CComposite";

interface ViewerMostPopularProps {}

const ViewerMostPopular: FC<ViewerMostPopularProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const grid = useSelector((state: any) => state.appState.grid);

  const [dataLoading, setDataLoading] = useState(false);
  const [contentList, setContentList] = useState<any>([]);

  useEffect(() => {
    setDataLoading(true);
    API.getMostPopularContent(id).then((response) => {
      setDataLoading(false);
      const tempContentList = response.mostPopularContent?.map(
        (item: any) => item.contentDetails
      );
      setContentList([...tempContentList]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          backgroundColor: Colors.blackBackground2,
          height: "100vh",
          width: "100vw",
        }}
      >
        <CUIPresets.ViewerTopBar
          options={[]}
          selected={""}
          setSelected={() => undefined}
        />

        <Box
          sx={{
            height: 56,
            width: "100vw",
            backgroundColor: Colors.blackBackground4,
            ...Styles.SStyles.RowCenterSpace,
          }}
        >
          <ButtonBase
            onClick={() => {
              setTimeout(() => {
                navigate(-1);
              }, 200);
            }}
            sx={{
              ...Styles.SStyles.RowCenterCenter,
              ml: 4,
              ...Styles.CStyles.viewerHoverEffect,
            }}
          >
            <ArrowLeft
              size={22}
              style={{ color: Colors.cyan, marginRight: 8 }}
            />
            <CText.Jakarta.Size16.Light.White text={"Go Back"} />
          </ButtonBase>
          <Box
            sx={{
              ...Styles.SStyles.RowCenterCenter,
            }}
          >
            <CUIPresets.GridSelection />
          </Box>
        </Box>

        {dataLoading && <CUIPresets.ViewerLoading />}

        {!dataLoading && (
          <Box
            sx={{
              ml: 6,
              overflowY: "scroll",
              height: window.innerHeight - 140,
            }}
          >
            <CText.Jakarta.Size40.Mid.White
              text={"Most Popular"}
              style={{ marginTop: 16 }}
            />

            <Grid container sx={{ mt: 2, mb: 4 }}>
              {contentList.length > 0 && (
                <CComposite.ContentListSubspace
                  subspaceId={'0'}
                  grid={grid}
                  contentList={contentList}
                />
              )}
              {contentList.length === 0 && (
                <CUIPresets.ViewerStatusText
                  sx={{ height: "50vh" }}
                  text="No Content"
                />
              )}
            </Grid>
          </Box>
        )}
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default ViewerMostPopular;
