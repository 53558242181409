// React Imports
import React, { useState, FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";

// Functional Imports
import Images from "../../utils/Images";

// Local Imports
import Login from "./Login";
import Register from "./Register";
import CTools from "../../atoms/CTools";
import EnterOTPComp from "./EnterOTPComp";
import ForgotPassword from "./ForgotPassword";
import ConfirmChangePassword from "./ConfirmChangePassword";

const AuthStates = {
  Login: "login",
  Register: "register",
  Otp: "otp",
  ForgotPassword: "ForgotPassword",
  ChangePassword: "ChangePassword",
};

interface AuthenticationProps {}

const Authentication: FC<AuthenticationProps> = (props) => {
  const [authState, setAuthState] = useState(AuthStates.Register);
  const [email, setEmail] = useState("");

  return (
    <Grid container sx={{ overflowY: "hidden" }}>
      <Grid
        item
        xs={7}
        md={7}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
          },
        }}
      >
        <CTools.ImageContainer
          src={Images.ClientBackground}
          height={"100vh"}
          width={"100%"}
        />
      </Grid>
      {authState === AuthStates.Login && (
        <Login setAuthState={setAuthState} setEmail={setEmail} />
      )}
      {authState === AuthStates.Register && (
        <Register setLoginOrRegister={setAuthState} />
      )}
      {authState === AuthStates.Otp && (
        <EnterOTPComp setAuthState={setAuthState} email={email} />
      )}
      {authState === AuthStates.ForgotPassword && (
        <ForgotPassword
          setAuthState={setAuthState}
          email={email}
          setEmail={setEmail}
        />
      )}
      {authState === AuthStates.ChangePassword && (
        <ConfirmChangePassword setAuthState={setAuthState} email={email} />
      )}
    </Grid>
  );
};

export default Authentication;
