// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { Information, StringManipulation } from "../../utils/Functions";
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";
import Arrays from "../../utils/Arrays";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";

interface AdvertiserContractListProps {}

const AdvertiserContractList: FC<AdvertiserContractListProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    if (id === "0" || id === "1") {
      API.getContractsAdvertiser().then((response) => {
        setDataLoading(false);

        if (id === "0") {
          const activeData = response.contracts?.filter(
            (item: any) => item.status === "active"
          );
          populateData(activeData);
        } else if (id === "1") {
          const otherData = response.contracts?.filter(
            (item: any) => item.status !== "active"
          );
          populateData(otherData);
        }
      });
    } else {
      API.getContractsAdvertiser(id).then((response) => {
        setDataLoading(false);
        populateData(response.contracts);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateData = (dataArray: any) => {
    const apiData: any = [];

    dataArray?.map((item: any) => {
      apiData.push([
        StringManipulation.stringLimiter(
          item.entityDetails?.content?.contentDetails?.name,
          12
        ),
        item.entityDetails?.advertiser?.fullName,
        Information.retrieveCountry(item.adSlotDetails?.selectedCountryCode),
        item.adSlotDetails?.selectedAdSlot,
        <CUIPresets.ChipSelect.Contract status={item.status} />,
        <CUIPresets.RightArrowAvatarWhite
          sx={{ ml: 2 }}
          onClick={() =>
            navigate(PATHS.ADVERTISER_CONTRACT_DETAILS + "/" + item._id)
          }
        />,
      ]);

      return 0;
    });

    setTableData(apiData);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader
          title={
            id === "0"
              ? "Active Contracts"
              : id === "1"
              ? "Other Contracts"
              : "Contracts By Creator"
          }
        />

        {!dataLoading && (
          <>
            <CTableRow.FlexTableTop
              sx={{
                width: 900,
              }}
              titleArray={Arrays.Titles.ContractDetails}
            />
            {tableData.map((item) => (
              <CTableRow.FlexTableRow
                sx={{
                  width: 900,
                }}
                contentArray={item}
              />
            ))}
            {tableData.length === 0 && (
              <CTableRow.FlexTableRow
                sx={{
                  width: 900,
                }}
                contentArray={["No Contracts"]}
              />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdvertiserContractList;
