// React Imports
import React, { FC } from "react";

// MUI Imports
import { Button as MUIButton, ButtonBase, Box } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Local Imports
import CText from "../CText";
import Styles from "../../utils/Styles";
import { Next, Previous } from "iconsax-react";

interface CButtonsProps {
  sx?: any;
  title?: any;
  color?: any;
  onClick?: any;
  disabled?: any;
}

const OutlinedButton: FC<CButtonsProps> = (props) => {
  const onClick = () => {
    setTimeout(() => {
      if (props.onClick) {
        props.onClick();
      }
    }, 100);
  };

  return (
    <MUIButton
      variant="outlined"
      onClick={onClick}
      sx={{
        width: "180px",
        height: "40px",
        textTransform: "none",
        border: "2px solid" + props.color,
        ":hover": {
          backgroundColor: "#EEE",
          border: "2px solid" + props.color,
        },
        ...props.sx,
      }}
    >
      <CText.Outfit.Size18.SemiBold.Blue3
        style={{ color: props.color }}
        text={props.title}
      />
    </MUIButton>
  );
};

OutlinedButton.defaultProps = {
  color: Colors.buttonBlueBackground,
};

const FilledButton: FC<CButtonsProps> = (props) => {
  return (
    <MUIButton
      variant="outlined"
      onClick={props.onClick}
      disabled={props.disabled}
      sx={{
        width: "180px",
        height: "40px",
        textTransform: "none",
        backgroundColor: props.color,
        border: "none",
        ":hover": {
          border: "none",
          backgroundColor: props.color + "CC",
        },
        ":disabled": {
          backgroundColor: props.color + "AA",
        },
        ...props.sx,
      }}
    >
      <CText.Outfit.Size18.SemiBold.White text={props.title} />
    </MUIButton>
  );
};

const FilledButtonViewer: FC<CButtonsProps> = (props) => {
  return (
    <MUIButton
      variant="outlined"
      disabled={props.disabled}
      onClick={props.onClick}
      sx={{
        width: "180px",
        height: "40px",
        textTransform: "none",
        backgroundColor: Colors.cyan,
        border: "none",
        ":hover": {
          border: "none",
          backgroundColor: Colors.cyan + "CC",
        },        
        ":disabled": {
          backgroundColor: Colors.cyan + "AA",
        },
        ...props.sx,
      }}
    >
      <CText.Outfit.Size18.SemiBold.White text={props.title} />
    </MUIButton>
  );
};

const OutlinedButtonViewer: FC<CButtonsProps> = (props) => {
  return (
    <MUIButton
      variant="outlined"
      onClick={props.onClick}
      sx={{
        width: "180px",
        height: "40px",
        textTransform: "none",
        backgroundColor: "transparent",
        border: "2px solid " + Colors.cyan,
        ":hover": {
          border: "2px solid " + Colors.cyan + "CC",
        },
        ...props.sx,
      }}
    >
      <CText.Outfit.Size18.SemiBold.Cyan text={props.title} />
    </MUIButton>
  );
};

const OutlinedButtonViewer2: FC<CButtonsProps> = (props) => {
  return (
    <ButtonBase
      onClick={props.onClick}
      sx={{
        height: 35,
        minWidth: 90,
        border: "1px solid " + Colors.greyText3,
        borderRadius: 1.5,
        ":hover": {
          backgroundColor: Colors.blackBackground2,
          border: "0px solid " + Colors.white,
        },
        ...props.sx,
      }}
    >
      <CText.Outfit.Size14.Light.White
        style={{ marginRight: 16, marginLeft: 16 }}
        text={props.title}
      />
    </ButtonBase>
  );
};

const ChipButtonWhite: FC<CButtonsProps> = (props) => {
  return (
    <ButtonBase
      onClick={props.onClick}
      sx={{
        height: 35,
        minWidth: 90,
        border: "1px solid " + Colors.greyText3,
        borderRadius: 1.5,
        ":hover": {
          backgroundColor: Colors.blackBackground2,
          border: "0px solid " + Colors.white,
        },
        ...props.sx,
      }}
    >
      <CText.Outfit.Size14.Light.White
        style={{ marginRight: 16, marginLeft: 16 }}
        text={props.title}
      />
    </ButtonBase>
  );
};

const ChipButtonCyan: FC<CButtonsProps> = (props) => {
  return (
    <ButtonBase
      onClick={props.onClick}
      sx={{
        height: 35,
        minWidth: 90,
        border: "1px solid " + Colors.cyan,
        borderRadius: 1.5,
        ":hover": {
          backgroundColor: Colors.blackBackground2,
          border: "0px solid " + Colors.white,
        },
        ...props.sx,
      }}
    >
      <CText.Outfit.Size14.Light.Cyan
        style={{ marginRight: 16, marginLeft: 16 }}
        text={props.title}
      />
    </ButtonBase>
  );
};

FilledButton.defaultProps = {
  color: Colors.buttonBlueBackground,
};

interface ViewerFilled2Props {
  title?: any;
  sx?: any;
}

const ViewerFilled2: FC<ViewerFilled2Props> = (props) => {
  return (
    <ButtonBase
      sx={{
        width: "90%",
        height: 50,
        backgroundColor: Colors.blackBackground4,
        ...Styles.SStyles.midElevation,
        borderRadius: 1,
        ...props.sx,
      }}
    >
      <CText.Jakarta.Size16.Mid.White text={props.title} />
    </ButtonBase>
  );
};

interface ChangeContentButtonProps {
  type?: any;
  disabled?: any;
  onClick?: any;
  sx?: any;
}

const ChangeContentButton: FC<ChangeContentButtonProps> = (props) => {
  const handleClick = () => {
    if (props.onClick) {
      setTimeout(() => {
        props.onClick();
      }, 200);
    }
  };

  return (
    <ButtonBase
      disabled={props.disabled}
      onClick={handleClick}
      sx={{
        width: "46%",
        height: "100%",
        opacity: props.disabled ? 0.6 : 1,
        backgroundColor: Colors.blackBackground4,
        ...Styles.SStyles.midElevation,
        // borderRadius: 1
        ...Styles.SStyles.ColumnCenterCenter,
        ...Styles.CStyles.viewerHoverEffect,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FFF1",
          p: 1.5,
          borderRadius: 8,
        }}
      >
        {props.type === "previous" && (
          <Previous size="32" color={Colors.cyan} />
        )}
        {props.type === "next" && <Next size="32" color={Colors.cyan} />}
      </Box>
      <CText.Jakarta.Size16.Mid.White
        style={{ marginTop: 8 }}
        text={props.type === "previous" ? "Previous" : "Next"}
      />
    </ButtonBase>
  );
};

const CButton = {
  Outlined: OutlinedButton,
  Filled: FilledButton,
  Filled2: ViewerFilled2,
  ViewerFilled: FilledButtonViewer,
  ViewerOutlined: OutlinedButtonViewer,
  ViewerOutlined2: OutlinedButtonViewer2,
  ChipWhite: ChipButtonWhite,
  ChipCyan: ChipButtonCyan,
  ChangeContent: ChangeContentButton,
};

export default CButton;
