// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";

// Functional Imports

// Local Imports
import CText from "../../atoms/CText";
import CUIPresets from "../../atoms/CUIPresets";
import CComposite from "../../atoms/CComposite";

interface SearchSpacesProps {
  searchText?: any;
  grid?: any;
  spacesList?: any;
  dataLoading?: any;
  searched?: any;
  searchedText?: any;
}

const SearchSpaces: FC<SearchSpacesProps> = (props) => {
  return (
    <CUIPresets.AnimationWrapper>
      <>
        {/* To ask viewer to search for something */}
        {props.searchedText === "" && !props.dataLoading && (
          <CUIPresets.ViewerStatusText text={"Search something..."} />
        )}

        {/* When loading */}
        {props.dataLoading && <CUIPresets.ViewerLoading sx={{ mt: 12 }} />}

        {/* Render when no results are found */}
        {!props.dataLoading &&
          props.searchedText.length > 0 &&
          props.spacesList.length === 0 && (
            <>
              <CText.Jakarta.Size16.Mid.White
                text={'No results found for "' + props.searchedText + '"'}
                style={{ marginBottom: 8, marginTop: 16 }}
              />
              <CUIPresets.ViewerStatusText text={"No results"} />
            </>
          )}

        {/* Render when results are found */}
        {!props.dataLoading &&
          props.searchedText.length > 0 &&
          props.spacesList.length > 0 && (
            <CText.Jakarta.Size16.Mid.White
              text={'Showing results for "' + props.searchedText + '"'}
              style={{ marginBottom: 8, marginTop: 16 }}
            />
          )}

        {!props.dataLoading && (
          <Grid container sx={{ width: '100%' }} >
            <CComposite.SpacesList
              grid={props.grid}
              spacesList={props.spacesList}
            />
          </Grid>
        )}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default SearchSpaces;
