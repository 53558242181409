// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import {
  Money,
  Ankr,
  Document,
  HambergerMenu,
  VideoHorizontal,
} from "iconsax-react";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";

interface AdminCreatorAccessProps {}

const AdminCreatorAccess: FC<AdminCreatorAccessProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Creator Access" />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<Money size="60" color={Colors.blueBackground} />}
            title="Advertisements"
            onClick={() => navigate(PATHS.ADMIN_CREATOR_REQUESTS + "/" + id)}
          />
          <CUIPresets.SelectFlow
            Icon={<Document size="60" color={Colors.blueBackground} />}
            title="Master Files"
            onClick={() =>
              navigate(PATHS.ADMIN_CREATOR_VIEW_MASTER_FILES + "/" + id)
            }
          />
          <CUIPresets.SelectFlow
            Icon={<Ankr size="60" color={Colors.blueBackground} />}
            title="Creator Space"
          />
          <CUIPresets.SelectFlow
            Icon={<VideoHorizontal size="60" color={Colors.blueBackground} />}
            title="Content"
            onClick={() =>
              navigate(PATHS.ADMIN_CREATOR_VIEW_CONTENT + "/" + id)
            }
          />
          <CUIPresets.SelectFlow
            Icon={<HambergerMenu size="60" color={Colors.blueBackground} />}
            title="Subspaces"
            onClick={() =>
              navigate(PATHS.ADMIN_CREATOR_VIEW_SUBSPACES + "/" + id)
            }
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminCreatorAccess;
