// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box, Grid, IconButton } from "@mui/material";
import { Back } from "iconsax-react";

// Functional Imports
import { LocalStorage, ToastGenerator } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";
import Colors from "../../utils/Colors";
import OtpInput from "react-otp-input";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";

interface EnterOTPCompProps {
  setAuthState?: any;
  email?: any;
}

const EnterOTPComp: FC<EnterOTPCompProps> = (props) => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [disableConfirm, setDisableConfirm] = useState(false);

  const onConfirm = () => {
    setDisableConfirm(true);
    const payload = {
      email: props.email,
      otp: otp,
    };

    API.verifyOtp(payload).then((response) => {
      setDisableConfirm(false);
      ToastGenerator.success(response.message);
      LocalStorage.setString("JWT", response.token);
      LocalStorage.setString("Type", response.client?.type);
      LocalStorage.setString("ClientID", response.client?.id);
      setTimeout(() => {
        window.location.reload();
      }, 500);
      setTimeout(() => {
        navigate(PATHS.HOME);
      }, 1000);
    });
  };

  return (
    <Grid
      item
      xs={12}
      md={5}
      paddingLeft={5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        height: "100vh",
        overflowY: "scroll",
        backgroundColor: Colors.lightBlueBackground,
      }}
    >
      <CUIPresets.AnimationWrapper>
        <>
          <Box width={470} height={60} />

          <Box
            sx={{
              ...Styles.SStyles.RowCenterStart,
            }}
          >
            <IconButton
              sx={{
                ml: -3,
              }}
              onClick={() => {
                props.setAuthState("login");
              }}
            >
              <Back size="32" color={Colors.black} />
            </IconButton>
            <CText.Jakarta.Size32.Bold.Black
              style={{
                marginLeft: 8,
              }}
              text="Enter OTP"
            />
          </Box>

          <CText.Jakarta.Size18.Light.Black
            style={{
              marginTop: 24,
              width: 420,
            }}
            text={"We have sent an OTP to your registered email: "}
          />

          <CText.Jakarta.Size18.Light.Black
            style={{
              marginTop: 8,
              width: 420,
            }}
            text={props.email}
          />

          <Box
            sx={{
              width: 420,
              ...Styles.SStyles.RowCenterCenter,
              mt: 6,
              mb: 8,
            }}
          >
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              containerStyle={{
                width: 420,
              }}
              inputStyle={{
                height: 60,
                width: 45,
                fontSize: 42,
                marginRight: 24,
                borderRadius: 8,
                border: "none",
              }}
              renderSeparator={<span> </span>}
              inputType="tel"
              renderInput={(props) => <input {...props} />}
            />
          </Box>

          <CUIPresets.FilledButton
            disabled={otp.length !== 6 || disableConfirm}
            onClick={onConfirm}
            title="Confirm"
            sx={{
              width: 430,
              mt: 3,
            }}
          />
        </>
      </CUIPresets.AnimationWrapper>
    </Grid>
  );
};

export default EnterOTPComp;
