// React Imports
import React, { FC } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports

// Local Imports
import LandingPageMobile from "./LandingPageMobile";
import LandingPageDesktop from "./LandingPageDesktop";

interface LandingPageProps {}

const LandingPage: FC<LandingPageProps> = (props) => {
  return (
    <div>
      {window.innerWidth < 600 && <LandingPageMobile />}
      {window.innerWidth > 600 && <LandingPageDesktop />}
    </div>
  );
};

export default LandingPage;
