// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports

// Functional Imports
import { useParams } from "react-router-dom";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import ContractDetails from "./ContractDetails";
import ContractList from "./ContractList";

const displayContractInit = {
  adSlot: "",
  activeContract: true,
  contract: {
    entityDetails: {
      advertiser: "",
      creator: "",
      content: "",
    },
    adSlotDetails: {
      selectedCountryCode: "",
      selectedAdSlot: "",
      selectedAdFile: "",
    },
    contractTerms: {
      typeOfContract: "",
      duration: 0,
      rateOramount: 0,
    },
    startDate: "",
    endDate: "",
  },
  adFile: {
    bunnyStreamLink: "",
  },
};

interface ActiveContractsProps {}

const ActiveContracts: FC<ActiveContractsProps> = (props) => {
  const { id } = useParams();

  const [dataLoading, setDataLoading] = useState(false);
  const [listOrDetails, setListOrDetails] = useState("list");

  const [contractsDetails, setContractsDetails] = useState([]);
  const [displayContract, setDisplayContract] = useState(displayContractInit);

  useEffect(() => {
    setDataLoading(true);
    API.getActiveContractsAndStreamLinks(id).then((response) => {
      let contractsData: any = [];

      response.details?.activeContracts?.map((item: any) => {
        let contractItemData = { ...item };

        response.details?.streamLinks?.map((itemChild: any) => {
          if (itemChild.countryCode === item.countryCode) {
            contractItemData = {
              ...contractItemData,
              streamLink: itemChild.streamLink,
            };
          }

          return 0;
        });

        contractsData.push(contractItemData);

        return 0;
      });

      setContractsDetails(contractsData);
      setDataLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (dataLoading) {
    return <CUIPresets.LoadingSpinner />;
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          {listOrDetails === "list" && (
            <ContractList
              contractsDetails={contractsDetails}
              setListOrDetails={setListOrDetails}
              setDisplayContract={setDisplayContract}
            />
          )}
          {listOrDetails === "details" && (
            <ContractDetails
              displayContract={displayContract}
              setListOrDetails={setListOrDetails}
            />
          )}
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default ActiveContracts;
