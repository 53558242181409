// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import {
  AddSquare,
  HambergerMenu,  HierarchySquare3,
} from "iconsax-react";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";

interface CreatorCreatorSpaceProps {}

const CreatorCreatorSpace: FC<CreatorCreatorSpaceProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Creator's Space" disabled />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<AddSquare size="60" color={Colors.blueBackground} />}
            title="Create New Subspace"
            onClick={() => navigate(PATHS.CREATOR_CREATE_NEW_SUBSPACE + "/0")}
            />
          <CUIPresets.SelectFlow
            Icon={<HambergerMenu size="60" color={Colors.blueBackground} />}
            title="Manage Subspaces"
            onClick={() => navigate(PATHS.CREATOR_MANAGE_SUBSPACES)}
          />
          <CUIPresets.SelectFlow
            Icon={<HierarchySquare3 size="60" color={Colors.blueBackground} />}
            title="Space Configurations"
            onClick={() => navigate(PATHS.CREATOR_SPACE_CONFIGURATION)}
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorCreatorSpace;
