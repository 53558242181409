// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box, IconButton } from "@mui/material";
import { BackSquare } from "iconsax-react";

// Functional Imports
import { DateFunctions, Information } from "../../utils/Functions";
import Styles from "../../utils/Styles";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import CTableRow from "../../atoms/CTableRow";
import API from "../../api/API";

interface ContractDetailsProps {
  setListOrDetails?: any;
  displayContract?: any;
}

const ContractDetails: FC<ContractDetailsProps> = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [contractDetails, setContractDetails] = useState<any>();
  const [transactionsTable, setTransactionsTable] = useState([]);
  const [totalTransfered, setTotalTransfered] = useState(0);

  useEffect(() => {
    setDataLoading(true);
    API.getContractDetails(props.displayContract).then((response) => {
      setDataLoading(false);
      setContractDetails(response.contract?.contractDetails);
      generateTransactionsData(
        response.contract?.transactions,
        response.contract?.contractDetails?.contractTerms?.rateOramount
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.displayContract]);

  const generateTransactionsData = (transactions: any, rateOrAmount: any) => {
    const apiData: any = [];
    let totalTransfered = 0;

    transactions?.map((item: any) => {
      if (item.type === "ATC") {
        apiData.push([
          DateFunctions.format(item.date),
          String(item.views),
          String(rateOrAmount),
          String(item.amountInSSC),
        ]);

        totalTransfered = totalTransfered + item.amountInSSC;
      }

      return 0;
    });

    setTotalTransfered(totalTransfered);
    setTransactionsTable(apiData);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Box
            sx={{
              ...Styles.SStyles.RowCenterStart,
            }}
          >
            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 12,
              }}
              text="Contract Participants"
            />
            <IconButton onClick={() => props.setListOrDetails("list")}>
              <BackSquare color={Colors.blueText} size={24} />
            </IconButton>
          </Box>

          {dataLoading && <CUIPresets.LoadingSpinner />}

          {!dataLoading && (
            <>
              <CUIPresets.LabelValueHorizontal
                sx={{ mt: 1 }}
                label={"Creator Name"}
                value={contractDetails?.entityDetails?.creator?.fullName}
              />
              <CUIPresets.LabelValueHorizontal
                label={"Content Name"}
                value={
                  contractDetails?.entityDetails?.content?.contentDetails?.name
                }
              />
              <CUIPresets.LabelValueHorizontal
                label={"Advertiser Name"}
                value={contractDetails?.entityDetails?.advertiser?.fullName}
              />
              <CUIPresets.LabelValueHorizontal
                label={"Start Date"}
                value={DateFunctions.format(contractDetails?.startDate)}
              />
              <CUIPresets.LabelValueHorizontal
                label={"End Date"}
                value={DateFunctions.format(contractDetails?.endDate)}
              />

              <CText.Roboto.Size28.Light.BlackUnderline
                style={{
                  marginBottom: 16,
                  marginTop: 32,
                }}
                text="Ad Slot Details"
              />

              <CUIPresets.LabelValueHorizontal
                label={"Country"}
                value={Information.retrieveCountry(
                  contractDetails?.adSlotDetails?.selectedCountryCode
                )}
              />
              <CUIPresets.LabelValueHorizontal
                label={"Ad Slot"}
                value={contractDetails?.adSlotDetails?.selectedAdSlot}
              />
              {/* <CUIPresets.LabelIframeHorizontal
            label="Ad Preview"
            iframe={APIResponse.contractDetails?.adSlotDetails?.selectedAdFile}
          /> */}

              <CText.Roboto.Size28.Light.BlackUnderline
                style={{
                  marginBottom: 16,
                  marginTop: 32,
                }}
                text="Contract Terms"
              />
              <CUIPresets.LabelValueHorizontal
                label={"Type Of Contract"}
                value={contractDetails?.contractTerms?.typeOfContract}
              />
              <CUIPresets.LabelValueHorizontal
                label={"Amount"}
                value={contractDetails?.contractTerms?.rateOramount}
              />
              <CUIPresets.LabelValueHorizontal
                label={"Contract Duration"}
                value={contractDetails?.contractTerms?.duration}
              />
              <CText.Roboto.Size28.Light.BlackUnderline
                style={{
                  marginBottom: 16,
                  marginTop: 32,
                }}
                text="Active Data"
              />
              <CUIPresets.LabelValueHorizontal
                label={"Total Transferred"}
                value={totalTransfered}
              />
              <CUIPresets.LabelValueHorizontal
                label={"Lapsed Duration"}
                value={DateFunctions.differenceInDays(
                  contractDetails?.startDate,
                  new Date()
                )}
              />
              <CUIPresets.LabelValueHorizontal
                label={"Remaining Duration"}
                value={DateFunctions.differenceInDays(
                  new Date(),
                  contractDetails?.endDate
                )}
              />

              <CText.Roboto.Size28.Light.BlackUnderline
                style={{
                  marginBottom: 16,
                  marginTop: 32,
                }}
                text="Transactions"
              />
              <CTableRow.FlexTableTop titleArray={CTVArray} />
              {transactionsTable.map((item) => (
                <CTableRow.FlexTableRow contentArray={item} />
              ))}
            </>
          )}
        </Box>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default ContractDetails;

const CTVArray = ["Date", "Views", "Rate", "Amount"];
