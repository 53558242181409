// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box, Button } from "@mui/material";

// Functional Imports
import { LocalStorage } from "../../utils/Functions";
import API from "../../api/API";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CComposite from "../../atoms/CComposite";
import CInputOutput from "../../atoms/CInputOutput";
import CText from "../../atoms/CText";
import Colors from "../../utils/Colors";
import CTools from "../../atoms/CTools";

interface AdvertiserProfileInfoProps {}

const AdvertiserProfileInfo: FC<AdvertiserProfileInfoProps> = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    fullName: "",
    userName: "",
    email: "",
  });
  const [editEnabled, setEditEnabled] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    setDataLoading(true);
    API.getClientAccountById(LocalStorage.getString("ClientID")).then(
      (response) => {
        setDataLoading(false);
        setProfileInfo(response.client);
      }
    );
  }, []);

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Profile Info" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Profile Info" />

          <Box sx={{ mt: 1, ml: 1 }}>
            <CUIPresets.LabelValueHorizontal
              label={"Email"}
              value={profileInfo.email}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Username"}
              value={profileInfo.userName}
            />
            {!editEnabled && (
              <>
                <CUIPresets.LabelValueHorizontal
                  label={"Full Name"}
                  value={profileInfo.fullName}
                />

                <CUIPresets.LabelValueHorizontal
                  label={"Description"}
                  value={"-"}
                />

                <CUIPresets.LabelValueHorizontal
                  label={"Profile Image"}
                  value={"-"}
                />

                <CUIPresets.OutlinedButton
                  sx={{
                    width: 150,
                  }}
                  title="Edit"
                  onClick={() => setEditEnabled(true)}
                />
              </>
            )}

            {editEnabled && (
              <>
                <CInputOutput.LabelInputHorizontal
                  label="Full Name"
                  textFieldProps={{
                    placeholder: "Enter duration",
                    value: profileInfo.fullName,
                  }}
                  sx={{
                    mt: -2,
                  }}
                />

                <CInputOutput.LabelInputHorizontal
                  label="Description"
                  textFieldProps={{
                    placeholder: "Enter description",
                    value: profileInfo.fullName,
                  }}
                  sx={{
                    mt: 0,
                  }}
                />

                <LabelImageUpload
                  image={'https://plus.unsplash.com/premium_photo-1661572997144-066a404b67bb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dWklMjB1eHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60npm'}
                  setImage={setSelectedImage}
                />

                <CComposite.FooterActions.Filled
                  onClick={() => undefined}
                  onCancel={() => setEditEnabled(false)}
                  sx={{
                    mb: 4,
                    mt: 2,
                    width: 560,
                  }}
                  confirmText={"Confirm"}
                />
              </>
            )}
          </Box>
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdvertiserProfileInfo;

interface LabelImageUploadProps {
  image?: any;
  setImage?: any;
}

const LabelImageUpload: FC<LabelImageUploadProps> = (props) => {
  return (
    <Box
      sx={{
        width: 560,
        ...Styles.SStyles.RowCenterSpace,
      }}
    >
      <CText.Jakarta.Size16.Mid.Grey3 text={"Profile Image"} />

      <Box width={240}>
        <CTools.ImageContainer src={props.image} />

        <Button
          variant="outlined"
          sx={{
            mt: 1,
            width: "240px",
            height: "40px",
            textTransform: "none",
            border: "2px solid " + Colors.buttonBlueBackground,
            ":hover": {
              backgroundColor: "#EEE",
              border: "2px solid" + Colors.buttonBlueBackground,
            },
          }}
          component="label"
        >
          <CText.Outfit.Size18.SemiBold.Blue3
            style={{ color: Colors.buttonBlueBackground }}
            text={"Upload Image"}
          />
          <input
            type="file"
            accept={"image/*"}
            hidden
            onChange={(event: any) => {
              const EventTarget = event.target.files[0];

              if (EventTarget) {
                props.setImage(EventTarget);
              }
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};
