// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import {
  DateFunctions,
  Information,
  ToastGenerator,
} from "../../utils/Functions";
import { useParams } from "react-router-dom";
import API from "../../api/API";
import moment from "moment";
import Styles from "../../utils/Styles";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CComposite from "../../atoms/CComposite";
import CText from "../../atoms/CText";
import CTableRow from "../../atoms/CTableRow";

interface CreatorContentWatchtimeDetailsProps {}

const CreatorContentWatchtimeDetails: FC<
  CreatorContentWatchtimeDetailsProps
> = (props) => {
  const { id } = useParams();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [optionSelected, setOptionSelected] = useState(
    Constants.TYPES.WATCHTIME_DETAILS.BY_COUNTRY
  );

  const [dataLoading, setDataLoading] = useState(false);
  const [contentName, setContentName] = useState("");
  const [aggregateData, setAggregateData] = useState([]);
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    const endDateTemp = new Date();
    const startDateTemp = new Date(
      new Date().setDate(endDateTemp.getDate() - 30)
    );
    setStartDate(startDateTemp);

    apiCall(id, startDateTemp, endDateTemp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apiCall = (id: any, startDate: any, endDate: any) => {
    setDataLoading(true);
    API.getViewsAndWatchtimeDetails(
      id,
      DateFunctions.format(startDate, "YYYY-MM-DD"),
      DateFunctions.format(endDate, "YYYY-MM-DD")
    ).then((response) => {
      setDataLoading(false);
      setContentName(response.viewsAndWatchtimeDetails?.contentName);
      populateAggregateData(
        response.viewsAndWatchtimeDetails?.viewsAndWatchtimeByCountry
      );
      populateRawData(
        response.viewsAndWatchtimeDetails?.viewsAndWatchtimeByDate
      );
    });
  };

  const populateAggregateData = (dataArray: any) => {
    let pushData: any = [];
    dataArray.map((item: any) => {
      pushData.push([
        Information.retrieveCountry(item.countryCode),
        String(item.views),
        String(item.watchTime),
      ]);

      return 0;
    });
    setAggregateData(pushData);
  };

  const populateRawData = (dataArray: any) => {
    let pushData: any = [];
    dataArray.map((item: any) => {
      pushData.push([
        DateFunctions.format(item.date),
        Information.retrieveCountry(item.countryCode),
        String(item.views),
        String(item.watchTime),
      ]);

      return 0;
    });
    setRawData(pushData);
  };

  return (
    <>
      <CUIPresets.PageHeader title={"Watchtime Details"} />

      <Box
        sx={{
          ...Styles.SStyles.RowCenterSpace,
          width: 400,
        }}
      >
        <CText.Jakarta.Size16.Mid.Black text="Content Name" />
        <CText.Jakarta.Size16.Mid.Black text={contentName} />
      </Box>

      <Box
        sx={{
          ...Styles.SStyles.RowCenterSpace,
          mt: 2,
          width: 400,
        }}
      >
        <CText.Jakarta.Size16.Mid.Black text="Start Date" />
        <CComposite.DatePicker date={startDate} setDate={setStartDate} />
      </Box>

      <Box
        sx={{
          ...Styles.SStyles.RowCenterSpace,
          width: 400,
          mt: 2,
        }}
      >
        <CText.Jakarta.Size16.Mid.Black text="End Date" />
        <CComposite.DatePicker date={endDate} setDate={setEndDate} />
      </Box>

      <CUIPresets.OutlinedButton
        sx={{
          mt: 2,
          ml: 19,
          width: 250,
        }}
        title="Apply"
        onClick={() => {
          if (moment(startDate).isAfter(endDate)) {
            ToastGenerator.info("Start date has to be before End date!");
            return;
          }

          apiCall(id, startDate, endDate);
        }}
      />

      <CUIPresets.HorizontalOptionsBar
        options={[
          Constants.TYPES.WATCHTIME_DETAILS.ALL,
          Constants.TYPES.WATCHTIME_DETAILS.BY_COUNTRY,
        ]}
        sx={{
          width: 300,
          mt: 2,
        }}
        value={optionSelected}
        setValue={(e: any) => setOptionSelected(e)}
      />

      {dataLoading && <CUIPresets.LoadingSpinner />}

      {!dataLoading &&
        optionSelected === Constants.TYPES.WATCHTIME_DETAILS.ALL && (
          <>
            <CTableRow.FlexTableTop
              sx={{
                mt: 2,
              }}
              titleArray={["Date", "Country", "Views", "Watchtime"]}
            />
            {rawData.map((item) => (
              <CTableRow.FlexTableRow contentArray={item} />
            ))}
          </>
        )}

      {!dataLoading &&
        optionSelected === Constants.TYPES.WATCHTIME_DETAILS.BY_COUNTRY && (
          <>
            <CTableRow.FlexTableTop
              sx={{
                mt: 2,
              }}
              titleArray={["Country", "Views", "Watchtime"]}
            />
            {aggregateData.map((item) => (
              <CTableRow.FlexTableRow contentArray={item} />
            ))}
          </>
        )}
    </>
  );
};

export default CreatorContentWatchtimeDetails;
