// React Imports
import React from "react";
import Dashboard from "../components/Dashboard";
import RoutePath from "./RoutePath";
import { LocalStorage } from "../utils/Functions";

// Functional Imports

export const ROUTE = {
  ADMIN: "admin",
  CREATOR: "creator",
  ADVERTISER: "advertiser",
  VIEWER: "viewer",
  EMPTY: "",
};

// Local Imports

const RouteSetter = () => {
  const JWT = LocalStorage.getString("JWT");
  const Type = LocalStorage.getString("Type");

  if (JWT !== "" && Type === ROUTE.ADMIN) {
    return <Dashboard.Admin />;
  } else if (JWT !== "" && Type === ROUTE.CREATOR) {
    return <Dashboard.Creator />;
  } else if (JWT !== "" && Type === ROUTE.ADVERTISER) {
    return <Dashboard.Advertiser />;
  } else if (JWT !== "" && Type === ROUTE.VIEWER) {
    return <RoutePath.Viewer />;
  } else if (Type === ROUTE.EMPTY || JWT === "") {
    return <RoutePath.NoAuth />;
  } else {
    return null;
  }
};

export default RouteSetter;
