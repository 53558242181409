// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { ToastGenerator } from "../../utils/Functions";
import API from "../../api/API";
import Styles from "../../utils/Styles";
import Constants from "../../utils/Constants";
import OTPInput from "react-otp-input";

// Local Imports
import CText from "../../atoms/CText";
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";

interface ChangePasswordProps {
  setAuthState?: any;
  email?: any;
}

const ChangePassword: FC<ChangePasswordProps> = (props) => {
  const [otp, setOtp] = useState("");
  const [enterOtpOrChangePassword, setEnterOtpOrChangePassword] =
    useState(true);
  const [disableConfirm, setDisableConfirm] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleNextAndConfirm = () => {
    if (enterOtpOrChangePassword) {
      setEnterOtpOrChangePassword(false);
    } else {
      if (newPassword === "" || confirmPassword === "") {
        ToastGenerator.infoDark("Missing fields!");
        return;
      }

      if (newPassword !== confirmPassword) {
        ToastGenerator.infoDark("Passwords do not match");
        return;
      }

      if (newPassword.length < 8) {
        ToastGenerator.infoDark("Password too short!");
        return;
      }

      const payload = {
        otp: otp,
        email: props.email,
        newPassword: newPassword,
      };
      setDisableConfirm(true);

      API.viewerVerifyChangePassword(payload).then((response) => {
        setDisableConfirm(false);
        if (response.actionSuccess) {
          ToastGenerator.successDark("Password changed successfully!");
          props.setAuthState(Constants.TYPES.VIEWER_AUTH_STATES.LOGIN);
        }
      });
    }
  };

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: 500,
          height: 500,
        }}
      >
        {enterOtpOrChangePassword && (
          <>
            <CText.Jakarta.Size24.SemiBold.White
              style={{ marginTop: 32, marginLeft: 32 }}
              text="Enter OTP"
            />

            <CText.Jakarta.Size18.Light.White
              style={{ marginTop: 16, marginLeft: 32 }}
              text="Enter the OTP sent to your registered email:"
            />
            <CText.Jakarta.Size18.Light.White
              style={{ marginTop: 4, marginLeft: 32 }}
              text={props.email}
            />
            <Box
              sx={{
                // width: 420,
                ...Styles.SStyles.RowCenterCenter,
                mt: 4,
                mb: 4,
              }}
            >
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle={{
                  height: 60,
                  width: 42,
                  fontSize: 42,
                  marginRight: 24,
                  borderRadius: 8,
                  backgroundColor: "black",
                  border: "1px solid grey",
                  color: "white",
                }}
                renderSeparator={<span> </span>}
                inputType="tel"
                renderInput={(props) => <input {...props} />}
              />
            </Box>
          </>
        )}
        {!enterOtpOrChangePassword && (
          <>
            <CText.Jakarta.Size24.SemiBold.White
              style={{ marginTop: 32, marginLeft: 32 }}
              text="Change Password"
            />
            <CInputOutput.LabelInputLightPassword
              sx={{ width: 400, mt: 2, ml: 4 }}
              label="New Password"
              textFieldProps={{
                placeholder: "",
                value: newPassword,
                onChange: (e: any) => setNewPassword(e.target.value),
              }}
            />

            <CInputOutput.LabelInputLightPassword
              sx={{ width: 400, mt: 2, ml: 4 }}
              label="Confirm Password"
              textFieldProps={{
                placeholder: "",
                value: confirmPassword,
                onChange: (e: any) => setConfirmPassword(e.target.value),
              }}
            />
          </>
        )}
        <CUIPresets.ViewerFilled
          onClick={handleNextAndConfirm}
          disabled={enterOtpOrChangePassword ? otp.length < 6 : disableConfirm}
          title={enterOtpOrChangePassword ? "Next" : "Confirm"}
          sx={{ width: 400, height: 40, ml: 4, mt: 4 }}
        />

        <CUIPresets.ViewerOutlined
          title={"Back"}
          onClick={() => {
            if (enterOtpOrChangePassword) {
              props.setAuthState(Constants.TYPES.VIEWER_AUTH_STATES.LOGIN);
            } else {
              setEnterOtpOrChangePassword(true);
            }
          }}
          sx={{ width: 400, height: 40, ml: 4, mt: 2 }}
        />
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default ChangePassword;
