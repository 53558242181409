// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { useParams } from "react-router-dom";
import Constants from "../../utils/Constants";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import RaisingStatus from "./RaisingStatus";
import RaisedStatus from "./RaisedStatus";

const requestDetailsEmpty = {
  requestDetails: {
    amountInSSC: 0,
    currency: "",
    amountInCurrency: 0,
    modeOfPayment: "",
    receiverDetails: {
      paypalId: "",
      upiId: "",
      accountNumber: "",
      accountHolderName: "",
      ifscCode: "",
    },
    createdAt: "",
  },
  _id: "",
  clientId: "",
  fullName: "",
  status: "",
  requestType: "",
};

interface CreatorRaisePaymentRequestsProps {}

const CreatorRaisePaymentRequests: FC<
  CreatorRaisePaymentRequestsProps
> = (props) => {
  const { id } = useParams();

  const [active, setActive] = useState(
    Constants.STATUS_FLAGS.WITHDRAW_FUNDS_REQUEST.RAISING
  );
  const [requestDetails, setRequestDetails] = useState(requestDetailsEmpty);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (id === "0") {
      setActive(Constants.STATUS_FLAGS.WITHDRAW_FUNDS_REQUEST.RAISING);
    } else {
      setDataLoading(true);
      API.getPaymentRequestById(id).then((response) => {        
        setRequestDetails(response.paymentSelected);
        setActive(response.paymentSelected?.status);
        setDataLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Payment Request" />

        {!dataLoading &&
          active === Constants.STATUS_FLAGS.WITHDRAW_FUNDS_REQUEST.RAISING && (
            <RaisingStatus />
          )}

        {dataLoading && <CUIPresets.LoadingSpinner />}

        {!dataLoading &&
          active !== Constants.STATUS_FLAGS.WITHDRAW_FUNDS_REQUEST.RAISING && (
            <RaisedStatus requestDetails={requestDetails} />
          )}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorRaisePaymentRequests;
