// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box, TextField, InputAdornment } from "@mui/material";
import { SearchNormal1 } from "iconsax-react";

// Functional Imports
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import API from "../../api/API";
import ACTION_TYPES from "../../redux/actions/actionTypes";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import SearchSpaces from "./SearchSpaces";

interface ViewerSearchProps {}

const ViewerSearch: FC<ViewerSearchProps> = (props) => {
  const dispatch = useDispatch();

  const grid = useSelector((state: any) => state.appState.grid);
  const searchedText = useSelector((state: any) => state.appState.searchedText);
  const searched = useSelector((state: any) => state.appState.searched);
  const spacesList = useSelector((state: any) => state.appState.spacesList);

  const [dataLoading, setDataLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const onSearch = () => {
    if (searchText === "") return;

    setDataLoading(true);
    API.searchSpaces(searchText).then((response) => {
      setDataLoading(false);
      dispatch({ type: ACTION_TYPES.SEARCHED, payload: true });
      dispatch({ type: ACTION_TYPES.SEARCHED_TEXT, payload: searchText });
      dispatch({ type: ACTION_TYPES.SPACES_LIST, payload: response.spaces });
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          backgroundColor: Colors.blackBackground2,
          height: "100vh",
          width: "100vw",
        }}
      >
        <CUIPresets.ViewerTopBar
          options={[]}
          selected={""}
          setSelected={() => undefined}
        />

        <Box
          sx={{
            height: 56,
            width: "100vw",
            backgroundColor: Colors.blackBackground4,
            ...Styles.SStyles.RowCenterSpace,
          }}
        >
          <Box
            sx={{
              ...Styles.SStyles.RowCenterCenter,
            }}
          >
            <TextField
              variant="standard"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchNormal1
                      style={{ marginRight: 12 }}
                      size={18}
                      color={Colors.greyText5}
                    />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Spaces"
              onWheel={(event) => {
                event.preventDefault();
              }}
              sx={{
                width: "400px",
                height: "40px",
                justifyContent: "center",
                paddingLeft: 1,
                borderRadius: "3px",
                borderWidth: 0,
                fontFamily: "Plus Jakarta",
                fontWeight: 400,
                backgroundColor: Colors.greyBackground2,
                ml: 4,
                input: { color: Colors.white },
              }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <CUIPresets.ViewerOutlined2
              onClick={() => onSearch()}
              title="Search"
              sx={{ ml: 2 }}
            />
          </Box>
          <CUIPresets.GridSelection />
        </Box>

        <Box
          sx={{
            ml: 4,
            overflowY: "scroll",
            height: window.innerHeight - 140,
          }}
        >
          <SearchSpaces
            dataLoading={dataLoading}
            spacesList={spacesList}
            grid={grid}
            searchText={searchText}
            searched={searched}
            searchedText={searchedText}
          />
        </Box>
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default ViewerSearch;
