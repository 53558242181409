// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  Calculations,
  NumberTypeInputProps,
  ToastGenerator,
} from "../../utils/Functions";
import * as yup from "yup";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";
import CComposite from "../../atoms/CComposite";
import CText from "../../atoms/CText";

const initValues = {
  amountInSSC: "",
  currency: "",
  modeOfPayment: "",
  txnId: "",
  senderAddress: "",
  image: {
    name: "",
    file: null,
    size: "",
  },
  dateOfTransaction: null,
};

interface CreatorWatchtimeUploadProofProps {}

const CreatorWatchtimeUploadProof: FC<CreatorWatchtimeUploadProofProps> = (
  props
) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [conversionFactor, setConversionFactor]: any = useState("");
  const [currencyConversions, setCurrencyConversions] = useState({
    USD: 0,
    INR: 0,
  });

  const [dataLoading, setDataLoading] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  useEffect(() => {
    API.getCurrencyConversion().then((response) => {
      setCurrencyConversions({
        USD: response.currencyConversion?.[0]?.USD,
        INR: response.currencyConversion?.[0]?.INR,
      });
    });

    setDataLoading(true);
    API.getWatchtimeBillByIdCreator(id).then((response) => {
      setDataLoading(false);
      formik.setFieldValue(
        "amountInSSC",
        response.watchtimeBill?.totalAmountInSSC
      );
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({
      amountInSSC: yup.string().required("Amount is required"),
      currency: yup.string().required("Currency is required"),
      modeOfPayment: yup.string().required("Mode of Payment is required"),
    }),
    onSubmit: (values: any) => {
      setModal({
        open: true,
        apiLoading: false,
      });
    },
  });

  const confirmModalCall = () => {
    setModal({
      open: true,
      apiLoading: true,
    });

    console.log("formik.values");
    console.log(JSON.stringify(formik.values, null, 4));

    const payload = {
      amountInSSC: formik.values.amountInSSC,
      currency: formik.values.currency,
      amountInCurrency: Calculations.rounding(
        conversionFactor * Number(formik.values.amountInSSC),
        0
      ),
      modeOfPayment: formik.values.modeOfPayment,
      senderAddress: formik.values.senderAddress,
      dateOfTransaction: formik.values.dateOfTransaction,
      txnId: formik.values.txnId,
      image: formik.values.image.file,
    };

    API.uploadProofForWatchtimeBill(payload, id).then((response) => {
      if (response.message === Constants.API_RESPONSES.PROOF_UPLOAD_SUCCESS) {
        ToastGenerator.success(response.message);
        setModal({
          open: false,
          apiLoading: false,
        });
        navigate(-1);
      }
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title={"Upload Proof - Watchtime Bill"} />

        <CUIPresets.LabelValueHorizontal
          label={"Amount"}
          value={Calculations.rounding(formik.values.amountInSSC) + " SSC"}
        />

        <CInputOutput.SelectBoxOutlined
          label="Currency"
          placeholder="Select Currency Of Payment"
          sx={{
            width: 400,
            mr: 18,
            mt: 0,
          }}
          options={[
            { name: "INR", code: "INR" },
            { name: "USD", code: "USD" },
          ]}
          xsKey="code"
          xsValue="name"
          error={formik.touched.currency && formik.errors.currency}
          selected={formik.values.currency}
          setValue={(e: any) => {
            formik.setFieldValue("currency", e.target.value);
            formik.setFieldValue("modeOfPayment", "");
            if (e.target.value === "INR") {
              setConversionFactor(currencyConversions.INR);
            } else if (e.target.value === "USD") {
              setConversionFactor(currencyConversions.USD);
            }
          }}
        />

        {formik.values.currency !== "" && (
          <>
            <CInputOutput.SelectBoxOutlined
              label="Mode of Payment"
              placeholder="Select Mode Of Payment"
              sx={{
                width: 400,
                mr: 18,
              }}
              options={
                formik.values.currency === "USD"
                  ? [{ name: "Paypal", code: "PAYPAL" }]
                  : [
                      { name: "Bank Account", code: "BANK" },
                      { name: "UPI", code: "UPI" },
                    ]
              }
              xsKey="code"
              xsValue="name"
              error={
                formik.touched.modeOfPayment && formik.errors.modeOfPayment
              }
              selected={formik.values.modeOfPayment}
              setValue={(e: any) => {
                formik.setFieldValue("modeOfPayment", e.target.value);
              }}
            />

            <CUIPresets.LabelValueVertical2
              label={"Exchange Rate"}
              value={
                "1 SSC = " + conversionFactor + " " + formik.values.currency
              }
            />

            <CUIPresets.LabelValueVertical2
              label={"Amount To Be Sent"}
              value={
                Calculations.rounding(
                  conversionFactor * Number(formik.values.amountInSSC),
                  0
                ) +
                " " +
                formik.values.currency
              }
            />
          </>
        )}

        {formik.values.modeOfPayment ===
          Constants.TYPES.MODE_OF_PAYMENTS.UPI && (
          <>
            <CText.Jakarta.Size18.Light.Grey1
              text="Make your payment to the following UPI address"
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
            />

            <CUIPresets.LabelValueHorizontal
              label={"UPI ID"}
              value="7795180292@ybl"
            />
          </>
        )}

        {formik.values.modeOfPayment ===
          Constants.TYPES.MODE_OF_PAYMENTS.BANK && (
          <>
            <CText.Jakarta.Size18.Light.Grey1
              text="Make your payment to the following Bank Account"
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Account Holder Name"}
              value="Basavachetan Mathapati"
            />

            <CUIPresets.LabelValueHorizontal
              label={"Account Number"}
              value="847276487434"
            />

            <CUIPresets.LabelValueHorizontal
              label={"IFSC Code"}
              value="SBIN0001729"
            />
          </>
        )}

        {formik.values.modeOfPayment ===
          Constants.TYPES.MODE_OF_PAYMENTS.PAYPAL && (
          <>
            <CText.Jakarta.Size18.Light.Grey1
              text="Make your payment to the following paypal address"
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Paypal Address"}
              value="paypal@username"
            />
          </>
        )}

        {formik.values.modeOfPayment !== "" && (
          <>
            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Proof of Payment"
            />

            <CText.Jakarta.Size18.Light.Grey1
              text="Upload a screenshot of the payment."
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
            />

            <CInputOutput.SelectImage
              title={"Upload Proof"}
              setImage={(ev: any) => {
                formik.setFieldValue("image", {
                  file: ev,
                  name: ev.name,
                  size: Number(ev.size) / (1024 * 1024),
                });
              }}
            />

            <CUIPresets.SelectedFile
              imageExists={formik.values.image.file}
              fileName={formik.values.image.name}
              fileSize={formik.values.image.size}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Transaction Details"
            />

            <CInputOutput.LabelInputOutlined
              sx={{ width: 400, mt: 1 }}
              label="Transaction ID"
              textFieldProps={{
                placeholder: "Enter Txn ID",
                helperText: formik.touched.txnId && formik.errors.txnId,
                error: Boolean(formik.touched.txnId && formik.errors.txnId),
                onBlur: formik.handleBlur,
                onChange: formik.handleChange,
                value: formik.values.txnId,
                name: "txnId",
              }}
            />

            <CInputOutput.LabelInputOutlined
              sx={{ width: 400, mt: 2 }}
              label={
                formik.values.modeOfPayment ===
                Constants.TYPES.MODE_OF_PAYMENTS.BANK
                  ? "Sender's Account Number"
                  : formik.values.modeOfPayment ===
                    Constants.TYPES.MODE_OF_PAYMENTS.PAYPAL
                  ? "Sender's Paypal ID"
                  : "Sender's UPI ID"
              }
              textFieldProps={{
                placeholder: "Enter Address",
                helperText:
                  formik.touched.senderAddress && formik.errors.senderAddress,
                error: Boolean(
                  formik.touched.senderAddress && formik.errors.senderAddress
                ),
                onBlur: formik.handleBlur,
                onChange: formik.handleChange,
                value: formik.values.senderAddress,
                name: "senderAddress",
              }}
            />

            <CInputOutput.DatePicker.Vertical
              label="Date of Transaction"
              value={formik.values.dateOfTransaction}
              touched={Boolean(formik.touched.dateOfTransaction)}
              setValue={(newDate: any) =>
                formik.setFieldValue("dateOfTransaction", newDate)
              }
              sx={{ mr: 1 }}
              error={formik.errors.dateOfTransaction}
            />

            <CComposite.FooterActions.Filled
              onClick={() => formik.handleSubmit()}
              sx={{
                mt: 4,
                mb: 4,
                width: 760,
              }}
              confirmText={"Upload Proof"}
            />
          </>
        )}

        <CUIPresets.CustomDialog
          title="Raise Request"
          content={["Are you sure about raising this request?"]}
          confirmText={"Confirm"}
          cancelText="Cancel"
          confirmCall={confirmModalCall}
          modalOpen={modal.open}
          cancelCall={() =>
            setModal({
              open: false,
              apiLoading: false,
            })
          }
          apiLoading={modal.apiLoading}
        />
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorWatchtimeUploadProof;
