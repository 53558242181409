// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Calculations,
  NumberTypeInputProps,
  ToastGenerator,
} from "../../utils/Functions";
import * as yup from "yup";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";
import CComposite from "../../atoms/CComposite";

const initValues = {
  amountInSSC: "",
  currency: "",
  modeOfPayment: "",
  paypalId: "",
  upiId: "",
  accountNumber: "",
  accountHolderName: "",
  ifscCode: "",
};

interface RaisingStatusProps {}

const RaisingStatus: FC<RaisingStatusProps> = (props) => {
  const navigate = useNavigate();
  const [conversionFactor, setConversionFactor]: any = useState("");
  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });
  const [walletBalance, setWalletBalance] = useState(0);
  const [currencyConversions, setCurrencyConversions] = useState({
    USD: 0,
    INR: 0,
  });

  useEffect(() => {
    API.getWalletBalance().then((response) => {
      setWalletBalance(response.wallet?.balance);
    });

    API.getCurrencyConversion().then((response) => {
      setCurrencyConversions({
        USD: response.currencyConversion?.[0]?.USD,
        INR: response.currencyConversion?.[0]?.INR,
      });
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({
      amountInSSC: yup
        .number()
        .max(walletBalance, "Amount can't exceed Balance!")
        .required("Amount is required"),
      currency: yup.string().required("Currency is required"),
    }),
    onSubmit: (values: any) => {
      if (
        formik.values.modeOfPayment ===
          Constants.TYPES.MODE_OF_PAYMENTS.PAYPAL &&
        formik.values.paypalId === ""
      ) {
        ToastGenerator.info("Add Paypal ID");
        return;
      }

      if (
        formik.values.modeOfPayment === Constants.TYPES.MODE_OF_PAYMENTS.UPI &&
        formik.values.upiId === ""
      ) {
        ToastGenerator.info("Add UPI ID");
        return;
      }

      if (
        formik.values.modeOfPayment === Constants.TYPES.MODE_OF_PAYMENTS.BANK &&
        (formik.values.accountHolderName === "" ||
          formik.values.accountNumber === "" ||
          formik.values.ifscCode === "")
      ) {
        ToastGenerator.info("Add Bank Details");
        return;
      }

      setModal({
        open: true,
        apiLoading: false,
      });
    },
  });

  const confirmModalCall = () => {
    setModal({
      open: true,
      apiLoading: true,
    });

    const payload = {
      amountInSSC: formik.values.amountInSSC,
      currency: formik.values.currency,
      amountInCurrency: Calculations.rounding(
        conversionFactor * Number(formik.values.amountInSSC),
        0
      ),
      modeOfPayment: formik.values.modeOfPayment,
      receiverDetails: {
        paypalId: formik.values.paypalId,
        upiId: formik.values.upiId,
        accountNumber: formik.values.accountNumber,
        accountHolderName: formik.values.accountHolderName,
        ifscCode: formik.values.ifscCode,
      },
    };

    API.raisePaymentRequest(payload).then((response) => {
      if (response.message === Constants.API_RESPONSES.ADD_FUNDS_SUCCESS) {
        ToastGenerator.success('Payment Request created successfully!');
        setModal({
          open: false,
          apiLoading: false,
        });
        navigate(-1);
      }
    });
  };

  return (
    <Box width={"100%"}>
      <CInputOutput.LabelInputOutlined
        sx={{ width: 400, mt: 1 }}
        label="Amount"
        textFieldProps={{
          placeholder: "Enter Amount",
          InputProps: NumberTypeInputProps,
          helperText: formik.touched.amountInSSC && formik.errors.amountInSSC,
          error: Boolean(
            formik.touched.amountInSSC && formik.errors.amountInSSC
          ),
          onBlur: formik.handleBlur,
          onChange: formik.handleChange,
          value: formik.values.amountInSSC,
          name: "amountInSSC",
        }}
      />

      <CInputOutput.SelectBoxOutlined
        label="Currency"
        placeholder="Select Currency Of Payment"
        sx={{
          width: 400,
          mr: 18,
        }}
        options={[
          { name: "INR", code: "INR" },
          { name: "USD", code: "USD" },
        ]}
        xsKey="code"
        xsValue="name"
        error={formik.touched.currency && formik.errors.currency}
        selected={formik.values.currency}
        setValue={(e: any) => {
          formik.setFieldValue("currency", e.target.value);
          formik.setFieldValue("modeOfPayment", "");
          if (e.target.value === "INR") {
            setConversionFactor(currencyConversions.INR);
          } else if (e.target.value === "USD") {
            setConversionFactor(currencyConversions.USD);
          }
        }}
      />

      {formik.values.amountInSSC !== "" && formik.values.currency !== "" && (
        <>
          <CInputOutput.SelectBoxOutlined
            label="Mode of Payment"
            placeholder="Select Mode Of Payment"
            sx={{
              width: 400,
              mr: 18,
            }}
            options={
              formik.values.currency === "USD"
                ? [{ name: "Paypal", code: "PAYPAL" }]
                : [
                    { name: "Bank Account", code: "BANK" },
                    { name: "UPI", code: "UPI" },
                  ]
            }
            xsKey="code"
            xsValue="name"
            error={formik.touched.modeOfPayment && formik.errors.modeOfPayment}
            selected={formik.values.modeOfPayment}
            setValue={(e: any) => {
              formik.setFieldValue("modeOfPayment", e.target.value);
            }}
          />

          <CUIPresets.LabelValueVertical2
            label={"Exchange Rate"}
            value={"1 SSC = " + conversionFactor + " " + formik.values.currency}
          />

          <CUIPresets.LabelValueVertical2
            label={"Amount To Be Recieved"}
            value={
              Calculations.rounding(
                conversionFactor * Number(formik.values.amountInSSC),
                0
              ) +
              " " +
              formik.values.currency
            }
          />
        </>
      )}

      {formik.values.modeOfPayment !== "" && (
        <>
          {formik.values.modeOfPayment ===
            Constants.TYPES.MODE_OF_PAYMENTS.PAYPAL && (
            <CInputOutput.LabelInputOutlined
              sx={{ width: 400, mt: 2 }}
              label="Paypal ID"
              textFieldProps={{
                placeholder: "Paypal ID",
                helperText: formik.touched.paypalId && formik.errors.paypalId,
                error: Boolean(
                  formik.touched.paypalId && formik.errors.paypalId
                ),
                onBlur: formik.handleBlur,
                onChange: formik.handleChange,
                value: formik.values.paypalId,
                name: "paypalId",
              }}
            />
          )}

          {formik.values.modeOfPayment ===
            Constants.TYPES.MODE_OF_PAYMENTS.UPI && (
            <CInputOutput.LabelInputOutlined
              sx={{ width: 400, mt: 2 }}
              label="UPI ID"
              textFieldProps={{
                placeholder: "UPI ID",
                helperText: formik.touched.upiId && formik.errors.upiId,
                error: Boolean(formik.touched.upiId && formik.errors.upiId),
                onBlur: formik.handleBlur,
                onChange: formik.handleChange,
                value: formik.values.upiId,
                name: "upiId",
              }}
            />
          )}

          {formik.values.modeOfPayment ===
            Constants.TYPES.MODE_OF_PAYMENTS.BANK && (
            <>
              <CInputOutput.LabelInputOutlined
                sx={{ width: 400, mt: 2 }}
                label="Account Holder Name"
                textFieldProps={{
                  placeholder: "Enter account holder name",
                  helperText:
                    formik.touched.accountHolderName &&
                    formik.errors.accountHolderName,
                  error: Boolean(
                    formik.touched.accountHolderName &&
                      formik.errors.accountHolderName
                  ),
                  onBlur: formik.handleBlur,
                  onChange: formik.handleChange,
                  value: formik.values.accountHolderName,
                  name: "accountHolderName",
                }}
              />

              <CInputOutput.LabelInputOutlined
                sx={{ width: 400, mt: 2 }}
                label="Account Number"
                textFieldProps={{
                  placeholder: "Enter account number",
                  helperText:
                    formik.touched.accountNumber && formik.errors.accountNumber,
                  error: Boolean(
                    formik.touched.accountNumber && formik.errors.accountNumber
                  ),
                  onBlur: formik.handleBlur,
                  onChange: formik.handleChange,
                  value: formik.values.accountNumber,
                  name: "accountNumber",
                }}
              />

              <CInputOutput.LabelInputOutlined
                sx={{ width: 400, mt: 2 }}
                label="IFSC Code"
                textFieldProps={{
                  placeholder: "Enter IFSC Code",
                  helperText: formik.touched.ifscCode && formik.errors.ifscCode,
                  error: Boolean(
                    formik.touched.ifscCode && formik.errors.ifscCode
                  ),
                  onBlur: formik.handleBlur,
                  onChange: formik.handleChange,
                  value: formik.values.ifscCode,
                  name: "ifscCode",
                }}
              />
            </>
          )}

          <CComposite.FooterActions.Filled
            sx={{
              mt: 4,
              mb: 4,
              width: 760,
            }}
            onClick={() => formik.handleSubmit()}
            confirmText={"Raise Request"}
          />
        </>
      )}

      <CUIPresets.CustomDialog
        title="Raise Request"
        content={["Are you sure about raising this request?"]}
        confirmText={"Confirm"}
        cancelText="Cancel"
        confirmCall={confirmModalCall}
        modalOpen={modal.open}
        cancelCall={() =>
          setModal({
            open: false,
            apiLoading: false,
          })
        }
        apiLoading={modal.apiLoading}
      />
    </Box>
  );
};

export default RaisingStatus;
