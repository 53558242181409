// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box, Grid } from "@mui/material";
import { FallingLines } from "react-loader-spinner";

// Functional Imports
import { DateFunctions, ToastGenerator } from "../../utils/Functions";
import API from "../../api/API";
import moment from "moment";
import Styles from "../../utils/Styles";
import Colors from "../../utils/Colors";

// Local Imports
import CComposite from "../../atoms/CComposite";
import CUIPresets from "../../atoms/CUIPresets";
import ListGeneratorSavedContent from "./ListGeneratorSavedContent";
import Constants from "../../utils/Constants";

const limit = Constants.QUANTITIES.API_REQUEST_LIMIT;

interface SavedContentProps {}

const SavedContent: FC<SavedContentProps> = (props) => {
  const [searchText, setSearchText] = useState("");

  const [dataLoading, setDataLoading] = useState(false);
  const [contentList, setContentList] = useState<any>([]);

  const [offset, setOffset] = useState(0);
  const [loadingMore, setLoadingMore] = useState(
    Constants.STATUS_FLAGS.SAVED_CONTENT.START
  );
  const [enableLoadMore, setEnableLoadMore] = useState(false);

  const [isResetEnabled, setResetEnabled] = useState(false);

  const [dateModal, setDateModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    initialiseData();

    return () => {
      setContentList([]);
      setOffset(0);
      setStartDate(null);
      setEndDate(null);
      setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.START);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialiseData = () => {
    setDataLoading(true);
    API.getSavedContent("", "", "", limit, 0).then((response) => {
      setDataLoading(false);
      if (response.savedContent?.length === limit) {
        setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.READY);
      } else {
        setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.FINISHED);
      }
      setContentList(response.savedContent);
    });
  };

  useEffect(() => {
    if (offset !== 0 && enableLoadMore) {
      if (startDate !== null && endDate !== null) {
        loadMoreWithDates();
      } else {
        loadMore();
      }
      setEnableLoadMore(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, enableLoadMore]);

  const loadMore = () => {
    setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.LOADING);
    API.getSavedContent("", "", "", limit, offset).then((response) => {
      setContentList([...contentList, ...response.savedContent]);
      if (response.savedContent?.length === limit) {
        setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.READY);
      } else {
        setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.FINISHED);
      }
    });
  };

  const onSearch = () => {
    if (searchText === "") return;

    setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.FINISHED);
    setDataLoading(true);
    API.getSavedContent(searchText, "", "").then((response) => {
      setDataLoading(false);
      setContentList(response.savedContent);
      setResetEnabled(true);
    });
  };

  const afterReset = async () => {
    setContentList([]);
    setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.START);
    setSearchText("");
    setResetEnabled(false);
    setStartDate(null);
    setEndDate(null);
    setOffset(0);
    initialiseData();
  };

  const onDateApply = () => {
    setDataLoading(true);
    setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.START);
    API.getSavedContent(
      "",
      DateFunctions.format(startDate, "YYYY-MM-DD"),
      DateFunctions.format(endDate, "YYYY-MM-DD"),
      limit,
      0
    ).then((response) => {
      if (response.savedContent?.length === 0) {
        setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.FINISHED);
      } else {
        setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.READY);
      }
      setDataLoading(false);
      setContentList(response.savedContent);
      setOffset(0);
    });
  };

  const loadMoreWithDates = () => {
    setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.LOADING);
    API.getSavedContent(
      "",
      DateFunctions.format(startDate, "YYYY-MM-DD"),
      DateFunctions.format(endDate, "YYYY-MM-DD"),
      limit,
      offset
    ).then((response) => {
      if (response.savedContent?.length === limit) {
        setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.READY);
      } else {
        setLoadingMore(Constants.STATUS_FLAGS.SAVED_CONTENT.FINISHED);
      }
      setContentList([...contentList, ...response.savedContent]);
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CComposite.SearchDateBar
          searchPlaceholder={"Search saved content"}
          sx={{ width: "100%" }}
          searchText={searchText}
          setSearchText={(e: any) => setSearchText(e)}
          onSearch={onSearch}
          setDateModal={() => setDateModal(!dateModal)}
          isResetEnabled={isResetEnabled}
          afterReset={afterReset}
        />
        <Box
          sx={{
            ml: 4,
            overflowY: "scroll",
            overflowX: "hidden",
            height: window.innerHeight - 128,
          }}
        >
          <Grid container sx={{ mt: 0, mb: 3 }}>
            {dataLoading && <CUIPresets.ViewerLoading />}

            {!dataLoading && contentList.length === 0 && (
              <CUIPresets.ViewerStatusText text="No Content" />
            )}

            {!dataLoading && contentList.length > 0 && (
              <ListGeneratorSavedContent
                contentList={contentList}
                setContentList={(e: any) => {
                  setOffset(offset - 1);
                  setContentList(e);
                }}
              />
            )}

            <Box
              sx={{
                width: "100%",
                ...Styles.SStyles.RowCenterCenter,
                mb: 2,
                mt: 2,
              }}
            >
              {loadingMore === Constants.STATUS_FLAGS.SAVED_CONTENT.READY && (
                <CUIPresets.ViewerOutlined2
                  onClick={() => {
                    setEnableLoadMore(true);
                    setOffset(offset + limit);
                  }}
                  title="Load more"
                  sx={{ mr: 2 }}
                />
              )}
              {loadingMore === Constants.STATUS_FLAGS.SAVED_CONTENT.LOADING && (
                <FallingLines color={Colors.cyan} width="100" visible={true} />
              )}
            </Box>
          </Grid>
        </Box>
        <CComposite.DateModal
          modalOpen={dateModal}
          setModalOpen={setDateModal}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          applyDate={() => {
            if (startDate === null || endDate === null) {
              ToastGenerator.infoDark("Both start date and end date required!");
              return;
            }

            if (moment(startDate).isAfter(endDate)) {
              ToastGenerator.infoDark("Start date has to be before End date!");
              return;
            }

            setDateModal(false);
            setResetEnabled(true);
            onDateApply();
          }}
        />
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default SavedContent;
