// React Imports
import React, { FC } from "react";

// MUI Imports
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

interface DatePickerProps {
  date?: any;
  setDate?: any;
}

const DatePicker: FC<DatePickerProps> = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MUIDatePicker
        value={moment(props.date)}
        onChange={(e: any) => {
          if (props.setDate) {
            props.setDate(e);
          }
        }}
        slotProps={{
          textField: {
            InputProps: {
              style: {
                height: 40,
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
