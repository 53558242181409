// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box, ButtonBase } from "@mui/material";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";

// Local Imports
import CText from "../../atoms/CText";
import CTools from "../../atoms/CTools";
import CUIPresets from "../../atoms/CUIPresets";

const ImageLinks = {
  Subscriptions:
    "https://images.unsplash.com/photo-1559125148-869baf508c95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  Trending:
    "https://images.unsplash.com/photo-1509824227185-9c5a01ceba0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=415&q=80",
  Search:
    "https://images.unsplash.com/photo-1554696468-19f8c7a71ad5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  MySpace:
    "https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  LogOut:
    "https://images.unsplash.com/uploads/1412533519888a485b488/bb9f9777?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80",
};

interface ViewerNavigateToProps {}

const ViewerNavigateTo: FC<ViewerNavigateToProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          backgroundColor: Colors.blackBackground2,
          height: "100vh",
          width: "100vw",
          ...Styles.SStyles.ColumnCenterCenter,
        }}
      >
        <CText.Jakarta.Size40.Mid.White
          style={{
            fontSize: 56,
          }}
          text="Navigate To"
        />
        <Box
          sx={{
            ...Styles.SStyles.RowCenterCenter,
            mt: 4,
            mb: 4,
          }}
        >
          <NavigateTile
            onClick={() => navigate(PATHS.VIEWER_SUBSCRIPTIONS)}
            image={ImageLinks.Subscriptions}
            label="Subscriptions"
          />
          <NavigateTile
            onClick={() => navigate(PATHS.VIEWER_TRENDING)}
            image={ImageLinks.Trending}
            label="Trending"
          />
          <NavigateTile
            onClick={() => navigate(PATHS.VIEWER_SEARCH)}
            image={ImageLinks.Search}
            label="Search"
          />
          <NavigateTile
            image={ImageLinks.MySpace}
            onClick={() => navigate(PATHS.VIEWER_MY_SPACE)}
            label="My Space"
          />
          <NavigateTile
            image={ImageLinks.LogOut}
            label="Profile"
            onClick={() => navigate(PATHS.VIEWER_PROFILE)}
          />          
        </Box>
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default ViewerNavigateTo;

interface NavigateTileProps {
  label?: any;
  image?: any;
  onClick?: any;
}

const NavigateTile: FC<NavigateTileProps> = (props) => {
  const handleClick = () => {
    if (props.onClick) {
      setTimeout(() => {
        props.onClick();
      }, 200);
    }
  };

  return (
    <ButtonBase
      onClick={handleClick}
      sx={{
        ...Styles.SStyles.ColumnCenterCenter,
        mx: 1,
        p: 2,
        cursor: "pointer",
        borderRadius: 1,
        ":hover": {
          backgroundColor: Colors.blackBackground,
          ...Styles.SStyles.midElevation,
        },
      }}
    >
      <CTools.ImageContainer height={110} width={110} src={props.image} />
      <CText.Jakarta.Size16.Mid.Grey1
        style={{
          marginTop: 8,
        }}
        text={props.label}
      />
    </ButtonBase>
  );
};
