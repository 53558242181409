const URLPATH = {
  SERVER: "https://www.server.spacshutl.com/api",
  FFMPEG: "https://www.ffmpeg.spacshutl.com/api",
};

const ENDPOINTS = {
  // SERVER
  CLIENT: {
    REGISTER_CLIENT_ACCOUNT: URLPATH.SERVER + "/client/register-client-account",
    LOGIN_CLIENT_ACCOUNT: URLPATH.SERVER + "/client/login-client-account",
    GET_CLIENTS_LIST: URLPATH.SERVER + "/client/get-clients-list",
    GET_CLIENT_ACCOUNT: URLPATH.SERVER + "/client/get-client-account",
    VERIFY_CLIENT_ACCOUNT: URLPATH.SERVER + "/client/verify-client-account",
    CHANGE_PASSWORD: URLPATH.SERVER + "/client/change-password",
    FORGOT_CLIENT_ACCOUNT: URLPATH.SERVER + "/client/forgot-client-account",
    VERIFY_CHANGE_PASSWORD: URLPATH.SERVER + "/client/verify-change-password",
    UPDATE_CLIENT_DETAILS: URLPATH.SERVER + "/client/update-client-details",
  },
  MASTER: {
    GET_MASTER_FILES: URLPATH.SERVER + "/master/get-master-files",
    MASTER_FILE_ID: URLPATH.SERVER + "/master/master-file-id",
    DELETE_MASTER_FILE: URLPATH.SERVER + "/master/delete-master-file",
  },
  ADFILES: {
    GET_AD_FILES: URLPATH.SERVER + "/adfiles/get-ad-files",
    GET_AD_FILE: URLPATH.SERVER + "/adfiles/get-ad-file",
    DELETE_AD_FILE: URLPATH.SERVER + "/adfiles/delete-ad-file",
  },
  FUNDS: {
    ADD_FUNDS_REQUEST: URLPATH.SERVER + "/funds/add-funds-request",
    UPLOAD_PROOF_AF: URLPATH.SERVER + "/funds/upload-proof-af",
    ADVERTISER_FUNDS_REQUESTS:
      URLPATH.SERVER + "/funds/advertiser-funds-requests",
    ADVERTISER_FUNDS_REQUEST:
      URLPATH.SERVER + "/funds/advertiser-funds-request",
    ADMIN_APPROVAL_AF: URLPATH.SERVER + "/funds/admin-approval-af",
    WITHDRAW_FUNDS_REQUEST: URLPATH.SERVER + "/funds/withdraw-funds-request",
    ADMIN_APPROVAL_WF: URLPATH.SERVER + "/funds/admin-approval-wf",
    PAYMENT_REQUEST: URLPATH.SERVER + "/funds/payment-request",
    GET_PAYMENT_REQUEST: URLPATH.SERVER + "/funds/get-payment-request",
    ADMIN_UPDATE_PR: URLPATH.SERVER + "/funds/admin-update-pr",
    WATCHTIME_BILL_PROOF: URLPATH.SERVER + "/funds/watchtime-bill-proof",
    GET_WATCHTIME_BILLS: URLPATH.SERVER + "/funds/get-watchtime-bills",
    GET_PAYMENT_REQUESTS: URLPATH.SERVER + "/funds/get-payment-requests",
    UPLOAD_PROOF_WB: URLPATH.SERVER + "/funds/upload-proof-wb",
  },
  WALLET: {
    GET_WALLET_BALANCE: URLPATH.SERVER + "/wallet/get-wallet-balance",
  },
  REQUESTS: {
    ADD_FUNDS_REQUESTS: URLPATH.SERVER + "/requests/add-funds-requests",
    ADD_FUNDS_REQUEST: URLPATH.SERVER + "/requests/add-funds-request",
    WITHDRAW_FUNDS_REQUESTS:
      URLPATH.SERVER + "/requests/withdraw-funds-requests",
  },
  SPACE: {
    CREATOR_SPACE_CONFIG: URLPATH.SERVER + "/space/creator-space-config",
    CREATOR_SPACE_IMAGES: URLPATH.SERVER + "/space/creator-space-images",
    GET_ALL_SPACES: URLPATH.SERVER + "/space/get-all-spaces",
    CREATE_SUBSPACE: URLPATH.SERVER + "/space/create-subspace",
    GET_ALL_SUBSPACES: URLPATH.SERVER + "/space/get-all-subspaces",
    GET_SUBSPACE: URLPATH.SERVER + "/space/get-subspace",
    DELETE_SUBSPACE: URLPATH.SERVER + "/space/delete-subspace",
    UPDATE_SUBSPACE: URLPATH.SERVER + "/space/update-subspace",
  },
  CONTENT: {
    CREATE_CONTENT: URLPATH.SERVER + "/content/create-content",
    GET_CONTENT_LIST: URLPATH.SERVER + "/content/get-content-list",
    GET_CONTENT_ID: URLPATH.SERVER + "/content/get-content-id",
    GET_CONTENT_DATA: URLPATH.SERVER + "/content/get-content-data",
    GET_REQUEST_DATA: URLPATH.SERVER + "/content/get-request-data",
    CREATE_CONTENT_SUGGESTION:
      URLPATH.SERVER + "/content/create-content-suggestion",
    CONTENT_SUGGESTIONS: URLPATH.SERVER + "/content/content-suggestions",
    PRE_REQUEST_DATA: URLPATH.SERVER + "/content/pre-request-data",
    ACTIVE_CONTRACTS_STREAMLINKS:
      URLPATH.SERVER + "/content/active-contracts-streamlinks",
  },
  CONTRACT: {
    CREATE_REQUEST: URLPATH.SERVER + "/contract/create-request",
    GET_ALL_REQUESTS: URLPATH.SERVER + "/contract/get-all-requests",
    GET_REQUEST_ID: URLPATH.SERVER + "/contract/get-request-id",
    WITHDRAW_REQUEST: URLPATH.SERVER + "/contract/withdraw-request",
    GET_ALL_REQUESTS_CREATOR:
      URLPATH.SERVER + "/contract/get-all-requests-creator",
    GET_REQUEST_ID_CREATOR: URLPATH.SERVER + "/contract/get-request-id-creator",
    CREATOR_RESPONSE_REQUEST:
      URLPATH.SERVER + "/contract/creator-response-request",
    GET_TRANSACTIONS_BY_DATE:
      URLPATH.SERVER + "/contract/get-transactions-by-date",
    GET_CONTRACT_DETAILS: URLPATH.SERVER + "/contract/get-contract-details",
    GET_CONTRACTS_CREATOR: URLPATH.SERVER + "/contract/get-contracts-creator",
    GET_CREATORS_WITH_CONTRACTS:
      URLPATH.SERVER + "/contract/get-creators-with-contracts",
    GET_CONTRACTS_ADVERTISER:
      URLPATH.SERVER + "/contract/get-contracts-advertiser",
  },
  VIEWER: {
    REGISTER_VIEWER: URLPATH.SERVER + "/viewer/register-viewer",
    LOGIN_VIEWER: URLPATH.SERVER + "/viewer/login-viewer",
    CHANGE_PASSWORD: URLPATH.SERVER + "/viewer/change-password",
    GET_VIEWER_PROFILE: URLPATH.SERVER + "/viewer/get-viewer-profile",
    FORGOT_VIEWER_ACCOUNT: URLPATH.SERVER + "/viewer/forgot-viewer-account",
    VERIFY_CHANGE_PASSWORD: URLPATH.SERVER + "/viewer/verify-change-password",
  },
  VIEWER_SPACE: {
    SEARCH_SPACES: URLPATH.SERVER + "/viewer-space/search-spaces",
    GET_SPACE_AND_SUBSPACES:
      URLPATH.SERVER + "/viewer-space/get-space-and-subspaces",
    MANAGE_SUBSCRIPTIONS: URLPATH.SERVER + "/viewer-space/manage-subscriptions",
    GET_SUBSPACE_BY_ID: URLPATH.SERVER + "/viewer-space/get-subspace-by-id",
    GET_ALL_SUBSCRIBED_SPACES:
      URLPATH.SERVER + "/viewer-space/get-all-subscribed-spaces",
    TRENDING_SPACES: URLPATH.SERVER + "/viewer-space/trending-spaces",
  },
  VIEWER_CONTENT: {
    GET_SUBSPACE_BY_ID: URLPATH.SERVER + "/viewer-content/get-content-by-id",
    MANAGE_SAVED_CONTENT:
      URLPATH.SERVER + "/viewer-content/manage-saved-content",
    MANAGE_WATCH_LATER: URLPATH.SERVER + "/viewer-content/manage-watch-later",
    MANAGE_VIEWS_AND_WATCHTIME:
      URLPATH.SERVER + "/viewer-content/manage-views-and-watchtime",
    GET_ALL_SUBSCRIBED_CONTENT:
      URLPATH.SERVER + "/viewer-content/get-all-subscribed-content",
    GET_WATCH_LATER: URLPATH.SERVER + "/viewer-content/get-watch-later",
    GET_SAVED_CONTENT: URLPATH.SERVER + "/viewer-content/get-saved-content",
    GET_HISTORY: URLPATH.SERVER + "/viewer-content/get-history",
    RECENTLY_UPLOADED_CONTENT:
      URLPATH.SERVER + "/viewer-content/recently-uploaded-content",
    MOST_POPULAR_CONTENT:
      URLPATH.SERVER + "/viewer-content/most-popular-content",
    TRENDING_CONTENT: URLPATH.SERVER + "/viewer-content/trending-content",
    GET_CONTINUE_WATCHING:
      URLPATH.SERVER + "/viewer-content/get-continue-watching",
    UPDATE_CONTINUE_WATCHING:
      URLPATH.SERVER + "/viewer-content/update-continue-watching",
  },
  ADMIN: {
    GET_CONTRACT_REQUESTS: URLPATH.SERVER + "/admin/get-contract-requests",
    GET_CONTRACT_REQUEST_ID: URLPATH.SERVER + "/admin/get-contract-request-id",
    GET_MASTER_FILES: URLPATH.SERVER + "/admin/get-master-files",
    GET_MASTER_FILE: URLPATH.SERVER + "/admin/get-master-file",
    GET_SUBSPACES: URLPATH.SERVER + "/admin/get-subspaces",
    GET_SUBSPACE: URLPATH.SERVER + "/admin/get-subspace",
    GET_CONTENTS: URLPATH.SERVER + "/admin/get-contents",
    GET_CONTENT: URLPATH.SERVER + "/admin/get-content",
    GET_VIEWERS: URLPATH.SERVER + "/admin/get-viewers",
    GET_HISTORY_VIEWER: URLPATH.SERVER + "/admin/get-history-viewer",
    GET_WATCH_LATER_VIEWER: URLPATH.SERVER + "/admin/get-watch-later-viewer",
    GET_SAVED_CONTENT_VIEWER:
      URLPATH.SERVER + "/admin/get-saved-content-viewer",
    GET_AD_FILES: URLPATH.SERVER + "/admin/get-ad-files",
    GET_AD_FILE: URLPATH.SERVER + "/admin/get-ad-file",
    GET_CLIENTS: URLPATH.SERVER + "/admin/get-clients",
    GET_WATCHTIME_RATES: URLPATH.SERVER + "/admin/get-watchtime-rates",
    UPDATE_WATCHTIME_RATES: URLPATH.SERVER + "/admin/update-watchtime-rates",
    GET_CURRENCY_CONVERSION: URLPATH.SERVER + "/admin/get-currency-conversion",
    UPDATE_CURRENCY_CONVERSION:
      URLPATH.SERVER + "/admin/update-currency-conversion",
    UPDATE_ADMIN_ALLOW: URLPATH.SERVER + "/admin/update-admin-allow",
    GET_PAYMENT_REQUESTS: URLPATH.SERVER + "/admin/get-payment-requests",
    GET_WATCHTIME_BILLS: URLPATH.SERVER + "/admin/get-watchtime-bills",
    GET_WATCHTIME_BILL: URLPATH.SERVER + "/admin/get-watchtime-bill",
    UPDATE_WATCHTIME_BILL: URLPATH.SERVER + "/admin/update-watchtime-bill",
    WATCHTIME_BILL_PROOFS: URLPATH.SERVER + "/admin/watchtime-bill-proofs",
    ADMIN_APPROVAL_WB: URLPATH.SERVER + "/admin/admin-approval-wb",
  },
  OVERVIEW: {
    GET_OVERVIEW: URLPATH.SERVER + "/overview/get-overview",
    GET_CONTENT_VIEW_DETAILS:
      URLPATH.SERVER + "/overview/get-content-view-details",
    GET_CONTENT_VIEW_DETAILS_ID:
      URLPATH.SERVER + "/overview/get-content-view-details-id",
    GET_SPACE_VIEWS: URLPATH.SERVER + "/overview/get-space-views",
    GET_CONTENT_VIEWS_AND_WATCHTIME:
      URLPATH.SERVER + "/overview/get-content-views-and-watchtime",
  },

  // FFMPEG
  VIDEOS: {
    UPLOAD_MASTER_FILE: URLPATH.FFMPEG + "/videos/upload-master-file",
    UPLOAD_AD_FILE: URLPATH.FFMPEG + "/videos/upload-ad-file",
  },
};

export default ENDPOINTS;
