// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";

interface ContentDetailsProps {
  contentDetails?: any;
  masterFileDetails?: any;
  dataLoading?: any;
}

const ContentDetails: FC<ContentDetailsProps> = (props) => {
  if (props.dataLoading) {
    return <CUIPresets.LoadingSpinner />;
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <Box sx={{ mt: 4, ml: 1 }}>
            <CUIPresets.LabelImageHorizontal
              label={"Cover Photo"}
              src={props.contentDetails.coverPhoto}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Content Name"}
              value={props.contentDetails.name}
            />

            <CUIPresets.LabelIframeHorizontal
              label={"Content Preview"}
              iframe={props.masterFileDetails.bunnyStreamLink}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Content Duration"}
              value={props.masterFileDetails.duration}
            />

            {props.masterFileDetails?.adSlots?.map((item: any, index: any) => (
              <CUIPresets.LabelValueHorizontal
                label={"Ad Slot " + (index + 1)}
                value={item}
              />
            ))}

            <SelectedCountries
              countryArray={props.contentDetails.countryArray}
            />
          </Box>
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default ContentDetails;

interface SelectedCountriesProps {
  countryArray?: any;
}

const SelectedCountries: FC<SelectedCountriesProps> = (props) => {
  return (
    <Box
      display="flex"
      sx={{
        mb: 3,
      }}
    >
      <Box width={320}>
        <CText.Jakarta.Size16.Mid.Grey3 text={"Countries"} />
      </Box>
      <Box maxWidth={500}>
        {props.countryArray?.map((item: any, index: any) => (
          <CText.Jakarta.Size16.Mid.Black
            key={index}
            text={item.countryName}
            style={{ marginBottom: 8 }}
          />
        ))}
      </Box>
    </Box>
  );
};
