// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";
import {} from "iconsax-react";

// Functional Imports
import { useDispatch, useSelector } from "react-redux";
import ACTION_TYPES from "../../redux/actions/actionTypes";
import Colors from "../../utils/Colors";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import History from "./History";
import WatchLater from "./WatchLater";
import SavedContent from "./SavedContent";
import ContinueWatching from "./ContinueWatching";

interface ViewerMySpaceProps {}

const ViewerMySpace: FC<ViewerMySpaceProps> = (props) => {
  const dispatch = useDispatch();

  const selectedOption = useSelector(
    (state: any) => state.appState.myspaceOptionSelected
  );

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          backgroundColor: Colors.blackBackground2,
          height: "100vh",
          width: "100vw",
        }}
      >
        <CUIPresets.ViewerTopBar
          options={[
            Constants.TYPES.MY_SPACE.CONTINUE_WATCHING,
            Constants.TYPES.MY_SPACE.HISTORY,
            Constants.TYPES.MY_SPACE.SAVED_CONTENT,
            Constants.TYPES.MY_SPACE.WATCH_LATER,
          ]}
          selected={selectedOption}
          setSelected={(e: any) =>
            dispatch({ type: ACTION_TYPES.MYSPACE_OPTION_SELECTED, payload: e })
          }
        />
        {selectedOption === Constants.TYPES.MY_SPACE.CONTINUE_WATCHING && (
          <ContinueWatching />
        )}
        {selectedOption === Constants.TYPES.MY_SPACE.HISTORY && <History />}
        {selectedOption === Constants.TYPES.MY_SPACE.SAVED_CONTENT && (
          <SavedContent />
        )}
        {selectedOption === Constants.TYPES.MY_SPACE.WATCH_LATER && (
          <WatchLater />
        )}
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default ViewerMySpace;

