// React Imports
import React, { FC } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import Constants from "../../utils/Constants";

// Local Imports
import StaticBits from "./StaticBits";

interface ChipSelectProps {
  status?: any;
}

const MasterFileChipSelect: FC<ChipSelectProps> = (props) => {
  if (props.status === Constants.STATUS_FLAGS.MASTER_FILE.UNASSIGNED) {
    return <StaticBits.OrangeChip label={"Unassigned"} />;
  } else if (props.status === Constants.STATUS_FLAGS.MASTER_FILE.ERROR) {
    return <StaticBits.RedChip label={"Error"} />;
  } else if (props.status === Constants.STATUS_FLAGS.MASTER_FILE.PROCESSING) {
    return <StaticBits.BlueChip label={"Processing"} />;
  } else if (props.status === Constants.STATUS_FLAGS.MASTER_FILE.ASSIGNED) {
    return <StaticBits.GreenChip label={"Assigned"} />;
  } else {
    return null;
  }
};

const AdFileChipSelect: FC<ChipSelectProps> = (props) => {
  if (props.status === Constants.STATUS_FLAGS.AD_FILE.UNASSIGNED) {
    return <StaticBits.GreenChip label={"Ready"} />;
  } else if (props.status === Constants.STATUS_FLAGS.AD_FILE.ERROR) {
    return <StaticBits.RedChip label={"Error"} />;
  } else if (props.status === Constants.STATUS_FLAGS.AD_FILE.PROCESSING) {
    return <StaticBits.BlueChip label={"Processing"} />;
  } else if (props.status === Constants.STATUS_FLAGS.AD_FILE.ASSIGNED) {
    return <StaticBits.GreenChip label={"Assigned"} />;
  } else {
    return null;
  }
};

const AdvertiserFundsChipSelected: FC<ChipSelectProps> = (props) => {
  if (props.status === Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.RAISED) {
    return <StaticBits.OrangeChip label={"Raised"} />;
  } else if (
    props.status === Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.UPLOADED
  ) {
    return <StaticBits.BlueChip label={"Proof Uploaded"} />;
  } else if (
    props.status === Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.APPROVED
  ) {
    return <StaticBits.GreenChip label={"Approved"} />;
  } else if (
    props.status === Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.REJECTED
  ) {
    return <StaticBits.RedChip label={"Rejected"} />;
  } else {
    return null;
  }
};

const ContractRequestChipSelected: FC<ChipSelectProps> = (props) => {
  if (props.status === Constants.STATUS_FLAGS.CONTRACT_REQUEST.RAISED) {
    return <StaticBits.OrangeChip label={"Raised"} />;
  } else if (
    props.status === Constants.STATUS_FLAGS.CONTRACT_REQUEST.WITHDRAWN
  ) {
    return <StaticBits.OrangeChip label={"Withdrawn"} />;
  } else if (props.status === Constants.STATUS_FLAGS.CONTRACT_REQUEST.EXPIRED) {
    return <StaticBits.OrangeChip label={"Expired"} />;
  } else if (
    props.status === Constants.STATUS_FLAGS.CONTRACT_REQUEST.REJECTED
  ) {
    return <StaticBits.RedChip label={"Rejected"} />;
  } else {
    return null;
  }
};

const ContractChipSelected: FC<ChipSelectProps> = (props) => {
  if (props.status === Constants.STATUS_FLAGS.CONTRACT.ACTIVE) {
    return <StaticBits.GreenChip label={"Active"} />;
  } else if (props.status === Constants.STATUS_FLAGS.CONTRACT.EXPIRED) {
    return <StaticBits.OrangeChip label={"Expired"} />;
  } else if (props.status === Constants.STATUS_FLAGS.CONTRACT.ABORTED) {
    return <StaticBits.RedChip label={"Aborted"} />;
  } else {
    return null;
  }
};

const WatchtimeBillSelected: FC<ChipSelectProps> = (props) => {
  if (props.status === Constants.STATUS_FLAGS.WATCHTIME_BILLS.GENERATED) {
    return <StaticBits.OrangeChip label={"Generated"} />;
  } else if (props.status === Constants.STATUS_FLAGS.WATCHTIME_BILLS.SENT) {
    return <StaticBits.BlueChip label={"Sent"} />;
  } else if (props.status === Constants.STATUS_FLAGS.WATCHTIME_BILLS.PAID) {
    return <StaticBits.GreenChip label={"Paid"} />;
  } else {
    return null;
  }
};

const WatchtimeBillProofSelected: FC<ChipSelectProps> = (props) => {
  if (props.status === Constants.STATUS_FLAGS.WATCHTIME_BILL_PROOF.RAISED) {
    return <StaticBits.OrangeChip label={"Raised"} />;
  } else if (props.status === Constants.STATUS_FLAGS.WATCHTIME_BILL_PROOF.APPROVED) {
    return <StaticBits.GreenChip label={"Approved"} />;
  } else if (props.status === Constants.STATUS_FLAGS.WATCHTIME_BILL_PROOF.REJECTED) {
    return <StaticBits.RedChip label={"Rejected"} />;
  } else {
    return null;
  }
};

const ChipSelect = {
  MasterFile: MasterFileChipSelect,
  AdFile: AdFileChipSelect,
  AdvertiserFunds: AdvertiserFundsChipSelected,
  ContractRequest: ContractRequestChipSelected,
  Contract: ContractChipSelected,
  WatchtimeBills: WatchtimeBillSelected,
  WatchtimeBillProof: WatchtimeBillProofSelected
};

export default ChipSelect;
