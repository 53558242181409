import axios from "axios";
import ENDPOINTS from "./ENDPOINTS";
import { LocalStorage, ToastGenerator, errorLog } from "../utils/Functions";

const registerClientAccount = async (values: any) => {
  const data = {
    fullName: values.fullName,
    userName: values.userName,
    email: values.email,
    password: values.password,
    type: values.type,
  };

  var config = {
    method: "post",
    url: ENDPOINTS.CLIENT.REGISTER_CLIENT_ACCOUNT,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      ToastGenerator.success(response.data.message);
    })
    .catch((e) => {
      ToastGenerator.fail(e.response.data.message);
    });
};

const loginClientAccount = async (values: any, navigate: any) => {
  const data = {
    email: values.email,
    password: values.password,
  };

  var config = {
    method: "post",
    url: ENDPOINTS.CLIENT.LOGIN_CLIENT_ACCOUNT,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      errorLog(e);
      ToastGenerator.fail(e.response.data.message);
    });
};

const verifyOtp = async (payload: any) => {
  let data = JSON.stringify({
    email: payload.email,
    otp: payload.otp,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CLIENT.VERIFY_CLIENT_ACCOUNT,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      ToastGenerator.fail(error.response.data.message);
    });
};

const forgotPassword = async (email: any) => {
  let data = JSON.stringify({
    email: email,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CLIENT.FORGOT_CLIENT_ACCOUNT,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      ToastGenerator.fail(error.response.data.message);
    });
};

const verifyEmailAndChangePassword = async (payload: any) => {
  let data = JSON.stringify({
    otp: payload.otp,
    email: payload.email,
    newPassword: payload.newPassword,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CLIENT.VERIFY_CHANGE_PASSWORD,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      ToastGenerator.fail(error.response.data.message);
    });
};

const JWTToken: any = LocalStorage.getString("JWT");

const getClientAccountById = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CLIENT.GET_CLIENT_ACCOUNT + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const changeClientPassword = async (payload: any) => {
  let data = JSON.stringify({
    oldPassword: payload.oldPassword,
    newPassword: payload.newPassword,
  });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CLIENT.CHANGE_PASSWORD,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      ToastGenerator.fail(error.response?.data?.message);
    });
};

const creatorUpdate = async (fullName: any) => {
  let data = new FormData();
  data.append("fullName", fullName);

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CLIENT.UPDATE_CLIENT_DETAILS,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const advertiserUpdate = async (payload: any) => {
  let data = new FormData();
  data.append("fullName", payload.fullName);
  data.append("description", payload.description);
  data.append("image", payload.image);

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CLIENT.UPDATE_CLIENT_DETAILS,
    headers: {
      Authorization: "Bearer " + JWTToken,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StaticJWT =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDczNDNjOGIwMTkzZDg4NjU5ZDhmOTIiLCJlbWFpbCI6ImJhc3VnbTAwMEBnbWFpbC5jb20iLCJ0eXBlIjoiYWRtaW4iLCJmdWxsTmFtZSI6IkFkbWluaXN0cmF0b3IiLCJpYXQiOjE3MDM0MDEwMTgsImV4cCI6MTcwNTk5MzAxOH0.CJTG31xlofGLEIaNHe8krbcmw7jEfJnqJWPvG0GCuSw";

const getMasterFiles = async (search: any, sort: any) => {
  let url = ENDPOINTS.MASTER.GET_MASTER_FILES;

  if (search !== "") {
    url = url + "?search=" + search;
  } else if (sort !== "") {
    url = url + "?sort=" + sort;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getMasterFileById = async (ID: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.MASTER.MASTER_FILE_ID + "?id=" + ID,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const deleteMasterFileById = async (ID: any) => {
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: ENDPOINTS.MASTER.DELETE_MASTER_FILE + "?id=" + ID,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAdFiles = async (search: any, sort: any) => {
  let url = ENDPOINTS.ADFILES.GET_AD_FILES;

  if (search !== "") {
    url = url + "?search=" + search;
  } else if (sort !== "") {
    url = url + "?sort=" + sort;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAdFileById = async (ID: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADFILES.GET_AD_FILE + "?id=" + ID,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const deleteAdFileById = async (ID: any) => {
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADFILES.DELETE_AD_FILE + "?id=" + ID,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const addFundsRequest = async (payload: any) => {
  let data = JSON.stringify({
    amountInSSC: payload.amountInSSC,
    currency: payload.currency,
    amountInCurrency: payload.amountInCurrency,
    modeOfPayment: payload.modeOfPayment,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.ADD_FUNDS_REQUEST,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const uploadProofAddFunds = async (id: any, payload: any) => {
  let data = new FormData();
  data.append("txnId", payload.txnId);
  data.append("senderAddress", payload.senderAddress);
  data.append("image", payload.image.file);
  data.append("dateOfTransaction", payload.dateOfTransaction);

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.UPLOAD_PROOF_AF + "?id=" + id,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAllAdvertiserFundsRequests = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.ADVERTISER_FUNDS_REQUESTS,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getWalletBalance = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.WALLET.GET_WALLET_BALANCE,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAdvertiserRequestById = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.ADVERTISER_FUNDS_REQUEST + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAdvertiserListBySearch = async (searchQuery: any) => {
  let url = ENDPOINTS.CLIENT.GET_CLIENTS_LIST + "?type=advertiser";

  if (searchQuery !== "") {
    url = url + "&search=" + searchQuery;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getCreatorListBySearch = async (searchQuery: any) => {
  let url = ENDPOINTS.CLIENT.GET_CLIENTS_LIST + "?type=creator";

  if (searchQuery !== "") {
    url = url + "&search=" + searchQuery;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAddFundsRequests = async (type: any, value: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      ENDPOINTS.REQUESTS.ADD_FUNDS_REQUESTS +
      "?type=" +
      type +
      "&value=" +
      value,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const adminAdvertiserFundsRequestById = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.REQUESTS.ADD_FUNDS_REQUEST + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const adminApprovalOfAddFundsRequest = async (id: any, payload: any) => {
  let data = new FormData();
  data.append("image", payload.image);
  data.append("madeBy", payload.madeBy);
  data.append("response", payload.response);
  data.append("reason", payload.reason);

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.ADMIN_APPROVAL_AF + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const withdrawFundsRequest = async (payload: any) => {
  let data = JSON.stringify({
    amountInSSC: payload.amountInSSC,
    currency: payload.currency,
    amountInCurrency: payload.amountInCurrency,
    modeOfPayment: payload.modeOfPayment,
    receiverDetails: payload.receiverDetails,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.WITHDRAW_FUNDS_REQUEST,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getWithdrawFundsRequests = async (type: any, value: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      ENDPOINTS.REQUESTS.WITHDRAW_FUNDS_REQUESTS +
      "?type=" +
      type +
      "&value=" +
      value,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const adminApprovalOfWithdrawFundsRequest = async (id: any, payload: any) => {
  let data = new FormData();
  data.append("image", payload.image);
  data.append("actionBy", payload.actionBy);
  data.append("txnId", payload.txnId);

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.ADMIN_APPROVAL_WF + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getSpaceConfiguration = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.SPACE.CREATOR_SPACE_CONFIG,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const postSpaceConfiguration = async (payload: any) => {
  let data = new FormData();
  data.append("name", payload.name);
  data.append("description", payload.description);
  data.append("backgroundImage", payload.backgroundImage);
  data.append("profileImage", payload.profileImage);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.SPACE.CREATOR_SPACE_CONFIG,
    headers: {
      Authorization: "Bearer " + JWTToken,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const putSpaceConfiguration = async (payload: any) => {
  let data = {
    name: payload.name,
    description: payload.description,
  };

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.SPACE.CREATOR_SPACE_CONFIG + "?id=" + payload.id,
    headers: {
      Authorization: "Bearer " + JWTToken,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const putSpaceConfigurationImages = async (payload: any) => {
  let data = new FormData();
  data.append("image", payload.image);

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url:
      ENDPOINTS.SPACE.CREATOR_SPACE_IMAGES +
      "?type=" +
      payload.type +
      "&id=" +
      payload.id,
    headers: {
      Authorization: "Bearer " + JWTToken,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const chooseMasterFileForContent = async (searchQuery: any = "") => {
  let params = "?sort=alphabetical&filter=unassigned";

  if (searchQuery !== "") {
    params = params + "&search=" + searchQuery;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.MASTER.GET_MASTER_FILES + params,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const createContent = async (payload: any) => {
  let data = new FormData();
  data.append("name", payload.name);
  data.append("image", payload.image);
  data.append("masterFileId", payload.masterFileId);

  payload.countryArray?.map((item: any, index: any) => {
    data.append("countryArray[" + index + "][countryName]", item.name);
    data.append("countryArray[" + index + "][countryCode]", item.code);
    data.append("countryArray[" + index + "][enabled]", item.enabled);

    return 0;
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTENT.CREATE_CONTENT,
    headers: {
      Authorization: "Bearer " + JWTToken,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContentList = async (search: any, sort: any) => {
  let url = ENDPOINTS.CONTENT.GET_CONTENT_LIST;

  if (search !== "") {
    url = url + "?search=" + search;
  } else if (sort !== "") {
    url = url + "?sort=" + sort;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContentById = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTENT.GET_CONTENT_ID + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContentData = async (id: any, searchQuery: any = "") => {
  let url = ENDPOINTS.CONTENT.GET_CONTENT_DATA + "?id=" + id;

  if (searchQuery !== "") {
    url = url + "&search=" + searchQuery;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getRequestData = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTENT.GET_REQUEST_DATA + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const createContractRequest = (payload: any) => {
  let data = {
    entityDetails: {
      advertiser: payload.advertiserId,
      creator: payload.creatorId,
      content: payload.contentId,
    },
    adSlotDetails: {
      selectedCountryCode: payload.selectedCountryCode,
      selectedAdFile: payload.adFileId,
      selectedAdSlot: payload.selectedAdSlot,
    },
    contractTerms: {
      typeOfContract: payload.typeOfContract,
      rateOramount: payload.rateOramount,
      duration: payload.duration,
    },
  };

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTRACT.CREATE_REQUEST,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: JSON.stringify(data),
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAllRequestsByType = async (type: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTRACT.GET_ALL_REQUESTS + "?type=" + type,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getRequestById = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTRACT.GET_REQUEST_ID + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const createContentSuggestion = async (payload: any) => {
  let data = JSON.stringify({
    contentId: payload.contentId,
    suggestion: payload.suggestion,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTENT.CREATE_CONTENT_SUGGESTION,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const updateContentSuggestion = async (id: any, payload: any) => {
  let data = JSON.stringify({
    suggestion: payload.suggestion,
  });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTENT.CONTENT_SUGGESTIONS + "?id=" + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContentSuggestion = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTENT.CONTENT_SUGGESTIONS + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAllSpaces = async (searchQuery: any = "") => {
  let url = ENDPOINTS.SPACE.GET_ALL_SPACES;

  if (searchQuery !== "") {
    url = url + "?search=" + searchQuery;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const withdrawContractRequest = async (id: any) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTRACT.WITHDRAW_REQUEST + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAllRequestsCreatorByType = async (type: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTRACT.GET_ALL_REQUESTS_CREATOR + "?type=" + type,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getRequestByIdCreator = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTRACT.GET_REQUEST_ID_CREATOR + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const creatorResponseRequest = async (id: any, payload: any) => {
  let data = JSON.stringify({
    response: payload.response,
  });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTRACT.CREATOR_RESPONSE_REQUEST + "?id=" + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getPreRequestData = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTENT.PRE_REQUEST_DATA + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getActiveContractsAndStreamLinks = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTENT.ACTIVE_CONTRACTS_STREAMLINKS + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const createSubspace = async (payload: any) => {
  let data = JSON.stringify({
    subSpaceName: payload.subSpaceName,
    description: payload.description,
    contents: payload.contents,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.SPACE.CREATE_SUBSPACE,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAllSubspaces = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.SPACE.GET_ALL_SUBSPACES,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getSubspaceById = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.SPACE.GET_SUBSPACE + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const deleteSubspaceById = async (id: any) => {
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: ENDPOINTS.SPACE.DELETE_SUBSPACE + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const updateSubspace = async (payload: any, id: any) => {
  let data = JSON.stringify({
    subSpaceName: payload.subSpaceName,
    description: payload.description,
    contents: payload.contents,
  });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.SPACE.UPDATE_SUBSPACE + "?id=" + id,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const registerViewer = async (payload: any) => {
  let data = JSON.stringify({
    fullName: payload.fullName,
    email: payload.email,
    password: payload.password,
    countryCode: payload.countryCode,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER.REGISTER_VIEWER,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const loginViewer = async (payload: any) => {
  let data = JSON.stringify({
    email: payload.email,
    password: payload.password,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER.LOGIN_VIEWER,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const searchSpaces = async (searchText: any = "") => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_SPACE.SEARCH_SPACES + "?search=" + searchText,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getSpaceAndSubspaces = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_SPACE.GET_SPACE_AND_SUBSPACES + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const manageSubscriptions = async (payload: any) => {
  let data = JSON.stringify({
    spaceId: payload.spaceId,
    action: payload.action,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_SPACE.MANAGE_SUBSCRIPTIONS,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getSubspaceForViewer = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_SPACE.GET_SUBSPACE_BY_ID + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContentForViewer = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_CONTENT.GET_SUBSPACE_BY_ID + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const manageSavedContent = async (payload: any) => {
  let data = JSON.stringify({
    contentId: payload.contentId,
    action: payload.action,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_CONTENT.MANAGE_SAVED_CONTENT,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const manageWatchLater = async (payload: any) => {
  let data = JSON.stringify({
    contentId: payload.contentId,
    action: payload.action,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_CONTENT.MANAGE_WATCH_LATER,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const manageViewsAndWatchtime = async (payload: any) => {
  let data = JSON.stringify({
    watchTime: Number(payload.watchTime),
  });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url:
      ENDPOINTS.VIEWER_CONTENT.MANAGE_VIEWS_AND_WATCHTIME + "?id=" + payload.id,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAllSubscribedSpaces = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_SPACE.GET_ALL_SUBSCRIBED_SPACES,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAllSubscribedContent = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_CONTENT.GET_ALL_SUBSCRIBED_CONTENT,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getWatchLater = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_CONTENT.GET_WATCH_LATER,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getSavedContent = async (
  search: any = "",
  start: any = "",
  end: any = "",
  limit: any = "",
  offset: any = ""
) => {
  let url = ENDPOINTS.VIEWER_CONTENT.GET_SAVED_CONTENT;

  if (search !== "") {
    url = url + "?search=" + search;
  }

  let dateExists = false;

  if (start !== "" && end !== "") {
    dateExists = true;
    url = url + "?start=" + start + "&end=" + end;
  }

  if (limit !== "" && offset !== "") {
    if (dateExists) {
      url = url + "&limit=" + Number(limit) + "&offset=" + Number(offset);
    } else {
      url = url + "?limit=" + Number(limit) + "&offset=" + Number(offset);
    }
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getHistory = async (
  search: any = "",
  start: any = "",
  end: any = "",
  limit: any = "",
  offset: any = ""
) => {
  let url = ENDPOINTS.VIEWER_CONTENT.GET_HISTORY;

  if (search !== "") {
    url = url + "?search=" + search;
  }

  let dateExists = false;

  if (start !== "" && end !== "") {
    dateExists = true;
    url = url + "?start=" + start + "&end=" + end;
  }

  if (limit !== "" && offset !== "") {
    if (dateExists) {
      url = url + "&limit=" + Number(limit) + "&offset=" + Number(offset);
    } else {
      url = url + "?limit=" + Number(limit) + "&offset=" + Number(offset);
    }
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getRecentlyUploadedContent = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_CONTENT.RECENTLY_UPLOADED_CONTENT + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getMostPopularContent = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_CONTENT.MOST_POPULAR_CONTENT + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getTrendingContent = async (period: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_CONTENT.TRENDING_CONTENT + "?period=" + period,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getTrendingSpaces = async (period: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_SPACE.TRENDING_SPACES + "?period=" + period,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContractRequestsAdmin = async (
  creatorId: any = "",
  advertiserId: any = "",
  status: any = "",
  date: any = ""
) => {
  //  "?creatorId=643e9fe7c3e5fb8684628ddd&advertiserId=6459614076c136c336797791&status=expired&date=2023-06-14",

  let params = [];

  if (creatorId !== "") {
    params.push({
      key: "creatorId",
      value: creatorId,
    });
  }

  if (advertiserId !== "") {
    params.push({
      key: "advertiserId",
      value: advertiserId,
    });
  }

  if (status !== "") {
    params.push({
      key: "status",
      value: status,
    });
  }

  if (date !== "") {
    params.push({
      key: "date",
      value: date,
    });
  }

  let paramString = "";

  params.map((item, index) => {
    if (index === 0) {
      paramString = paramString + "?" + item.key + "=" + item.value;
    } else {
      paramString = paramString + "&" + item.key + "=" + item.value;
    }

    return 0;
  });

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_CONTRACT_REQUESTS + paramString,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContractRequestByIdAdmin = (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_CONTRACT_REQUEST_ID + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getMasterFilesOfCreatorAdmin = async (creatorId: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_MASTER_FILES + "?id=" + creatorId,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getMasterFileDetailsAdmin = async (contentId: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_MASTER_FILE + "?id=" + contentId,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAllSubspacesOfCreatorAdmin = async (creatorId: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_SUBSPACES + "?creatorId=" + creatorId,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getSubspaceByIdAdmin = async (subspaceId: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_SUBSPACE + "?id=" + subspaceId,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContentOfViewerAdmin = async (creatorId: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_CONTENTS + "?creatorId=" + creatorId,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContentByIdAdmin = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_CONTENT + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getViewersBySearchAndCountryAdmin = async (
  search: any = "",
  country: any = ""
) => {
  let url = ENDPOINTS.ADMIN.GET_VIEWERS;

  // "?search=spar&country=IN"
  if (search !== "") {
    url = url + "?search=" + search;
  }

  if (country !== "") {
    url = url + "&country=" + country;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getHistoryViewerAdmin = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_HISTORY_VIEWER + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getSavedContentViewerAdmin = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_SAVED_CONTENT_VIEWER + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getWatchLaterViewerAdmin = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_WATCH_LATER_VIEWER + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAdFilesOfCreatorAdmin = async (creatorId: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_AD_FILES + "?id=" + creatorId,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAdFileAdmin = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_AD_FILE + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getClientsAdmin = async (type: any = "", search: any = "") => {
  let url = ENDPOINTS.ADMIN.GET_CLIENTS;

  // "?type=all&search=mitt"
  if (type !== "") {
    url = url + "?type=" + type;
  }

  if (search !== "") {
    url = url + "&search=" + search;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getOverview = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.OVERVIEW.GET_OVERVIEW,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContentViewDetails = async (countryCode: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      ENDPOINTS.OVERVIEW.GET_CONTENT_VIEW_DETAILS +
      "?countryCode=" +
      countryCode,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContentViewDetailsById = async (contentId: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.OVERVIEW.GET_CONTENT_VIEW_DETAILS_ID + "?id=" + contentId,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getWatchtimeRates = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_WATCHTIME_RATES,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const updateWatchtimeRates = async (payload: any) => {
  let data = JSON.stringify({
    countryCode: payload.countryCode,
    rateInSSC: Number(payload.rateInSSC),
  });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.UPDATE_WATCHTIME_RATES,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getSpaceViews = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.OVERVIEW.GET_SPACE_VIEWS,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getViewsAndWatchtimeDetails = async (
  contentId: any,
  startDate: any,
  endDate: any
) => {
  // "?id=64ba8e4d80b33631befd7770&start=2023-08-25&end=2023-08-27",

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url:
      ENDPOINTS.OVERVIEW.GET_CONTENT_VIEWS_AND_WATCHTIME +
      "?id=" +
      contentId +
      "&start=" +
      startDate +
      "&end=" +
      endDate,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getCurrencyConversion = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_CURRENCY_CONVERSION,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const updateCurrencyConversion = async (payload: any) => {
  let data = JSON.stringify({
    USD: payload.USD,
    INR: payload.INR,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.UPDATE_CURRENCY_CONVERSION,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContinueWatching = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_CONTENT.GET_CONTINUE_WATCHING,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const updateContinueWatching = async (payload: any) => {
  let data = JSON.stringify({
    contentId: payload.contentId,
    subSpaceId: payload.subSpaceId,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER_CONTENT.UPDATE_CONTINUE_WATCHING,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

// https://www.server.spacshutl.com/api/contract/get-transactions-by-date?start=2023-09-17&end=2023-09-18
const getTransactionsByDate = async (start: any = "", end: any = "") => {
  let url = ENDPOINTS.CONTRACT.GET_TRANSACTIONS_BY_DATE;

  if (start !== "" && end !== "") {
    url = url + "?start=" + start + "&end=" + end;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContractDetails = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTRACT.GET_CONTRACT_DETAILS + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const updateAdminAllow = async (payload: any) => {
  let data = JSON.stringify({
    type: payload.type,
    id: payload.id,
    newAdminAllowValue: payload.newAdminAllowValue,
  });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.UPDATE_ADMIN_ALLOW,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContractsCreator = async (contentId: any = "") => {
  let url = ENDPOINTS.CONTRACT.GET_CONTRACTS_CREATOR;

  if (contentId !== "") {
    url = url + "?id=" + contentId;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getCreatorsWithContracts = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.CONTRACT.GET_CREATORS_WITH_CONTRACTS,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getContractsAdvertiser = async (creatorId: any = "") => {
  let url = ENDPOINTS.CONTRACT.GET_CONTRACTS_ADVERTISER;

  if (creatorId !== "") {
    url = url + "?id=" + creatorId;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const viewerChangePassword = async (payload: any) => {
  let data = JSON.stringify({
    oldPassword: payload.oldPassword,
    newPassword: payload.newPassword,
  });

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER.CHANGE_PASSWORD,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      ToastGenerator.failDark(error.response?.data?.message);
    });
};

const getViewerProfile = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER.GET_VIEWER_PROFILE,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const forgotViewerProfile = async (email: any) => {
  let data = JSON.stringify({
    email: email,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER.FORGOT_VIEWER_ACCOUNT,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      ToastGenerator.failDark(error.response?.data?.message);
    });
};

const viewerVerifyChangePassword = async (payload: any) => {
  let data = JSON.stringify({
    otp: payload.otp,
    email: payload.email,
    newPassword: payload.newPassword,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.VIEWER.VERIFY_CHANGE_PASSWORD,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      ToastGenerator.failDark(error.response?.data?.message);
    });
};

const raisePaymentRequest = async (payload: any) => {
  let data = JSON.stringify({
    amountInSSC: payload.amountInSSC,
    currency: payload.currency,
    amountInCurrency: payload.amountInCurrency,
    modeOfPayment: payload.modeOfPayment,
    receiverDetails: payload.receiverDetails,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.PAYMENT_REQUEST,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + JWTToken,
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getPaymentRequestById = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.GET_PAYMENT_REQUEST + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

// ?date=2023-10-04&id=643e9fe7c3e5fb8684628ddd&filter=approved
const getPaymentRequestsAdmin = async (type: any, value: any) => {
  let url = ENDPOINTS.ADMIN.GET_PAYMENT_REQUESTS;
  if (type === "client") {
    url = url + "?id=" + value;
  } else if (type === "date") {
    url = url + "?date=" + value;
  } else if (type === "filter") {
    url = url + "?filter=" + value;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const paymentRequestadminUpdate = async (id: any, payload: any) => {
  let data = new FormData();
  data.append("image", payload.image);
  data.append("actionBy", payload.actionBy);
  data.append("txnId", payload.txnId);

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.ADMIN_UPDATE_PR + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

// ?status=sent&month=2023-09&id=643e9fe7c3e5fb8684628ddd
const getWatchtimeBillsAdmin = async (type: any, value: any) => {
  let url = ENDPOINTS.ADMIN.GET_WATCHTIME_BILLS;
  if (type === "creator") {
    url = url + "?id=" + value;
  } else if (type === "month") {
    url = url + "?month=" + value;
  } else if (type === "status") {
    url = url + "?status=" + value;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getWatchtimeBillByIdAdmin = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_WATCHTIME_BILL + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const updateWatchtimeBill = async (id: any) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.UPDATE_WATCHTIME_BILL + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getWatchtimeBillProofsAdmin = async (type: any, value: any) => {
  let url = ENDPOINTS.ADMIN.WATCHTIME_BILL_PROOFS;
  if (type === "creator") {
    url = url + "?id=" + value;
  } else if (type === "month") {
    url = url + "?month=" + value;
  } else if (type === "status") {
    url = url + "?status=" + value;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getWatchtimeBillProofById = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.WATCHTIME_BILL_PROOF + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const adminApprovalForWatchtimeBill = async (payload: any, id: any) => {
  let data = new FormData();
  data.append("madeBy", payload.madeBy);
  data.append("reason", payload.reason);
  data.append("response", payload.response);
  data.append("image", payload.image);

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.ADMIN_APPROVAL_WB + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getWatchtimeBillsForCreator = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.GET_WATCHTIME_BILLS,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getWatchtimeBillByIdCreator = async (id: any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.ADMIN.GET_WATCHTIME_BILL + "?id=" + id,
    headers: {
      Authorization: "Bearer " + StaticJWT,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getPaymentRequestsCreator = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.GET_PAYMENT_REQUESTS,
    headers: {
      Authorization: "Bearer " + JWTToken,
    },
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const uploadProofForWatchtimeBill = async (payload: any, id: any) => {
  let data = new FormData();
  data.append("amountInSSC", payload.amountInSSC);
  data.append("currency", payload.currency);
  data.append("amountInCurrency", payload.amountInCurrency);
  data.append("modeOfPayment", payload.modeOfPayment);
  data.append("senderAddress", payload.senderAddress);
  data.append("dateOfTransaction", payload.dateOfTransaction);
  data.append("txnId", payload.txnId);
  data.append("image", payload.image);

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: ENDPOINTS.FUNDS.UPLOAD_PROOF_WB + "?id=" + id,
    headers: {
      Authorization: "Bearer " + JWTToken,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const API = {
  // CLIENT
  registerClientAccount,
  loginClientAccount,
  getAdvertiserListBySearch,
  getClientAccountById,
  getCreatorListBySearch,
  verifyOtp,
  changeClientPassword,
  forgotPassword,
  verifyEmailAndChangePassword,
  creatorUpdate,
  advertiserUpdate,

  // MASTER
  getMasterFiles,
  getMasterFileById,
  deleteMasterFileById,
  chooseMasterFileForContent,

  // ADFILES
  getAdFiles,
  getAdFileById,
  deleteAdFileById,

  // FUNDS
  addFundsRequest,
  uploadProofAddFunds,
  getAllAdvertiserFundsRequests,
  getAdvertiserRequestById,
  adminApprovalOfAddFundsRequest,
  withdrawFundsRequest,
  adminApprovalOfWithdrawFundsRequest,
  raisePaymentRequest,
  getPaymentRequestById,
  paymentRequestadminUpdate,
  getWatchtimeBillProofById,
  getWatchtimeBillsForCreator,
  getWatchtimeBillByIdCreator,
  getPaymentRequestsCreator,
  uploadProofForWatchtimeBill,

  // WALLET
  getWalletBalance,

  // REQUEST
  getAddFundsRequests,
  adminAdvertiserFundsRequestById,
  getWithdrawFundsRequests,

  // SPACE
  getSpaceConfiguration,
  postSpaceConfiguration,
  putSpaceConfiguration,
  putSpaceConfigurationImages,
  getAllSpaces,
  createSubspace,
  getAllSubspaces,
  getSubspaceById,
  deleteSubspaceById,
  updateSubspace,

  // CONTENT
  createContent,
  getContentList,
  getContentById,
  getContentData,
  getRequestData,
  createContentSuggestion,
  updateContentSuggestion,
  getContentSuggestion,
  getPreRequestData,
  getActiveContractsAndStreamLinks,

  // CONTRACT
  createContractRequest,
  getAllRequestsByType,
  getRequestById,
  withdrawContractRequest,
  getAllRequestsCreatorByType,
  getRequestByIdCreator,
  creatorResponseRequest,
  getTransactionsByDate,
  getContractDetails,
  getContractsCreator,
  getCreatorsWithContracts,
  getContractsAdvertiser,

  // OVERVIEW
  getOverview,
  getContentViewDetails,
  getContentViewDetailsById,
  getSpaceViews,
  getViewsAndWatchtimeDetails,

  // ADMIN
  getContractRequestsAdmin,
  getContractRequestByIdAdmin,
  getMasterFilesOfCreatorAdmin,
  getMasterFileDetailsAdmin,
  getAllSubspacesOfCreatorAdmin,
  getSubspaceByIdAdmin,
  getContentOfViewerAdmin,
  getContentByIdAdmin,
  getViewersBySearchAndCountryAdmin,
  getHistoryViewerAdmin,
  getSavedContentViewerAdmin,
  getWatchLaterViewerAdmin,
  getAdFilesOfCreatorAdmin,
  getAdFileAdmin,
  getClientsAdmin,
  getWatchtimeRates,
  updateWatchtimeRates,
  getCurrencyConversion,
  updateCurrencyConversion,
  updateAdminAllow,
  getPaymentRequestsAdmin,
  getWatchtimeBillsAdmin,
  getWatchtimeBillByIdAdmin,
  updateWatchtimeBill,
  getWatchtimeBillProofsAdmin,
  adminApprovalForWatchtimeBill,

  // VIEWER
  registerViewer,
  loginViewer,
  viewerChangePassword,
  getViewerProfile,
  viewerVerifyChangePassword,

  // VIEWER SPACE
  searchSpaces,
  getSpaceAndSubspaces,
  manageSubscriptions,
  getSubspaceForViewer,
  getAllSubscribedSpaces,
  getTrendingSpaces,

  // VIEWER CONTENT
  getContentForViewer,
  manageSavedContent,
  manageWatchLater,
  manageViewsAndWatchtime,
  getAllSubscribedContent,
  getWatchLater,
  getSavedContent,
  getHistory,
  getRecentlyUploadedContent,
  getMostPopularContent,
  getTrendingContent,
  getContinueWatching,
  updateContinueWatching,
  forgotViewerProfile,
};

export default API;
