// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { DateFunctions, ToastGenerator } from "../../utils/Functions";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Styles from "../../utils/Styles";
import API from "../../api/API";
import * as yup from "yup";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import Constants from "../../utils/Constants";
import Colors from "../../utils/Colors";
import CInputOutput from "../../atoms/CInputOutput";

const initValues = {
  image: {
    file: null,
    name: "",
    size: 0,
  },
  madeBy: "",
  response: "",
  reason: "",
};

interface AdminWatchtimeBillProofDetailsProps {}

const AdminWatchtimeBillProofDetails: FC<
  AdminWatchtimeBillProofDetailsProps
> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [billProofDetails, setBillProofDetails] = useState(BillProofInit);
  const [senderDetails, setSenderDetails] = useState([]);

  const [apiLoading, setApiLoading] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({
      madeBy: yup.string().required("Made By is required"),
    }),
    onSubmit: (values: any) => {
      console.log("Code Reachable!");
      if (values.image.file === null) {
        ToastGenerator.info("Upload photo reciept!");
        return;
      }

      if (
        values.response === Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.REJECTED &&
        values.reason === ""
      ) {
        ToastGenerator.info("Enter reason for rejection!");
        return;
      }

      setModal({
        open: true,
        apiLoading: false,
      });
    },
  });

  useEffect(() => {
    setApiLoading(true);
    API.getWatchtimeBillProofById(id).then((response) => {
      setApiLoading(false);
      setBillProofDetails(response?.proof);
    });
  }, []);

  useEffect(() => {
    const SenderDetails: any = billProofDetails.requestDetails?.senderDetails;

    let SenderDetailsKeyValuePairs: any = [];

    if (SenderDetails !== null && SenderDetails !== undefined) {
      SenderDetailsKeyValuePairs = Object.keys(SenderDetails).map((key) => ({
        name: key,
        value: SenderDetails[key],
      }));
    }

    setSenderDetails(SenderDetailsKeyValuePairs);
  }, [billProofDetails]);

  const confirmModalCall = () => {
    setModal({
      open: true,
      apiLoading: true,
    });

    const payload = {
      image: formik.values.image.file,
      madeBy: formik.values.madeBy,
      response: formik.values.response,
      reason: formik.values.reason,
    };

    API.adminApprovalForWatchtimeBill(payload, id).then((response) => {
      if (response.message === Constants.API_RESPONSES.BILL_UPDATED) {
        ToastGenerator.success(response.message);
        setModal({
          open: false,
          apiLoading: false,
        });
        navigate(-1);
      }
    });
  };

  if (apiLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Bill Proof Details" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <Box
            sx={{
              width: 800,
              ...Styles.SStyles.RowCenterSpace,
            }}
          >
            <CUIPresets.PageHeader title="Bill Proof Details" />
            <CUIPresets.OutlinedButton
              sx={{
                width: 200,
                mt: 1,
              }}
              title="Watchtime Details"
              // onClick={() => setModal({ open: true, apiLoading: false })}
            />
          </Box>
          <Box sx={{ mt: 1, ml: 1 }}>
            <CUIPresets.LabelStatus
              chipSelect={"watchtimeBillProof"}
              status={billProofDetails.status}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Request Details"
            />
            <CUIPresets.LabelValueHorizontal
              label={"Amount In SSC"}
              value={billProofDetails.requestDetails?.amountInSSC}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Currency"}
              value={billProofDetails.requestDetails?.currency}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Amount In Currency"}
              value={billProofDetails.requestDetails?.amountInCurrency}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Mode Of Payment"}
              value={billProofDetails.requestDetails?.modeOfPayment}
            />

            {senderDetails?.map((item: any) => (
              <CUIPresets.LabelValueHorizontal
                label={item.name}
                value={item.value}
              />
            ))}

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Proof Details"
            />

            <CUIPresets.LabelImageHorizontal
              label={"Proof of Payment"}
              src={billProofDetails.proofDetails?.photoProof}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Transaction ID"}
              value={billProofDetails.proofDetails?.txnId}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Transaction Date"}
              value={DateFunctions.format(billProofDetails.proofDetails?.date)}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Actions"
            />

            {billProofDetails.status ===
              Constants.STATUS_FLAGS.WATCHTIME_BILL_PROOF.RAISED && (
              <>
                <CText.Jakarta.Size18.Light.Grey1
                  text="Upload a screenshot of reciept of payment."
                  style={{
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                />

                <CInputOutput.SelectImage
                  title={"Upload Photo Reciept"}
                  setImage={(ev: any) => {
                    formik.setFieldValue("image", {
                      file: ev,
                      name: ev.name,
                      size: Number(ev.size) / (1024 * 1024),
                    });
                  }}
                />

                <CUIPresets.SelectedFile
                  imageExists={formik.values.image.file}
                  fileName={formik.values.image.name}
                  fileSize={formik.values.image.size}
                />

                <CInputOutput.LabelInputOutlined
                  sx={{ width: 400, mt: 2 }}
                  label="Made By"
                  textFieldProps={{
                    placeholder: "Enter Made By",
                    helperText: formik.touched.madeBy && formik.errors.madeBy,
                    error: Boolean(
                      formik.touched.madeBy && formik.errors.madeBy
                    ),
                    onBlur: formik.handleBlur,
                    onChange: formik.handleChange,
                    value: formik.values.madeBy,
                    name: "madeBy",
                  }}
                />

                <CInputOutput.LabelInputOutlined
                  sx={{ width: 400, mt: 2 }}
                  label="Reason"
                  textFieldProps={{
                    placeholder: "Enter reason",
                    helperText: formik.touched.reason && formik.errors.reason,
                    error: Boolean(
                      formik.touched.reason && formik.errors.reason
                    ),
                    onBlur: formik.handleBlur,
                    onChange: formik.handleChange,
                    value: formik.values.reason,
                    name: "reason",
                  }}
                />

                <CUIPresets.FilledButton
                  onClick={() => {
                    formik.setFieldValue(
                      "response",
                      Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.APPROVED
                    );
                    formik.handleSubmit();
                  }}
                  color={Colors.green}
                  sx={{ width: 170, mt: 2, mb: 2 }}
                  title="Approve"
                />

                <CUIPresets.FilledButton
                  onClick={() => {
                    formik.setFieldValue(
                      "response",
                      Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.REJECTED
                    );
                    formik.handleSubmit();
                  }}
                  color={Colors.red}
                  sx={{ width: 170, mt: 2, mb: 2, ml: 3 }}
                  title="Reject"
                />
              </>
            )}

            {(billProofDetails.status ===
              Constants.STATUS_FLAGS.WATCHTIME_BILL_PROOF.APPROVED ||
              billProofDetails.status ===
                Constants.STATUS_FLAGS.WATCHTIME_BILL_PROOF.REJECTED) && (
              <>
                <CUIPresets.LabelImageHorizontal
                  label={"Status"}
                  src={billProofDetails.adminResponse?.receiptImage}
                />

                <CUIPresets.LabelValueHorizontal
                  label={"Made By"}
                  value={billProofDetails.adminResponse?.madeBy}
                />

                {billProofDetails.status ===
                  Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.REJECTED && (
                  <CUIPresets.LabelValueHorizontal
                    label={"Reason"}
                    value={billProofDetails.adminResponse?.reason}
                  />
                )}
              </>
            )}
          </Box>
          <CUIPresets.CustomDialog
            title="Admin Approval"
            content={[
              "Are you sure about " +
                (formik.values.response ===
                Constants.STATUS_FLAGS.WATCHTIME_BILL_PROOF.APPROVED
                  ? "approving"
                  : "rejecting") +
                " this add funds request?",
            ]}
            confirmText={"Confirm"}
            cancelText="Cancel"
            confirmCall={confirmModalCall}
            modalOpen={modal.open}
            cancelCall={() =>
              setModal({
                open: false,
                apiLoading: false,
              })
            }
            apiLoading={modal.apiLoading}
          />
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdminWatchtimeBillProofDetails;

const BillProofInit = {
  status: "",
  requestDetails: {
    amountInSSC: 0,
    currency: "",
    amountInCurrency: 0,
    modeOfPayment: "",
    senderDetails: {},
  },
  proofDetails: {
    photoProof: "",
    txnId: "",
    date: "",
  },
  watchtimeBillId: "",
  creatorDetails: {
    fullName: "",
  },
  adminResponse: {
    receiptImage: "",
    madeBy: "",
    reason: "",
  },
};
