// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Grid, IconButton, TextField } from "@mui/material";
import { MessageEdit, Verify } from "iconsax-react";

// Functional Imports
import { NumberTypeInputProps, ToastGenerator } from "../../utils/Functions";
import Styles from "../../utils/Styles";
import Colors from "../../utils/Colors";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";

interface AdminWatchtimeRatesProps {}

const AdminWatchtimeRates: FC<AdminWatchtimeRatesProps> = (props) => {
  const [watchtimeRates, setWatchtimeRates] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getWatchtimeRates().then((response) => {
      setDataLoading(false);
      setWatchtimeRates(response.watchtimeRatesData);
    });
  }, []);

  if (dataLoading) {
    return (
      <>
        <CUIPresets.PageHeader title="Watchtime Rates" />
        <CUIPresets.LoadingSpinner />
      </>
    );
  } else {
    return (
      <>
        <CUIPresets.PageHeader title="Watchtime Rates" />

        <Grid container sx={{ ml: 1 }}>
          {watchtimeRates.map((item: any) => (
            <WatchtimeRate
              countryName={item.countryName}
              rateInSSC={item.rateInSSC}
              countryCode={item.countryCode}
            />
          ))}
        </Grid>
      </>
    );
  }
};

export default AdminWatchtimeRates;

interface WatchtimeRateProps {
  countryName?: any;
  rateInSSC?: any;
  countryCode?: any;
}

const WatchtimeRate: FC<WatchtimeRateProps> = (props) => {
  const [editEnabled, setEditEnabled] = useState(false);
  const [rateInSSC, setRateInSSC] = useState(props.rateInSSC);

  const toggleEdit = () => {
    if (editEnabled) {
      const payload = {
        countryCode: props.countryCode,
        rateInSSC: rateInSSC,
      };

      API.updateWatchtimeRates(payload).then((response) => {
        if (response.actionSuccess) {
          ToastGenerator.success("Update Successful!");
        }
      });
      setEditEnabled(false);
    } else {
      setEditEnabled(true);
    }
  };

  return (
    <Grid
      item
      sx={{
        ...Styles.SStyles.RowStartStart,
        mr: 6,
        mb: 2,
      }}
    >
      <CText.Jakarta.Size16.Mid.Grey3
        style={{ width: editEnabled ? 165 : 180, marginTop: 8 }}
        text={props.countryName}
      />
      {!editEnabled && (
        <CText.Jakarta.Size16.Mid.Black
          style={{ width: 60, marginTop: 8 }}
          text={rateInSSC}
        />
      )}
      {editEnabled && (
        <TextField
          onWheel={(event) => {
            event.preventDefault();
          }}
          InputProps={{ ...NumberTypeInputProps }}
          size="small"
          sx={{
            width: 75,
            maxHeight: "70px",
            borderRadius: "3px",
            borderWidth: 0,
            backgroundColor: Colors.white,
          }}
          value={rateInSSC}
          onChange={(e: any) => setRateInSSC(e.target.value)}
        />
      )}
      <IconButton
        onClick={toggleEdit}
        sx={{
          ml: 8,
        }}
      >
        {!editEnabled && <MessageEdit />}
        {editEnabled && <Verify color={Colors.blueText} />}
      </IconButton>
    </Grid>
  );
};
