// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import {
  Box,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import {
  ArrowCircleRight2,
  ArrowDown2,
  ArrowUp2,
  Grid1,
  Grid2,
  InfoCircle,
} from "iconsax-react";
import { ProgressBar, ThreeDots } from "react-loader-spinner";

// Functional Imports
import { useDispatch, useSelector } from "react-redux";
import { DateFunctions } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Constants from "../../utils/Constants";
import Images from "../../utils/Images";
import Styles from "../../utils/Styles";
import Colors from "../../utils/Colors";

// Local Imports
import CText from "../CText";
import CTools from "../CTools";
import CUIPresets, { Transition } from "./CUIPresets";
import CButton from "./CButton";
import ACTION_TYPES from "../../redux/actions/actionTypes";

interface ViewerSelectFlowProps {
  options?: any;
  value?: any;
  setValue?: any;
}

const ViewerSelectFlow: FC<ViewerSelectFlowProps> = (props) => {
  return (
    <>
      {props.options?.map((item: any, index: number) => (
        <ViewerSelectFlowTile
          label={item}
          selected={props.value === item}
          setValue={props.setValue}
          sx={index === 0 ? { ml: 3 } : { ml: 1 }}
        />
      ))}
    </>
  );
};

interface ViewerSelectFlowTileProps {
  label?: any;
  selected?: any;
  setValue?: any;
  sx?: any;
}

const ViewerSelectFlowTile: FC<ViewerSelectFlowTileProps> = (props) => {
  return (
    <ButtonBase
      sx={{ ...Styles.CStyles.viewerHoverEffect, ...props.sx }}
      onClick={() => props.setValue(props.label)}
    >
      {props.selected ? (
        <CText.Jakarta.Size16.Light.Cyan text={props.label} />
      ) : (
        <CText.Jakarta.Size16.Light.White text={props.label} />
      )}
    </ButtonBase>
  );
};

interface GridSelectionProps {}

const GridSelection: FC<GridSelectionProps> = (props) => {
  const grid = useSelector((state: any) => state.appState.grid);
  const dispatch = useDispatch();

  const setGrid = (type: any) => {
    dispatch({ type: ACTION_TYPES.GRID, payload: type });
  };

  return (
    <Box sx={{ ...Styles.SStyles.RowCenterCenter }}>
      <ButtonBase
        onClick={() => setGrid("comfortable")}
        sx={{
          ...Styles.CStyles.viewerHoverEffect,
          px: 1.5,
        }}
      >
        <Grid2
          style={{
            color: grid === "comfortable" ? Colors.cyan : Colors.white,
          }}
        />
      </ButtonBase>
      <ButtonBase
        onClick={() => setGrid("compact")}
        sx={{
          ...Styles.CStyles.viewerHoverEffect,
          px: 1.5,
          mr: 4,
        }}
      >
        <Grid1
          style={{
            color: grid === "compact" ? Colors.cyan : Colors.white,
          }}
        />
      </ButtonBase>
    </Box>
  );
};

interface ContentTileProps {
  image?: any;
  spaceName?: any;
  sx?: any;
  imageStyles?: any;
  onClick?: any;
}

const LargeContentTile: FC<ContentTileProps> = (props) => {
  const handleClick = () => {
    setTimeout(() => {
      if (props.onClick) {
        props.onClick();
      }
    }, 300);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <Grid
        item
        component={ButtonBase}
        onClick={handleClick}
        sx={{
          width: "28vw",
          height: "20vw",
          m: 1,
          ml: 0,
          mr: 4,
          mt: 2,
          borderRadius: 1,
          backgroundColor: Colors.blackBackground4,
          ...Styles.SStyles.ColumnStartStart,
          cursor: "pointer",
          ":hover": {
            backgroundColor: Colors.greyBackground2,
          },
          ...props.sx,
        }}
      >
        <CTools.ImageContainer
          imageBgColor={null}
          height={"17vw"}
          width={"28vw"}
          src={props.image}
          imageStyles={{
            borderRadius: 0,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            ...props.imageStyles,
          }}
        />
        <CText.Roboto.Sise16.Light.Grey7
          text={props.spaceName}
          style={{ marginTop: 8, marginLeft: 8, fontFamily: "Roboto" }}
        />
      </Grid>
    </CUIPresets.AnimationWrapper>
  );
};

const SmallContentTile: FC<ContentTileProps> = (props) => {
  const handleClick = () => {
    setTimeout(() => {
      if (props.onClick) {
        props.onClick();
      }
    }, 300);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <Grid
        item
        component={ButtonBase}
        onClick={handleClick}
        sx={{
          width: "22vw",
          height: "16vw",
          m: 1,
          ml: 0,
          mr: 2,
          borderRadius: 1,
          backgroundColor: Colors.blackBackground4,
          ...Styles.SStyles.midElevation,
          ...Styles.SStyles.ColumnStartStart,
          ":hover": {
            backgroundColor: Colors.greyBackground2,
          },
          ...props.sx,
        }}
      >
        <CTools.ImageContainer
          imageBgColor={null}
          height={"13vw"}
          width={"22vw"}
          src={props.image}
          imageStyles={{
            borderRadius: 0,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            ...props.imageStyles,
          }}
        />
        <CText.Roboto.Sise16.Light.Grey7
          text={props.spaceName}
          style={{ marginTop: 8, marginLeft: 8, fontFamily: "Roboto" }}
        />
      </Grid>
    </CUIPresets.AnimationWrapper>
  );
};

interface SpaceTileProps {
  backgroundImageSource?: any;
  displayImageSource?: any;
  creatorName?: any;
  creatorDescription?: any;
  onClick?: any;
  sx?: any;
}

const LargeSpaceTile: FC<SpaceTileProps> = (props) => {
  const handleClick = () => {
    if (props.onClick) {
      setTimeout(() => {
        props.onClick();
      }, 200);
    }
  };

  return (
    <CUIPresets.AnimationWrapper>
      <Grid
        item
        onClick={handleClick}
        component={ButtonBase}
        sx={{
          height: "60vh",
          width: "45vw",
          maxHeight: 500,
          mr: 3,
          mt: 1,
          mb: 1,
          ...Styles.CStyles.viewerHoverEffect,
          ":hover": {
            backgroundColor: Colors.black,
            opacity: 1,
            ...Styles.SStyles.elevation,
          },
          ...props.sx,
        }}
      >
        <CTools.ImageContainer
          imageBgColor={null}
          src={props.backgroundImageSource}
          height={"60vh"}
          width={"45vw"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            maxHeight: 500,
          }}
          imageStyles={{
            opacity: 0.5,
          }}
        />
        <Box
          sx={{
            zIndex: 1,
            ...Styles.SStyles.ColumnCenterCenter,
            width: "70%",
          }}
        >
          <CTools.ImageContainer
            imageBgColor={null}
            src={props.displayImageSource}
            width={150}
            height={150}
            imageStyles={{ borderRadius: 50 }}
          />

          <CText.Jakarta.Size16.Mid.White
            text={props.creatorName}
            style={{ marginTop: 16 }}
          />

          <CText.Jakarta.Size14.Mid.White
            text={props.creatorDescription}
            style={{ marginTop: 8 }}
          />
        </Box>
      </Grid>
    </CUIPresets.AnimationWrapper>
  );
};

const SmallSpaceTile: FC<SpaceTileProps> = (props) => {
  const handleClick = () => {
    if (props.onClick) {
      setTimeout(() => {
        props.onClick();
      }, 200);
    }
  };

  return (
    <CUIPresets.AnimationWrapper>
      <Grid
        item
        onClick={handleClick}
        component={ButtonBase}
        sx={{
          height: "30vh",
          minHeight: 240,
          width: "30vw",
          mr: 3,
          mt: 1,
          mb: 1,
          ":hover": {
            backgroundColor: Colors.black,
            opacity: 1,
            ...Styles.SStyles.elevation,
          },
        }}
      >
        <CTools.ImageContainer
          imageBgColor={null}
          src={props.backgroundImageSource}
          height={"30vh"}
          width={"30vw"}
          sx={{
            minHeight: 240,
            position: "absolute",
            top: 0,
            left: 0,
          }}
          imageStyles={{
            opacity: 0.5,
          }}
        />
        <Box
          sx={{
            zIndex: 1,
            ...Styles.SStyles.ColumnCenterCenter,
            width: "70%",
          }}
        >
          <CTools.ImageContainer
            imageBgColor={null}
            src={props.displayImageSource}
            width={100}
            height={100}
            imageStyles={{ borderRadius: 50 }}
          />

          <CText.Jakarta.Size16.Mid.White
            text={props.creatorName}
            style={{ marginTop: 16 }}
          />
        </Box>
      </Grid>
    </CUIPresets.AnimationWrapper>
  );
};

interface ViewerTopBarProps {
  options?: any;
  selected?: any;
  setSelected?: any;
  confirmAction?: any;
}

const ViewerTopBar: FC<ViewerTopBarProps> = (props) => {
  const navigate = useNavigate();

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  return (
    <Box
      sx={{
        height: 72,
        width: "100vw",
        backgroundColor: Colors.blackBackground3,
        ...Styles.SStyles.RowCenterSpace,
      }}
    >
      <Box
        sx={{
          ...Styles.SStyles.RowCenterCenter,
          pl: 5,
        }}
      >
        <CTools.ImageContainer
          imageBgColor={null}
          height={36}
          width={52}
          src={Images.LogoBlue}
        />
        <CUIPresets.ViewerSelectFlow
          options={props.options}
          value={props.selected}
          setValue={props.setSelected}
        />
      </Box>

      <ButtonBase
        onClick={() => {
          if (props.confirmAction) {
            setModal({
              open: true,
              apiLoading: false,
            });
          } else {
            setTimeout(() => {
              navigate(PATHS.VIEWER_NAVIGATE_TO);
            }, 200);
          }
        }}
        sx={{
          ...Styles.SStyles.RowCenterCenter,
          mr: 4,
          ...Styles.CStyles.viewerHoverEffect,
        }}
      >
        <CText.Jakarta.Size16.Light.White text={"Navigate To"} />
        <ArrowCircleRight2
          size={22}
          style={{ color: Colors.cyan, marginLeft: 8 }}
        />
      </ButtonBase>

      <CUIPresets.ViewerDialog
        title="Content Player"
        content={["Are you sure about leaving player?"]}
        confirmText={"Confirm"}
        cancelText="Cancel"
        confirmCall={() => {
          setModal({
            open: false,
            apiLoading: false,
          });
          navigate(PATHS.VIEWER_NAVIGATE_TO);
        }}
        modalOpen={modal.open}
        cancelCall={() =>
          setModal({
            open: false,
            apiLoading: false,
          })
        }
        apiLoading={modal.apiLoading}
      />
    </Box>
  );
};

interface ViewerLoadingProps {
  sx?: any;
}

const ViewerLoading: FC<ViewerLoadingProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        ...Styles.SStyles.RowCenterCenter,
        mt: 10,
        ...props.sx,
      }}
    >
      <ProgressBar
        height={240}
        width={320}
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass="progress-bar-wrapper"
        borderColor={Colors.white}
        barColor={Colors.cyan}
      />
    </Box>
  );
};

interface ViewerStatusTextProps {
  text?: any;
  sx?: any;
  textStyle?: any;
}

const ViewerStatusText: FC<ViewerStatusTextProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 400,
        ...Styles.SStyles.RowCenterCenter,
        ...props.sx,
      }}
    >
      <CText.Jakarta.Size64.Mid.Grey
        text={props.text}
        style={{
          marginBottom: 8,
          ...props.textStyle,
        }}
      />
    </Box>
  );
};

interface SubscribeButtonProps {
  type?: any;
  sx?: any;
  subscriptionDate?: any;
  onClick?: any;
}

const SubscribeButton: FC<SubscribeButtonProps> = (props) => {
  const [hover, setHover] = useState(false);

  const addOneYear = () => {
    const currentDate = new Date(props.subscriptionDate);
    return currentDate.setFullYear(currentDate.getFullYear() + 1);
  };

  return (
    <Box
      sx={{
        ...Styles.SStyles.RowCenterCenter,
        ...props.sx,
      }}
    >
      <ButtonBase
        onClick={props.onClick}
        sx={{
          height: 35,
          minWidth: 90,
          border:
            props.type === "subscribe"
              ? "1px solid " + Colors.white
              : "1px solid " + Colors.cyan,
          borderRadius: 1.5,
          px: 2,
          backgroundColor: Colors.white + "66",
          ":hover": {
            backgroundColor: Colors.greyBackground2 + "33",
          },
        }}
      >
        {props.type === Constants.TYPES.SUBSCRIPTION_TYPES.SUBSCRIBE && (
          <CText.Outfit.Size14.Light.White
            style={{ marginRight: 16, marginLeft: 16 }}
            text={"Subscribe"}
          />
        )}

        {props.type === Constants.TYPES.SUBSCRIPTION_TYPES.SUBSCRIBED && (
          <CText.Outfit.Size14.Light.Cyan
            style={{ marginRight: 16, marginLeft: 16 }}
            text={"Subscribed"}
          />
        )}

        {props.type === Constants.TYPES.SUBSCRIPTION_TYPES.LOADING && (
          <ThreeDots
            height="80"
            width="60"
            radius="9"
            color={Colors.cyan}
            ariaLabel="three-dots-loading"
            visible={true}
          />
        )}
      </ButtonBase>
      {props.type === Constants.TYPES.SUBSCRIPTION_TYPES.SUBSCRIBED && (
        <ButtonBase
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          sx={{
            ml: 1,
            borderRadius: 4,
          }}
        >
          <InfoCircle color={Colors.cyan} size={28} />
          {hover && (
            <CUIPresets.AnimationWrapper>
              <Box
                sx={{
                  position: "absolute",
                  top: 12,
                  left: 12,
                  border: "1px solid " + Colors.cyan,
                  width: 200,
                  height: 58,
                  borderRadius: 2,
                  backgroundColor: Colors.greyBackground2,
                  pl: 2,
                  ...Styles.SStyles.ColumnStartStart,
                }}
              >
                <CText.Outfit.Size14.Light.White
                  style={{ marginTop: 6 }}
                  text={
                    "Subscribed On: " +
                    DateFunctions.format(props.subscriptionDate)
                  }
                />

                <CText.Outfit.Size14.Light.White
                  style={{ marginTop: 4 }}
                  text={"Expiry On: " + DateFunctions.format(addOneYear())}
                />
              </Box>
            </CUIPresets.AnimationWrapper>
          )}
        </ButtonBase>
      )}
    </Box>
  );
};

interface SubspaceTileProps {
  label?: any;
  onClick?: any;
}

const SubspaceTile: FC<SubspaceTileProps> = (props) => {
  return (
    <Grid
      item
      sx={{
        width: "41vw",
        height: 120,
        m: 2,
        borderRadius: 2,
        backgroundColor: Colors.blackBackground4,
        ...Styles.SStyles.RowCenterSpace,
        px: 4,
      }}
    >
      <CText.Jakarta.Size32.Mid.White text={props.label} />
      <CUIPresets.RightArrowAvatarLight onClick={props.onClick} iconSize={42} />
    </Grid>
  );
};

interface ViewerDialogProps {
  title?: any;
  content?: any;
  cancelCall?: any;
  cancelText?: any;
  confirmCall?: any;
  confirmText?: any;
  modalOpen?: any;
  apiLoading?: any;
}

const ViewerDialog: FC<ViewerDialogProps> = (props) => {
  return (
    <Dialog
      open={props.modalOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.cancelCall}
      sx={{
        borderRadius: 10,
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: Colors.blackBackground6,
          color: "white",
          height: "25px",
          pb: 2,
        }}
      >
        <CText.Jakarta.Size18.SemiBold.White text={props.title} />
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: "320px",
          pt: 2,
          backgroundColor: Colors.blackBackground7,
        }}
      >
        <DialogContentText
          sx={{
            mt: 2,
            fontWeight: "500",
            color: "white",
          }}
        >
          {props.content.map((item: any) => (
            <CText.Jakarta.Size16.Mid.White text={item} />
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: Colors.blackBackground7,
        }}
      >
        {props.cancelText.length > 0 && (
          <ButtonBase
            sx={{
              px: 1.5,
              py: 1,
              br: 1,
              ":hover": {
                backgroundColor: Colors.blackBackground8 + "FF",
              },
            }}
            onClick={props.cancelCall}
          >
            <CText.Outfit.Size18.SemiBold.White text={props.cancelText} />
          </ButtonBase>
        )}

        {!props.apiLoading && (
          <CButton.Outlined
            title={props.confirmText}
            onClick={props.confirmCall}
            color={Colors.white}
            sx={{
              width: "100px",
              marginRight: 1,
              borderWidth: 0,
              ":hover": {
                backgroundColor: Colors.blackBackground8 + "FF",
                borderWidth: 0,
                borderColor: Colors.white,
              },
            }}
          />
        )}

        {props.apiLoading && (
          <Box
            sx={{
              height: "40px",
              width: "100px",
              borderRadius: 1,
              marginRight: 1,
              ...Styles.SStyles.lowElevation,
              ...Styles.SStyles.ColumnCenterCenter,
            }}
          >
            <ThreeDots
              height="40"
              width="60"
              radius="9"
              color="white"
              wrapperStyle={{}}
              visible={true}
            />
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface ContentWithoutTitleProps {
  image?: any;
  sx?: any;
  onClick?: any;
}

const LargeContentWithoutTitle: FC<ContentWithoutTitleProps> = (props) => {
  const handleClick = () => {
    if (props.onClick) {
      setTimeout(() => {
        props.onClick();
      }, 200);
    }
  };

  return (
    <CUIPresets.AnimationWrapper>
      <Grid
        onClick={handleClick}
        item
        component={ButtonBase}
        sx={{
          width: "29vw",
          height: "18vw",
          minHeight: 275,
          m: 1,
          p: 1.5,
          ml: 0,
          mr: 4,
          borderRadius: 1,
          backgroundColor: Colors.blackBackground4,
          ...Styles.SStyles.RowCenterCenter,
          cursor: "pointer",
          ":hover": {
            backgroundColor: Colors.greyBackground2,
          },
          ...props.sx,
        }}
      >
        <CTools.ImageContainer
          height={"17vw"}
          width={"29vw"}
          src={props.image}
          sx={{
            minHeight: 250,
          }}
          imageStyles={{
            borderRadius: 0,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        />
      </Grid>
    </CUIPresets.AnimationWrapper>
  );
};

const SmallContentWithoutTitle: FC<ContentWithoutTitleProps> = (props) => {
  const handleClick = () => {
    if (props.onClick) {
      setTimeout(() => {
        props.onClick();
      }, 200);
    }
  };

  return (
    <CUIPresets.AnimationWrapper>
      <Grid
        onClick={handleClick}
        item
        component={ButtonBase}
        sx={{
          width: "22vw",
          height: "14vw",
          minHeight: 200,
          m: 1,
          p: 1,
          ml: 0,
          mr: 2,
          borderRadius: 1,
          backgroundColor: Colors.blackBackground4,
          ...Styles.SStyles.midElevation,
          ...Styles.SStyles.RowCenterCenter,
          ":hover": {
            backgroundColor: Colors.greyBackground2,
          },
          ...props.sx,
        }}
      >
        <CTools.ImageContainer
          height={"13vw"}
          width={"21vw"}
          src={props.image}
          sx={{
            minHeight: 184,
          }}
          imageStyles={{
            borderRadius: 0,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        />
      </Grid>
    </CUIPresets.AnimationWrapper>
  );
};

interface ContentActionTileProps {
  title?: any;
  upOrDown?: any;
  sx?: any;
  onClick?: any;
}

const ContentActionTile: FC<ContentActionTileProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "15%",
        mt: 2,
        backgroundColor: Colors.blackBackground4,
        ...Styles.SStyles.RowCenterSpace,
        ...Styles.SStyles.midElevation,
        ...props.sx,
      }}
    >
      <CText.Jakarta.Size24.Mid.White
        style={{
          marginLeft: 24,
        }}
        text={props.title}
      />
      <IconButton
        onClick={props.onClick}
        sx={{
          marginRight: 3,
          ":hover": {
            backgroundColor: Colors.cyan + "22",
          },
        }}
      >
        {props.upOrDown === "down" && (
          <ArrowDown2 size="32" color={Colors.cyan} />
        )}
        {props.upOrDown === "up" && <ArrowUp2 size="32" color={Colors.cyan} />}
      </IconButton>
    </Box>
  );
};

interface ViewerActionProps {
  sx?: any;
  title?: any;
  loading?: any;
  onClick?: any;
}

const ViewerAction: FC<ViewerActionProps> = (props) => {
  const handleClick = () => {
    if (props.onClick) {
      setTimeout(() => {
        props.onClick();
      }, 200);
    }
  };

  return (
    <ButtonBase
      onClick={handleClick}
      sx={{
        width: "90%",
        height: 50,
        backgroundColor: Colors.blackBackground4,
        ...Styles.SStyles.midElevation,
        ...Styles.CStyles.viewerHoverEffect,
        borderRadius: 1,
        ...props.sx,
      }}
    >
      {props.loading && (
        <ThreeDots
          height="80"
          width="60"
          radius="9"
          color={Colors.white}
          ariaLabel="three-dots-loading"
          visible={true}
        />
      )}
      {!props.loading && <CText.Jakarta.Size16.Mid.White text={props.title} />}
    </ButtonBase>
  );
};

interface ViewerContentPlayerProps {
  streamLink?: any;
  style?: any;
}

const ViewerContentPlayer: FC<ViewerContentPlayerProps> = (props) => {
  const getIframeDeliveryLink = () => {
    const streamLinkArr = props.streamLink?.split("/");

    let streamLinkModified = "https://iframe.mediadelivery.net/embed";

    streamLinkModified =
      streamLinkModified +
      "/" +
      streamLinkArr[4] +
      "/" +
      streamLinkArr[5] +
      "?autoplay=true&preload=true";

    return streamLinkModified;
  };

  return (
    <iframe
      title="unique"
      src={getIframeDeliveryLink()}
      style={{
        border: "none",
        height: "90%",
        width: "100%",
        backgroundColor: Colors.blackBackground,
        ...Styles.SStyles.midElevation,

        ...props.style,
      }}
      allowFullScreen
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
    />
  );
};

const ViewerCUIPresets = {
  ViewerSelectFlow,
  GridSelection,
  LargeContentTile,
  SmallContentTile,
  LargeSpaceTile,
  SmallSpaceTile,
  ViewerTopBar,
  ViewerLoading,
  ViewerStatusText,
  SubscribeButton,
  SubspaceTile,
  ViewerDialog,
  LargeContentWithoutTitle,
  SmallContentWithoutTitle,
  ContentActionTile,
  ViewerAction,
  ViewerContentPlayer,
};

export default ViewerCUIPresets;
