// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";
import {} from "iconsax-react";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import Constants from "../../utils/Constants";
import API from "../../api/API";

// Local Imports
import CTools from "../../atoms/CTools";
import CText from "../../atoms/CText";
import CUIPresets from "../../atoms/CUIPresets";
import { UI } from "../../utils/Functions";

interface RightSectionProps {
  contentDetails?: any;
  spaceDetails?: any;
  suggestions?: any;
  contentActions?: any;

  setContentDetails?: any;
}

const RightSection: FC<RightSectionProps> = (props) => {
  const navigate = useNavigate();

  const [displayType, setDisplayType] = useState(
    Constants.TYPES.PLAYER_RIGHT_DISPLAY.NONE
  );
  const [loadingFlags, setLoadingFlags] = useState({
    visitSpace: false,
    watchLater: false,
    savedContent: false,
  });

  const toggleSavedContent = () => {
    setLoadingFlags({
      ...loadingFlags,
      savedContent: true,
    });

    const payload = {
      contentId: props.contentDetails?.contentId,
      action: props.contentDetails?.savedContent ? "remove" : "add",
    };

    API.manageSavedContent(payload).then((response) => {
      setLoadingFlags({
        ...loadingFlags,
        savedContent: false,
      });

      if (response.actionStatus) {
        props.setContentDetails({
          ...props.contentDetails,
          savedContent: !props.contentDetails?.savedContent,
        });
      }
    });
  };

  const toggleWatchLater = () => {
    setLoadingFlags({
      ...loadingFlags,
      watchLater: true,
    });

    const payload = {
      contentId: props.contentDetails?.contentId,
      action: props.contentDetails?.watchLater ? "remove" : "add",
    };

    API.manageWatchLater(payload).then((response) => {
      setLoadingFlags({
        ...loadingFlags,
        watchLater: false,
      });

      if (response.actionStatus) {
        props.setContentDetails({
          ...props.contentDetails,
          watchLater: !props.contentDetails?.watchLater,
        });
      }
    });
  };

  const onVisitSpace = () => {
    setLoadingFlags({
      ...loadingFlags,
      visitSpace: true,
    });

    setTimeout(() => {
      setLoadingFlags({
        ...loadingFlags,
        visitSpace: false,
      });
      if (props.spaceDetails?.spaceId) {
        navigate(PATHS.VIEWER_SPACE + "/" + props.spaceDetails?.spaceId);
      }
    }, 1000);
  };

  if (displayType === Constants.TYPES.PLAYER_RIGHT_DISPLAY.NONE) {
    return (
      <>
        <Box
          sx={{
            width: "100%",
            height: "64%",
            maxHeight: 340,
            backgroundColor: Colors.blackBackground4,
            position: "relative",
          }}
        >
          <CTools.ImageContainer
            height={"50%"}
            width={"100%"}
            src={props.spaceDetails?.backgroundImage}
          />
          <Box
            sx={{
              height: "50%",
              width: "100%",
              ...Styles.SStyles.RowCenterCenter,
            }}
          >
            <CText.Jakarta.Size14.Mid.Grey1
              style={{
                marginTop: 32,
                width: "80%",
                textAlign: "center",
              }}
              text={props.spaceDetails?.description}
            />
          </Box>

          <CTools.ImageContainer
            height={120}
            width={120}
            imageBgColor={null}
            src={props.spaceDetails?.profileImage}
            sx={{
              position: "absolute",
              top: "28%",
              left: "35%",
            }}
            imageStyles={{
              borderRadius: 60,
            }}
          />
        </Box>

        <CUIPresets.ContentActionTile
          title="Suggestions"
          upOrDown={"up"}
          onClick={() =>
            setDisplayType(Constants.TYPES.PLAYER_RIGHT_DISPLAY.SUGGESTIONS)
          }
        />
        <CUIPresets.ContentActionTile
          title="Actions"
          upOrDown={"up"}
          onClick={() =>
            setDisplayType(Constants.TYPES.PLAYER_RIGHT_DISPLAY.ACTIONS)
          }
        />
      </>
    );
  } else if (displayType === Constants.TYPES.PLAYER_RIGHT_DISPLAY.SUGGESTIONS) {
    return (
      <Box
        sx={{
          height: "100%",
          maxHeight: 600,
          backgroundColor: Colors.blackBackground5,
        }}
      >
        <CUIPresets.ContentActionTile
          title="Suggestions"
          onClick={() =>
            setDisplayType(Constants.TYPES.PLAYER_RIGHT_DISPLAY.NONE)
          }
          upOrDown={"down"}
          sx={{
            mt: 0,
            height: 80,
          }}
        />
        <Box
          sx={{
            height: "80%",
            overflowY: "scroll",
            overflowX: "hidden",
            ...Styles.SStyles.ColumnCenterCenter,
          }}
        >
          {props.suggestions.length === 0 && (
            <CUIPresets.ViewerStatusText
              text={"No Suggestions"}
              textStyle={{
                fontSize: 32,
              }}
            />
          )}
          {props.suggestions?.map((item: any, index: number) => (
            <CUIPresets.LargeContentWithoutTitle
              onClick={() =>
                navigate(PATHS.VIEWER_PLAYER + "/" + item._id + "/0")
              }
              image={item.contentDetails?.coverPhoto}
              sx={{
                mt: UI.getMTOnSuggestions(index, props.suggestions),
                ":hover": {
                  backgroundColor: Colors.blackBackground,
                },
              }}
            />
          ))}
        </Box>
      </Box>
    );
  } else if (displayType === Constants.TYPES.PLAYER_RIGHT_DISPLAY.ACTIONS) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          maxHeight: 560,
          backgroundColor: Colors.blackBackground5,
        }}
      >
        <CUIPresets.ContentActionTile
          onClick={() =>
            setDisplayType(Constants.TYPES.PLAYER_RIGHT_DISPLAY.NONE)
          }
          title="Actions"
          upOrDown={"down"}
          sx={{
            mt: 0,
            height: 80,
          }}
        />
        <Box
          sx={{
            width: "100%",
            height: "80%",
            ...Styles.SStyles.ColumnCenterCenter,
          }}
        >
          <CUIPresets.ViewerAction
            title="Visit Space"
            onClick={onVisitSpace}
            loading={loadingFlags.visitSpace}
            sx={{
              height: "13%",
            }}
          />
          <CUIPresets.ViewerAction
            onClick={toggleWatchLater}
            title={
              props.contentDetails?.watchLater
                ? "Remove from Watch Later"
                : "Add to Watch Later"
            }
            loading={loadingFlags.watchLater}
            sx={{ mt: 2, height: "13%" }}
          />
          <CUIPresets.ViewerAction
            onClick={toggleSavedContent}
            title={
              props.contentDetails?.savedContent
                ? "Remove from Saved Content"
                : "Save Content"
            }
            loading={loadingFlags.savedContent}
            sx={{ mt: 2, height: "13%" }}
          />
          <Box
            sx={{
              width: "90%",
              height: "35%",
              mt: 3,
              ...Styles.SStyles.RowCenterSpace,
            }}
          >
            <CUIPresets.ChangeContent
              type="previous"
              disabled={props.contentActions?.previous === ""}
              onClick={() => {
                navigate(
                  PATHS.VIEWER_PLAYER +
                    "/" +
                    props.contentActions?.previous +
                    "/" +
                    props.contentActions?.subspaceId
                );
              }}
            />
            <CUIPresets.ChangeContent
              type="next"
              disabled={props.contentActions?.next === ""}
              onClick={() => {
                navigate(
                  PATHS.VIEWER_PLAYER +
                    "/" +
                    props.contentActions?.next +
                    "/" +
                    props.contentActions?.subspaceId
                );
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  } else return null;
};

export default RightSection;
