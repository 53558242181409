// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import Styles from "../../utils/Styles";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import { DateFunctions } from "../../utils/Functions";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";
import CText from "../../atoms/CText";
import Arrays from "../../utils/Arrays";
import Constants from "../../utils/Constants";

interface AdvertiserFundsOverviewProps {}

const AdvertiserFundsOverview: FC<AdvertiserFundsOverviewProps> = (props) => {
  const navigate = useNavigate();

  const [advertiserFundsRequests, setAdvertiserFundsRequests] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [walletDetails, setWalletDetails] = useState({
    walletExists: false,
    walletBalance: 0,
  });

  useEffect(() => {
    setDataLoading(true);
    API.getWalletBalance().then((response) => {
      setWalletDetails({
        walletExists: response.walletFound,
        walletBalance: response.wallet?.balance,
      });
    });

    API.getAllAdvertiserFundsRequests().then((response) => {
      const dataArray: any = [];

      response.requests.map((item: any) => {
        dataArray.push([
          item.requestType ===
          Constants.TYPES.ADVERTISER_FUNDS_REQUESTS.ADD_FUNDS
            ? "Add"
            : "Withdraw",
            DateFunctions.format(item.requestDetails?.createdAt),
          item.requestDetails?.amountInSSC?.toString(),
          <CUIPresets.ChipSelect.AdvertiserFunds status={item.status} />,
          <CUIPresets.RightArrowAvatarWhite
            onClick={() => {
              if (
                item.requestType ===
                Constants.TYPES.ADVERTISER_FUNDS_REQUESTS.ADD_FUNDS
              ) {
                navigate(PATHS.ADVERTISER_ADD_FUNDS_REQUEST + "/" + item._id);
              } else {
                navigate(
                  PATHS.ADVERTISER_WITHDRAW_FUNDS_REQUEST + "/" + item._id
                );
              }
            }}
            sx={{
              marginLeft: 2,
            }}
          />,
        ]);

        return 0;
      });

      setAdvertiserFundsRequests(dataArray);
      setDataLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Funds Overview" />

        <Box
          sx={{
            width: 895,
            mb: 3,
            ...Styles.SStyles.RowCenterSpace,
          }}
        >
          <CText.Jakarta.Size18.SemiBold.Black
            text={
              walletDetails.walletExists
                ? "Balance: " + walletDetails.walletBalance + " SSC"
                : ""
            }
          />

          <Box
            sx={{
              ...Styles.SStyles.RowCenterCenter,
            }}
          >
            <CUIPresets.OutlinedButton
              onClick={() =>
                navigate(PATHS.ADVERTISER_ADD_FUNDS_REQUEST + "/0")
              }
              sx={{
                mr: 2,
              }}
              title={"Add Funds"}
            />
            <CUIPresets.OutlinedButton
              onClick={() =>
                navigate(PATHS.ADVERTISER_WITHDRAW_FUNDS_REQUEST + "/0")
              }
              title={"Withdraw Funds"}
            />
          </Box>
        </Box>

        {!dataLoading && (
          <>
            <CTableRow.FlexTableTop
              sx={{
                width: 880,
              }}
              titleArray={Arrays.Titles.FundsOverview}
            />
            {advertiserFundsRequests.map((item) => (
              <CTableRow.FlexTableRow
                sx={{
                  width: 880,
                }}
                contentArray={item}
              />
            ))}
            {advertiserFundsRequests.length === 0 && (
              <CTableRow.FlexTableRow contentArray={["No Data"]} />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdvertiserFundsOverview;
