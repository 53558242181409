// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box, TextField, InputAdornment } from "@mui/material";
import { SearchNormal1 } from "iconsax-react";

// Functional Imports
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";

interface SearchDateBarProps {
  searchText?: any;
  searchPlaceholder?: any;
  isResetEnabled?: any;

  sx?: any;
  inputSx?: any;

  onSearch?: any;
  afterReset?: any;
  setDateModal?: any;
  setSearchText?: any;
}

const SearchDateBar: FC<SearchDateBarProps> = (props) => {
  return (
    <Box
      sx={{
        height: 56,
        backgroundColor: Colors.blackBackground4,
        ...Styles.SStyles.RowCenterSpace,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          ...Styles.SStyles.RowCenterCenter,
        }}
      >
        <TextField
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <SearchNormal1
                  style={{ marginRight: 12 }}
                  size={18}
                  color={Colors.greyText5}
                />
              </InputAdornment>
            ),
          }}
          value={props.searchText}
          onChange={(e) => {
            props.setSearchText(e.target.value);
          }}
          placeholder={props.searchPlaceholder}
          onWheel={(event) => {
            event.preventDefault();
          }}
          sx={{
            width: "400px",
            height: "40px",
            justifyContent: "center",
            paddingLeft: 1,
            borderRadius: "3px",
            borderWidth: 0,
            fontFamily: "Plus Jakarta",
            fontWeight: 400,
            backgroundColor: Colors.greyBackground2,
            ml: 4,
            input: { color: Colors.white },
            ...props.inputSx,
          }}
        />
        <CUIPresets.ViewerOutlined2
          onClick={() => {
            if (props.onSearch) {
              props.onSearch();
            }
          }}
          title="Search"
          sx={{ ml: 2 }}
        />
      </Box>
      <Box
        sx={{
          ...Styles.SStyles.RowCenterCenter,
          mr: 4,
        }}
      >
        {props.isResetEnabled && (
          <CUIPresets.ViewerOutlined2
            onClick={() => {
              if (props.afterReset) {
                props.afterReset();
              }
            }}
            title="Reset"
            sx={{ mr: 2 }}
          />
        )}
        <CUIPresets.ViewerOutlined2
          onClick={() => {
            if (props.setDateModal) {
              props.setDateModal();
            }
          }}
          title="Select Date Range"
          sx={{ mr: 2 }}
        />
      </Box>
    </Box>
  );
};

export default SearchDateBar;
