// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Grid, Box } from "@mui/material";

// Functional Imports
import { DateFunctions } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Constants from "../../utils/Constants";
import API from "../../api/API";

// Local Imports
import WatchLaterTitle from "./WatchLaterTitle";
import CComposite from "../../atoms/CComposite";
import CUIPresets from "../../atoms/CUIPresets";

interface WatchLaterProps {}

const WatchLater: FC<WatchLaterProps> = (props) => {
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState(false);
  const [contentList, setContentList] = useState<any>([]);

  useEffect(() => {
    setDataLoading(true);
    API.getWatchLater().then((response: any) => {
      setDataLoading(false);
      setContentList(response.watchLaterData);
    });
  }, []);

  console.log();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <Box
          sx={{
            ml: 4,
            overflowY: "scroll",
            overflowX: "hidden",
            height: window.innerHeight - 72,
          }}
        >
          <WatchLaterTitle sx={{ mt: 2 }} />
          <Grid container sx={{ mb: 4 }}>
            {dataLoading && <CUIPresets.ViewerLoading />}

            {!dataLoading && contentList.length === 0 && (
              <CUIPresets.ViewerStatusText text="No Content" />
            )}

            {!dataLoading &&
              contentList.length > 0 &&
              contentList?.map((item: any) => (
                <CComposite.MySpaceTile
                  contentName={item.watchLaterContent?.contentDetails?.name}
                  spaceName={item.watchLaterContent?.spaceDetails?.name}
                  image={item.watchLaterContent?.contentDetails?.coverPhoto}
                  date={DateFunctions.format(item.createdAt)}
                  type={Constants.TYPES.MY_SPACE.WATCH_LATER}
                  onClick={() => {
                    navigate(
                      PATHS.VIEWER_PLAYER +
                        "/" +
                        item.watchLaterContent?._id +
                        "/0"
                    );
                  }}
                />
              ))}
          </Grid>
        </Box>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default WatchLater;
