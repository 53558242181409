// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";
import {} from "iconsax-react";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import {
  DateFunctions,
  ToastGenerator,
} from "../../utils/Functions";
import API from "../../api/API";
import * as yup from "yup";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import CInputOutput from "../../atoms/CInputOutput";
import Constants from "../../utils/Constants";
import CComposite from "../../atoms/CComposite";
import { useFormik } from "formik";

const requestDataInit = {
  requestDetails: {
    amountInSSC: 0,
    currency: "",
    amountInCurrency: 0,
    modeOfPayment: "",
    createdAt: null,
    receiverDetails: {
      paypalId: "",
      upiId: "",
      accountNumber: "",
      accountHolderName: "",
      ifscCode: "",
    },
  },
  adminResponse: {
    photoReceipt: "",
    actionBy: "",
    txnId: "",
  },
  _id: "",
  clientId: "",
  status: "",
  requestType: "",
  reason: "",
  __v: 0,
};

const initValues = {
  image: {
    file: null,
    name: "",
    size: 0,
  },
  actionBy: "",
  txnId: "",
};

interface AdminWithdrawFundsRequestDetailsProps {}

const AdminWithdrawFundsRequestDetails: FC<
  AdminWithdrawFundsRequestDetailsProps
> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({
      actionBy: yup.string().required("Made By is required"),
      txnId: yup.string().required("Transaction ID is required"),
    }),
    onSubmit: (values: any) => {
      if (values.image.file === null) {
        ToastGenerator.info("Upload photo proof!");
        return;
      }

      setModal({
        open: true,
        apiLoading: false,
      });
    },
  });

  const [requestData, setRequestData] = useState(requestDataInit);
  const [dataLoading, setDataLoading] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  useEffect(() => {
    setDataLoading(true);
    API.adminAdvertiserFundsRequestById(id).then((response) => {
      setRequestData(response.addFundsRequest);
      setDataLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmModalCall = () => {
    setModal({
      open: true,
      apiLoading: true,
    });

    const payload = {
      image: formik.values.image.file,
      actionBy: formik.values.actionBy,
      txnId: formik.values.txnId,
    };

    API.adminApprovalOfWithdrawFundsRequest(id, payload).then((response) => {
      if (response.message === Constants.API_RESPONSES.ADMIN_RESPONSE_SUCCESS) {
        ToastGenerator.success(response.message);
        setModal({
          open: false,
          apiLoading: false,
        });
        navigate(-1);
      }
    });
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Add Funds Request Details" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <>
        <CUIPresets.PageHeader title="Withdraw Funds Request Details" />

        <Box sx={{ ml: 1 }}>
          <CUIPresets.LabelValueHorizontal
            label={"SSC Amount"}
            value={requestData.requestDetails?.amountInSSC}
          />

          <CUIPresets.LabelValueHorizontal
            label={"Currency"}
            value={requestData.requestDetails?.currency}
          />

          <CUIPresets.LabelValueHorizontal
            label={requestData.requestDetails?.currency + " Amount"}
            value={requestData.requestDetails?.amountInCurrency}
          />

          <CUIPresets.LabelStatus
            chipSelect={"advertiserFunds"}
            status={requestData.status}
          />

          <CUIPresets.LabelValueHorizontal
            label={"Date of Request"}
            value={DateFunctions.format(requestData.requestDetails?.createdAt)}
          />

          <CUIPresets.LabelValueHorizontal
            label={"Mode of Payment"}
            value={requestData.requestDetails?.modeOfPayment}
          />

          <CText.Roboto.Size28.Light.BlackUnderline
            style={{
              marginBottom: 16,
              marginTop: 32,
            }}
            text="Reciever's Details"
          />

          {requestData.requestDetails?.modeOfPayment ===
            Constants.TYPES.MODE_OF_PAYMENTS.UPI && (
            <CUIPresets.LabelValueHorizontal
              label={"UPI ID"}
              value={requestData.requestDetails?.receiverDetails?.upiId}
            />
          )}

          {requestData.requestDetails?.modeOfPayment ===
            Constants.TYPES.MODE_OF_PAYMENTS.PAYPAL && (
            <CUIPresets.LabelValueHorizontal
              label={"Paypal ID"}
              value={requestData.requestDetails?.receiverDetails?.paypalId}
            />
          )}

          {requestData.requestDetails?.modeOfPayment ===
            Constants.TYPES.MODE_OF_PAYMENTS.BANK && (
            <>
              <CUIPresets.LabelValueHorizontal
                label={"Account Holder Name"}
                value={
                  requestData.requestDetails?.receiverDetails?.accountHolderName
                }
              />

              <CUIPresets.LabelValueHorizontal
                label={"Account Number"}
                value={
                  requestData.requestDetails?.receiverDetails?.accountNumber
                }
              />

              <CUIPresets.LabelValueHorizontal
                label={"IFSC Code"}
                value={requestData.requestDetails?.receiverDetails?.ifscCode}
              />
            </>
          )}

          {requestData.status ===
            Constants.STATUS_FLAGS.WITHDRAW_FUNDS_REQUEST.RAISED && (
            <>
              <CText.Roboto.Size28.Light.BlackUnderline
                style={{
                  marginBottom: 16,
                  marginTop: 32,
                }}
                text="Actions"
              />

              <CText.Jakarta.Size18.Light.Grey1
                text="Upload a screenshot of the payment."
                style={{
                  marginTop: 8,
                  marginBottom: 8,
                }}
              />

              <CInputOutput.SelectImage
                title={"Upload Proof"}
                setImage={(ev: any) => {
                  formik.setFieldValue("image", {
                    file: ev,
                    name: ev.name,
                    size: Number(ev.size) / (1024 * 1024),
                  });
                }}
              />

              <CUIPresets.SelectedFile
                imageExists={formik.values.image.file}
                fileName={formik.values.image.name}
                fileSize={formik.values.image.size}
              />

              <CInputOutput.LabelInputOutlined
                sx={{ width: 400, mt: 2 }}
                label="Made By"
                textFieldProps={{
                  placeholder: "Enter Made By",
                  helperText: formik.touched.actionBy && formik.errors.actionBy,
                  error: Boolean(
                    formik.touched.actionBy && formik.errors.actionBy
                  ),
                  onBlur: formik.handleBlur,
                  onChange: formik.handleChange,
                  value: formik.values.actionBy,
                  name: "actionBy",
                }}
              />

              <CInputOutput.LabelInputOutlined
                sx={{ width: 400, mt: 2 }}
                label="Txn ID"
                textFieldProps={{
                  placeholder: "Enter Txn ID",
                  helperText: formik.touched.txnId && formik.errors.txnId,
                  error: Boolean(formik.touched.txnId && formik.errors.txnId),
                  onBlur: formik.handleBlur,
                  onChange: formik.handleChange,
                  value: formik.values.txnId,
                  name: "txnId",
                }}
              />

              <CComposite.FooterActions.Filled
                onClick={() => formik.handleSubmit()}
                sx={{
                  mt: 4,
                  mb: 4,
                  width: 760,
                }}
                confirmText={"Approve"}
              />
            </>
          )}

          {requestData.status ===
            Constants.STATUS_FLAGS.WITHDRAW_FUNDS_REQUEST.APPROVED && (
            <>
              <CText.Roboto.Size28.Light.BlackUnderline
                style={{
                  marginBottom: 16,
                  marginTop: 32,
                }}
                text="Platform Response"
              />

              <CUIPresets.LabelImageHorizontal
                label={"Photo Reciept"}
                src={requestData.adminResponse?.photoReceipt}
              />

              <CUIPresets.LabelValueHorizontal
                label={"Made By"}
                value={requestData.adminResponse?.actionBy}
              />

              <CUIPresets.LabelValueHorizontal
                label={"Transaction ID"}
                value={requestData.adminResponse?.txnId}
              />
            </>
          )}
        </Box>

        <CUIPresets.CustomDialog
          title="Admin Approval"
          content={[
            "Are you sure about approving this withdraw funds request?",
          ]}
          confirmText={"Confirm"}
          cancelText="Cancel"
          confirmCall={confirmModalCall}
          modalOpen={modal.open}
          cancelCall={() =>
            setModal({
              open: false,
              apiLoading: false,
            })
          }
          apiLoading={modal.apiLoading}
        />
      </>
    );
  }
};

export default AdminWithdrawFundsRequestDetails;
