// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import { BoxSearch, GlobalSearch, SearchStatus1 } from "iconsax-react";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import Colors from "../../utils/Colors";

interface CreatorOverviewOptionsProps {}

const CreatorOverviewOptions: FC<CreatorOverviewOptionsProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Overview" disabled />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<SearchStatus1 size="60" color={Colors.blueBackground} />}            
            title="Overview"
            onClick={() => navigate(PATHS.CREATOR_OVERVIEW)}
            />
          <CUIPresets.SelectFlow
            Icon={<BoxSearch size="60" color={Colors.blueBackground} />}                           
            title="Content Overview"
            onClick={() => navigate(PATHS.CREATOR_CONTENT_OVERVIEW)}
            />          
          <CUIPresets.SelectFlow
            Icon={<GlobalSearch size="60" color={Colors.blueBackground} />}                                           
            title="Space Overview"
            onClick={() => navigate(PATHS.CREATOR_SPACE_OVERVIEW)}
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorOverviewOptions;
