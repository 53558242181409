// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import { DateFunctions, StringManipulation } from "../../utils/Functions";
import Arrays from "../../utils/Arrays";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";

interface AdvertiserManageAdFilesProps {}

const AdvertiserManageAdFiles: FC<AdvertiserManageAdFilesProps> = (props) => {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortValue, setSortValue] = useState("upload_date");
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getAdFiles("", "").then((response) => {
      populateData(response.adFileData);
      setDataLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortAction = (sortPass: any) => {
    setSortValue(sortPass);
    setDataLoading(true);
    API.getAdFiles("", sortPass).then((response) => {
      populateData(response.adFileData);
      setDataLoading(false);
    });
  };

  const searchAction = (searchPass: any) => {
    setSearchText(searchPass);
    setDataLoading(true);
    API.getAdFiles(searchPass, "").then((response) => {
      populateData(response.adFileData);
      setDataLoading(false);
    });
  };

  const populateData = (dataArray: any) => {
    const apiData: any = [];

    dataArray?.map((item: any) => {
      apiData.push([
        StringManipulation.stringLimiter(item.adFileName, 20),
        DateFunctions.format(item.createdAt),
        <CUIPresets.RightArrowAvatarWhite
          sx={{
            ml: 2,
          }}
          onClick={() =>
            navigate(PATHS.ADVERTISER_VIEW_AD_DETAILS + "/" + item._id)
          }
        />,
      ]);

      return 0;
    });

    setTableData(apiData);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Manage Ad Files" />
        <Box
          sx={{
            width: 800,
            marginBottom: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CUIPresets.SearchBar
            placeholder={"Search Ad Files"}
            textFieldProps={{
              onChange: (e: any) => searchAction(e.target.value),
              value: searchText,
            }}
          />

          <CUIPresets.SortBox
            options={Arrays.Sorting.AdFiles}
            xsKey="typeId"
            xsValue="type"
            selected={sortValue}
            setValue={(e: any) => sortAction(e.target.value)}
          />
        </Box>

        {!dataLoading && (
          <>
            <CTableRow.FlexTableTop titleArray={Arrays.Titles.AdFiles} />
            {tableData.map((item) => (
              <CTableRow.FlexTableRow contentArray={item} />
            ))}
            {tableData.length === 0 && (
              <CTableRow.FlexTableRow contentArray={["No Ad Files"]} />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdvertiserManageAdFiles;
