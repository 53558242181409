// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { ToastGenerator } from "../../utils/Functions";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";
import CComposite from "../../atoms/CComposite";

interface UploadNewAdProps {
  onConfirm?: any;
}

const UploadNewAd: FC<UploadNewAdProps> = (props) => {
  const [videoFile, setVideoFile]: any = useState({
    file: null,
    name: "",
    size: "",
  });
  const [adFileName, setAdFileName] = useState("");
  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  const handleServerUpload = () => {
    if (videoFile.file === null) {
      ToastGenerator.info("Select video file");
    } else if (Number(videoFile.size) > 1024) {
      ToastGenerator.info("Video File has to be less than 1 GB");
    } else if (adFileName === "") {
      ToastGenerator.info("Ad Reference name cannot be empty");
    } else {
      setModal({
        open: true,
        apiLoading: false,
      });
    }
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Upload New Ad" />

        <Box display={"flex"}>
          <Box>
            <CInputOutput.SelectVideo
              setVideo={(ev: any) => {
                setVideoFile({
                  file: ev,
                  name: ev.name,
                  size: Number(ev.size) / (1024 * 1024),
                });
              }}
            />

            <CUIPresets.SelectedFile
              imageExists={videoFile.file}
              fileName={videoFile.name}
              fileSize={videoFile.size}
            />
          </Box>

          <Box sx={{ ml: 8, width: 500 }}>
            <CInputOutput.LabelInputOutlined
              sx={{ width: "80%", mt: 0 }}
              label="Ad Reference Name"
              textFieldProps={{
                placeholder: "Enter Ad Reference Name",
                value: adFileName,
                onChange: (e: any) => setAdFileName(e.target.value),
              }}
            />

            <CComposite.FooterActions.Filled
              onClick={handleServerUpload}
              sx={{
                mt: 24,
                mb: 4,
                width: 400,
              }}
              confirmText={"Upload To Server"}
            />
          </Box>
        </Box>

        <CUIPresets.CustomDialog
          title="Upload To Server"
          content={["Are you sure about uploading this ad to server?"]}
          confirmText={"Confirm"}
          cancelText="Cancel"
          confirmCall={() => {
            const payload = {
              adFileName,
              videoFile,
            };
            props.onConfirm(payload);
            setModal({
              open: true,
              apiLoading: true,
            });
          }}
          apiLoading={modal.apiLoading}
          modalOpen={modal.open}
          cancelCall={() =>
            setModal({
              open: false,
              apiLoading: false,
            })
          }
        />
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default UploadNewAd;
