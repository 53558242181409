// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box, ButtonBase, Grid, IconButton, Typography } from "@mui/material";
import {
  ArrowCircleRight,
  ArrowCircleRight2,
  ArrowDown2,
  ArrowLeft2,
  ArrowRight2,
  ArrowUp2,
} from "iconsax-react";

// Functional Imports
import Styles from "../../utils/Styles";
import CTools from "../../atoms/CTools";
import Colors from "../../utils/Colors";

// Local Imports
import { useNavigate } from "react-router-dom";
import CText from "../../atoms/CText";

interface TitleCollapseProps {
  title?: any;
  sx?: any;
  expanded?: any;
  onClick?: any;
}

const TitleCollapse: FC<TitleCollapseProps> = (props) => {
  return (
    <Box
      sx={{
        width: "80%",
        ...Styles.SStyles.RowCenterSpace,
        borderRadius: 1,
        py: 0.5,
        backgroundColor: props.expanded ? null : Colors.blackBackground,
        ...props.sx,
      }}
    >
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 28,
          color: "white",
          marginLeft: 16,
        }}
      >
        {props.title}
      </Typography>

      <IconButton
        sx={{
          mr: 1,
        }}
        onClick={props.onClick}
      >
        {props.expanded && <ArrowUp2 size={32} color={Colors.cyan} />}{" "}
        {!props.expanded && <ArrowDown2 size={32} color={Colors.cyan} />}{" "}
      </IconButton>
    </Box>
  );
};

const TitleCollapseMobile: FC<TitleCollapseProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        ...Styles.SStyles.RowCenterSpace,
        borderRadius: 1,
        py: 0.5,
        backgroundColor: props.expanded ? null : Colors.blackBackground,
        ...props.sx,
      }}
    >
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 22,
          color: "white",
          marginLeft: 16,
        }}
      >
        {props.title}
      </Typography>

      <IconButton
        sx={{
          mr: 1,
        }}
        onClick={props.onClick}
      >
        {props.expanded && <ArrowUp2 size={24} color={Colors.cyan} />}{" "}
        {!props.expanded && <ArrowDown2 size={24} color={Colors.cyan} />}{" "}
      </IconButton>
    </Box>
  );
};

interface GradientTileProps {
  degree?: any;
  color1?: any;
  color2?: any;
  title?: any;
  sx?: any;
  textStyle?: any;
}

const GradientTile: FC<GradientTileProps> = (props) => {
  return (
    <Box
      sx={{
        width: "50%",
        height: 240,
        mr: 2,
        borderRadius: 1,
        background:
          "linear-gradient(" +
          props.degree +
          ", " +
          props.color1 +
          " 0%, " +
          props.color2 +
          " 100%)",
        ...Styles.SStyles.RowCenterCenter,
        ...props.sx,
      }}
    >
      <Typography
        style={{
          width: "80%",
          fontFamily: "Heebo",
          fontSize: 28,
          color: "white",
          textAlign: "center",
          ...props.textStyle,
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
};

const GradientTileMobile: FC<GradientTileProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: 160,
        mr: 2,
        mb: 2,
        borderRadius: 1,
        background:
          "linear-gradient(" +
          props.degree +
          ", " +
          props.color1 +
          " 0%, " +
          props.color2 +
          " 100%)",
        ...Styles.SStyles.RowCenterCenter,
        ...props.sx,
      }}
    >
      <Typography
        style={{
          width: "80%",
          fontFamily: "Heebo",
          fontSize: 22,
          color: "white",
          textAlign: "center",
          ...props.textStyle,
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
};

interface HowAchieveTileProps {
  title1?: any;
  title2?: any;
  subtitle?: any;
  illustration?: any;
  imageOn?: any;
  sx?: any;
  boxShadowColor?: any;
}

const HowAchieveTile: FC<HowAchieveTileProps> = (props) => {
  return (
    <Box
      sx={{
        width: "80%",
        mt: 2,
        height: 375,
        borderRadius: 2,
        ...Styles.SStyles.RowCenterSpace,
        backgroundColor: Colors.black,
        boxShadow: "0px 4px 44px 0px " + props.boxShadowColor,
        ...props.sx,
      }}
    >
      {props.imageOn === "left" && (
        <Box
          sx={{
            width: "45%",
            mt: 2,
            ml: 6,
            height: 325,
          }}
        >
          <CTools.ImageContainer
            imageBgColor={null}
            src={props.illustration}
            height="100%"
          />
        </Box>
      )}

      <Box
        sx={{
          width: "45%",
          mt: 2,
          ml: 6,
          height: 325,
        }}
      >
        <Typography
          style={{
            fontFamily: "Heebo",
            fontSize: 48,
            color: "white",
          }}
        >
          {props.title1}
        </Typography>

        <Typography
          style={{
            marginLeft: 48,
            fontFamily: "Heebo",
            fontSize: 48,
            color: "white",
          }}
        >
          {props.title2}
        </Typography>

        <Typography
          style={{
            width: "80%",
            marginLeft: 48,
            fontFamily: "Heebo",
            fontSize: 28,
            color: "white",
          }}
        >
          {props.subtitle}
        </Typography>
      </Box>

      {props.imageOn === "right" && (
        <Box
          sx={{
            width: "45%",
            mt: 2,
            mr: 6,
            height: 325,
          }}
        >
          <CTools.ImageContainer
            imageBgColor={null}
            src={props.illustration}
            height="100%"
          />
        </Box>
      )}
    </Box>
  );
};

const HowAchieveTileMobile: FC<HowAchieveTileProps> = (props) => {
  return (
    <Box
      sx={{
        width: "90%",
        mt: 2,
        py: 2,
        borderRadius: 2,
        ...Styles.SStyles.ColumnCenterCenter,
        backgroundColor: Colors.black,
        boxShadow: "0px 4px 44px 0px " + props.boxShadowColor,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          width: "90%",
          height: 240,
        }}
      >
        <CTools.ImageContainer
          imageBgColor={null}
          src={props.illustration}
          height="100%"
        />
      </Box>

      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 28,
          color: "white",
          width: "90%",
          marginTop: 16,
          marginBottom: 8,
        }}
      >
        {props.title1}
      </Typography>

      <Typography
        style={{
          width: "90%",
          fontFamily: "Heebo",
          textAlign: "justify",
          fontSize: 20,
          color: "white",
        }}
      >
        {props.subtitle}
      </Typography>
    </Box>
  );
};

interface ColorInfoTileProps {
  color?: any;
  title?: any;
  sx?: any;
  onLeftClick?: any;
  onRightClick?: any;
}

const ColorInfoTile: FC<ColorInfoTileProps> = (props) => {
  return (
    <Box
      sx={{
        mt: 2,
        width: "80%",
        height: 320,
        backgroundColor: props.color,
        borderRadius: 2,
        ...Styles.SStyles.RowCenterSpace,
        ...props.sx,
      }}
    >
      <IconButton
        onClick={props.onLeftClick}
        sx={{
          backgroundColor: Colors.blackBackground2,
          ml: 6,
          ":hover": {
            backgroundColor: Colors.blackBackground2 + "66",
          },
        }}
      >
        <ArrowLeft2 color={props.color} size={48} />
      </IconButton>
      <Typography
        style={{
          width: "70%",
          fontFamily: "Heebo",
          fontSize: 48,
          color: "white",
          textAlign: "center",
        }}
      >
        {props.title}
      </Typography>

      <IconButton
        onClick={props.onRightClick}
        sx={{
          backgroundColor: Colors.blackBackground2,
          mr: 6,
          ":hover": {
            backgroundColor: Colors.blackBackground2 + "66",
          },
        }}
      >
        <ArrowRight2 color={props.color} size={48} />
      </IconButton>
    </Box>
  );
};

const ColorInfoMobileTile: FC<ColorInfoTileProps> = (props) => {
  return (
    <Box
      sx={{
        mt: 2,
        ml: 2,
        width: "95%",
        py: 1,
        height: 120,
        backgroundColor: props.color,
        borderRadius: 2,
        ...Styles.SStyles.RowCenterSpace,
        ...props.sx,
      }}
    >
      <IconButton
        onClick={props.onLeftClick}
        sx={{
          backgroundColor: Colors.blackBackground2,
          ml: 1,
          ":hover": {
            backgroundColor: Colors.blackBackground2 + "66",
          },
        }}
      >
        <ArrowLeft2 color={props.color} size={24} />
      </IconButton>
      <Typography
        style={{
          width: "70%",
          fontFamily: "Heebo",
          fontSize: 20,
          color: "white",
          textAlign: "center",
        }}
      >
        {props.title}
      </Typography>

      <IconButton
        onClick={props.onRightClick}
        sx={{
          backgroundColor: Colors.blackBackground2,
          mr: 1,
          ":hover": {
            backgroundColor: Colors.blackBackground2 + "66",
          },
        }}
      >
        <ArrowRight2 color={props.color} size={24} />
      </IconButton>
    </Box>
  );
};

interface ColorInfoProps {
  info?: any;
}

const ColorInfo: FC<ColorInfoProps> = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleLeftClick = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleRightClick = () => {
    if (activeIndex < props.info?.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  return (
    <>
      {props.info?.map(
        (item: any, index: number) =>
          activeIndex === index && (
            <ColorInfoTile
              onLeftClick={handleLeftClick}
              onRightClick={handleRightClick}
              color={item.color}
              title={item.content}
            />
          )
      )}
    </>
  );
};

const ColorInfoMobile: FC<ColorInfoProps> = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleLeftClick = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleRightClick = () => {
    if (activeIndex < props.info?.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  return (
    <>
      {props.info?.map(
        (item: any, index: number) =>
          activeIndex === index && (
            <ColorInfoMobileTile
              onLeftClick={handleLeftClick}
              onRightClick={handleRightClick}
              color={item.color}
              title={item.content}
            />
          )
      )}
    </>
  );
};

interface BulletInfoProps {
  sx?: any;
  textStyle?: any;
  title?: any;
}

const BulletInfo: FC<BulletInfoProps> = (props) => {
  return (
    <Box
      sx={{
        ...Styles.SStyles.RowStartStart,
        ...props.sx,
      }}
    >
      <ArrowCircleRight size={28} color="#F3C05E" />
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 24,
          color: "white",
          marginLeft: 16,
          ...props.textStyle,
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
};

const BulletInfoMobile: FC<BulletInfoProps> = (props) => {
  return (
    <Box
      sx={{
        ...Styles.SStyles.RowStartStart,
        ...props.sx,
      }}
    >
      <ArrowCircleRight size={24} color="#F3C05E" />

      <Typography
        style={{
          width: "80%",
          fontFamily: "Heebo",
          fontSize: 20,
          color: "white",
          marginLeft: 16,
          ...props.textStyle,
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
};

interface LabelQuantityProps {
  sx?: any;
  label?: any;
  value1?: any;
  value2?: any;
}

const LabelQuantity: FC<LabelQuantityProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        ...Styles.SStyles.RowStartSpace,
        mt: 4,
        ...props.sx,
      }}
    >
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 24,
          color: "white",
        }}
      >
        {props.label}
      </Typography>

      <Box
        sx={{
          ...Styles.SStyles.ColumnEndCenter,
        }}
      >
        <Typography
          style={{
            fontFamily: "Heebo",
            fontSize: 24,
            color: "white",
            fontWeight: 600,
          }}
        >
          {props.value1}
        </Typography>
        <Typography
          style={{
            fontFamily: "Heebo",
            fontSize: 24,
            color: "white",
            fontWeight: 600,
          }}
        >
          {props.value2}
        </Typography>
      </Box>
    </Box>
  );
};

const LabelQuantityMobile: FC<LabelQuantityProps> = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        ...Styles.SStyles.ColumnStartStart,
        mt: 2,
        ...props.sx,
      }}
    >
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 16,
          color: "white",
        }}
      >
        {props.label}
      </Typography>

      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 16,
          color: "white",
          fontWeight: 600,
        }}
      >
        {props.value1}
      </Typography>
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 16,
          color: "white",
          fontWeight: 600,
        }}
      >
        {props.value2}
      </Typography>
    </Box>
  );
};

interface InfoIconTitleSubtitleProps {
  background?: any;
  sx?: any;
  icon?: any;
  title?: any;
  description?: any;
  imageSx?: any;
  titleStyle?: any;
  descriptionStyle?: any;
}

const InfoIconTitleSubtitle: FC<InfoIconTitleSubtitleProps> = (props) => {
  return (
    <Box
      sx={{
        borderRadius: 3,
        width: 320,
        height: 300,
        background: props.background,
        ...Styles.SStyles.ColumnCenterCenter,
        ...props.sx,
      }}
    >
      {props.icon && (
        <CTools.ImageContainer
          height={140}
          width={180}
          src={props.icon}
          imageBgColor={null}
          sx={{
            ...props.imageSx,
          }}
        />
      )}

      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 36,
          color: "white",
          fontWeight: 700,
          marginTop: 8,
          ...props.titleStyle,
        }}
      >
        {props.title}
      </Typography>

      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 24,
          color: "white",
          textAlign: "center",
          width: "90%",
          marginTop: 8,
          ...props.descriptionStyle,
        }}
      >
        {props.description}
      </Typography>
    </Box>
  );
};

const InfoIconTitleSubtitleMobile: FC<InfoIconTitleSubtitleProps> = (props) => {
  return (
    <Box
      sx={{
        borderRadius: 3,
        background: props.background,
        py: 2,
        mb: 2,
        ...Styles.SStyles.ColumnCenterCenter,
        ...props.sx,
      }}
    >
      <CTools.ImageContainer
        height={100}
        width={130}
        src={props.icon}
        imageBgColor={null}
        sx={{
          ...props.imageSx,
        }}
      />

      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 24,
          color: "white",
          fontWeight: 700,
          marginTop: 8,
          ...props.titleStyle,
        }}
      >
        {props.title}
      </Typography>

      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 18,
          color: "white",
          textAlign: "center",
          width: "90%",
          marginTop: 8,
          ...props.descriptionStyle,
        }}
      >
        {props.description}
      </Typography>
    </Box>
  );
};

interface EncourageCreatorTileProps {
  image?: any;
  title?: any;
  description?: any;
  sx?: any;
}

const EncourageCreatorTile: FC<EncourageCreatorTileProps> = (props) => {
  return (
    <Box
      sx={{
        mb: 4,
        ...props.sx,
      }}
    >
      <CTools.ImageContainer src={props.image} imageBgColor={null} />
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 28,
          color: "white",
          fontWeight: 600,
          marginTop: 16,
        }}
      >
        {props.title}
      </Typography>
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 24,
          color: "white",
          marginTop: 8,
        }}
      >
        {props.description}
      </Typography>
    </Box>
  );
};

const EncourageCreatorTileMobile: FC<EncourageCreatorTileProps> = (props) => {
  return (
    <Box
      sx={{
        mb: 2,
        ...props.sx,
      }}
    >
      <CTools.ImageContainer src={props.image} imageBgColor={null} />
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 24,
          color: "white",
          fontWeight: 600,
          marginTop: 8,
        }}
      >
        {props.title}
      </Typography>
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 18,
          color: "white",
          marginTop: 8,
        }}
      >
        {props.description}
      </Typography>
    </Box>
  );
};

interface SignUpRedirectButtonProps {
  title?: any;
  redirect?: any;
  sx?: any;
}

const SignUpRedirectButton: FC<SignUpRedirectButtonProps> = (props) => {
  const navigate = useNavigate();

  return (
    <ButtonBase
      onClick={() => {
        setTimeout(() => {
          navigate(props.redirect);
        }, 200);
      }}
      sx={{
        ...Styles.SStyles.RowCenterCenter,
        mr: 4,
        ...Styles.CStyles.viewerHoverEffect,
        ...props.sx,
      }}
    >
      <CText.Jakarta.Size16.Light.White text={props.title} />
      <ArrowCircleRight2
        size={22}
        style={{ color: Colors.cyan, marginLeft: 8 }}
      />
    </ButtonBase>
  );
};

interface SignUpTileProps {
  sx?: any;
  icon?: any;
  title?: any;
  onClick?: any;
}

const SignUpTile: FC<SignUpTileProps> = (props) => {
  return (
    <Box
      sx={{
        height: 300,
        minWidth: 300,
        width: "40%",
        borderRadius: 2,
        ...Styles.SStyles.ColumnCenterCenter,
        backgroundColor: "#222222",
        mx: 3,
        ...props.sx,
      }}
    >
      <CTools.ImageContainer
        height={140}
        width={140}
        src={props.icon}
        imageBgColor={null}
      />

      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 28,
          color: "white",
          fontWeight: 500,
        }}
      >
        {props.title}
      </Typography>

      <ButtonBase
        onClick={() => {
          if (props.onClick) {
            setTimeout(() => {
              props.onClick();
            }, 200);
          }
        }}
        sx={{
          height: 50,
          width: "90%",
          backgroundColor: "#04A9B6",
          borderRadius: 8,
          marginTop: 4,
        }}
      >
        <Typography
          style={{
            fontFamily: "Heebo",
            fontSize: 20,
            color: "white",
            fontWeight: 500,
          }}
        >
          Click Here
        </Typography>
      </ButtonBase>
    </Box>
  );
};

interface TeamMemberTileProps {
  sx?: any;
  image?: any;
  name?: any;
  designation?: any;
}

const TeamMemberTile: FC<TeamMemberTileProps> = (props) => {
  return (
    <Grid
      item
      sx={{
        height: 400,
        width: "28%",
        m: 2,
        mx: 3,
        backgroundColor: "#222222",
        borderRadius: 4,
        ...Styles.SStyles.ColumnCenterCenter,
        ...props.sx,
      }}
    >
      <CTools.ImageContainer
        src={props.image}
        height={200}
        width={200}
        imageBgColor={null}
      />
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 32,
          color: "white",
          marginTop: 32,
          marginBottom: 8,
        }}
      >
        {props.name}
      </Typography>

      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 24,
          color: "white",
        }}
      >
        {props.designation}
      </Typography>
    </Grid>
  );
};

const LandingPageUtilities = {
  // DESKTOP
  TitleCollapse,
  GradientTile,
  HowAchieveTile,
  ColorInfo,
  BulletInfo,
  LabelQuantity,
  InfoIconTitleSubtitle,
  EncourageCreatorTile,
  SignUpRedirectButton,
  SignUpTile,
  TeamMemberTile,

  // MOBILE
  TitleCollapseMobile,
  GradientTileMobile,
  HowAchieveTileMobile,
  ColorInfoMobile,
  LabelQuantityMobile,
  BulletInfoMobile,
  InfoIconTitleSubtitleMobile,
  EncourageCreatorTileMobile,
};

export default LandingPageUtilities;
