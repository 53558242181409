// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box, ButtonBase } from "@mui/material";
import { InfoCircle } from "iconsax-react";

// Functional Imports
import CText from "../../atoms/CText";
import Styles from "../../utils/Styles";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";

interface WatchLaterTitleProps {
  sx?: any;
}

const WatchLaterTitle: FC<WatchLaterTitleProps> = (props) => {
  const [hover, setHover] = useState(false);

  return (
    <Box
      sx={{
        ...Styles.SStyles.RowCenterStart,
        mt: 1,
        mb: 1,
        ...props.sx,
      }}
    >
      <CText.Jakarta.Size16.Mid.White
        text={"Watch Later"}
      />
      <ButtonBase
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          ml: 1,
          borderRadius: 4,
        }}
      >
        <InfoCircle color={Colors.cyan} size={24} />
        {hover && (
          <CUIPresets.AnimationWrapper>
            <Box
              sx={{
                position: "absolute",
                top: 12,
                left: 12,
                border: "1px solid " + Colors.cyan,
                width: 430,
                height: 40,
                borderRadius: 2,
                backgroundColor: Colors.greyBackground2,
                ...Styles.SStyles.RowCenterCenter,
              }}
            >
              <CText.Outfit.Size14.Light.White
                text={
                  "Content in Watch Later will be automatically deleted after 30 Days"
                }
              />
            </Box>
          </CUIPresets.AnimationWrapper>
        )}
      </ButtonBase>
    </Box>
  );
};

export default WatchLaterTitle;
