// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import { ReceiptSearch, ReceiptText, FilterSquare } from "iconsax-react";

// Functional Imports
import Colors from "../../utils/Colors";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import Constants from "../../utils/Constants";

interface AdminAddFundsRequestsProps {}

const AdminAddFundsRequests: FC<AdminAddFundsRequestsProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Add Funds Requests" />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<ReceiptSearch size="60" color={Colors.blueBackground} />}
            title="View Requests - Advertiser"
            onClick={() =>
              navigate(
                PATHS.ADMIN_ADVERTISER_SEARCH +
                  "/" +
                  Constants.TYPES.ADVERTISER_SEARCH.ADD_FUNDS
              )
            }
          />
          <CUIPresets.SelectFlow
            Icon={<ReceiptText size="60" color={Colors.blueBackground} />}
            title="View Requests - Date"
            onClick={() =>
              navigate(
                PATHS.ADMIN_ADD_FUNDS_REQUESTS_LIST +
                  "/" +
                  Constants.TYPES.ADD_FUNDS_DISPLAY.DATE +
                  "/0"
              )
            }
          />
          <CUIPresets.SelectFlow
            Icon={<FilterSquare size="60" color={Colors.blueBackground} />}
            title="View Requests - Status"
            onClick={() =>
              navigate(
                PATHS.ADMIN_ADD_FUNDS_REQUESTS_LIST +
                  "/" +
                  Constants.TYPES.ADD_FUNDS_DISPLAY.FILTER +
                  "/0"
              )
            }
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminAddFundsRequests;
