// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box } from "@mui/material";
import {
  Home2,
  Money,
  VideoSquare,
  TableDocument,
  EmptyWallet,
  SearchStatus1,
  Ankr,
  Copyright,
  Firstline,
  Export,
  PlayCricle,
  Profile,
} from "iconsax-react";

// Functional Imports
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import Constants from "../../utils/Constants";

interface SideBarProps {}

const PageNames = {
  Creator: {
    Home: "Home",
    Advertisement: "Advertisements",
    ContentManagement: "Content Management",
    MasterFiles: "Master Files",
    CreatorSpace: "Creator Space",
    FundManagement: "Funds Management",
    Overview: "Overview",
    Profile: "Profile",
  },
  Advertiser: {
    Home: "Home",
    Creators: "Creators",
    AdCatalogue: "Ad Catalogue",
    FundsManagement: "Funds Management",
    RequestManagement: "Request Management",
    ContractManagement: "Contracts",
    Profile: "Profile",
  },
  Admin: {
    Home: "Home",
    CreatorAccess: "Creator Access",
    AdvertiserAccess: "Advertiser Access",
    ViewerAccess: "Viewer Access",
    Payments: "Payments",
    Profile: "Profile",
  },
};

const Creator: FC<SideBarProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTile, setSelectedTile] = useState(PageNames.Creator.Home);

  useEffect(() => {
    if (
      location.pathname.includes(PATHS.CREATOR_CONTENT_MANAGEMENT) ||
      location.pathname.includes(PATHS.CREATOR_CHOOSE_MASTER_FILE) ||
      location.pathname.includes(PATHS.CREATOR_CREATE_CONTENT) ||
      location.pathname.includes(PATHS.CREATOR_CONTENT_LIST + "/details") ||
      location.pathname.includes(PATHS.CREATOR_CONTENT_DETAILS)
    ) {
      setSelectedTile(PageNames.Creator.ContentManagement);
    } else if (
      location.pathname.includes(PATHS.CREATOR_ADVERTISEMENTS) ||
      location.pathname.includes(PATHS.CREATOR_CREQUESTS) ||
      location.pathname.includes(PATHS.CREATOR_CREQUEST_DETAILS)
    ) {
      setSelectedTile(PageNames.Creator.Advertisement);
    } else if (
      location.pathname.includes(PATHS.CREATOR_MASTER_FILES) ||
      location.pathname.includes(PATHS.CREATOR_UPLOAD_MASTER_FILE) ||
      location.pathname.includes(PATHS.CREATOR_MANAGE_MASTER_FILES) ||
      location.pathname.includes(PATHS.CREATOR_MASTER_FILE_DETAILS)
    ) {
      setSelectedTile(PageNames.Creator.MasterFiles);
    } else if (
      location.pathname.includes(PATHS.CREATOR_CREATOR_SPACE) ||
      location.pathname.includes(PATHS.CREATOR_SPACE_CONFIGURATION) ||
      location.pathname.includes(PATHS.CREATOR_MANAGE_SUBSPACES) ||
      location.pathname.includes(PATHS.CREATOR_VIEW_SUBSPACE) ||
      location.pathname.includes(PATHS.CREATOR_CREATE_NEW_SUBSPACE) ||
      location.pathname.includes(PATHS.CREATOR_UPDATE_SUBSPACE)
    ) {
      setSelectedTile(PageNames.Creator.CreatorSpace);
    } else if (
      location.pathname.includes(PATHS.CREATOR_OVERVIEW) ||
      location.pathname.includes(PATHS.CREATOR_CONTENT_OVERVIEW) ||
      location.pathname.includes(PATHS.CREATOR_OVERVIEWS) ||
      location.pathname.includes(PATHS.CREATOR_SPACE_OVERVIEW) ||
      location.pathname.includes(PATHS.CREATOR_DETAILED_OVERVIEW) ||
      location.pathname.includes(PATHS.CREATOR_WATCHTIME_DETAILS)
    ) {
      setSelectedTile(PageNames.Creator.Overview);
    } else if (
      location.pathname.includes(PATHS.CREATOR_FUND_MANAGEMENT) ||
      location.pathname.includes(PATHS.CREATOR_TRANSACTIONS) ||
      location.pathname.includes(PATHS.CREATOR_CONTRACTS) ||
      location.pathname.includes(PATHS.CREATOR_CONTRACT_LIST) ||
      location.pathname.includes(PATHS.CREATOR_CONTRACT_DETAILS) ||
      location.pathname.includes(PATHS.CREATOR_WATCHTIME_BILL) ||
      location.pathname.includes(PATHS.CREATOR_WATCHTIME_BILLS) ||
      location.pathname.includes(PATHS.CREATOR_CONTENT_LIST + "/contracts")
    ) {
      setSelectedTile(PageNames.Creator.FundManagement);
    }
  }, [location]);

  const selectedAction = (ev: any) => {
    setSelectedTile(ev);

    if (ev === PageNames.Creator.Home) {
      navigate(PATHS.CREATOR_HOME);
    } else if (ev === PageNames.Creator.Advertisement) {
      navigate(PATHS.CREATOR_ADVERTISEMENTS);
    } else if (ev === PageNames.Creator.ContentManagement) {
      navigate(PATHS.CREATOR_CONTENT_MANAGEMENT);
    } else if (ev === PageNames.Creator.MasterFiles) {
      navigate(PATHS.CREATOR_MASTER_FILES);
    } else if (ev === PageNames.Creator.CreatorSpace) {
      navigate(PATHS.CREATOR_CREATOR_SPACE);
    } else if (ev === PageNames.Creator.Overview) {
      navigate(PATHS.CREATOR_OVERVIEWS);
    } else if (ev === PageNames.Creator.FundManagement) {
      navigate(PATHS.CREATOR_FUND_MANAGEMENT);
    } else if (ev === PageNames.Creator.Profile) {
      navigate(PATHS.CREATOR_PROFILE);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: Colors.blueBackground,
        ...Styles.SStyles.elevation,
      }}
    >
      <CUIPresets.LogowText title="Creator Console" />

      <Box marginTop={4} />

      <CUIPresets.SideBarTile
        Icon={
          <Home2
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Creator.Home}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <Money
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Creator.Advertisement}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <VideoSquare
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Creator.ContentManagement}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <TableDocument
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Creator.MasterFiles}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <Ankr
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Creator.CreatorSpace}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <EmptyWallet
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Creator.FundManagement}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <SearchStatus1
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Creator.Overview}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <Profile
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Creator.Profile}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
    </Box>
  );
};

const Advertiser: FC<SideBarProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTile, setSelectedTile] = useState("");

  useEffect(() => {
    if (location.pathname.includes(PATHS.ADVERTISER_HOME)) {
      setSelectedTile(PageNames.Advertiser.Home);
    } else if (
      location.pathname.includes(PATHS.ADVERTISER_CREATORS) ||
      location.pathname.includes(PATHS.ADVERTISER_SEARCH_CREATOR) ||
      location.pathname.includes(PATHS.ADVERTISER_SELECT_CONTENT) ||
      location.pathname.includes(PATHS.ADVERTISER_CONTRACT_REQUEST)
    ) {
      setSelectedTile(PageNames.Advertiser.Creators);
    } else if (
      location.pathname.includes(PATHS.ADVERTISER_AD_CATALOGUE) ||
      location.pathname.includes(PATHS.ADVERTISER_UPLOAD_NEW_AD) ||
      location.pathname.includes(PATHS.ADVERTISER_MANAGE_AD_FILES) ||
      location.pathname.includes(PATHS.ADVERTISER_VIEW_AD_DETAILS)
    ) {
      setSelectedTile(PageNames.Advertiser.AdCatalogue);
    } else if (
      location.pathname.includes(PATHS.ADVERTISER_FUNDS_MANAGEMENT) ||
      location.pathname.includes(PATHS.ADVERTISER_FUNDS_OVERVIEW) ||
      location.pathname.includes(PATHS.ADVERTISER_ADD_FUNDS_REQUEST) ||
      location.pathname.includes(PATHS.ADVERTISER_WITHDRAW_FUNDS_REQUEST)
    ) {
      setSelectedTile(PageNames.Advertiser.FundsManagement);
    } else if (
      location.pathname.includes(PATHS.ADVERTISER_REQUEST_MANAGEMENT) ||
      location.pathname.includes(PATHS.ADVERTISER_REQUESTS) ||
      location.pathname.includes(PATHS.ADVERTISER_REQUEST_DETAILS)
    ) {
      setSelectedTile(PageNames.Advertiser.RequestManagement);
    } else if (
      location.pathname.includes(PATHS.ADVERTISER_CONTRACTS) ||
      location.pathname.includes(PATHS.ADVERTISER_CONTRACT_DETAILS) ||
      location.pathname.includes(PATHS.ADVERTISER_TRANSACTIONS) ||
      location.pathname.includes(PATHS.ADVERTISER_CONTRACT_LIST) ||
      location.pathname.includes(PATHS.ADVERTISER_CONTRACT_TYPES) ||
      location.pathname.includes(PATHS.ADVERTISER_CREATORS_WITH_CONTRACTS)
    ) {
      setSelectedTile(PageNames.Advertiser.ContractManagement);
    }
  }, [location]);

  const selectedAction = (ev: any) => {
    setSelectedTile(ev);

    if (ev === PageNames.Advertiser.Home) {
      navigate(PATHS.ADVERTISER_HOME);
    } else if (ev === PageNames.Advertiser.Creators) {
      navigate(PATHS.ADVERTISER_CREATORS);
    } else if (ev === PageNames.Advertiser.AdCatalogue) {
      navigate(PATHS.ADVERTISER_AD_CATALOGUE);
    } else if (ev === PageNames.Advertiser.FundsManagement) {
      navigate(PATHS.ADVERTISER_FUNDS_MANAGEMENT);
    } else if (ev === PageNames.Advertiser.RequestManagement) {
      navigate(PATHS.ADVERTISER_REQUEST_MANAGEMENT);
    } else if (ev === PageNames.Advertiser.ContractManagement) {
      navigate(PATHS.ADVERTISER_CONTRACTS);
    } else if (ev === PageNames.Advertiser.Profile) {
      navigate(PATHS.ADVERTISER_PROFILE);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: Colors.blueBackground,
        ...Styles.SStyles.elevation,
      }}
    >
      <CUIPresets.LogowText title="Advertiser Console" />

      <Box marginTop={4} />

      <CUIPresets.SideBarTile
        Icon={
          <Home2
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Advertiser.Home}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <Copyright
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Advertiser.Creators}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <Firstline
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Advertiser.AdCatalogue}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <EmptyWallet
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Advertiser.FundsManagement}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <Export
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Advertiser.RequestManagement}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <TableDocument
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Advertiser.ContractManagement}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <Profile
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Advertiser.Profile}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
    </Box>
  );
};

const Admin: FC<SideBarProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTile, setSelectedTile] = useState(PageNames.Admin.Home);

  useEffect(() => {
    if (
      location.pathname.includes(PATHS.ADMIN_CREATOR_SEARCH) ||
      location.pathname.includes(PATHS.ADMIN_CREATOR_VIEW_SUBSPACES) ||
      location.pathname.includes(PATHS.ADMIN_CREATOR_MASTER_FILE_DETAILS) ||
      location.pathname.includes(PATHS.ADMIN_CREATOR_SUBSPACES) ||
      location.pathname.includes(PATHS.ADMIN_CREATOR_SUBSPACE_DETAILS)
    ) {
      setSelectedTile(PageNames.Admin.CreatorAccess);
    } else if (
      location.pathname.includes(PATHS.ADMIN_PAYMENTS) ||
      location.pathname.includes(PATHS.ADMIN_ADVERTISER_SEARCH) ||
      location.pathname.includes(PATHS.ADMIN_ADD_FUNDS_REQUESTS) ||
      location.pathname.includes(PATHS.ADMIN_ADD_FUNDS_REQUESTS_LIST) ||
      location.pathname.includes(PATHS.ADMIN_ADD_FUNDS_REQUESTS_DETAILS)
    ) {
      setSelectedTile(PageNames.Admin.Payments);
    }
  }, [location]);

  const selectedAction = (ev: any) => {
    setSelectedTile(ev);
    if (ev === PageNames.Admin.CreatorAccess) {
      navigate(
        PATHS.ADMIN_CREATOR_SEARCH +
          "/" +
          Constants.TYPES.CREATOR_SEARCH.CREATOR_ACCESS
      );
    } else if (ev === PageNames.Admin.Payments) {
      navigate(PATHS.ADMIN_PAYMENTS);
    } else if (ev === PageNames.Admin.ViewerAccess) {
      navigate(PATHS.ADMIN_VIEWER_SEARCH);
    } else if (ev === PageNames.Admin.AdvertiserAccess) {
      navigate(
        PATHS.ADMIN_ADVERTISER_SEARCH +
          "/" +
          Constants.TYPES.ADVERTISER_SEARCH.ADVERTISER_ACCESS
      );
    } else if ( ev === PageNames.Admin.Profile ) {
      navigate(PATHS.ADMIN_PROFILE)
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: Colors.blueBackground,
        ...Styles.SStyles.elevation,
      }}
    >
      <CUIPresets.LogowText title="Admin Console" />

      <Box marginTop={4} />

      <CUIPresets.SideBarTile
        Icon={
          <Home2
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Admin.Home}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <Copyright
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Admin.CreatorAccess}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <Money
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Admin.AdvertiserAccess}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <PlayCricle
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Admin.ViewerAccess}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
      <CUIPresets.SideBarTile
        Icon={
          <EmptyWallet
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Admin.Payments}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />

      <CUIPresets.SideBarTile
        Icon={
          <Profile
            size="20"
            style={{
              marginLeft: 16,
            }}
            color={Colors.white}
          />
        }
        title={PageNames.Advertiser.Profile}
        selected={selectedTile}
        setSelected={(ev: any) => selectedAction(ev)}
      />
    </Box>
  );
};

const SideBar = {
  Creator,
  Advertiser,
  Admin,
};

export default SideBar;
