// STATUS FLAGS

const MASTER_FILE_STATUS_FLAGS = {
  PROCESSING: "processing",
  UNASSIGNED: "unassigned",
  ASSIGNED: "assigned",
  ERROR: "error",
};

const AD_FILE_STATUS_FLAGS = {
  PROCESSING: "processing",
  UNASSIGNED: "unassigned",
  ASSIGNED: "assigned",
  ERROR: "error",
};

const ADVERTISER_ADD_FUNDS_REQUESTS_STATUS = {
  RAISING: "raising",
  RAISED: "raised",
  UPLOADED: "uploaded",
  APPROVED: "approved",
  REJECTED: "rejected",
};

const ADVERTISER_WITHDRAW_FUNDS_REQUESTS_STATUS = {
  RAISING: "raising",
  RAISED: "raised",
  APPROVED: "approved",
  PAID: "paid",
};

const CREATOR_PAYMENT_REQUESTS_STATUS = {
  RAISING: "raising",
  RAISED: "raised",
  APPROVED: "approved",
  PAID: "paid",
};

const CONTRACT_REQUEST_STATUS_FLAGS = {
  RAISED: "raised",
  WITHDRAWN: "withdrawn",
  EXPIRED: "expired",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
};

const CONTRACT_STATUS_FLAGS = {
  ACTIVE: "active",
  EXPIRED: "expired",
  ABORTED: "aborted",
};

const SAVED_CONTENT_STATUS_FLAGS = {
  START: "START",
  READY: "READY",
  LOADING: "LOADING",
  FINISHED: "FINISHED",
};

const WATCHTIME_BILLS_STATUS_FLAGS = {
  GENERATED: "generated",
  SENT: "sent",
  PAID: "paid",
};

const WATCHTIME_BILL_PROOF_STATUS_FLAGS = {
  RAISED: "raised",
  APPROVED: "approved",
  REJECTED: "rejected",
};

const STATUS_FLAGS = {
  MASTER_FILE: MASTER_FILE_STATUS_FLAGS,
  AD_FILE: AD_FILE_STATUS_FLAGS,
  ADD_FUNDS_REQUEST: ADVERTISER_ADD_FUNDS_REQUESTS_STATUS,
  WITHDRAW_FUNDS_REQUEST: ADVERTISER_WITHDRAW_FUNDS_REQUESTS_STATUS,
  CREATOR_PAYMENT_REQUESTS: CREATOR_PAYMENT_REQUESTS_STATUS,
  CONTRACT_REQUEST: CONTRACT_REQUEST_STATUS_FLAGS,
  SAVED_CONTENT: SAVED_CONTENT_STATUS_FLAGS,
  CONTRACT: CONTRACT_STATUS_FLAGS,
  WATCHTIME_BILLS: WATCHTIME_BILLS_STATUS_FLAGS,
  WATCHTIME_BILL_PROOF: WATCHTIME_BILL_PROOF_STATUS_FLAGS,
};

// TYPES

const ADVERTISER_FUNDS_REQUESTS_TYPES = {
  ADD_FUNDS: "add-funds",
  WITHDRAW_FUNDS: "withdraw-funds",
};

const ADMIN_ADD_FUNDS_REQUEST_DISPLAY_TYPES = {
  DATE: "date",
  ADVERTISER: "advertiser",
  FILTER: "status",
};

const ADMIN_WITHDRAW_FUNDS_REQUEST_DISPLAY_TYPES = {
  DATE: "date",
  ADVERTISER: "advertiser",
  FILTER: "status",
};

const PAYMENT_REQUEST_DISPLAY_TYPES = {
  DATE: "date",
  CREATOR: "creator",
  FILTER: "status",
};

const ADMIN_WATCHTIME_BILLS_DISPLAY_TYPES = {
  MONTH: "month",
  STATUS: "status",
  CREATOR: "creator",
};

const MODE_OF_PAYMENTS = {
  UPI: "UPI",
  BANK: "BANK",
  PAYPAL: "PAYPAL",
};

const ADVERTISER_SEARCH_TYPES = {
  ADD_FUNDS: "add-funds",
  WITHDRAW_FUNDS: "withdraw-funds",
  ADVERTISER_ACCESS: "advertiser-access",
};

const VIEWER_DISPLAY_TYPES = {
  COMPACT: "compact",
  COMFORTABLE: "comfortable",
};

const SUBSCRIPTION_TYPES = {
  SUBSCRIBE: "subscribe",
  LOADING: "loading",
  SUBSCRIBED: "subscribed",
};

const PLAYER_RIGHT_DISPLAY_TYPES = {
  NONE: "NONE",
  SUGGESTIONS: "SUGGESTIONS",
  ACTIONS: "ACTIONS",
};

const MY_SPACE_TYPES = {
  CONTINUE_WATCHING: "Continue Watching",
  HISTORY: "History",
  SAVED_CONTENT: "Saved Content",
  WATCH_LATER: "Watch Later",
};

const TRENDING_SUBSCRIPTION_PAGE_TYPES = {
  CONTENT: "Content",
  SPACES: "Spaces",
};

const TRENDING_FILTER_TYPES = {
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
};

const CREATOR_SEARCH_TYPES = {
  CREATOR_ACCESS: "creator-access",
  PAYMENT_REQUESTS: "payment-requests",
  WATCHTIME_BILLS: "watchtime-bills",
  WATCHTIME_BILL_PROOFS: "watchtime-bill-proofs",
};

const WATCHTIME_DETAILS_DISPLAY_TYPES = {
  BY_COUNTRY: "By Country",
  ALL: "All",
};

const VIEWER_AUTH_STATES_TYPES = {
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  VERIFY_CHANGE: "VERIFY_CHANGE",
};

const TYPES = {
  ADVERTISER_FUNDS_REQUESTS: ADVERTISER_FUNDS_REQUESTS_TYPES,
  MODE_OF_PAYMENTS,
  ADD_FUNDS_DISPLAY: ADMIN_ADD_FUNDS_REQUEST_DISPLAY_TYPES,
  WITHDRAW_FUNDS_DISPLAY: ADMIN_WITHDRAW_FUNDS_REQUEST_DISPLAY_TYPES,
  ADVERTISER_SEARCH: ADVERTISER_SEARCH_TYPES,
  CREATOR_SEARCH: CREATOR_SEARCH_TYPES,
  VIEWER_DISPLAY: VIEWER_DISPLAY_TYPES,
  SUBSCRIPTION_TYPES: SUBSCRIPTION_TYPES,
  PLAYER_RIGHT_DISPLAY: PLAYER_RIGHT_DISPLAY_TYPES,
  MY_SPACE: MY_SPACE_TYPES,
  TRENDING_SUBSCRIPTION: TRENDING_SUBSCRIPTION_PAGE_TYPES,
  TRENDING_FILTER: TRENDING_FILTER_TYPES,
  WATCHTIME_DETAILS: WATCHTIME_DETAILS_DISPLAY_TYPES,
  VIEWER_AUTH_STATES: VIEWER_AUTH_STATES_TYPES,
  PAYMENT_REQUEST: PAYMENT_REQUEST_DISPLAY_TYPES,
  WATCHTIME_BILLS_DISPLAY: ADMIN_WATCHTIME_BILLS_DISPLAY_TYPES,
};

// API RESPONSES

const API_RESPONSES = {
  ADD_FUNDS_SUCCESS: "Add Funds Request created successfully",
  UPLOAD_PROOF_SUCCESS: "Proof uploaded successfully",
  ADMIN_RESPONSE_SUCCESS: "Admin response added successfully",
  DELETE_AD_FILE: "AdFile deleted successfully",
  DELETE_MASTER_FILE: "Masterfile deleted successfully",
  WITHDRAW_FUNDS_SUCCESS: "Withdraw Funds Request created successfully",
  SPACE_CREATE_SUCCESS: "Space created successfully",
  SPACE_CONFIG_UPDATE_SUCCESS: " Space Configuration Updated Successfully..",
  SPACE_IMAGES_UPDATE_SUCCESS: "Space Images Updated Successfully..",
  CONTENT_CREATE_SUCCESS: "Content Created Successfully!",
  REQUEST_CREATE_SUCCESS: "Request created successfully!",
  CONTENT_SUGGESTION_CREATE_SUCCESS: "Content suggestion created successfully!",
  CONTENT_SUGGESTION_UPDATE_SUCCESS: "Content suggestion updated successfully!",
  REQUEST_WITHDRAWN_SUCCESS: " Request withdrawn successfully!",
  CONTRACT_CREATE: "Contract created!",
  REQUEST_REJECT: "Request rejected!",
  SUBSPACE_CREATE_SUCCESS: "SubSpace created successfully!",
  SUBSPACE_UPDATE_SUCCESS: "SubSpace updated successfully!",
  SUBSPACE_DELETE_SUCCESS: "Sub Space Deleted Successfully",
  VIEWER_ACCOUNT_CREATE_SUCCESS: "viewer account created successfully",
  VIEWER_LOGIN_SUCCESS: "Viewer logged in successfully",
  BILL_UPDATED: "Bill updated",
  PROOF_UPLOAD_SUCCESS: "Proof uploaded successfully",
};

const REGEX = {
  EMAIL: /^\S+@\S+\.\S+$/,
};

const QUANTITIES = {
  API_REQUEST_LIMIT: 30,
};

const Constants = {
  STATUS_FLAGS,
  TYPES,
  API_RESPONSES,
  REGEX,
  QUANTITIES,
};

export default Constants;
