// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { Information } from "../../utils/Functions";
import API from "../../api/API";
import Arrays from "../../utils/Arrays";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";

interface CreatorSpaceOverviewProps {}

const CreatorSpaceOverview: FC<CreatorSpaceOverviewProps> = (props) => {
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getSpaceViews().then((response) => {
      setDataLoading(false);
      populateData(response.spaceViewDetails);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateData = (dataArray: any) => {
    const apiData: any = [];

    dataArray?.map((item: any) => {
      apiData.push([
        Information.retrieveCountry(item.countryCode),
        String(item.d1Views),
        String(item.d7Views),
        String(item.d30Views),
        String(item.allTimeViews),
      ]);

      return 0;
    });

    setTableData(apiData);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Space Overview" />

        {!dataLoading && (
          <>
            <CTableRow.FlexTableTopCustom
              flexArray={[1, 0.5, 0.5, 0.5, 0.5]}
              titleArray={Arrays.Titles.SpaceOverview}
            />
            {tableData.map((item) => (
              <CTableRow.FlexTableRowCustom
                flexArray={[1, 0.5, 0.5, 0.5, 0.5]}
                contentArray={item}
              />
            ))}
            {tableData.length === 0 && (
              <CTableRow.FlexTableRow contentArray={["No Data"]} />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorSpaceOverview;
