// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { ToastGenerator } from "../../utils/Functions";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";
import Arrays from "../../utils/Arrays";

// Local Imports
import CTableRow from "../../atoms/CTableRow";
import CUIPresets from "../../atoms/CUIPresets";

interface AdminViewerSearchProps {}

const AdminViewerSearch: FC<AdminViewerSearchProps> = (props) => {
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState(false);
  const [viewerList, setViewerList] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("all");

  const handleClick = (id: any) => {
      navigate(PATHS.ADMIN_VIEWER_ACCESS + "/" + id);
  };

  const onSearch = () => {
    if (searchText === "") {
      ToastGenerator.info("Search cannot be empty!");
      return;
    }

    setDataLoading(true);
    API.getViewersBySearchAndCountryAdmin(searchText, selectedCountry).then(
      (response) => {
        setDataLoading(false);
        setViewerList(response.viewers);
      }
    );
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Viewer Search" />

        <Box
          sx={{
            width: 800,
            marginBottom: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CUIPresets.SearchBar
            placeholder={"Search Viewers"}
            sx={{ width: 300 }}
            textFieldProps={{
              value: searchText,
              onChange: (ev: any) => setSearchText(ev.target.value),
            }}
          />
          <CUIPresets.SortBox
            options={[
              { code: "all", name: "All" },
              ...Arrays.Constants.ListOfCountries,
            ]}
            label={""}
            xsKey="code"
            xsValue="name"
            selected={selectedCountry}
            setValue={(ev: any) => setSelectedCountry(ev.target.value)}
            sx={{ mb: 0, mt: 1 }}
          />

          <CUIPresets.OutlinedButton
            title={"Search"}
            sx={{ mt: 1 }}
            onClick={onSearch}
          />
        </Box>

        {!dataLoading && (
          <>
            {viewerList?.map((item: any) => (
              <CTableRow.SearchClientRow
                fullName={item.fullName}
                email={item.email}
                onClick={() => handleClick(item._id)}
                sx={{
                  height: 60,
                }}
              />
            ))}
            {viewerList?.length === 0 && (
              <CTableRow.FlexTableRow contentArray={["No Data"]} />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminViewerSearch;
