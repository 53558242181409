// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import {
  Box,
  IconButton,
  Paper,
  ButtonBase,
  Checkbox,
  Grid,
} from "@mui/material";

// Functional Imports
import { ArrowCircleDown2, ArrowCircleUp2, Menu, Timer1 } from "iconsax-react";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import { SortableItem, SortableKnob } from "react-easy-sort";
import { StringManipulation } from "../../utils/Functions";

// Local Imports
import CUIPresets from "../CUIPresets";
import CText from "../CText";
import CTools from "../CTools";

interface SearchClientRowProps {
  email?: any;
  username?: any;
  fullName?: any;
  onClick?: any;
  sx?: any
}

const SearchClientRow: FC<SearchClientRowProps> = (props) => {
  return (
    <Paper
      sx={{
        width: 750,
        height: 120,
        borderBottom: "3px solid " + Colors.greyBorder2,
        borderRight: "3px solid " + Colors.greyBorder2,
        ...Styles.SStyles.RowCenterSpace,
        px: 3,
        borderRadius: 2,
        mb: 2,
        backgroundColor: Colors.lightBlueBackground2,
        ...props.sx
      }}
    >
      <Box sx={{ ...Styles.SStyles.ColumnStartStart }}>
        <CUIPresets.LabelValueHorizontal
          sx={{ m: 0, flex: 1 }}
          labelSx={{ width: 200 }}
          label={"Email"}
          value={StringManipulation.stringLimiter(props.email, 30)}
        />
        {props.username?.length > 0 && (
          <CUIPresets.LabelValueHorizontal
            sx={{ m: 0, flex: 1 }}
            labelSx={{ width: 200 }}
            label={"Username"}
            value={StringManipulation.stringLimiter(props.username, 30)}
          />
        )}
        <CUIPresets.LabelValueHorizontal
          sx={{ m: 0, flex: 1 }}
          labelSx={{ width: 200 }}
          label={"Full Name"}
          value={StringManipulation.stringLimiter(props.fullName, 30)}
        />
      </Box>

      <CUIPresets.RightArrowAvatarWhite onClick={props.onClick} />
    </Paper>
  );
};

interface SpaceRowProps {
  coverPhoto?: any;
  creatorName?: any;
  onClick?: any;
}

const SpaceRow: FC<SpaceRowProps> = (props) => {
  return (
    <Grid
      item
      sx={{
        width: 400,
        height: 120,
        mr: 2,
        border: "3px solid" + Colors.blueBorderColor,
        ":hover": {
          backgroundColor: Colors.lightBlueBackground2,
        },
        ...Styles.SStyles.RowCenterSpace,
        px: 3,
        borderRadius: 2,
        mb: 2,
      }}
    >
      <CTools.ImageContainer
        imageBgColor={Colors.lightBlueBackground2}
        src={props.coverPhoto}
        height={100}
        width={160}
      />
      <Box
        sx={{
          width: "70%",
          ml: 2,
        }}
      >
        <CText.Jakarta.Size18.Mid.Black text={props.creatorName} />
      </Box>

      <CUIPresets.RightArrowAvatarWhite onClick={props.onClick} />
    </Grid>
  );
};

interface SelectContentRowProps {
  coverPhoto?: any;
  contentName?: any;
  duration?: any;
  adSlotInfo?: any;
  onClick?: any;
}

const SelectContentRow: FC<SelectContentRowProps> = (props) => {
  return (
    <Paper
      sx={{
        width: 750,
        height: 120,
        border: "3px solid" + Colors.blueBorderColor,
        ":hover": {
          backgroundColor: Colors.lightBlueBackground2,
        },
        ...Styles.SStyles.RowCenterSpace,
        px: 3,
        borderRadius: 2,
        mb: 2,
      }}
    >
      <CTools.ImageContainer
        imageBgColor={Colors.lightBlueBackground2}
        src={props.coverPhoto}
        height={100}
        width={160}
      />

      <Box
        sx={{
          width: "65%",
        }}
      >
        <CText.Jakarta.Size18.Mid.Black text={props.contentName} />
        <Box
          sx={{
            ...Styles.SStyles.RowCenterStart,
            mt: 2,
          }}
        >
          <Timer1 size="18" color={Colors.blueText2} />
          <CText.Jakarta.Size16.Light.Black
            style={{
              marginLeft: 8,
            }}
            text={props.duration}
          />
          <CText.Jakarta.Size14.SemiBold.Blue2
            style={{
              marginLeft: 24,
            }}
            text="Ad Slots"
          />
          <CText.Jakarta.Size16.Light.Black
            style={{
              marginLeft: 8,
            }}
            text={props.adSlotInfo}
          />
        </Box>
      </Box>

      <CUIPresets.RightArrowAvatarWhite onClick={props.onClick} />
    </Paper>
  );
};

interface CreatorContentRowProps {
  image?: any;
  title?: any;
  sx?: any;
  onClick?: any;
}

const CreatorContentRow: FC<CreatorContentRowProps> = (props) => {
  return (
    <Box
      sx={{
        width: 770,
        backgroundColor: Colors.lightBlueBackground2,
        borderTop: "3px solid " + Colors.lightBlueBackground3,
        borderLeft: "3px solid " + Colors.lightBlueBackground3,
        height: 70,
        px: 2,
        borderRadius: 2,
        mb: 1,
        ...Styles.SStyles.RowCenterSpace,
        ...props.sx,
      }}
    >
      <CTools.ImageContainer
        imageBgColor={Colors.white}
        src={props.image}
        height={60}
        width={100}
      />

      <CText.Jakarta.Size16.Mid.Black
        style={{
          width: "75%",
        }}
        text={props.title}
      />

      <CUIPresets.RightArrowAvatarWhite onClick={props.onClick} />
    </Box>
  );
};

interface CreatorContentCheckRowProps {
  image?: any;
  title?: any;
  sx?: any;
  checked?: any;
  onClick?: any;
}

const CreatorContentCheckRow: FC<CreatorContentCheckRowProps> = (props) => {
  return (
    <Box
      sx={{
        width: 770,
        borderColor: Colors.lightBlueBackground2,
        borderTop: "3px solid " + Colors.lightBlueBackground3,
        borderLeft: "3px solid " + Colors.lightBlueBackground3,
        height: 70,
        px: 2,
        borderRadius: 2,
        mb: 1,
        ...Styles.SStyles.RowCenterSpace,
        ...props.sx,
      }}
    >
      <CTools.ImageContainer src={props.image} height={60} width={100} />

      <CText.Jakarta.Size16.Mid.Black
        style={{
          width: "75%",
        }}
        text={props.title}
      />

      <Checkbox
        sx={{ color: Colors.blueBackground }}
        checked={props.checked}
        onChange={props.onClick}
      />
    </Box>
  );
};

interface ContentSelectRowProps {
  image?: any;
  title?: any;
  sx?: any;
}

const ContentSelectRow: FC<ContentSelectRowProps> = (props) => {
  return (
    <Box
      sx={{
        width: 770,
        backgroundColor: Colors.lightBlueBackground2,
        borderTop: "3px solid " + Colors.lightBlueBackground3,
        borderLeft: "3px solid " + Colors.lightBlueBackground3,
        height: 70,
        px: 2,
        borderRadius: 2,
        mb: 1,
        ...Styles.SStyles.RowCenterSpace,
        ...props.sx,
      }}
    >
      <Box
        component={"img"}
        src={props.image}
        sx={{
          height: 50,
          width: 100,
          borderRadius: 1,
        }}
      />

      <CText.Jakarta.Size16.Mid.Black
        style={{
          width: "75%",
        }}
        text={props.title}
      />

      <Checkbox
        sx={{
          color: Colors.blueBackground,
        }}
      />

      {/* <CUIPresets.RightArrowAvatarWhite /> */}
    </Box>
  );
};

interface ContentSortRowProps {
  image?: any;
  title?: any;
  sx?: any;
  index?: any;
}

const ContentSortRow: FC<ContentSortRowProps> = (props) => {
  return (
    <SortableItem key={"item"}>
      <Box
        sx={{
          width: 770,
          backgroundColor: Colors.lightBlueBackground2,
          borderTop: "3px solid " + Colors.lightBlueBackground3,
          borderLeft: "3px solid " + Colors.lightBlueBackground3,
          height: 80,
          px: 2,
          borderRadius: 2,
          mb: 1,
          ...Styles.SStyles.RowCenterSpace,
          ...props.sx,
        }}
      >
        <CText.Jakarta.Size32.Bold.Black
          text={props.index}
          style={{
            marginRight: 16,
          }}
        />
        <CTools.ImageContainer src={props.image} height={60} width={100} />

        <CText.Jakarta.Size16.Mid.Black
          style={{
            width: "65%",
          }}
          text={props.title}
        />

        <SortableKnob>
          <IconButton
            sx={{
              backgroundColor: Colors.white,
              ":hover": {
                backgroundColor: Colors.greyBackground,
              },
            }}
          >
            <Menu size={24} color={Colors.blueBackground} />
          </IconButton>
        </SortableKnob>
      </Box>
    </SortableItem>
  );
};

interface ContractRequestDetailsProps {
  open?: any;
}

const ContractRequestDetails: FC<ContractRequestDetailsProps> = (props) => {
  const [open, setOpen] = useState(false);

  if (!open) {
    return (
      <Box
        sx={{
          width: 800,

          height: 80,
          backgroundColor: Colors.lightBlueBackground3,
          borderRadius: 1,
          mt: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "30%" }}>
          <CText.Jakarta.Size16.SemiBold.Black
            style={{
              marginLeft: 20,
              width: "30%",
              textAlign: "start",
            }}
            text="Lorem Ipsum"
          />
        </Box>

        <Box sx={{ width: "30%" }}>
          <CText.Jakarta.Size16.SemiBold.Black
            style={{
              width: "30%",
              textAlign: "start",
            }}
            text="45 Days"
          />
        </Box>

        <Box sx={{ width: "30%" }}>
          <CText.Jakarta.Size16.SemiBold.Black
            style={{
              width: "30%",
              textAlign: "start",
            }}
            text="40"
          />
        </Box>

        <IconButton onClick={() => setOpen(true)} sx={{ marginRight: 2 }}>
          <ArrowCircleDown2 size="32" />
        </IconButton>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: 800,
          height: 400,

          backgroundColor: Colors.lightBlueBackground3,
          borderRadius: 1,
          mt: 2,
          display: "flex",
          position: "relative",
        }}
      >
        <Box>
          <CText.Jakarta.Size16.SemiBold.Grey3
            style={{
              marginLeft: 20,
              paddingTop: 16,
              textAlign: "start",
            }}
            text="Ad Preview"
          />

          <CTools.IFrameContainer
            height={210}
            width={260}
            style={{ marginLeft: 20, marginTop: 12 }}
          />
        </Box>

        <Box>
          <Box display="flex">
            <CUIPresets.LabelValueVertical
              label={"Advertiser"}
              value="ACC Ltd"
            />
            <CUIPresets.LabelValueVertical label={"Duration"} value="30 Days" />
          </Box>

          <Box display="flex">
            <CUIPresets.LabelValueVertical
              label={"Expiry of Request"}
              value="16/01/2023"
            />
            <CUIPresets.LabelValueVertical
              label={"Accepted Start Date"}
              value="16/01/2023"
            />
          </Box>

          <Box display="flex">
            <CUIPresets.LabelValueVertical
              label={"Type of Contract"}
              value="Fixed"
            />
            <CUIPresets.LabelValueVertical label={"Rate/Amount"} value="200" />
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: 20,
              right: 40,
            }}
          >
            <CUIPresets.OutlinedButton
              color={Colors.red}
              sx={{
                textTransform: "none",
                width: "90px",
                marginRight: 1,
                borderWidth: 0,
                ":hover": {
                  borderWidth: 0,
                },
              }}
              title="Modify"
            />
            <CUIPresets.FilledButton
              color={Colors.red}
              sx={{
                width: "130px",
                marginRight: 1,
              }}
              title="Reject"
            />
            <CUIPresets.FilledButton
              color={Colors.green}
              sx={{
                width: "130px",
              }}
              title="Accept"
            />
          </Box>
        </Box>

        <IconButton
          onClick={() => setOpen(false)}
          style={{ height: 50, width: 50, marginTop: 16, marginLeft: 36 }}
        >
          <ArrowCircleUp2 size="32" />
        </IconButton>
      </Box>
    );
  }
};

interface OpenRequestRowProps {
  videoThumbnail?: any;
  videoTitle?: any;
  requestNo?: any;
  sx?: any;
}

const OpenRequestRow: FC<OpenRequestRowProps> = (props) => {
  return (
    <ButtonBase
      sx={{
        width: 800,
        height: 60,

        backgroundColor: Colors.blueTableColor,
        borderBottom: "2px solid " + Colors.blueBorderColor,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 3,
        borderRadius: 1,
        mt: 1,
        ...props.sx,
      }}
    >
      <Box
        component="img"
        src={props.videoThumbnail}
        style={{ height: 45, width: 70, borderRadius: 3 }}
      />

      <CText.Jakarta.Size18.Mid.Black
        style={{
          width: "75%",
          textAlign: "start",
        }}
        text={props.videoTitle}
      />

      <CText.Jakarta.Size40.UltraBold.Black text={props.requestNo} />
    </ButtonBase>
  );
};

interface FlexTableRowProps {
  contentArray?: any;
  sx?: any;
  textStyle?: any;
  flexArray?: any;
  lastFlex?: any;
}

const FlexTableRow: FC<FlexTableRowProps> = (props) => {
  return (
    <Box
      sx={{
        width: 785,
        minHeight: 48,
        py: 0.5,
        px: 1,
        mt: 1,
        ...Styles.SStyles.RowCenterSpace,
        backgroundColor: Colors.lightBlueBackground2,
        ...props.sx,
      }}
    >
      {props.contentArray?.map((item: any, index: any) => {
        if (typeof item === "string") {
          return (
            <Box
              sx={{
                flex:
                  index === props.contentArray?.length - 1 ? props.lastFlex : 1,
              }}
            >
              <CText.Jakarta.Size16.SemiBold.Black
                style={{
                  marginRight: 8,
                  marginLeft: 8,
                  ...props.textStyle,
                }}
                text={item}
              />
            </Box>
          );
        } else {
          return (
            <Box
              sx={{
                flex:
                  index === props.contentArray?.length - 1 ? props.lastFlex : 1,
              }}
            >
              {item}
            </Box>
          );
        }
      })}
    </Box>
  );
};

FlexTableRow.defaultProps = {
  lastFlex: 0.5,
};

const FlexTableRowCustom: FC<FlexTableRowProps> = (props) => {
  return (
    <Box
      sx={{
        width: 785,
        minHeight: 48,
        py: 0.5,
        px: 1,
        mt: 1,
        ...Styles.SStyles.RowCenterSpace,
        backgroundColor: Colors.lightBlueBackground2,
        ...props.sx,
      }}
    >
      {props.contentArray?.map((item: any, index: any) => {
        if (typeof item === "string") {
          return (
            <Box sx={{ flex: props.flexArray[index] }}>
              <CText.Jakarta.Size16.SemiBold.Black
                style={{
                  marginRight: 8,
                  marginLeft: 8,
                  ...props.textStyle,
                }}
                text={item}
              />
            </Box>
          );
        } else {
          return <Box sx={{ flex: props.flexArray[index] }}>{item}</Box>;
        }
      })}
    </Box>
  );
};

interface FlexTableTopProps {
  titleArray?: any;
  sx?: any;
  textStyle?: any;
  flexArray?: any;
  lastFlex?: any;
}

const FlexTableTop: FC<FlexTableTopProps> = (props) => {
  return (
    <Box
      sx={{
        width: 785,
        minHeight: 40,
        px: 1,
        ...Styles.SStyles.RowCenterSpace,
        backgroundColor: Colors.lightGreyBackground,
        ...props.sx,
      }}
    >
      {props.titleArray?.map((item: any, index: any) => (
        <Box
          sx={{
            flex: index === props.titleArray?.length - 1 ? props.lastFlex : 1,
          }}
        >
          <CText.Jakarta.Size16.SemiBold.Grey3
            style={{
              marginRight: 8,
              marginLeft: 8,
              ...props.textStyle,
            }}
            text={item}
          />
        </Box>
      ))}
    </Box>
  );
};

FlexTableTop.defaultProps = {
  lastFlex: 0.5,
};

const FlexTableTopCustom: FC<FlexTableTopProps> = (props) => {
  return (
    <Box
      sx={{
        width: 785,
        minHeight: 40,
        px: 1,
        ...Styles.SStyles.RowCenterSpace,
        backgroundColor: Colors.lightGreyBackground,
        ...props.sx,
      }}
    >
      {props.titleArray?.map((item: any, index: any) => (
        <Box sx={{ flex: props.flexArray[index] }}>
          <CText.Jakarta.Size16.SemiBold.Grey3
            style={{
              marginRight: 8,
              marginLeft: 8,
              ...props.textStyle,
            }}
            text={item}
          />
        </Box>
      ))}
    </Box>
  );
};

interface ViewSubscriptionAdminProps {}

const ViewSubscriptionAdmin: FC<ViewSubscriptionAdminProps> = (props) => {
  return (
    <Paper
      sx={{
        width: 640,
        height: 120,
        border: "2px solid " + Colors.greyBorder2,
        ...Styles.SStyles.RowCenterSpace,
        px: 3,
        borderRadius: 2,
        mb: 2,
      }}
    >
      <Box
        component={"img"}
        src={"https://picsum.photos/300/200"}
        sx={{
          height: 100,
          borderRadius: 1,
        }}
      />
      <Box
        style={{
          width: "70%",
        }}
      >
        <CText.Jakarta.Size18.Mid.Black text="Creator Name Goes Here" />

        <Box
          sx={{
            ...Styles.SStyles.RowCenterStart,
            mt: 1,
          }}
        >
          <CText.Jakarta.Size14.Mid.Grey4 text="Subscribed On: 25-03-2023" />
          <Box
            sx={{
              height: 6,
              width: 6,
              backgroundColor: Colors.greyText4,
              borderRadius: 6,
              mx: 1,
            }}
          />
          <CText.Jakarta.Size14.Mid.Grey4 text="Subscribed Till: 25-03-2024" />
        </Box>
      </Box>
    </Paper>
  );
};

interface ViewHistoryAdminProps {}

const ViewHistoryAdmin: FC<ViewHistoryAdminProps> = (props) => {
  return (
    <Paper
      sx={{
        width: 640,
        height: 120,
        border: "2px solid " + Colors.greyBorder2,
        ...Styles.SStyles.RowCenterSpace,
        px: 3,
        borderRadius: 2,
        mb: 2,
      }}
    >
      <Box
        component={"img"}
        src={"https://picsum.photos/300/200"}
        sx={{
          height: 100,
          borderRadius: 1,
        }}
      />
      <Box
        style={{
          width: "70%",
        }}
      >
        <CText.Jakarta.Size18.Mid.Black text="Content Name Goes Here" />

        <Box
          sx={{
            ...Styles.SStyles.RowCenterStart,
            mt: 1,
          }}
        >
          <CText.Jakarta.Size14.Mid.Grey4 text="He made the mistake of getting married too quickly before really getting to know her.  However...he handled " />
        </Box>
      </Box>
    </Paper>
  );
};

interface SubspaceContentRowProps {
  sx?: any;
  index?: any;
  image?: any;
  title?: any;
}

const SubspaceContentRow: FC<SubspaceContentRowProps> = (props) => {
  return (
    <Box
      sx={{
        width: 770,
        backgroundColor: Colors.lightBlueBackground2,
        borderTop: "3px solid " + Colors.lightBlueBackground3,
        borderLeft: "3px solid " + Colors.lightBlueBackground3,
        height: 80,
        px: 2,
        borderRadius: 2,
        mb: 1,
        ...Styles.SStyles.RowCenterSpace,
        ...props.sx,
      }}
    >
      <CText.Jakarta.Size32.Bold.Black
        text={props.index}
        style={{
          marginRight: 16,
          marginLeft: 8,
        }}
      />
      <CTools.ImageContainer src={props.image} height={60} width={100} />

      <CText.Jakarta.Size16.Mid.Black
        style={{
          width: "75%",
        }}
        text={props.title}
      />
    </Box>
  );
};

interface SearchClientNonEmailRowProps {
  username?: any;
  fullName?: any;
  onClick?: any;
}

const SearchClientNonEmailRow: FC<SearchClientNonEmailRowProps> = (props) => {
  return (
    <Paper
      sx={{
        width: 750,
        height: 80,
        borderBottom: "3px solid " + Colors.greyBorder2,
        borderRight: "3px solid " + Colors.greyBorder2,
        ...Styles.SStyles.RowCenterSpace,
        px: 3,
        borderRadius: 2,
        mb: 2,
        backgroundColor: Colors.lightBlueBackground2,
      }}
    >
      <Box sx={{ ...Styles.SStyles.ColumnStartStart }}>
        <CUIPresets.LabelValueHorizontal
          sx={{ m: 0, flex: 1 }}
          labelSx={{ width: 200 }}
          label={"Username"}
          value={props.username}
        />
        <CUIPresets.LabelValueHorizontal
          sx={{ m: 0, flex: 1, mt: 1 }}
          labelSx={{ width: 200 }}
          label={"Full Name"}
          value={props.fullName}
        />
      </Box>

      <CUIPresets.RightArrowAvatarWhite onClick={props.onClick} />
    </Paper>
  );
};

interface ViewerAdminContentTileProps {
  sx?: any;
  date?: any;
  contentName?: any;
  spaceId?: any;
}

const ViewerAdminContentTile: FC<ViewerAdminContentTileProps> = (props) => {
  return (
    <Box
      sx={{
        border: "5px solid " + Colors.blueBackground,
        p: 2,
        width: 320,
        borderRadius: 2,
        mb: 1,
        mr: 1,
        ...props.sx,
      }}
    >
      <CText.Jakarta.Size16.Mid.Black text={"Date: " + props.date} />
      <CText.Jakarta.Size16.Mid.Black
        text={"Content: " + props.contentName}
        style={{ marginTop: 8 }}
      />
      <CText.Jakarta.Size16.Light.Black
        text={"Space: " + props.spaceId}
        style={{ marginTop: 8 }}
      />
    </Box>
  );
};

const CTableRow = {
  SearchClientRow,
  SearchClientNonEmailRow,
  SpaceRow,
  SelectContentRow,
  CreatorContentRow,
  CreatorContentCheckRow,
  ContentSelectRow,
  ContentSortRow,
  ContractRequestDetails,
  OpenRequestRow,
  FlexTableRow,
  FlexTableRowCustom,
  FlexTableTop,
  FlexTableTopCustom,
  SubspaceContentRow,
  ViewSubscriptionAdmin,
  ViewHistoryAdmin,
  ViewerAdminContentTile
};

export default CTableRow;
