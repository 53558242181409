// React Imports
import React, { FC } from "react";

// MUI Imports
import { Chip, IconButton } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import { ArrowRight2, Maximize4, Trash } from "iconsax-react";
import { ColorRing } from "react-loader-spinner";

// Local Imports

const ChipStyle = {
  fontWeight: 600,
  fontFamily: "Plus Jakarta Sans",
  width: 120,
  fontSize: 12,
  ...Styles.SStyles.lowElevation,
};

interface CChipProps {
  label?: any;
  sx?: any;
}

const BlueChip: FC<CChipProps> = (props) => {
  return (
    <Chip
      label={props.label}
      sx={{
        ...ChipStyle,
        backgroundColor: Colors.lightBlue,
        color: Colors.blueText2,
        ...props.sx,
      }}
    />
  );
};

const RedChip: FC<CChipProps> = (props) => {
  return (
    <Chip
      label={props.label}
      sx={{
        ...ChipStyle,
        backgroundColor: Colors.lightRed,
        color: Colors.redText,
        ...props.sx,
      }}
    />
  );
};

const GreenChip: FC<CChipProps> = (props) => {
  return (
    <Chip
      label={props.label}
      sx={{
        ...ChipStyle,
        backgroundColor: Colors.lightGreen,
        color: Colors.greenText,
        ...props.sx,
      }}
    />
  );
};

const OrangeChip: FC<CChipProps> = (props) => {
  return (
    <Chip
      label={props.label}
      sx={{
        ...ChipStyle,
        backgroundColor: Colors.lightOrange,
        color: Colors.orangeText,
        ...props.sx,
      }}
    />
  );
};

interface RightArrowAvatarProps {
  sx?: any;
  iconSize?: any;
  onClick?: any;
}

const RightArrowAvatarWhite: FC<RightArrowAvatarProps> = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        backgroundColor: Colors.white,
        ":hover": {
          backgroundColor: Colors.greyBackground,
        },
        ...props.sx,
      }}
    >
      <ArrowRight2 size={props.iconSize} color={Colors.blueBackground} />
    </IconButton>
  );
};

const MaximiseAvatarWhite: FC<RightArrowAvatarProps> = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        backgroundColor: Colors.white,
        ":hover": {
          backgroundColor: Colors.greyBackground,
        },
        ...props.sx,
      }}
    >
      <Maximize4 size={props.iconSize} color={Colors.blueBackground} />
    </IconButton>
  );
};

const RightArrowAvatarBlue: FC<RightArrowAvatarProps> = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        marginLeft: 2,
        backgroundColor: Colors.lightBlueBackground2,
        ":hover": {
          backgroundColor: Colors.lightGreyBackground2,
          ...Styles.SStyles.midElevation,
        },
        ...props.sx,
      }}
    >
      <ArrowRight2 size={props.iconSize} color={Colors.blueBackground} />
    </IconButton>
  );
};

const RightArrowAvatarLight: FC<RightArrowAvatarProps> = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        backgroundColor: Colors.blackBackground5,
        ":hover": {
          backgroundColor: Colors.blackBackground5 + "66",
        },
        ...props.sx,
      }}
    >
      <ArrowRight2 size={props.iconSize} color={Colors.cyan} />
    </IconButton>
  );
};

const RightArrowAvatarDelete: FC<RightArrowAvatarProps> = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        backgroundColor: Colors.blackBackground5,
        ":hover": {
          backgroundColor: Colors.blackBackground5 + "66",
        },
        ...props.sx,
      }}
    >
      <Trash size={props.iconSize} color={Colors.cyan} />
    </IconButton>
  );
};

const LoadingAction: FC<RightArrowAvatarProps> = (props) => {
  return (
    <IconButton
      sx={{
        backgroundColor: Colors.blackBackground5,
        ":hover": {
          backgroundColor: Colors.blackBackground5 + "66",
        },
        ...props.sx,
      }}
    >
      <ColorRing
        visible={true}
        height="43"
        width="43"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={[
          Colors.cyan,
          Colors.cyan,
          Colors.cyan,
          Colors.cyan,
          Colors.cyan,
        ]}
      />
      {/* <Trash size={props.iconSize} color={Colors.cyan} /> */}
    </IconButton>
  );
};

RightArrowAvatarLight.defaultProps = {
  iconSize: 28,
};

RightArrowAvatarWhite.defaultProps = {
  iconSize: 24,
};

MaximiseAvatarWhite.defaultProps = {
  iconSize: 20,
};

RightArrowAvatarBlue.defaultProps = {
  iconSize: 28,
};

RightArrowAvatarDelete.defaultProps = {
  iconSize: 28,
};

const StaticBits = {
  BlueChip,
  RedChip,
  GreenChip,
  OrangeChip,
  RightArrowAvatarWhite,
  MaximiseAvatarWhite,
  RightArrowAvatarBlue,
  RightArrowAvatarLight,
  RightArrowAvatarDelete,
  LoadingAction,
};

export default StaticBits;
