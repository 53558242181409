// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box, Grid } from "@mui/material";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";

// Local Imports
import CTableRow from "../../atoms/CTableRow";
import CUIPresets from "../../atoms/CUIPresets";

interface AdvertiserSearchCreatorProps {}

const AdvertiserSearchCreator: FC<AdvertiserSearchCreatorProps> = (props) => {
  const navigate = useNavigate();

  const [creatorList, setCreatorList] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setDataLoading(true);
    API.getAllSpaces().then((response) => {
      setCreatorList(response.spaces);
      setDataLoading(false);
    });
  }, []);

  const searchAction = (searchPass: any) => {
    setSearchText(searchPass);
    setDataLoading(true);
    API.getAllSpaces(searchPass).then((response) => {
      setCreatorList(response.spaces);
      setDataLoading(false);
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Search Creators" />

        <Box
          sx={{
            width: 800,
            marginBottom: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CUIPresets.SearchBar
            placeholder={"Search Creators"}
            sx={{ width: 500 }}
            textFieldProps={{
              onChange: (e: any) => searchAction(e.target.value),
              value: searchText,
            }}
          />
        </Box>

        <Grid container>
          {!dataLoading &&
            creatorList?.map((item: any) => (
              <CTableRow.SpaceRow
                creatorName={item.configuration?.name}
                coverPhoto={item.configuration?.profileImage}
                onClick={() =>
                  navigate(
                    PATHS.ADVERTISER_SELECT_CONTENT + "/" + item.clientId
                  )
                }
              />
            ))}
        </Grid>
        {!dataLoading && creatorList?.length === 0 && (
          <CTableRow.FlexTableRow contentArray={["No Data"]} />
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdvertiserSearchCreator;
