// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";
import { TimerPause, VideoHorizontal } from "iconsax-react";

// Functional Imports
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import CTools from "../../atoms/CTools";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";

interface ContractListProps {
  setListOrDetails?: any;
  contractsDetails?: any;
  setDisplayContract?: any;
}

const ContractList: FC<ContractListProps> = (props) => {
  return (
    <CUIPresets.AnimationWrapper>
      <>
        {props.contractsDetails?.map((item: any) => (
          <ActiveContractRow
            countryName={item.countryName}
            streamLink={item.streamLink}
            contracts={item.contracts}
            setListOrDetails={props.setListOrDetails}
            setDisplayContract={props.setDisplayContract}
          />
        ))}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default ContractList;

interface ActiveContractRowProps {
  countryName?: any;
  streamLink?: any;
  contracts?: any;
  setListOrDetails?: any;
  setDisplayContract?: any;
}

const ActiveContractRow: FC<ActiveContractRowProps> = (props) => {
  const navigate = useNavigate();
  const handleContractsDetails = (index: any) => {
    navigate(
      PATHS.CREATOR_CONTRACT_DETAILS +
        "/" +
        props.contracts[index]?.contract?._id
    );
  };

  return (
    <Box
      sx={{
        width: 800,
        mt: 2,
        border: "3px solid" + Colors.blueBorderColor,
        borderRadius: 1,
      }}
    >
      <CText.Jakarta.Size20.Mid.Black
        style={{
          margin: 8,
        }}
        text={props.countryName}
      />
      <Box
        sx={{
          height: 220,
          width: 800,
          ...Styles.SStyles.RowStartStart,
        }}
      >
        <CTools.IFrameContainer
          src={props.streamLink}
          height={200}
          width={300}
          iframeBgColor={Colors.lightBlueBackground2}
          style={{
            margin: 8,
            marginLeft: 16,
            marginBottom: 16,
          }}
        />

        <Box
          sx={{
            height: 200,
            width: 460,
            mt: 1,
          }}
        >
          {props.contracts?.map((item: any, index: number) => (
            <AdInActiveContractRow
              adSlot={item.adSlot}
              activeContract={item.activeContract}
              onClick={() => handleContractsDetails(index)}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

interface AdInActiveContractRowProps {
  sx?: any;
  adSlot?: any;
  activeContract?: any;
  onClick?: any;
}

const AdInActiveContractRow: FC<AdInActiveContractRowProps> = (props) => {
  return (
    <Box
      sx={{
        height: 60,
        width: 430,
        ...Styles.SStyles.RowCenterSpace,
        ":hover": {
          backgroundColor: Colors.lightBlueBackground2,
        },
        borderRadius: 1,
        px: 2,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          ...Styles.SStyles.RowCenterCenter,
        }}
      >
        <TimerPause color={Colors.blueText} />
        <CText.Jakarta.Size16.Mid.Black
          style={{ marginLeft: 8 }}
          text={props.adSlot}
        />
      </Box>
      <Box
        sx={{
          ...Styles.SStyles.RowCenterCenter,
        }}
      >
        <VideoHorizontal color={Colors.blueText} />
        <CText.Jakarta.Size16.Mid.Black
          style={{ marginLeft: 8 }}
          text={props.activeContract ? "Yes" : "No"}
        />
      </Box>
      {props.activeContract && (
        <CUIPresets.MaximiseAvatarWhite
          onClick={props.onClick}
          sx={{ p: 1.5 }}
        />
      )}
      {!props.activeContract && <Box sx={{ width: 45 }} />}
    </Box>
  );
};
