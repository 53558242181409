// React Imports
import React, { FC } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports

// Local Imports
import CInputOutput from "../../atoms/CInputOutput";
import CUIPresets from "../../atoms/CUIPresets";
import CComposite from "../../atoms/CComposite";
import CText from "../../atoms/CText";

interface CreateNewSubspaceProps {
  // Values
  createOrUpdate?: any;
  checkedList?: any;
  dataLoading?: any;
  contentList?: any;
  subspaceName?: any;
  description?: any;

  // Setters
  setContentSelected?: any;
  setSortEnabled?: any;
  setCheckedList?: any;
  setSubspaceName?: any;
  setDescription?: any;

  // Functions
  confirmContentSelection?: any;
}

const CreateNewSubspace: FC<CreateNewSubspaceProps> = (props) => {
  if (props.dataLoading) {
    return <CUIPresets.LoadingSpinner />;
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader
            title={
              props.createOrUpdate === "create"
                ? "Create New Subspace"
                : "Update Subspace"
            }
          />

          <CInputOutput.LabelInputOutlined
            sx={{ width: 400, mt: 0 }}
            label="Subspace name"
            textFieldProps={{
              placeholder: "Enter subspace name",
              value: props.subspaceName,
              onChange: (ev: any) => props.setSubspaceName(ev.target.value),
            }}
          />

          <CInputOutput.LabelInputOutlined
            sx={{ width: 400, mt: 2 }}
            label="Description"
            textFieldProps={{
              placeholder: "Enter description",
              multiline: true,
              rows: 2,
              value: props.description,
              onChange: (ev: any) => props.setDescription(ev.target.value),
            }}
            textFieldSx={{
              mt: 1,
            }}
          />

          <CText.Jakarta.Size16.Mid.Black
            style={{ marginTop: 32 }}
            text={"Select Content"}
          />

          {props.contentList?.map((item: any, index: number) => (
            <CUIPresets.CreatorContentCheckRow
              title={item.contentDetails?.name}
              image={item.contentDetails?.coverPhoto}
              checked={props.checkedList[index]}
              sx={{ mt: 1 }}
              onClick={(ev: any) => {
                let modCheckedList: any = props.checkedList;

                modCheckedList[index] = !modCheckedList[index];

                props.setCheckedList([...modCheckedList]);
              }}
            />
          ))}

          <CComposite.FooterActions.Outlined
            onClick={props.confirmContentSelection}
            sx={{
              mt: 8,
              mb: 4,
              width: 800,
            }}
            confirmText={"Continue"}
          />
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default CreateNewSubspace;
