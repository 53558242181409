// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";
import {} from "iconsax-react";

// Functional Imports
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CComposite from "../../atoms/CComposite";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import CTableRow from "../../atoms/CTableRow";
import CText from "../../atoms/CText";

interface CreatorChooseMasterFileProps {}

const CreatorChooseMasterFile: FC<CreatorChooseMasterFileProps> = (props) => {
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [masterFileList, setMasterFileList] = useState([]);
  const [selectedMasterFile, setSelectedMasterFile] = useState({
    masterFileName: "",
    _id: "",
  });
  const [spaceFound, setSpaceFound] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getSpaceConfiguration().then((response) => {
      if (response.entryFound) {
        API.chooseMasterFileForContent().then((response) => {
          setMasterFileList(response.masterFileData);
          setSpaceFound(true);
          setDataLoading(false);
        });
      } else {
        setSpaceFound(false);
        setDataLoading(false);
      }
    });
  }, []);

  const searchAction = (searchPass: any) => {
    setSearchText(searchPass);
    setDataLoading(true);
    setSelectedMasterFile({ masterFileName: "", _id: "" });
    API.chooseMasterFileForContent(searchPass).then((response) => {
      setMasterFileList(response.masterFileData);
      setDataLoading(false);
      setDataLoading(false);
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Choose Master File" />
        {spaceFound && (
          <>
            <Box
              sx={{
                width: 800,

                marginBottom: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CUIPresets.SearchBar
                placeholder={"Search Master Files"}
                textFieldProps={{
                  onChange: (e: any) => searchAction(e.target.value),
                  value: searchText,
                }}
              />
            </Box>

            {!dataLoading &&
              masterFileList?.map((item: any, index: any) => (
                <CUIPresets.ChooseMasterFileRow
                  masterFileName={item.masterFileName}
                  selected={selectedMasterFile.masterFileName}
                  onSelected={() => {
                    setSelectedMasterFile(item);
                  }}
                />
              ))}

            {!dataLoading && masterFileList.length === 0 && (
              <CTableRow.FlexTableRow contentArray={["No Data"]} />
            )}

            {selectedMasterFile.masterFileName !== "" && (
              <CComposite.FooterActions.Outlined
                onClick={() =>
                  navigate(
                    PATHS.CREATOR_CREATE_CONTENT + "/" + selectedMasterFile._id
                  )
                }
                sx={{
                  mt: 4,
                  mb: 4,
                  width: 800,
                }}
                confirmText={"Continue"}
              />
            )}

            <Box sx={{ height: "30px" }} />
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
        {!dataLoading && !spaceFound && (
          <>
            <CText.Jakarta.Size20.Mid.Grey6 text="You've to create space configuation before creating content." />
            <CText.Jakarta.Size20.Mid.Grey6 style={{ marginTop: 12 }} text="You can find it in Creator Space -> Space Configuration" />
          </>
        )}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorChooseMasterFile;
