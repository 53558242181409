// React Imports
import React, { FC } from "react";

// MUI Imports
import {
  Button as MUIButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Colors from "../../utils/Colors";
import CUIPresets from "../CUIPresets";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

interface CustomDialogProps {
  title?: any;
  subtitle?: any;
  cancelCall?: any;
  cancelText?: any;
  confirmCall?: any;
  confirmText?: any;
  modalOpen?: any;
}

const CustomDialog: FC<CustomDialogProps> = (props) => {
  return (
    <Dialog
      open={props.modalOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.cancelCall}
    >
      <DialogTitle
        sx={{
          backgroundColor: Colors.blueBackground,
          color: "white",
          height: "25px",
        }}
      >
        {props.title}
      </DialogTitle>
      <DialogContent sx={{ minWidth: "320px", mt: 2 }}>
        <DialogContentText
          sx={{
            fontWeight: "500",
            color: "black",
          }}
        >
          {props.subtitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          // border: '2px solid',
          backgroundColor: "#EEE",
        }}
      >
        {props.cancelText?.length > 0 && (
          <MUIButton
            sx={{
              color: Colors.black,
            }}
            onClick={props.cancelCall}
          >
            {props.cancelText}
          </MUIButton>
        )}
        <CUIPresets.FilledButton
          title={props.confirmText}
          color={Colors.blueBackground}
          sx={{ width: 100, height: 40, textTransform: "none" }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
