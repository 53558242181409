// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useParams } from "react-router-dom";
import API from "../../api/API";
import Styles from "../../utils/Styles";

// Local Imports
import CText from "../../atoms/CText";
import CUIPresets from "../../atoms/CUIPresets";

const contentDetailsInit = {
  adminAllow: false,
  contentDetails: {
    name: "",
    coverPhoto: "",
    countryArray: [],
  },
  masterFile: {
    masterFileName: "",
    adSlots: [],
    duration: "",
  },
  streamLinks: [],
  activeContracts: [],
  suggestions: [],
};

interface AdminCreatorContentDetailsProps {}

const AdminCreatorContentDetails: FC<AdminCreatorContentDetailsProps> = (
  props
) => {
  const { id } = useParams();

  const [contentDetails, setContentDetails] = useState(contentDetailsInit);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    apiCall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apiCall = () => {
    setDataLoading(true);
    API.getContentByIdAdmin(id).then((response) => {
      setDataLoading(false);
      setContentDetails(response.content);
    });
  };

  let countriesEnabled = "";
  let adSlots = "";
  let suggestions = "";
  let activeContractLabelValues: any = [];

  contentDetails.contentDetails?.countryArray?.map((item: any) => {
    countriesEnabled = countriesEnabled + " | " + item.countryName;

    return 0;
  });

  contentDetails.masterFile?.adSlots?.map((item) => {
    adSlots = adSlots + " | " + item;

    return 0;
  });

  contentDetails.suggestions?.map((item: any) => {
    suggestions = suggestions + " |\n " + item.contentDetails?.name;

    return 0;
  });

  contentDetails.activeContracts?.map((item: any) => {
    item.contracts?.map((itemChild: any) => {
      if (itemChild.activeContract) {
        activeContractLabelValues.push({
          label: "ID",
          value: itemChild.contract?._id,
        });
        activeContractLabelValues.push({
          label: "Ad Slot Details",
          value:
            itemChild.contract?.adSlotDetails?.selectedCountryCode +
            " | " +
            itemChild.contract?.adSlotDetails?.selectedAdSlot,
        });

        contentDetails.streamLinks?.map((item: any) => {
          if (
            itemChild.contract?.adSlotDetails?.selectedCountryCode ===
            item.countryCode
          ) {
            activeContractLabelValues.push({
              label: "Stream File",
              value: item.streamLink,
            });
          }

          return 0;
        });
      }
      return 0;
    });

    return 0;
  });

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title={"Content Details"} />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <Box
            sx={{
              ...Styles.SStyles.RowCenterSpace,
              width: 800,
            }}
          >
            <CUIPresets.PageHeader title="Content Details" />

            <CUIPresets.OutlinedButton
              sx={{
                mt: 1,
              }}
              title={"Admin Allow"}
              onClick={() => {
                const payload = {
                  type: "content",
                  id: id,
                  newAdminAllowValue: !contentDetails.adminAllow,
                };

                API.updateAdminAllow(payload).then((response) => {
                  apiCall();
                });
              }}
            />
          </Box>

          <CUIPresets.LabelValueHorizontal
            label={"Content Name"}
            value={contentDetails.contentDetails?.name}
          />

          <CUIPresets.LabelValueHorizontal
            label={"Countries Enabled"}
            value={countriesEnabled}
          />

          <CUIPresets.LabelValueHorizontal
            label={"Admin Allow"}
            value={contentDetails.adminAllow?.toString()}
          />

          <CUIPresets.LabelImageHorizontal
            label={"Cover Photo"}
            src={contentDetails.contentDetails?.coverPhoto}
          />

          <CUIPresets.LabelValueHorizontal
            label={"Suggestions"}
            value={suggestions === "" ? "No Suggestions" : suggestions}
          />

          <CText.Roboto.Size28.Light.BlackUnderline
            style={{
              marginBottom: 16,
              marginTop: 12,
            }}
            text="Master File Details"
          />

          <CUIPresets.LabelValueHorizontal
            label={"Master File"}
            value={contentDetails.masterFile?.masterFileName}
          />

          <CUIPresets.LabelValueHorizontal
            label={"Content Duration"}
            value={contentDetails.masterFile?.duration}
          />

          <CUIPresets.LabelValueHorizontal label={"Ad Slots"} value={adSlots} />

          <CText.Roboto.Size28.Light.BlackUnderline
            style={{
              marginBottom: 16,
              marginTop: 12,
            }}
            text="Active Contracts"
          />

          {activeContractLabelValues.length === 0 && (
            <CText.Jakarta.Size18.Mid.Black text="No Active Contracts" />
          )}

          {activeContractLabelValues.map((item: any) => {
            if (item.label === "Stream File") {
              return (
                <CUIPresets.LabelIframeHorizontal
                  label={"Stream File"}
                  iframe={item.value}
                />
              );
            } else {
              return (
                <CUIPresets.LabelValueHorizontal
                  label={item.label}
                  value={item.value}
                />
              );
            }
          })}
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdminCreatorContentDetails;
