// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Grid } from "@mui/material";

// Functional Imports
import { useSelector } from "react-redux";
import API from "../../api/API";

// Local Imports
import CComposite from "../../atoms/CComposite";
import CUIPresets from "../../atoms/CUIPresets";

interface SubscriptionContentProps {
  grid?: any;
}

const SubscriptionContent: FC<SubscriptionContentProps> = (props) => {
  const grid = useSelector((state: any) => state.appState.grid);

  const [dataLoading, setDataLoading] = useState(false);
  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    API.getAllSubscribedContent().then((response) => {
      setDataLoading(false);
      setContentList(response.subscriptionContent);
    });
  }, []);

  return (
    <>
      <Grid container sx={{ mt: 2, mb: 4 }}>
        {dataLoading && <CUIPresets.ViewerLoading sx={{ mt: 12 }} />}
        {!dataLoading && contentList.length === 0 && (
          <CUIPresets.ViewerStatusText text="No Content" />
        )}
        {!dataLoading && contentList.length > 0 && (
          <Grid container sx={{ width: "100%" }}>
            <CComposite.ContentList grid={grid} contentList={contentList} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SubscriptionContent;
