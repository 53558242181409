// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import {
  DateFunctions,
  StringManipulation,
} from "../../utils/Functions";
import API from "../../api/API";
import moment from "moment";
import Arrays from "../../utils/Arrays";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";
import CInputOutput from "../../atoms/CInputOutput";

interface AdminCreatorRequestsProps {}

const AdminCreatorRequests: FC<AdminCreatorRequestsProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [requestList, setRequestList] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [filterValue, setFilterValue] = useState(
    Arrays.Filters.ADMIN_CONTRACT_REQUESTS[0].typeId
  );
  const [dateValue, setDateValue] = useState(null);

  useEffect(() => {
    setDataLoading(true);

    API.getContractRequestsAdmin(id, "", "", "").then((response) => {
      populateData(response.requests);
      setDataLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyParams = () => {
    setDataLoading(true);
    let tempFilter = "";

    if (filterValue !== "all") {
      tempFilter = filterValue;
    }

    let date = "";
    if (dateValue !== null) {
      date = DateFunctions.format(dateValue, "YYYY-MM-DD");
    }

    API.getContractRequestsAdmin(id, "", tempFilter, date).then((response) => {
      populateData(response.requests);
      setDataLoading(false);
    });
  };

  const populateData = (dataArray: any) => {
    let pushData: any = [];
    dataArray.map((item: any) => {
      pushData.push([
        DateFunctions.format(item.createdAt),
        StringManipulation.stringLimiter(
          item.entityDetails?.content?.contentDetails?.name,
          12
        ),
        StringManipulation.stringLimiter(
          item.entityDetails?.advertiser?.fullName,
          12
        ),
        item.contractTerms?.typeOfContract,
        String(item.contractTerms.rateOramount),
        <CUIPresets.RightArrowAvatarWhite
          onClick={() =>
            navigate(PATHS.ADMIN_CONTRACT_REQUEST_DETAILS + "/" + item._id)
          }
          sx={{
            marginLeft: 2,
          }}
        />,
      ]);

      return 0;
    });

    setRequestList(pushData);
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title={"Contract Requests - Creator"} />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title={"Contract Requests - Creator"} />

          <Box
            sx={{
              width: 900,
              marginBottom: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CInputOutput.DatePicker.Horizontal
              error={false}
              touched={false}
              label=""
              value={moment(dateValue)}
              setValue={(newDate: any) => setDateValue(newDate)}
              sx={{ mt: 0, ml: -1 }}
              innerSx={{ width: 200 }}
            />

            <CUIPresets.SortBox
              options={Arrays.Filters.ADMIN_CONTRACT_REQUESTS}
              label="Filter By"
              xsKey="typeId"
              xsValue="type"
              selected={filterValue}
              setValue={(e: any) => setFilterValue(e.target.value)}
            />

            <CUIPresets.OutlinedButton
              onClick={() => applyParams()}
              title={"Apply"}
              color={Colors.blueBackground}
              sx={{ width: 100, height: 40, textTransform: "none" }}
            />
          </Box>

          <CTableRow.FlexTableTopCustom
            sx={{ width: 900 }}
            flexArray={[1, 1, 1, 1, 1, 0.5]}
            titleArray={Arrays.Titles.CreatorOpenRequests}
          />
          {requestList.map((item) => (
            <CTableRow.FlexTableRowCustom
              sx={{ width: 900 }}
              flexArray={[1, 1, 1, 1, 1, 0.5]}
              contentArray={item}
            />
          ))}
          {requestList?.length === 0 && (
            <CTableRow.FlexTableRow
              sx={{ width: 900 }}
              contentArray={["No Data"]}
            />
          )}
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdminCreatorRequests;
