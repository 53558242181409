import LogoBlue from "../assets/images/logo-blue.png";
import LogoWhite from "../assets/images/logo-white.png";
import AuthBG from "../assets/images/auth-bg.jpg";
import BlueEllipse from "../assets/images/blue-ellipse.png";
import PurpleEllipse from "../assets/images/purple-ellipse.png";
import UploadSuccessful from "../assets/images/upload-successful.png";
import ClientBackground from "../assets/illustrations/login-background.jpg";

const Images = {
  LogoBlue,
  LogoWhite,
  AuthBG,
  BlueEllipse,
  PurpleEllipse,
  UploadSuccessful,
  ClientBackground,
};

export default Images;
