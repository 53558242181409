// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { ToastGenerator } from "../../utils/Functions";
import { useParams } from "react-router-dom";
import API from "../../api/API";
import CUIPresets from "../../atoms/CUIPresets";
import Constants from "../../utils/Constants";
import Styles from "../../utils/Styles";

// Local Imports
import CText from "../../atoms/CText";
import CTableRow from "../../atoms/CTableRow";

interface ContentSuggestionsProps {}

const ContentSuggestions: FC<ContentSuggestionsProps> = (props) => {
  const { id } = useParams();

  const [dataLoading, setDataLoading] = useState({
    content: false,
    suggestions: false,
  });
  const [allContent, setAllContent]: any = useState([]);

  const [contentListIn, setContentListIn] = useState([]);
  const [contentListOut, setContentListOut] = useState([]);

  const [suggestionArray, setSuggestionArray]: any = useState([]);
  const [suggestionsId, setSuggestionsId] = useState("");

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  useEffect(() => {
    setDataLoading({
      content: true,
      suggestions: true,
    });
    API.getContentList("", "").then((response: any) => {
      const excludedParentArray = response.contentList.filter(
        (item: any) => item._id !== id
      );

      setAllContent([...excludedParentArray]);
      setDataLoading({
        ...dataLoading,
        content: false,
      });
    });

    API.getContentSuggestion(id).then((response) => {
      setDataLoading({
        ...dataLoading,
        suggestions: false,
      });
      if (response.entryFound) {
        let suggestions: any = [];
        setSuggestionsId(response.contentSuggestion?._id);

        response.contentSuggestion?.suggestedContent?.map((item: any) => {
          suggestions.push(item._id);

          return 0;
        });

        setSuggestionArray([...suggestions]);
      } else {
        const tempRef: any = [];

        setSuggestionArray([...tempRef]);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { inArray, outArray } = distributeContent(
      allContent,
      suggestionArray
    );

    setContentListIn(inArray);
    setContentListOut(outArray);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionArray, allContent]);

  const handleCheck = (content: any, currentStatus: any) => {
    if (currentStatus === "out") {
      // out -> in

      if (contentListIn.length === 3) {
        ToastGenerator.info("Maximum suggestions can be 3!");
        return;
      }

      const newSuggestionArray = [...suggestionArray, content._id];
      setSuggestionArray([...newSuggestionArray]);
    } else if (currentStatus === "in") {
      // in -> out
      const newSuggestionArray = suggestionArray.filter(
        (item: any) => item !== content._id
      );
      setSuggestionArray([...newSuggestionArray]);
    }
  };

  const confirmModalCall = () => {
    setModal({
      open: true,
      apiLoading: true,
    });

    if (suggestionsId === "") {
      const payload = {
        contentId: id,
        suggestion: suggestionArray,
      };

      API.createContentSuggestion(payload).then((response) => {
        if (
          response.message ===
          Constants.API_RESPONSES.CONTENT_SUGGESTION_CREATE_SUCCESS
        ) {
          ToastGenerator.success(response.message);
          setModal({
            open: false,
            apiLoading: false,
          });
        }
      });
    } else {
      const payload = {
        suggestion: suggestionArray,
      };

      API.updateContentSuggestion(suggestionsId, payload).then((response) => {
        if (
          response.message ===
          Constants.API_RESPONSES.CONTENT_SUGGESTION_UPDATE_SUCCESS
        ) {
          ToastGenerator.success(response.message);
          setModal({
            open: false,
            apiLoading: false,
          });
        }
      });
    }
  };

  if (dataLoading.content || dataLoading.suggestions) {
    return <CUIPresets.LoadingSpinner />;
  } else {
    return (
      <>
        {contentListIn.length > 0 && (
          <CText.Jakarta.Size16.Mid.Black
            style={{ marginBottom: 8 }}
            text={"Selected Suggestions"}
          />
        )}

        {contentListIn?.map((item: any) => (
          <CTableRow.CreatorContentCheckRow
            image={item.contentDetails?.coverPhoto}
            title={item.contentDetails?.name}
            checked={true}
            onClick={() => handleCheck(item, "in")}
          />
        ))}

        {contentListOut.length > 0 && (
          <CText.Jakarta.Size16.Mid.Black
            style={{ marginTop: 8, marginBottom: 8 }}
            text={"Options"}
          />
        )}

        {contentListOut?.map((item: any) => (
          <CTableRow.CreatorContentCheckRow
            image={item.contentDetails?.coverPhoto}
            title={item.contentDetails?.name}
            checked={false}
            onClick={() => handleCheck(item, "out")}
          />
        ))}

        {(contentListIn.length > 0 || contentListOut.length > 0) && (
          <Box
            sx={{
              width: 800,
              ...Styles.SStyles.RowCenterEnd,
              mt: 2,
              mb: 2,
            }}
          >
            <CUIPresets.FilledButton
              onClick={() => {
                if (contentListIn.length === 0) {
                  ToastGenerator.info("Need at least 1 suggestion!");
                  return;
                }

                setModal({
                  open: true,
                  apiLoading: false,
                });
              }}
              title={suggestionsId === "" ? "Create" : "Update"}
            />
          </Box>
        )}
        <CUIPresets.CustomDialog
          title="Content Suggestions"
          content={[
            "Are you sure about " +
              (suggestionsId === "" ? "creating" : "updating") +
              " content suggestions?",
          ]}
          confirmText={"Confirm"}
          cancelText="Cancel"
          confirmCall={confirmModalCall}
          modalOpen={modal.open}
          cancelCall={() =>
            setModal({
              open: false,
              apiLoading: false,
            })
          }
          apiLoading={modal.apiLoading}
        />
      </>
    );
  }
};

export default ContentSuggestions;

const distributeContent = (contentList: any, suggestionArray: any) => {
  const inArray: any = [];
  const outArray: any = [];

  contentList.map((item: any) => {
    let count = 0;

    suggestionArray.map((itemChild: any) => {
      if (itemChild === item._id) {
        count++;
      }

      return 0;
    });

    if (count > 0) {
      inArray.push(item);
    } else {
      outArray.push(item);
    }

    return 0;
  });

  return {
    inArray,
    outArray,
  };
};
