// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import { DateFunctions } from "../../utils/Functions";
import API from "../../api/API";
import Styles from "../../utils/Styles";
import Arrays from "../../utils/Arrays";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";
import CText from "../../atoms/CText";

interface CreatorFundsOverviewProps {}

const CreatorFundsOverview: FC<CreatorFundsOverviewProps> = (props) => {
  const navigate = useNavigate();

  const [paymentRequests, setPaymentRequests] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [walletDetails, setWalletDetails] = useState({
    walletExists: false,
    walletBalance: 0,
  });

  useEffect(() => {
    API.getWalletBalance().then((response) => {
      setWalletDetails({
        walletExists: response.walletFound,
        walletBalance: response.wallet?.balance,
      });
    });

    setDataLoading(true);
    API.getPaymentRequestsCreator().then((response) => {
      setDataLoading(false);
      populateData(response.paymentRequests);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateData = (dataArray: any) => {
    let pushData: any = [];

    dataArray.map((item: any) => {
      pushData.push([
        DateFunctions.format(item.requestDetails?.createdAt),
        item.requestDetails?.amountInSSC?.toString(),
        <CUIPresets.ChipSelect.AdvertiserFunds status={item.status} />,
        <CUIPresets.RightArrowAvatarWhite
          onClick={() => {
            navigate(PATHS.CREATOR_PAYMENT_REQUEST + "/" + item._id);
          }}
          sx={{
            marginLeft: 2,
          }}
        />,
      ]);

      return 0;
    });

    setPaymentRequests(pushData);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Funds Overview" />

        <Box
          sx={{
            width: 895,
            mb: 3,
            ...Styles.SStyles.RowCenterSpace,
          }}
        >
          <CText.Jakarta.Size18.SemiBold.Black
            text={
              walletDetails.walletExists
                ? "Balance: " + walletDetails.walletBalance + " SSC"
                : ""
            }
          />

          <Box
            sx={{
              ...Styles.SStyles.RowCenterCenter,
            }}
          >
            <CUIPresets.OutlinedButton
              onClick={() => navigate(PATHS.CREATOR_PAYMENT_REQUEST + "/0")}
              sx={{
                mr: 2,
              }}
              title={"Payment Request"}
            />
          </Box>
        </Box>

        {!dataLoading && (
          <>
            <CTableRow.FlexTableTop
              sx={{
                width: 880,
              }}
              titleArray={Arrays.Titles.CreatorFundsOverview}
            />
            {paymentRequests.map((item) => (
              <CTableRow.FlexTableRow
                sx={{
                  width: 880,
                }}
                contentArray={item}
              />
            ))}
            {paymentRequests.length === 0 && (
              <CTableRow.FlexTableRow
                sx={{
                  width: 880,
                }}
                contentArray={["No Data"]}
              />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorFundsOverview;
