// React Imports
import { FC } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { Routes, Route, Navigate } from "react-router-dom";
import { PATHS } from "./Paths";
import Colors from "../utils/Colors";

// Creator Screens
import CreateMasterFiles from "../components/CreatorMasterFiles";
import CreatorUploadNewVideo from "../components/CreatorUploadNewVideo";
import CreatorManageMasterFiles from "../components/CreatorManageMasterFiles";
import CreatorMasterFileDetails from "../components/CreatorMasterFileDetails";
import CreatorCreatorSpace from "../components/CreatorCreatorSpace";
import CreatorSpaceConfiguration from "../components/CreatorSpaceConfiguration";
import CreatorContentManagement from "../components/CreatorContentManagement";
import CreatorChooseMasterFile from "../components/CreatorChooseMasterFile";
import CreatorCreateContent from "../components/CreatorCreateContent";
import CreatorContentList from "../components/CreatorContentList";
import CreatorContentDetails from "../components/CreatorContentDetails";
import CreatorAdvertisements from "../components/CreatorAdvertisements";
import CreatorRequests from "../components/CreatorRequests";
import CreatorRequestDetails from "../components/CreatorRequestDetails";
import CreatorCreateNewSubspace from "../components/CreatorCreateNewSubspace";
import CreatorManageSubspaces from "../components/CreatorManageSubspaces";
import CreatorViewSubspace from "../components/CreatorViewSubspace";
import CreatorFundManagement from "../components/CreatorFundManagement";
import CreatorTransactionsDate from "../components/CreatorTransactionsDate";
import CreatorContracts from "../components/CreatorContracts";
import CreatorContractList from "../components/CreatorContractList";
import CreatorContractDetails from "../components/CreatorContractDetails";
import CreatorOverview from "../components/CreatorOverview";
import CreatorOverviewOptions from "../components/CreatorOverviewOptions";
import CreatorContentOverview from "../components/CreatorContentOverview";
import CreatorDetailedOverview from "../components/CreatorDetailedOverview";
import CreatorSpaceOverview from "../components/CreatorSpaceOverview";
import CreatorContentWatchtimeDetails from "../components/CreatorContentWatchtimeDetails";
import CreatorFundsOverview from "../components/CreatorFundsOverview";
import CreatorRaisePaymentRequests from "../components/CreatorRaisePaymentRequests";
import CreatorProfile from "../components/CreatorProfile";
import CreatorProfileInfo from "../components/CreatorProfileInfo";
import CreatorChangePassword from "../components/CreatorChangePassword";
import CreatorHome from "../components/CreatorHome";

// Advertiser Screens
import AdvertiserAdCatalogue from "../components/AdvertiserAdCatalogue";
import AdvertiserUploadNewAd from "../components/AdvertiserUploadNewAd";
import AdvertiserManageAdFiles from "../components/AdvertiserManageAdFiles";
import AdvertiserAdFileDetails from "../components/AdvertiserAdFileDetails";
import AdvertiserFundsManagement from "../components/AdvertiserFundsManagement";
import AdvertiserFundsOverview from "../components/AdvertiserFundsOverview";
import AdvertiserAddFundsRequest from "../components/AdvertiserAddFundsRequest";
import AdvertiserWithdrawFundsRequest from "../components/AdvertiserWithdrawFundsRequest";
import AdvertiserCreator from "../components/AdvertiserCreator";
import AdvertiserSearchCreator from "../components/AdvertiserSearchCreator";
import AdvertiserSelectContent from "../components/AdvertiserSelectContent";
import AdvertiserContractRequestForm from "../components/AdvertiserContractRequestForm";
import AdvertiserRequestManagement from "../components/AdvertiserRequestManagement";
import AdvertiserRequests from "../components/AdvertiserRequests";
import AdvertiserRequestDetails from "../components/AdvertiserRequestDetails";
import AdvertiserContracts from "../components/AdvertiserContracts";
import AdvertiserTransactionsDate from "../components/AdvertiserTransactionsDate";
import AdvertiserContractDetails from "../components/AdvertiserContractDetails";
import AdvertiserProfile from "../components/AdvertiserProfile";
import AdvertiserChangePassword from "../components/AdvertiserChangePassword";
import AdvertiserProfileInfo from "../components/AdvertiserProfileInfo";
import AdvertiserContractTypes from "../components/AdvertiserContractTypes";
import AdvertiserContractList from "../components/AdvertiserContractList";
import AdvertiserCreatorWithContracts from "../components/AdvertiserCreatorWithContracts";
import AdvertiserHome from "../components/AdvertiserHome";

// Admin Screens
import AdminPayments from "../components/AdminPayments";
import AdminAddFundsRequests from "../components/AdminAddFundsRequests";
import AdminAddFundsRequestsList from "../components/AdminAddFundsRequestsList";
import AdminAdvertiserSearch from "../components/AdminAdvertiserSearch";
import AdminAddFundsRequestDetails from "../components/AdminAddFundsRequestDetails";
import AdminWithdrawFundsRequests from "../components/AdminWithdrawFundsRequests";
import AdminWithdrawFundsRequestsList from "../components/AdminWithdrawFundsRequestsList";
import AdminWithdrawFundsRequestDetails from "../components/AdminWithdrawFundsRequestDetails";
import AdminCreatorSearch from "../components/AdminCreatorSearch";
import AdminCreatorAccess from "../components/AdminCreatorAccess";
import AdminCreatorViewMasterFiles from "../components/AdminCreatorViewMasterFiles";
import AdminCreatorRequests from "../components/AdminCreatorRequests";
import AdminContractRequestDetails from "../components/AdminContractRequestDetails";
import AdminCreatorMasterFileDetails from "../components/AdminCreatorMasterFileDetails";
import AdminCreatorViewSubspaces from "../components/AdminCreatorViewSubspaces";
import AdminCreatorSubspaceDetails from "../components/AdminCreatorSubspaceDetails";
import AdminCreatorViewContent from "../components/AdminCreatorViewContent";
import AdminCreatorContentDetails from "../components/AdminCreatorContentDetails";
import AdminViewerSearch from "../components/AdminViewerSearch";
import AdminViewerAccess from "../components/AdminViewerAccess";
import AdminViewerContentList from "../components/AdminViewerContentList";
import AdminAdvertiserAccess from "../components/AdminAdvertiserAccess";
import AdminAdvertiserViewAdFiles from "../components/AdminAdvertiserViewAdFiles";
import AdminAdvertiserAdFileDetails from "../components/AdminAdvertiserAdFileDetails";
import AdminWatchtimeRates from "../components/AdminWatchtimeRates";
import AdminCurrencyConversion from "../components/AdminCurrencyConversion";
import AdminPaymentRequests from "../components/AdminPaymentRequests.tsx";
import AdminPaymentRequestsList from "../components/AdminPaymentRequestsList";
import AdminPaymentRequestDetails from "../components/AdminPaymentRequestDetails";
import AdminProfile from "../components/AdminProfile";
import AdminChangePassword from "../components/AdminChangePassword";
import AdminWatchtimeBills from "../components/AdminWatchtimeBills";
import AdminWatchtimeBillsList from "../components/AdminWatchtimeBillsList";
import AdminWatchtimeBillDetails from "../components/AdminWatchtimeBillDetails";
import AdminWatchtimeBillProofDetails from "../components/AdminWatchtimeBillProofDetails";

// Viewer Screens
import ViewerNavigateTo from "../components/ViewerNavigateTo";
import ViewerSearch from "../components/ViewerSearch";
import ViewerSpace from "../components/ViewerSpace";
import ViewerSubspace from "../components/ViewerSubspace";
import ViewerPlayer from "../components/ViewerPlayer";
import ViewerSubscriptions from "../components/ViewerSubscriptions";
import ViewerMySpace from "../components/ViewerMySpace";
import ViewerRecentlyUploaded from "../components/ViewerRecentlyUploaded";
import ViewerMostPopular from "../components/ViewerMostPopular";
import ViewerTrending from "../components/ViewerTrending";
import ViewerAuth from "../components/ViewerAuth";
import ViewerProfile from "../components/ViewerProfile";

// No Authentication Screens
import Authentication from "../components/LoginPage";
import LandingPage from "../components/LandingPage";
import AdminWatchtimeBillProofList from "../components/AdminWatchtimeBillProofList";
import CreatorWatchtimeBills from "../components/CreatorWatchtimeBills";
import CreatorWatchtimeBillDetails from "../components/CreatorWatchtimeBillDetails";
import CreatorWatchtimeUploadProof from "../components/CreatorWatchtimeUploadProof";

interface RoutePathProps {}

const Creator: FC<RoutePathProps> = (props) => {
  return (
    <Routes>
      <Route
        path={PATHS.CREATOR_ADVERTISEMENTS}
        element={<CreatorAdvertisements />}
      />
      <Route
        path={PATHS.CREATOR_CREQUESTS + "/:type"}
        element={<CreatorRequests />}
      />
      <Route
        path={PATHS.CREATOR_CREQUEST_DETAILS + "/:id"}
        element={<CreatorRequestDetails />}
      />
      <Route
        path={PATHS.CREATOR_CONTENT_MANAGEMENT}
        element={<CreatorContentManagement />}
      />
      <Route
        path={PATHS.CREATOR_CHOOSE_MASTER_FILE}
        element={<CreatorChooseMasterFile />}
      />
      <Route
        path={PATHS.CREATOR_CREATE_CONTENT + "/:id"}
        element={<CreatorCreateContent />}
      />
      <Route
        path={PATHS.CREATOR_CONTENT_LIST + "/:type"}
        element={<CreatorContentList />}
      />
      <Route
        path={PATHS.CREATOR_CONTENT_DETAILS + "/:id"}
        element={<CreatorContentDetails />}
      />
      <Route
        path={PATHS.CREATOR_MASTER_FILES}
        element={<CreateMasterFiles />}
      />
      <Route
        path={PATHS.CREATOR_UPLOAD_MASTER_FILE}
        element={<CreatorUploadNewVideo />}
      />
      <Route
        path={PATHS.CREATOR_MANAGE_MASTER_FILES}
        element={<CreatorManageMasterFiles />}
      />
      <Route
        path={PATHS.CREATOR_MASTER_FILE_DETAILS + "/:id"}
        element={<CreatorMasterFileDetails />}
      />
      <Route
        path={PATHS.CREATOR_CREATOR_SPACE}
        element={<CreatorCreatorSpace />}
      />
      <Route
        path={PATHS.CREATOR_CREATE_NEW_SUBSPACE + "/:id"}
        element={<CreatorCreateNewSubspace />}
      />
      <Route
        path={PATHS.CREATOR_UPDATE_SUBSPACE + "/:id"}
        element={<CreatorCreateNewSubspace />}
      />
      <Route
        path={PATHS.CREATOR_MANAGE_SUBSPACES}
        element={<CreatorManageSubspaces />}
      />
      <Route
        path={PATHS.CREATOR_VIEW_SUBSPACE + "/:id"}
        element={<CreatorViewSubspace />}
      />
      <Route
        path={PATHS.CREATOR_SPACE_CONFIGURATION}
        element={<CreatorSpaceConfiguration />}
      />
      <Route path={PATHS.CREATOR_OVERVIEW} element={<CreatorOverview />} />
      <Route
        path={PATHS.CREATOR_OVERVIEWS}
        element={<CreatorOverviewOptions />}
      />
      <Route
        path={PATHS.CREATOR_CONTENT_OVERVIEW}
        element={<CreatorContentOverview />}
      />
      <Route
        path={PATHS.CREATOR_DETAILED_OVERVIEW + "/:id"}
        element={<CreatorDetailedOverview />}
      />
      <Route
        path={PATHS.CREATOR_SPACE_OVERVIEW}
        element={<CreatorSpaceOverview />}
      />
      <Route
        path={PATHS.CREATOR_WATCHTIME_DETAILS + "/:id"}
        element={<CreatorContentWatchtimeDetails />}
      />
      <Route
        path={PATHS.CREATOR_FUND_MANAGEMENT}
        element={<CreatorFundManagement />}
      />
      <Route
        path={PATHS.CREATOR_TRANSACTIONS}
        element={<CreatorTransactionsDate />}
      />
      <Route path={PATHS.CREATOR_CONTRACTS} element={<CreatorContracts />} />
      <Route
        path={PATHS.CREATOR_CONTRACT_LIST + "/:id"}
        element={<CreatorContractList />}
      />
      <Route
        path={PATHS.CREATOR_CONTRACT_DETAILS + "/:id"}
        element={<CreatorContractDetails />}
      />
      <Route path={PATHS.CREATOR_PROFILE} element={<CreatorProfile />} />
      <Route
        path={PATHS.CREATOR_PROFILE_INFO}
        element={<CreatorProfileInfo />}
      />
      <Route
        path={PATHS.CREATOR_CHANGE_PASSWORD}
        element={<CreatorChangePassword />}
      />
      <Route path={PATHS.CREATOR_HOME} element={<CreatorHome />} />
      <Route
        path={PATHS.CREATOR_FUNDS_OVERVIEW}
        element={<CreatorFundsOverview />}
      />
      <Route
        path={PATHS.CREATOR_PAYMENT_REQUEST + "/:id"}
        element={<CreatorRaisePaymentRequests />}
      />
      <Route
        path={PATHS.CREATOR_WATCHTIME_BILLS}
        element={<CreatorWatchtimeBills />}
      />
      <Route
        path={PATHS.CREATOR_WATCHTIME_BILL + "/:id"}
        element={<CreatorWatchtimeBillDetails />}
      />
      <Route
        path={PATHS.CREATOR_WATCHTIME_BILL_PROOF_UPLOAD + "/:id"}
        element={<CreatorWatchtimeUploadProof />}
      />
      <Route path="*" element={<Navigate to={PATHS.CREATOR_HOME} />} />
    </Routes>
  );
};

const Advertiser: FC<RoutePathProps> = (props) => {
  return (
    <Routes>
      <Route path={PATHS.ADVERTISER_CREATORS} element={<AdvertiserCreator />} />
      <Route
        path={PATHS.ADVERTISER_SEARCH_CREATOR}
        element={<AdvertiserSearchCreator />}
      />
      <Route
        path={PATHS.ADVERTISER_SELECT_CONTENT + "/:id"}
        element={<AdvertiserSelectContent />}
      />
      <Route
        path={PATHS.ADVERTISER_CONTRACT_REQUEST + "/:id"}
        element={<AdvertiserContractRequestForm />}
      />
      <Route
        path={PATHS.ADVERTISER_AD_CATALOGUE}
        element={<AdvertiserAdCatalogue />}
      />
      <Route
        path={PATHS.ADVERTISER_UPLOAD_NEW_AD}
        element={<AdvertiserUploadNewAd />}
      />
      <Route
        path={PATHS.ADVERTISER_MANAGE_AD_FILES}
        element={<AdvertiserManageAdFiles />}
      />
      <Route
        path={PATHS.ADVERTISER_VIEW_AD_DETAILS + "/:id"}
        element={<AdvertiserAdFileDetails />}
      />
      <Route
        path={PATHS.ADVERTISER_FUNDS_MANAGEMENT}
        element={<AdvertiserFundsManagement />}
      />
      <Route
        path={PATHS.ADVERTISER_FUNDS_OVERVIEW}
        element={<AdvertiserFundsOverview />}
      />
      <Route
        path={PATHS.ADVERTISER_ADD_FUNDS_REQUEST + "/:id"}
        element={<AdvertiserAddFundsRequest />}
      />
      <Route
        path={PATHS.ADVERTISER_WITHDRAW_FUNDS_REQUEST + "/:id"}
        element={<AdvertiserWithdrawFundsRequest />}
      />
      <Route
        path={PATHS.ADVERTISER_REQUEST_MANAGEMENT}
        element={<AdvertiserRequestManagement />}
      />
      <Route
        path={PATHS.ADVERTISER_REQUESTS + "/:type"}
        element={<AdvertiserRequests />}
      />
      <Route
        path={PATHS.ADVERTISER_REQUEST_DETAILS + "/:id"}
        element={<AdvertiserRequestDetails />}
      />
      <Route
        path={PATHS.ADVERTISER_CONTRACTS}
        element={<AdvertiserContracts />}
      />
      <Route
        path={PATHS.ADVERTISER_CONTRACT_TYPES}
        element={<AdvertiserContractTypes />}
      />
      <Route
        path={PATHS.ADVERTISER_TRANSACTIONS}
        element={<AdvertiserTransactionsDate />}
      />
      <Route
        path={PATHS.ADVERTISER_CONTRACT_DETAILS + "/:id"}
        element={<AdvertiserContractDetails />}
      />
      <Route
        path={PATHS.ADVERTISER_CONTRACT_LIST + "/:id"}
        element={<AdvertiserContractList />}
      />
      <Route
        path={PATHS.ADVERTISER_CREATORS_WITH_CONTRACTS}
        element={<AdvertiserCreatorWithContracts />}
      />
      <Route path={PATHS.ADVERTISER_PROFILE} element={<AdvertiserProfile />} />
      <Route
        path={PATHS.ADVERTISER_CHANGE_PASSWORD}
        element={<AdvertiserChangePassword />}
      />
      <Route
        path={PATHS.ADVERTISER_PROFILE_INFO}
        element={<AdvertiserProfileInfo />}
      />
      <Route path={PATHS.ADVERTISER_HOME} element={<AdvertiserHome />} />
      <Route path="*" element={<Navigate to={PATHS.ADVERTISER_HOME} />} />
    </Routes>
  );
};

const Admin: FC<RoutePathProps> = (props) => {
  return (
    <Routes>
      <Route path={PATHS.ADMIN_PAYMENTS} element={<AdminPayments />} />
      <Route
        path={PATHS.ADMIN_ADD_FUNDS_REQUESTS}
        element={<AdminAddFundsRequests />}
      />
      <Route
        path={PATHS.ADMIN_ADD_FUNDS_REQUESTS_LIST + "/:type/:id"}
        element={<AdminAddFundsRequestsList />}
      />
      <Route
        path={PATHS.ADMIN_ADD_FUNDS_REQUESTS_DETAILS + "/:id"}
        element={<AdminAddFundsRequestDetails />}
      />
      <Route
        path={PATHS.ADMIN_ADVERTISER_SEARCH + "/:type"}
        element={<AdminAdvertiserSearch />}
      />
      <Route
        path={PATHS.ADMIN_WITHDRAW_FUNDS_REQUESTS}
        element={<AdminWithdrawFundsRequests />}
      />
      <Route
        path={PATHS.ADMIN_WITHDRAW_FUNDS_REQUESTS_LIST + "/:type/:id"}
        element={<AdminWithdrawFundsRequestsList />}
      />
      <Route
        path={PATHS.ADMIN_WITHDRAW_FUNDS_REQUESTS_DETAILS + "/:id"}
        element={<AdminWithdrawFundsRequestDetails />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_SEARCH + "/:type"}
        element={<AdminCreatorSearch />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_ACCESS + "/:id"}
        element={<AdminCreatorAccess />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_VIEW_MASTER_FILES + "/:id"}
        element={<AdminCreatorViewMasterFiles />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_MASTER_FILE_DETAILS + "/:id"}
        element={<AdminCreatorMasterFileDetails />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_REQUESTS + "/:id"}
        element={<AdminCreatorRequests />}
      />
      <Route
        path={PATHS.ADMIN_CONTRACT_REQUEST_DETAILS + "/:id"}
        element={<AdminContractRequestDetails />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_VIEW_SUBSPACES + "/:id"}
        element={<AdminCreatorViewSubspaces />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_SUBSPACE_DETAILS + "/:id"}
        element={<AdminCreatorSubspaceDetails />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_VIEW_CONTENT + "/:id"}
        element={<AdminCreatorViewContent />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_CONTENT_DETAILS + "/:id"}
        element={<AdminCreatorContentDetails />}
      />
      <Route path={PATHS.ADMIN_VIEWER_SEARCH} element={<AdminViewerSearch />} />
      <Route
        path={PATHS.ADMIN_VIEWER_ACCESS + "/:id"}
        element={<AdminViewerAccess />}
      />
      <Route
        path={PATHS.ADMIN_VIEWER_CONTENT_LIST + "/:type/:id"}
        element={<AdminViewerContentList />}
      />
      <Route
        path={PATHS.ADMIN_ADVERTISER_ACCESS + "/:id"}
        element={<AdminAdvertiserAccess />}
      />
      <Route
        path={PATHS.ADMIN_ADVERTISER_VIEW_AD_FILES + "/:id"}
        element={<AdminAdvertiserViewAdFiles />}
      />
      <Route
        path={PATHS.ADMIN_ADVERTISER_AD_FILE_DETAILS + "/:id"}
        element={<AdminAdvertiserAdFileDetails />}
      />
      <Route
        path={PATHS.ADMIN_WATCHTIME_RATES}
        element={<AdminWatchtimeRates />}
      />
      <Route
        path={PATHS.ADMIN_CURRENCY_CONVERSION}
        element={<AdminCurrencyConversion />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_PAYMENT_REQUESTS}
        element={<AdminPaymentRequests />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_PAYMENT_REQUEST_LIST + "/:type/:id"}
        element={<AdminPaymentRequestsList />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_PAYMENT_REQUEST_LIST + "/:type/:id"}
        element={<AdminPaymentRequestsList />}
      />
      <Route
        path={PATHS.ADMIN_PAYMENT_REQUEST_DETAILS + "/:id"}
        element={<AdminPaymentRequestDetails />}
      />
      <Route path={PATHS.ADMIN_PROFILE} element={<AdminProfile />} />
      <Route
        path={PATHS.ADMIN_CHANGE_PASSWORD}
        element={<AdminChangePassword />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_WATCHTIME_BILLS}
        element={<AdminWatchtimeBills />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_WATCHTIME_BILLS_LIST + "/:type/:id"}
        element={<AdminWatchtimeBillsList />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_WATCHTIME_BILL_DETAILS + "/:id"}
        element={<AdminWatchtimeBillDetails />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_WATCHTIME_BILL_PROOF_DETAILS + "/:id"}
        element={<AdminWatchtimeBillProofDetails />}
      />
      <Route
        path={PATHS.ADMIN_CREATOR_WATCHTIME_BILL_PROOF_LIST + "/:type/:id"}
        element={<AdminWatchtimeBillProofList />}
      />
    </Routes>
  );
};

const Viewer: FC<RoutePathProps> = (props) => {
  return (
    <div
      style={{
        backgroundColor: Colors.blackBackground2,
        height: "100vh",
        width: "100vw",
      }}
    >
      <Routes>
        <Route
          path="/"
          element={<Navigate to={PATHS.VIEWER_NAVIGATE_TO} replace={true} />}
        />
        <Route path={PATHS.VIEWER_NAVIGATE_TO} element={<ViewerNavigateTo />} />
        <Route path={PATHS.VIEWER_SEARCH} element={<ViewerSearch />} />
        <Route path={PATHS.VIEWER_SPACE + "/:id"} element={<ViewerSpace />} />
        <Route path={PATHS.VIEWER_SPACE + "/:id"} element={<ViewerSpace />} />
        <Route
          path={PATHS.VIEWER_SUBSPACE + "/:id"}
          element={<ViewerSubspace />}
        />
        <Route
          path={PATHS.VIEWER_PLAYER + "/:contentid/:subspaceid"}
          element={<ViewerPlayer />}
        />
        <Route
          path={PATHS.VIEWER_SUBSCRIPTIONS}
          element={<ViewerSubscriptions />}
        />
        <Route path={PATHS.VIEWER_MY_SPACE} element={<ViewerMySpace />} />
        <Route
          path={PATHS.VIEWER_RECENTLY_UPLOADED + "/:id"}
          element={<ViewerRecentlyUploaded />}
        />
        <Route
          path={PATHS.VIEWER_MOST_POPULAR + "/:id"}
          element={<ViewerMostPopular />}
        />
        <Route path={PATHS.VIEWER_TRENDING} element={<ViewerTrending />} />
        <Route path={PATHS.VIEWER_PROFILE} element={<ViewerProfile />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

const NoAuth: FC<RoutePathProps> = (props) => {
  return (
    <Routes>
      <Route path={PATHS.ROOT} element={<LandingPage />} />
      <Route path={PATHS.NOAUTH_AUTH + "/:type"} element={<Authentication />} />
      <Route path={PATHS.VIEWER_AUTH} element={<ViewerAuth />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

const RoutePath = {
  Creator,
  Advertiser,
  Admin,
  Viewer,
  NoAuth,
};

export default RoutePath;
