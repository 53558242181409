// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import { HambergerMenu, DocumentUpload } from "iconsax-react";

// Functional Imports
import Colors from "../../utils/Colors";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";

interface AdvertiserAdCatalogueProps {}

const AdvertiserAdCatalogue: FC<AdvertiserAdCatalogueProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Ad Catalogue" disabled />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<DocumentUpload size="60" color={Colors.blueBackground} />}
            title="Upload New Ad"
            onClick={() => navigate(PATHS.ADVERTISER_UPLOAD_NEW_AD)}
          />
          <CUIPresets.SelectFlow
            Icon={<HambergerMenu size="60" color={Colors.blueBackground} />}
            title="Manage Ad Files"
            onClick={() => navigate(PATHS.ADVERTISER_MANAGE_AD_FILES)}
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdvertiserAdCatalogue;
