// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { StringManipulation } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";

interface AdvertiserCreatorWithContractsProps {}

const AdvertiserCreatorWithContracts: FC<
  AdvertiserCreatorWithContractsProps
> = (props) => {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getCreatorsWithContracts().then((response) => {
      setDataLoading(false);
      populateData(response.creators);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateData = (dataArray: any) => {
    const apiData: any = [];

    dataArray?.map((item: any) => {
      apiData.push([
        StringManipulation.stringLimiter(item.creatorDetails?.fullName, 20),
        StringManipulation.stringLimiter(
          item.creatorDetails?.spaceDetails?.configuration?.name,
          24
        ),
        <CUIPresets.RightArrowAvatarWhite
          sx={{ ml: 2 }}
          onClick={() =>
            navigate(
              PATHS.ADVERTISER_CONTRACT_LIST + "/" + item.creatorDetails?._id
            )
          }
        />,
      ]);

      return 0;
    });

    setTableData(apiData);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title={"Creators with Contracts"} />

        {!dataLoading && (
          <>
            <CTableRow.FlexTableTop
              sx={{
                width: 900,
              }}
              titleArray={["Creator", "Space", "Actions"]}
            />
            {tableData.map((item) => (
              <CTableRow.FlexTableRow
                sx={{
                  width: 900,
                }}
                contentArray={item}
              />
            ))}
            {tableData.length === 0 && (
              <CTableRow.FlexTableRow
                sx={{
                  width: 900,
                }}
                contentArray={["No Creators"]}
              />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdvertiserCreatorWithContracts;
