// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import { ReceiptSearch, ReceiptText, FilterSquare } from "iconsax-react";

// Functional Imports
import Colors from "../../utils/Colors";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import Constants from "../../utils/Constants";

interface AdminWatchtimeBillsProps {}

const AdminWatchtimeBills: FC<AdminWatchtimeBillsProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Watchtime Bills" />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<ReceiptSearch size="60" color={Colors.blueBackground} />}
            title="View Bills - Creators"
            onClick={() =>
              navigate(
                PATHS.ADMIN_CREATOR_SEARCH +
                  "/" +
                  Constants.TYPES.CREATOR_SEARCH.WATCHTIME_BILLS
              )
            }
          />
          <CUIPresets.SelectFlow
            Icon={<ReceiptText size="60" color={Colors.blueBackground} />}
            title="View Bills - Month"
            onClick={() =>
              navigate(
                PATHS.ADMIN_CREATOR_WATCHTIME_BILLS_LIST +
                  "/" +
                  Constants.TYPES.WATCHTIME_BILLS_DISPLAY.MONTH +
                  "/0"
              )
            }
          />
          <CUIPresets.SelectFlow
            Icon={<FilterSquare size="60" color={Colors.blueBackground} />}
            title="View Bills - Status"
            onClick={() =>
              navigate(
                PATHS.ADMIN_CREATOR_WATCHTIME_BILLS_LIST +
                  "/" +
                  Constants.TYPES.WATCHTIME_BILLS_DISPLAY.STATUS +
                  "/0"
              )
            }
          />

          <CUIPresets.SelectFlow
            Icon={<ReceiptSearch size="60" color={Colors.blueBackground} />}
            title="View Proofs - Creators"
            onClick={() =>
              navigate(
                PATHS.ADMIN_CREATOR_SEARCH +
                  "/" +
                  Constants.TYPES.CREATOR_SEARCH.WATCHTIME_BILL_PROOFS
              )
            }
          />
          <CUIPresets.SelectFlow
            Icon={<ReceiptText size="60" color={Colors.blueBackground} />}
            title="View Proofs - Month"
            onClick={() =>
              navigate(
                PATHS.ADMIN_CREATOR_WATCHTIME_BILL_PROOF_LIST +
                  "/" +
                  Constants.TYPES.WATCHTIME_BILLS_DISPLAY.MONTH +
                  "/0"
              )
            }
          />
          <CUIPresets.SelectFlow
            Icon={<FilterSquare size="60" color={Colors.blueBackground} />}
            title="View Proofs - Status"
            onClick={() =>
              navigate(
                PATHS.ADMIN_CREATOR_WATCHTIME_BILL_PROOF_LIST +
                  "/" +
                  Constants.TYPES.WATCHTIME_BILLS_DISPLAY.STATUS +
                  "/0"
              )
            }
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminWatchtimeBills;
