// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { DateFunctions, StringManipulation } from "../../utils/Functions";
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";
import Arrays from "../../utils/Arrays";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";

interface AdminCreatorViewMasterFilesProps {}

const AdminCreatorViewMasterFiles: FC<AdminCreatorViewMasterFilesProps> = (
  props
) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getMasterFilesOfCreatorAdmin(id).then((response) => {
      setDataLoading(false);
      populateData(response.masterFiles);
    });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateData = (dataArray: any) => {
    const apiData: any = [];

    dataArray?.map((item: any) => {
      apiData.push([
        StringManipulation.stringLimiter(item.masterFileName, 20),
        DateFunctions.format(item.createdAt),
        <CUIPresets.ChipSelect.MasterFile status={item.status} />,
        <CUIPresets.RightArrowAvatarWhite
          sx={{ ml: 2 }}
          onClick={() =>
            navigate(PATHS.ADMIN_CREATOR_MASTER_FILE_DETAILS + "/" + item._id)
          }
        />,
      ]);

      return 0;
    });

    setTableData(apiData);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="View Master Files" />

        {!dataLoading && (
          <>
            <CTableRow.FlexTableTop titleArray={Arrays.Titles.MasterFiles} />
            {tableData.map((item) => (
              <CTableRow.FlexTableRow contentArray={item} />
            ))}
            {tableData.length === 0 && (
              <CTableRow.FlexTableRow contentArray={["No Data"]} />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminCreatorViewMasterFiles;
