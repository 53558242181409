// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import {
  DateFunctions,
  StringManipulation,
} from "../../utils/Functions";
import API from "../../api/API";
import Arrays from "../../utils/Arrays";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";
import { PATHS } from "../../routes/Paths";

interface AdvertiserRequestsProps {}

const AdvertiserRequests: FC<AdvertiserRequestsProps> = (props) => {
  const { type } = useParams();
  const navigate = useNavigate();

  const [requestList, setRequestList] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getAllRequestsByType(type).then((response) => {
      setDataLoading(false);
      populateData(response.requests);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateData = (dataArray: any) => {
    let pushData: any = [];
    if (type === "open") {
      dataArray.map((item: any) => {
        pushData.push([
          DateFunctions.format(item.createdAt),
          StringManipulation.stringLimiter(
            item.entityDetails.content.contentDetails.name,
            20
          ),
          StringManipulation.stringLimiter(
            item.entityDetails.creator.fullName,
            20
          ),
          item.contractTerms.typeOfContract,
          String(item.contractTerms.rateOramount),
          <CUIPresets.RightArrowAvatarWhite
            onClick={() =>
              navigate(PATHS.ADVERTISER_REQUEST_DETAILS + "/" + item._id)
            }
            sx={{
              marginLeft: 2,
            }}
          />,
        ]);

        return 0;
      });
    } else if (type === "other") {
      dataArray.map((item: any) => {
        pushData.push([
          DateFunctions.format(item.createdAt),
          StringManipulation.stringLimiter(
            item.entityDetails.content.contentDetails.name,
            15
          ),
          StringManipulation.stringLimiter(
            item.entityDetails.creator.fullName,
            15
          ),
          <CUIPresets.ChipSelect.ContractRequest status={item.status} />,
          <CUIPresets.RightArrowAvatarWhite
            onClick={() =>
              navigate(PATHS.ADVERTISER_REQUEST_DETAILS + "/" + item._id)
            }
            sx={{
              marginLeft: 2,
            }}
          />,
        ]);

        return 0;
      });
    }
    setRequestList(pushData);
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader
            title={type === "open" ? "Requests - Open" : "Requests - Others"}
          />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader
            title={type === "open" ? "Requests - Open" : "Requests - Others"}
          />

          <CTableRow.FlexTableTopCustom
            flexArray={
              type === "open" ? [0.7, 1, 1, 0.5, 0.5, 0.5] : [1, 1, 1, 1, 1]
            }
            sx={{ width: 900 }}
            titleArray={
              type === "open"
                ? Arrays.Titles.AdvertiserOpenRequests
                : Arrays.Titles.AdvertiserOtherRequests
            }
          />
          {requestList.map((item) => (
            <CTableRow.FlexTableRowCustom
              flexArray={
                type === "open" ? [0.7, 1, 1, 0.5, 0.5, 0.5] : [1, 1, 1, 1, 1]
              }
              sx={{ width: 900 }}
              contentArray={item}
            />
          ))}
          {requestList?.length === 0 && (
            <CTableRow.FlexTableRow
              sx={{ width: 900 }}
              contentArray={["No Data"]}
            />
          )}
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdvertiserRequests;
