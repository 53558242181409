// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import Arrays from "../../utils/Arrays";
import SortableList from "react-easy-sort";
import arrayMove from "array-move";

// Local Imports
import CTableRow from "../../atoms/CTableRow";
import CUIPresets from "../../atoms/CUIPresets";
import CComposite from "../../atoms/CComposite";

interface SortContentsProps {
  setSortEnabled?: any;
  contentSelected?: any;
  setContentSelected?: any;
  confirmCreateSubspace?: any;
  setModal?: any;
}

const SortContents: FC<SortContentsProps> = (props) => {
  const [sortValue, setSortValue] = useState("created_date");

  const [sortedContent, setSortedContent] = React.useState(
    props.contentSelected
  );

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setSortedContent((array: any) => arrayMove(array, oldIndex, newIndex));
  };

  const sortAction = (sortPass: any) => {
    setSortValue(sortPass);

    if (sortPass === "alphabetical") {
      let modContentSelected = props.contentSelected;

      modContentSelected.sort((a: any, b: any) =>
        a.contentDetails?.name.localeCompare(b.contentDetails?.name)
      );

      setSortedContent([...modContentSelected]);
    } else if (sortPass === "created_date") {
      let modContentSelected = props.contentSelected;

      modContentSelected.sort(function (a: any, b: any) {
        const substract =
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
        return substract;
      });

      console.dir(modContentSelected);

      setSortedContent([...modContentSelected]);
    }
  };

  const confirmCreateSubspace = () => {
    props.setContentSelected([...sortedContent]);
    props.setModal({
      open: true,
      apiLoading: false,
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader
          onClick={() => props.setSortEnabled(false)}
          title="Sort Content"
        />

        <Box
          sx={{
            width: 800,
            marginBottom: 2,
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <CUIPresets.SortBox
            options={Arrays.Sorting.CreateSubspace}
            xsKey="typeId"
            xsValue="type"
            selected={sortValue}
            setValue={(e: any) => sortAction(e.target.value)}
          />
        </Box>
        <SortableList
          onSortEnd={onSortEnd}
          className="list"
          draggedItemClassName="dragged"
        >
          {sortedContent?.map((item: any, index: any) => (
            <CTableRow.ContentSortRow
              title={item.contentDetails?.name}
              image={item.contentDetails?.coverPhoto}
              index={index + 1}
            />
          ))}
        </SortableList>

        <CComposite.FooterActions.Filled
          cancelText={"Back"}
          onCancel={() => props.setSortEnabled(false)}
          sx={{
            mt: 6,
            mb: 4,
            width: 800,
          }}
          confirmText={"Confirm"}
          onClick={confirmCreateSubspace}
        />
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default SortContents;
