// React Imports
import React, { FC } from "react";

// MUI Imports
import { Typography } from "@mui/material";

// Functional Imports

// Local Imports

// FontWeight Reference
// 400 - Light
// 500 - Mid
// 600 - SemiBold
// 700 - Bold
// 800 - UltraBold

const CONFIG = {
  // Jakarta
  JakartaLight12: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 12,
    fontWeight: 400,
  },
  JakartaSemiBold14: {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 600,
    fontSize: 14,
  },
  JakartaLight14: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 14,
    fontWeight: 400,
  },
  JakartaMid14: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 14,
    fontWeight: 500,
  },
  JakartaMid16: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 16,
    fontWeight: 500,
  },
  JakartaLight16: {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: 16,
  },
  JakartaSemiBold16: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 16,
    fontWeight: 600,
  },
  JakartaLight18: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 18,
    fontWeight: 400,
  },
  JakartaMid18: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 18,
    fontWeight: 500,
  },
  JakartaSemiBold18: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 18,
    fontWeight: 600,
  },
  JakartaLight20: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 20,
    fontWeight: 400,
  },
  JakartaMid20: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 20,
    fontWeight: 500,
  },
  JakartaSemibold20: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 20,
    fontWeight: 600,
  },
  JakartaMid24: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 24,
    fontWeight: 500,
  },
  JakartaSemiBold24: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 24,
    fontWeight: 600,
  },
  JakartaMid32: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 32,
    fontWeight: 500,
  },
  JakartaBold32: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 32,
    fontWeight: 700,
  },
  JakartaUltraBold40: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 40,
    fontWeight: 800,
  },
  JakartaMid40: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 40,
    fontWeight: 500,
  },
  JakartaSemiBold48: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 48,
    fontWeight: 600,
  },
  JakartaMid64: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: 64,
    fontWeight: 500,
  },

  // Outfit
  OutfitLight14: {
    fontFamily: "Outfit",
    fontSize: 14,
    fontWeight: 400,
  },
  OutfitLight16: {
    fontFamily: "Outfit",
    fontSize: 16,
    fontWeight: 400,
  },
  OutfitSemiBold18: {
    fontFamily: "Outfit",
    fontSize: 18,
    fontWeight: 600,
  },
  OutfitLight20: {
    fontFamily: "Outfit",
    fontSize: 20,
    fontWeight: 400,
  },

  // Roboto
  RobotoLight16: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
  },
  RobotoLight28Underline: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 400,
    textDecoration: "underline",
  },
  RobotoSemiBold48: {
    fontFamily: "Roboto",
    fontSize: 48,
    fontWeight: 600,
  },
};

const TCOLOR = {
  White: "#FFF",

  Black: "#000",

  Red: "#BA413B",

  Green: "#7FA06F",

  Cyan: "#04A9B6",

  Grey1: "#8D8D8D",
  Grey2: "#949494",
  Grey3: "#666666",
  Grey4: "#767676",
  Grey6: "#787878",
  Grey7: "#AEAEAE",

  Blue1: "#275AAD",
  Blue2: "#3373CC",
  Blue3: "#275AAD",
  Blue4: "#3373CC",
};

interface CTextProps {
  color?: any;
  text?: any;
  style?: any;
}

const JakartaLight12White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight12,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight14Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight14,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight14White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight14,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight14Cyan: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight14,
        color: TCOLOR.Cyan,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight14Grey7: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight14,
        color: TCOLOR.Grey7,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid14White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid14,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid14Grey4: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid14,
        color: TCOLOR.Grey4,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid14Grey1: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid14,
        color: TCOLOR.Grey1,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold14Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold14,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold14Grey1: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold14,
        color: TCOLOR.Grey1,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold14Blue2: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold14,
        color: TCOLOR.Blue2,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight16Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight16,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight16White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight16,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight16Cyan: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight16,
        color: TCOLOR.Cyan,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight16Grey7: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight16,
        color: TCOLOR.Grey7,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight16Blue4: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight16,
        color: TCOLOR.Blue4,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight16Grey2: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight16,
        color: TCOLOR.Grey2,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid16Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid16,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid16Grey1: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid16,
        color: TCOLOR.Grey1,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid16Grey3: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid16,
        color: TCOLOR.Grey3,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid16White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid16,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid16Blue4Underline: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid16,
        color: TCOLOR.Blue4,
        textDecoration: "underline",
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold16Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold16,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold16Blue1: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold16,
        color: TCOLOR.Blue1,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold16Grey1: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold16,
        color: TCOLOR.Grey1,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold16Grey3: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold16,
        color: TCOLOR.Grey3,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold16Cyan: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold16,
        color: TCOLOR.Cyan,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight18Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight18,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight18Grey1: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight18,
        color: TCOLOR.Grey1,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight18Cyan: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight18,
        color: TCOLOR.Cyan,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight18White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight18,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid18Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid18,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid18Grey1: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid18,
        color: TCOLOR.Grey1,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid18Blue4: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid18,
        color: TCOLOR.Blue4,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold18Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold18,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold18White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold18,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaLight20Grey7: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaLight20,
        color: TCOLOR.Grey7,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid20Blue2: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid20,
        color: TCOLOR.Blue2,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid20Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid20,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid20Grey6: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid20,
        color: TCOLOR.Grey6,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemibold20White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemibold20,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid24White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid24,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid24Grey1: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid24,
        color: TCOLOR.Grey1,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold24Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold24,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold24White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold24,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid32White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid32,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaBold32Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaBold32,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaUltraBold40Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaUltraBold40,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid40White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid40,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaMid64Grey: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaMid64,
        color: TCOLOR.Grey1,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const JakartaSemiBold48WhiteShadow: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.JakartaSemiBold48,
        color: TCOLOR.White,
        textShadow: TCOLOR.Grey1 + " 1px 0 5px",
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const OutfitLight14White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.OutfitLight14,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const OutfitLight14Cyan: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.OutfitLight14,
        color: TCOLOR.Cyan,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const OutfitSemiBold18Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.OutfitSemiBold18,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const OutfitSemiBold18Blue3: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.OutfitSemiBold18,
        color: TCOLOR.Blue3,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const OutfitSemiBold18White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.OutfitSemiBold18,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const OutfitSemiBold18Cyan: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.OutfitSemiBold18,
        color: TCOLOR.Cyan,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const OutfitLight20Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.OutfitLight20,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const OutfitLight20White: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.OutfitLight20,
        color: TCOLOR.White,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const RobotoLight16Grey7: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.RobotoLight16,
        color: TCOLOR.Grey7,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const RobotoLight28UnderlineBlack: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.RobotoLight28Underline,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const RobotoSemiBold48Black: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.RobotoSemiBold48,
        color: TCOLOR.Black,
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const RobotoSemiBold48WhiteShadow: FC<CTextProps> = (props) => {
  return (
    <Typography
      style={{
        ...CONFIG.RobotoSemiBold48,
        color: TCOLOR.White,
        textShadow: TCOLOR.Grey1 + " 1px 0 5px",
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
};

const Jakarta = {
  Size12: {
    Light: {
      White: JakartaLight12White,
    },
  },
  Size14: {
    Light: {
      Black: JakartaLight14Black,
      White: JakartaLight14White,
      Cyan: JakartaLight14Cyan,
      Grey7: JakartaLight14Grey7,
    },
    Mid: {
      White: JakartaMid14White,
      Grey4: JakartaMid14Grey4,
      Grey1: JakartaMid14Grey1,
    },
    SemiBold: {
      Black: JakartaSemiBold14Black,
      Grey1: JakartaSemiBold14Grey1,
      Blue2: JakartaSemiBold14Blue2,
    },
  },
  Size16: {
    Light: {
      Black: JakartaLight16Black,
      White: JakartaLight16White,
      Blue4: JakartaLight16Blue4,
      Grey2: JakartaLight16Grey2,
      Cyan: JakartaLight16Cyan,
      Grey7: JakartaLight16Grey7,
    },
    Mid: {
      Black: JakartaMid16Black,
      Grey1: JakartaMid16Grey1,
      Grey3: JakartaMid16Grey3,
      White: JakartaMid16White,
      Blue4Underline: JakartaMid16Blue4Underline,
    },
    SemiBold: {
      Black: JakartaSemiBold16Black,
      Blue1: JakartaSemiBold16Blue1,
      Grey1: JakartaSemiBold16Grey1,
      Grey3: JakartaSemiBold16Grey3,
      Cyan: JakartaSemiBold16Cyan,
    },
  },
  Size18: {
    Light: {
      Black: JakartaLight18Black,
      Grey1: JakartaLight18Grey1,
      Cyan: JakartaLight18Cyan,
      White: JakartaLight18White,
    },
    Mid: {
      Black: JakartaMid18Black,
      Grey1: JakartaMid18Grey1,
      Blue4: JakartaMid18Blue4,
    },
    SemiBold: {
      Black: JakartaSemiBold18Black,
      White: JakartaSemiBold18White,
    },
  },
  Size20: {
    Light: {
      Grey7: JakartaLight20Grey7,
    },
    Mid: {
      Blue2: JakartaMid20Blue2,
      Black: JakartaMid20Black,
      Grey6: JakartaMid20Grey6,
    },
    Semibold: {
      White: JakartaSemibold20White,
    },
  },
  Size24: {
    Mid: {
      White: JakartaMid24White,
      Grey1: JakartaMid24Grey1,
    },
    SemiBold: {
      Black: JakartaSemiBold24Black,
      White: JakartaSemiBold24White,
    },
  },
  Size32: {
    Mid: {
      White: JakartaMid32White,
    },
    Bold: {
      Black: JakartaBold32Black,
    },
  },
  Size40: {
    Mid: {
      White: JakartaMid40White,
    },
    UltraBold: {
      Black: JakartaUltraBold40Black,
    },
  },
  Size48: {
    SemiBold: {
      WhiteShadow: JakartaSemiBold48WhiteShadow,
    },
  },
  Size64: {
    Mid: {
      Grey: JakartaMid64Grey,
    },
  },
};

const Outfit = {
  Size14: {
    Light: {
      White: OutfitLight14White,
      Cyan: OutfitLight14Cyan,
    },
    SemiBold: {},
  },
  Size18: {
    SemiBold: {
      Black: OutfitSemiBold18Black,
      Blue3: OutfitSemiBold18Blue3,
      White: OutfitSemiBold18White,
      Cyan: OutfitSemiBold18Cyan,
    },
  },
  Size20: {
    Light: {
      Black: OutfitLight20Black,
      White: OutfitLight20White,
    },
  },
};

const Roboto = {
  Sise16: {
    Light: {
      Grey7: RobotoLight16Grey7,
    },
  },
  Size28: {
    Light: {
      BlackUnderline: RobotoLight28UnderlineBlack,
    },
  },
  Size48: {
    SemiBold: {
      Black: RobotoSemiBold48Black,
      WhiteShadow: RobotoSemiBold48WhiteShadow,
    },
  },
};

const CText = {
  Jakarta,
  Outfit,
  Roboto,
};

export default CText;
