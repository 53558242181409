// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports

// Functional Imports
import { useParams } from "react-router-dom";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import RaisingStatus from "./RaisingStatus";
import RaisedStatus from "./RaisedStatus";
import ProofStatus from "./ProofStatus";

const requestDetailsEmpty = {
  requestDetails: {
    amountInSSC: 0,
    currency: "",
    amountInCurrency: 0,
    modeOfPayment: "",
    createdAt: "",
  },
  proofDetails: {
    dateOfTransaction: "",
    photoProof: "",
    txnId: "",
    senderAddress: "",
  },
  adminResponse: {
    photoReceipt: "",
    actionBy: "",
  },
  reason: "",
  _id: "",
  clientId: "",
  status: "",
  requestType: "",
};

interface AdvertiserAddFundsRequestProps {}

const AdvertiserAddFundsRequest: FC<AdvertiserAddFundsRequestProps> = (
  props
) => {
  const { id } = useParams();

  const [active, setActive] = useState(
    Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.RAISING
  );
  const [requestDetails, setRequestDetails] = useState(requestDetailsEmpty);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (id === "0") {
      setActive(Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.RAISING);
    } else {
      setDataLoading(true);
      API.getAdvertiserRequestById(id).then((response) => {
        setRequestDetails(response.fundSelected);
        setActive(response.fundSelected?.status);
        setDataLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Add Funds Request" />

        {!dataLoading &&
          active === Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.RAISING && (
            <RaisingStatus />
          )}

        {dataLoading && <CUIPresets.LoadingSpinner />}

        {!dataLoading &&
          active === Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.RAISED && (
            <RaisedStatus requestDetails={requestDetails} />
          )}

        {!dataLoading &&
          (active === Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.UPLOADED ||
            active === Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.REJECTED ||
            active === Constants.STATUS_FLAGS.ADD_FUNDS_REQUEST.APPROVED) && (
            <ProofStatus requestDetails={requestDetails} />
          )}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdvertiserAddFundsRequest;
