// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";
import Styles from "../../utils/Styles";

// Functional Imports
import { useNavigate } from "react-router-dom";

// Local Imports
import UploadSuccessfulIMG from "../../assets/images/upload-successful.png";
import CText from "../../atoms/CText";
import CUIPresets from "../../atoms/CUIPresets";

interface UploadSuccessfulProps {
  setActive?: any;
  fileName?: any;
}

const UploadSuccessful: FC<UploadSuccessfulProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        ...Styles.SStyles.FullHeightFullWidth,
        ...Styles.SStyles.ColumnCenterCenter,
      }}
    >
      <Box
        component={"img"}
        src={UploadSuccessfulIMG}
        sx={{
          height: 200,
        }}
      />

      <CText.Jakarta.Size24.SemiBold.Black
        style={{
          marginTop: 20,
          marginBottom: 16,
        }}
        text="Upload Successful!"
      />

      <Box
        sx={{
          mt: 2,
          display: "flex",
          width: 500,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "60%" }}>
          <CText.Jakarta.Size18.Mid.Black text="Ad Reference Name" />
        </Box>
        <Box sx={{ width: "40%" }}>
          <CText.Jakarta.Size18.Mid.Grey1 text={props.fileName} />
        </Box>
      </Box>

      <CUIPresets.OutlinedButton
        sx={{
          mt: 3
        }}
        onClick={() => {
          navigate(-1);
          props.setActive("upload");
        }}
        title={"Ad Files"}
      />
    </Box>
  );
};

export default UploadSuccessful;
