// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import {  } from "@mui/material";
import {} from "iconsax-react";

// Functional Imports
import { NumberTypeInputProps, ToastGenerator } from "../../utils/Functions";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";

interface AdminCurrencyConversionProps {}

const AdminCurrencyConversion: FC<AdminCurrencyConversionProps> = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [usdConversionFactor, setUsdConversionFactor] = useState(0);
  const [inrConversionFactor, setInrConversionFactor] = useState(0);

  useEffect(() => {
    setDataLoading(true);
    API.getCurrencyConversion().then((response) => {
      setDataLoading(false);
      setUsdConversionFactor(response.currencyConversion?.[0]?.USD);
      setInrConversionFactor(response.currencyConversion?.[0]?.INR);
    });
  }, []);

  const updateCall = () => {
    const payload = {
      USD: Number(usdConversionFactor),
      INR: Number(inrConversionFactor),
    };

    API.updateCurrencyConversion(payload).then((response) => {
      if (response.actionSuccess) {
        ToastGenerator.success("Update successful!");
      }
    });
  };

  if (dataLoading) {
    return (
      <>
        <CUIPresets.PageHeader title="Currency Conversion" />
        <CUIPresets.LoadingSpinner />
      </>
    );
  } else {
    return (
      <>
        <CUIPresets.PageHeader title="Currency Conversion" />

        <CInputOutput.LabelInputOutlined
          sx={{ width: 400, mt: 1 }}
          label="USD Conversion Factor"
          textFieldProps={{
            placeholder: "Enter Conversion Factor",
            InputProps: NumberTypeInputProps,
            value: usdConversionFactor,
            onChange: (e: any) => setUsdConversionFactor(e.target.value),
          }}
        />
        <CInputOutput.LabelInputOutlined
          sx={{ width: 400, mt: 2 }}
          label="INR Conversion Factor"
          textFieldProps={{
            placeholder: "Enter Conversion Factor",
            InputProps: NumberTypeInputProps,
            value: inrConversionFactor,
            onChange: (e: any) => setInrConversionFactor(e.target.value),
          }}
        />

        <CUIPresets.OutlinedButton
          title={"Update"}
          sx={{
            marginTop: 4,
            width: 400,
          }}
          onClick={() => updateCall()}
        />
      </>
    );
  }
};

export default AdminCurrencyConversion;
