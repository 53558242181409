// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import {} from "@mui/material";
import {} from "iconsax-react";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { ToastGenerator } from "../../utils/Functions";
import Arrays from "../../utils/Arrays";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import ContentCreation from "./ContentCreation";
import ChooseCountries from "./ChooseCountries";

const initMasterFileData = {
  _id: "",
  masterFileName: "",
  creatorId: "",
  adSlots: [],
  status: "",
  spacesLink: [],
  bunnyStreamLink: "",
  duration: "",
};

interface CreatorCreateContentProps {}

const CreatorCreateContent: FC<CreatorCreateContentProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  const [masterFileData, setMasterFileData] = useState(initMasterFileData);
  const [dataLoading, setDataLoading] = useState(false);

  const [countries, setCountries]: any = useState([]);
  const [selectedCountries, setSelectedCountries] = useState(0);
  const [selectCountry, setSelectCountry] = useState(false);
  const [contentName, setContentName] = useState("");
  const [contentImage, setContentImage] = useState({
    file: null,
    name: "",
    size: 0,
  });

  useEffect(() => {
    setCountries([]);
    const tempRef = Arrays.Constants.ListOfCountries
    setCountries([...tempRef]);

    setDataLoading(true);
    API.getMasterFileById(id).then((response) => {
      setDataLoading(false);
      setMasterFileData(response.masterFileSelected);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirm = () => {    
    API.createContent({
      name: contentName,
      image: contentImage.file,
      masterFileId: masterFileData._id,
      countryArray: countries,
    }).then((response) => {
      if (response.message === Constants.API_RESPONSES.CONTENT_CREATE_SUCCESS) {
        ToastGenerator.success(response.message);
        navigate(-2);
      }
    });
  };

  if (!selectCountry) {
    return (
      <ContentCreation
        // Data
        countries={countries}
        selectedCountries={selectedCountries}
        masterFileData={masterFileData}
        dataLoading={dataLoading}
        contentName={contentName}
        contentImage={contentImage}
        modal={modal}
        // Functions
        setModal={setModal}
        setContentName={setContentName}
        setSelectCountry={setSelectCountry}
        setContentImage={setContentImage}
        onConfirm={onConfirm}
      />
    );
  } else {
    return (
      <ChooseCountries
        setSelectCountry={setSelectCountry}
        setCountries={setCountries}
        countries={countries}
        selectedCountries={selectedCountries}
        setSelectedCountries={setSelectedCountries}
      />
    );
  }
};

export default CreatorCreateContent;
