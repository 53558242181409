// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import { LockCircle, Logout, ProfileCircle } from "iconsax-react";

// Functional Imports
import { LocalStorage, ToastGenerator } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import Colors from "../../utils/Colors";

interface AdminProfileProps {}

const AdminProfile: FC<AdminProfileProps> = (props) => {
  const navigate = useNavigate();

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  const onLogout = () => {
    setModal({
      open: true,
      apiLoading: true,
    })

    LocalStorage.setString("JWT", "");
    LocalStorage.setString("Type", "");
    LocalStorage.setString("ClientID", "");
    ToastGenerator.success("Logging out user!");
    setTimeout(() => {
      navigate(PATHS.NOAUTH_AUTH + "/creator");
      window.location.reload();
    }, 500);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Profile" disabled />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<ProfileCircle size="60" color={Colors.blueBackground} />}
            title="Profile Info"
            onClick={() => navigate(PATHS.ADVERTISER_PROFILE_INFO)}
          />
          <CUIPresets.SelectFlow
            Icon={<LockCircle size="60" color={Colors.blueBackground} />}
            title="Change Password"
            onClick={() => navigate(PATHS.ADMIN_CHANGE_PASSWORD)}
          />
          <CUIPresets.SelectFlow
            Icon={<Logout size="60" color={Colors.blueBackground} />}
            title="Logout"
            onClick={() =>
              setModal({
                open: true,
                apiLoading: false,
              })
            }
          />
        </Grid>

        <CUIPresets.CustomDialog
          title="Logout"
          content={["Are you sure about logging out?"]}
          confirmText={"Confirm"}
          cancelText="Cancel"
          confirmCall={() => onLogout()}
          modalOpen={modal.open}
          apiLoading={modal.apiLoading}
          cancelCall={() =>
            setModal({
              open: false,
              apiLoading: false,
            })
          }
        />
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminProfile;
