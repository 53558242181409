// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastGenerator } from "../../utils/Functions";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CreateNewSubspace from "./CreateNewSubspace";
import SortContents from "./SortContents";
import CUIPresets from "../../atoms/CUIPresets";

interface CreatorCreateNewSubspaceProps {}

const CreatorCreateNewSubspace: FC<CreatorCreateNewSubspaceProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [sortEnabled, setSortEnabled] = useState(false);
  const [contentSelected, setContentSelected] = useState([]);
  const [checkedList, setCheckedList]: any = useState([]);

  const [dataLoading, setDataLoading] = useState(false);
  const [contentList, setContentList] = useState<any>([]);

  const [subspaceName, setSubspaceName] = useState("");
  const [description, setDescription] = useState("");

  const [createOrUpdate, setCreateOrUpdate] = useState("create");
  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  useEffect(() => {
    setDataLoading(true);
    API.getContentList("", "").then((response: any) => {
      generateCheckedList(response.contentList);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCheckedList = (contentList: any) => {
    if (location.pathname.includes(PATHS.CREATOR_UPDATE_SUBSPACE)) {
      setCreateOrUpdate("update");
      API.getSubspaceById(id).then((response) => {
        setSubspaceName(response.subSpace?.subSpaceName);
        setDescription(response.subSpace?.description);

        let checkedArr: any = [];

        contentList.map((item: any) => {
          let existsInSubspace = false;

          response.subSpace?.contents?.map((itemChild: any) => {
            if (itemChild._id === item._id) {
              existsInSubspace = true;
            }

            return 0;
          });

          checkedArr.push(existsInSubspace);

          return 0;
        });

        setCheckedList([...checkedArr]);
        setContentList([...contentList]);
        setDataLoading(false);
      });
    } else if (location.pathname.includes(PATHS.CREATOR_CREATE_NEW_SUBSPACE)) {
      let checkedArr: any = [];
      contentList?.map((item: any) => {
        checkedArr.push(false);
        return 0;
      });

      setCheckedList([...checkedArr]);
      setContentList([...contentList]);
      setDataLoading(false);
    }
  };

  const confirmContentSelection = () => {
    if (subspaceName === "") {
      ToastGenerator.info("Subspace name is required!");
      return;
    } else if (description === "") {
      ToastGenerator.info("Description is required!");
      return;
    }

    let contentArr: any = [];
    let count = 0;

    checkedList?.map((item: any, index: number) => {
      if (item) {
        contentArr.push(contentList[index]);
        count++;
      }

      return 0;
    });

    if (count > 0) {
      setContentSelected(contentArr);
      setSortEnabled(true);
    } else {
      ToastGenerator.info("Add at least 1 content!");
    }
  };

  const confirmCreateSubspace = () => {
    setModal({
      open: true,
      apiLoading: true,
    });

    let contentIds: any = [];

    contentSelected.map((item: any) => {
      contentIds.push(item._id);

      return 0;
    });

    const payload = {
      subSpaceName: subspaceName,
      description: description,
      contents: contentIds,
    };

    if (createOrUpdate === "create") {
      API.createSubspace(payload).then((response) => {
        if (
          response.message === Constants.API_RESPONSES.SUBSPACE_CREATE_SUCCESS
        ) {
          ToastGenerator.success(response.message);
          setModal({
            open: false,
            apiLoading: false,
          });
          navigate(-1);
        }
      });
    } else {
      API.updateSubspace(payload, id).then((response) => {
        if (
          response.message === Constants.API_RESPONSES.SUBSPACE_UPDATE_SUCCESS
        ) {
          ToastGenerator.success(response.message);
          setModal({
            open: false,
            apiLoading: false,
          });
          navigate(-1);
        }
      });
    }
  };

  return (
    <>
      {!sortEnabled && (
        <CreateNewSubspace
          createOrUpdate={createOrUpdate}
          checkedList={checkedList}
          dataLoading={dataLoading}
          contentList={contentList}
          subspaceName={subspaceName}
          description={description}
          setCheckedList={setCheckedList}
          setSortEnabled={setSortEnabled}
          setContentSelected={setContentSelected}
          setSubspaceName={setSubspaceName}
          setDescription={setDescription}
          confirmContentSelection={confirmContentSelection}
        />
      )}
      {sortEnabled && (
        <SortContents
          contentSelected={contentSelected}
          setSortEnabled={setSortEnabled}
          setContentSelected={setContentSelected}
          setModal={setModal}
          confirmCreateSubspace={confirmCreateSubspace}
        />
      )}
      <CUIPresets.CustomDialog
        title="Create Subspace"
        content={["Are you sure about creating this subspace?"]}
        confirmText={"Confirm"}
        cancelText="Cancel"
        confirmCall={confirmCreateSubspace}
        modalOpen={modal.open}
        cancelCall={() =>
          setModal({
            open: false,
            apiLoading: false,
          })
        }
        apiLoading={modal.apiLoading}
      />
    </>
  );
};

export default CreatorCreateNewSubspace;
