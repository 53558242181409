// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import Styles from "../../utils/Styles";
import CUIPresets from "../CUIPresets";

// Local Imports

interface FilterSettingsProps {
  options?: any;
  filter?: any;
  setFilter?: any;
  sx?: any;
}

const FilterSettings: FC<FilterSettingsProps> = (props) => {
  return (
    <Box
      sx={{
        ...Styles.SStyles.RowCenterCenter,
        ...props.sx,
      }}
    >
      {props.options?.map((item: any) => {
        if (props.filter === item) {
          return (
            <CUIPresets.ChipCyan
              onClick={() => props.setFilter(item)}
              title={item}
              sx={{ ml: 2 }}
            />
          );
        } else {
          return (
            <CUIPresets.ChipWhite
              onClick={() => props.setFilter(item)}
              title={item}
              sx={{ ml: 2 }}
            />
          );
        }
      })}
    </Box>
  );
};

export default FilterSettings;
