// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CTableRow from "../../atoms/CTableRow";
import CUIPresets from "../../atoms/CUIPresets";

interface AdminAdvertiserSearchProps {}

const AdminAdvertiserSearch: FC<AdminAdvertiserSearchProps> = (props) => {
  const { type } = useParams();
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState(false);
  const [advertiserList, setAdvertiserList] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setDataLoading(true);
    API.getAdvertiserListBySearch(searchText).then((response) => {
      setAdvertiserList(response.clientsData);
      setDataLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchAction = (searchPass: any) => {
    setSearchText(searchPass);
    setDataLoading(true);

    API.getAdvertiserListBySearch(searchPass).then((response) => {
      setAdvertiserList(response.clientsData);
      setDataLoading(false);
    });
  };

  const handleClick = (id: any) => {
    if (type === Constants.TYPES.ADVERTISER_SEARCH.ADD_FUNDS) {
      navigate(
        PATHS.ADMIN_ADD_FUNDS_REQUESTS_LIST +
          "/" +
          Constants.TYPES.ADD_FUNDS_DISPLAY.ADVERTISER +
          "/" +
          id
      );
    } else if (type === Constants.TYPES.ADVERTISER_SEARCH.WITHDRAW_FUNDS) {
      navigate(
        PATHS.ADMIN_WITHDRAW_FUNDS_REQUESTS_LIST +
          "/" +
          Constants.TYPES.WITHDRAW_FUNDS_DISPLAY.ADVERTISER +
          "/" +
          id
      );
    } else if (type === Constants.TYPES.ADVERTISER_SEARCH.ADVERTISER_ACCESS) {
      navigate(PATHS.ADMIN_ADVERTISER_ACCESS + "/" + id);
    }
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Advertiser Search" />

        <Box
          sx={{
            width: 800,            
            marginBottom: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CUIPresets.SearchBar
            placeholder={"Search Advertisers"}
            sx={{ width: 500 }}
            textFieldProps={{
              value: searchText,
              onChange: (ev: any) => searchAction(ev.target.value),
            }}
          />
        </Box>

        {!dataLoading && (
          <>
            {advertiserList?.map((item: any) => (
              <CTableRow.SearchClientRow
                fullName={item.fullName}
                username={item.userName}
                email={item.email}
                onClick={() => handleClick(item._id)}
              />
            ))}
            {advertiserList?.length === 0 && (
              <CTableRow.FlexTableRow contentArray={["No Data"]} />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminAdvertiserSearch;
