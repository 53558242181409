// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import {
  DateFunctions,
  Information,
  NumberFunctions,
  ToastGenerator,
} from "../../utils/Functions";
import API from "../../api/API";
import Arrays from "../../utils/Arrays";
import Styles from "../../utils/Styles";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";
import CText from "../../atoms/CText";

const watchtimeBillInit = {
  spaceId: {
    configuration: {
      name: "",
    },
  },
  creatorId: {
    fullName: "",
  },
  status: "",
  totalAmountInSSC: 0,
  totalWatchtime: 0,
  consumptionDetails: [],
  createdAt: null,
  fundsDetails: [],
};

interface AdminWatchtimeBillDetailsProps {}

const AdminWatchtimeBillDetails: FC<AdminWatchtimeBillDetailsProps> = (
  props
) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  const [watchtimeBill, setWatchtimeBill] = useState(watchtimeBillInit);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    apiCall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apiCall = async () => {
    setDataLoading(true);
    API.getWatchtimeBillByIdAdmin(id).then((response) => {
      setDataLoading(false);
      setWatchtimeBill(response.watchtimeBill);
    });
  };

  const onConfirm = () => {
    setModal({
      open: true,
      apiLoading: true,
    });
    API.updateWatchtimeBill(id).then((response) => {
      setModal({
        open: false,
        apiLoading: false,
      });

      if (response.actionSuccess) {
        ToastGenerator.success(response.message);
        apiCall();
      }
    });
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Watchtime Bill Details" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <Box
            sx={{
              ...Styles.SStyles.RowCenterSpace,
              width: 800,
            }}
          >
            <CUIPresets.PageHeader title="Watchtime Bill Details" />

            {watchtimeBill.status ===
              Constants.STATUS_FLAGS.WATCHTIME_BILLS.GENERATED && (
              <CUIPresets.FilledButton
                sx={{
                  width: 200,
                  mt: 1,
                }}
                title="Send to Creator"
                onClick={() => setModal({ open: true, apiLoading: false })}
              />
            )}
          </Box>

          <Box sx={{ mt: 1, ml: 1 }}>
            <CUIPresets.LabelValueHorizontal
              label={"Creator Name"}
              value={watchtimeBill.creatorId?.fullName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Space Name"}
              value={watchtimeBill.spaceId?.configuration?.name}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Month"}
              value={DateFunctions.format(watchtimeBill.createdAt, "YYYY-MM")}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Total (in SSC)"}
              value={String(
                NumberFunctions.round(watchtimeBill.totalAmountInSSC, 2)
              )}
            />

            <CUIPresets.LabelStatus
              chipSelect={"watchtimeBill"}
              status={watchtimeBill.status}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Watchtime Bill Proofs"
            />

            <CTableRow.FlexTableTop
              titleArray={Arrays.Titles.WatchtimeBillProofs}
            />

            {watchtimeBill.fundsDetails?.map((item: any) => (
              <CTableRow.FlexTableRow
                contentArray={[
                  DateFunctions.format(item.createdAt),
                  <CUIPresets.ChipSelect.WatchtimeBillProof
                    status={item.status}
                  />,
                  <CUIPresets.RightArrowAvatarWhite
                    onClick={() => {
                      navigate(
                        PATHS.ADMIN_CREATOR_WATCHTIME_BILL_PROOF_DETAILS +
                          "/" +
                          item._id
                      );
                    }}
                  />,
                ]}
              />
            ))}
            {watchtimeBill.fundsDetails?.length === 0 && (
              <CTableRow.FlexTableRow contentArray={["No Data"]} />
            )}

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Consumption Details"
            />

            <CTableRow.FlexTableTop
              titleArray={Arrays.Titles.WatchtimeBillConsumptionDetails}
            />
            {watchtimeBill.consumptionDetails?.map((item: any) => {
              const contentArray = [
                Information.retrieveCountry(item.countryCode),
                String(NumberFunctions.round(item.rateInSSC, 2)),
                String(NumberFunctions.round(item.watchtime, 2)),
                String(NumberFunctions.round(item.total, 2)),
              ];

              return <CTableRow.FlexTableRow contentArray={contentArray} />;
            })}
            {watchtimeBill.consumptionDetails?.length === 0 && (
              <CTableRow.FlexTableRow contentArray={["No Data"]} />
            )}
          </Box>
          <CUIPresets.CustomDialog
            title="Watchtime Bill"
            content={[
              "Are you sure about sending this watchtime bill to content creator?",
            ]}
            confirmText={"Confirm"}
            cancelText="Cancel"
            confirmCall={onConfirm}
            modalOpen={modal.open}
            apiLoading={modal.apiLoading}
            cancelCall={() =>
              setModal({
                open: false,
                apiLoading: false,
              })
            }
          />
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdminWatchtimeBillDetails;
