const TypeOfUser = [
  {
    typeId: "creator",
    type: "Content creator",
  },
  {
    typeId: "advertiser",
    type: "Advertiser",
  },
];

const MasterFilesTitleArray = ["Reference Name", "Date", "Status", "Actions"];

const AdFilesTitleArray = ["Reference Name", "Date", "Actions"];

const AddFundsRequestAdvertiserTitleArrayDate = [
  "Raised Date",
  "Amount",
  "Currency",
  "Status",
  "Action",
];

const AddFundsRequestAdvertiserTitleArrayAdvertiser = [
  "Advertiser",
  "Amount",
  "Currency",
  "Status",
  "Action",
];

const AddFundsRequestAdvertiserTitleArray = {
  Date: AddFundsRequestAdvertiserTitleArrayDate,
  Advertiser: AddFundsRequestAdvertiserTitleArrayAdvertiser,
};

const WatchtimeBillsCreatorTitleArrayMonth = [
  "Month",
  "Amount",
  "Watchtime",
  "Status",
  "Actions",
];

const WatchtimeBillsCreatorTitleArrayCreator = [
  "Creator",
  "Amount",
  "Watchtime",
  "Status",
  "Actions",
];

const WatchtimeBillsCreatorTitleArray = {
  Month: WatchtimeBillsCreatorTitleArrayMonth,
  Creator: WatchtimeBillsCreatorTitleArrayCreator,
};

const WatchtimeBillProofsCreatorTitleArrayMonth = [
  "Month",
  "Status",
  "Actions",
];

const WatchtimeBillProofsCreatorTitleArrayCreator = [
  "Creator",
  "Amount",
  "Actions",
];

const WatchtimeBillProofsCreatorTitleArray = {
  Month: WatchtimeBillProofsCreatorTitleArrayMonth,
  Creator: WatchtimeBillProofsCreatorTitleArrayCreator,
};

const PaymentRequestsCreatorTitleArrayDate = [
  "Raised Date",
  "Amount",
  "Currency",
  "Status",
  "Action",
];

const PaymentRequestsCreatorTitleArrayCreator = [
  "Creator",
  "Amount",
  "Currency",
  "Status",
  "Action",
];

const PaymentRequestsCreatorTitleArray = {
  Date: PaymentRequestsCreatorTitleArrayDate,
  Creator: PaymentRequestsCreatorTitleArrayCreator,
};

const FundsOverviewTitleArray = [
  "Type",
  "Raised Date",
  "Amount",
  "Status",
  "Actions",
];

const CreatorFundsOverviewTitleArray = [
  "Raised Date",
  "Amount",
  "Status",
  "Actions",
];

const AdvertiserOpenRequestTitleArray = [
  "Date",
  "Content Name",
  "Creator Name",
  "Type",
  "Rate/Amount",
  "Actions",
];

const AdvertiserOtherRequestTitleArray = [
  "Date",
  "Content Name",
  "Creator Name",
  "Status",
  "Actions",
];

const CreatorOpenRequestTitleArray = [
  "Date",
  "Content Name",
  "Advertiser Name",
  "Type",
  "Rate/Amount",
  "Actions",
];

const CreatorOtherRequestTitleArray = [
  "Date",
  "Content Name",
  "Advertiser Name",
  "Status",
  "Actions",
];

const AllSubspacesTitleArray = ["Name", "No of Content", "Actions"];

const ContentOverviewTitleArray = [
  "Content Name",
  "Last Day",
  "Last Week",
  "Last Month",
  "All Time",
  "Actions",
];

const SpaceOverviewTitleArray = [
  "Country Name",
  "Last Day",
  "Last Week",
  "Last Month",
  "All Time",
];

const DetailedOverviewTitleArray = [
  "Country",
  "Last Day",
  "Last Week",
  "Last Month",
  "All Time",
];

const ContractDetailsTitleArray = [
  "Content",
  "Advertiser",
  "Country",
  "Ad Slot",
  "Status",
  "Actions",
];

const CreatorContractListTitleArray = [
  "Advertiser",
  "Content",
  "Date",
  "Amount",
  "Actions",
];

const AdvertiserContractDetailsTitleArray = [
  "Type",
  "Date",
  "Views",
  "Rate",
  "Amount",
];

const CreatorContractDetailsTitleArray = ["Date", "Views", "Rate", "Amount"];

const WatchtimeBillConsumptionDetailsTitleArray = [
  "Country",
  "Rate",
  "Watchtime",
  "Total",
];

const WatchtimeBillProofsTitleArray = ["Date", "Status", "Actions"];

const Titles = {
  MasterFiles: MasterFilesTitleArray,
  AdFiles: AdFilesTitleArray,
  FundsOverview: FundsOverviewTitleArray,
  CreatorFundsOverview: CreatorFundsOverviewTitleArray,
  AddFundsAdvertiser: AddFundsRequestAdvertiserTitleArray,
  AdvertiserOpenRequests: AdvertiserOpenRequestTitleArray,
  AdvertiserOtherRequests: AdvertiserOtherRequestTitleArray,
  CreatorOpenRequests: CreatorOpenRequestTitleArray,
  CreatorOtherRequests: CreatorOtherRequestTitleArray,
  AllSubspaces: AllSubspacesTitleArray,
  ContentOverview: ContentOverviewTitleArray,
  DetailedOverview: DetailedOverviewTitleArray,
  SpaceOverview: SpaceOverviewTitleArray,
  ContractDetails: ContractDetailsTitleArray,
  CreatorContractList: CreatorContractListTitleArray,
  AdvertiserContractDetails: AdvertiserContractDetailsTitleArray,
  CreatorContractDetails: CreatorContractDetailsTitleArray,
  PaymentRequestsCreator: PaymentRequestsCreatorTitleArray,
  WatchtimeBillsCreator: WatchtimeBillsCreatorTitleArray,
  WatchtimeBillConsumptionDetails: WatchtimeBillConsumptionDetailsTitleArray,
  WatchtimeBillProofs: WatchtimeBillProofsTitleArray,
  WatchtimeBillProofsCreator: WatchtimeBillProofsCreatorTitleArray,
};

const Sorting = {
  OpenRequests: [
    {
      typeId: "1",
      type: "Alphabetical",
    },
    {
      typeId: "2",
      type: "Upload Date",
    },
    {
      typeId: "3",
      type: "No. of Requests",
    },
  ],
  MasterFiles: [
    {
      typeId: "alphabetical",
      type: "Alphabetical",
    },
    {
      typeId: "upload_date",
      type: "Upload Date",
    },
    {
      typeId: "status",
      type: "Status",
    },
  ],
  AdFiles: [
    {
      typeId: "alphabetical",
      type: "Alphabetical",
    },
    {
      typeId: "upload_date",
      type: "Upload Date",
    },
  ],
  ContentList: [
    {
      typeId: "alphabetical",
      type: "Alphabetical",
    },
    {
      typeId: "upload_date",
      type: "Upload Date",
    },
  ],
  CreateSubspace: [
    {
      typeId: "alphabetical",
      type: "Alphabetical",
    },
    {
      typeId: "created_date",
      type: "Created Date",
    },
  ],
};

const ADD_FUNDS_REQUESTS_FILTER = [
  {
    typeId: "raised",
    type: "Raised",
  },
  {
    typeId: "uploaded",
    type: "Uploaded",
  },
  {
    typeId: "approved",
    type: "Approved",
  },
  {
    typeId: "rejected",
    type: "Rejected",
  },
];

const WITHDRAW_FUNDS_REQUESTS_FILTER = [
  {
    typeId: "raised",
    type: "Raised",
  },
  {
    typeId: "approved",
    type: "Approved",
  },
];

const PAYMENT_REQUESTS_FILTER = [
  {
    typeId: "raised",
    type: "Raised",
  },
  {
    typeId: "approved",
    type: "Approved",
  },
];

const WATCHTIME_BILLS_FILTER = [
  {
    typeId: "generated",
    type: "Generated",
  },
  {
    typeId: "sent",
    type: "Sent",
  },
  {
    typeId: "paid",
    type: "Paid",
  },
];

const WATCHTIME_BILL_PROOFS_FILTER = [
  {
    typeId: "raised",
    type: "Raised",
  },
  {
    typeId: "approved",
    type: "Approved",
  },
  {
    typeId: "rejected",
    type: "Rejected",
  },
];

const ADMIN_CONTRACT_REQUESTS_FILTERS = [
  {
    typeId: "all",
    type: "All",
  },
  {
    typeId: "raised",
    type: "Raised",
  },
  {
    typeId: "accepted",
    type: "Accepted",
  },
  {
    typeId: "rejected",
    type: "Rejected",
  },
  {
    typeId: "expired",
    type: "Expired",
  },
  {
    typeId: "withdrawn",
    type: "Withdrawn",
  },
];

const Filters = {
  ADD_FUNDS: ADD_FUNDS_REQUESTS_FILTER,
  WITHDRAW_FUNDS: WITHDRAW_FUNDS_REQUESTS_FILTER,
  PAYMENT_REQUESTS: PAYMENT_REQUESTS_FILTER,
  ADMIN_CONTRACT_REQUESTS: ADMIN_CONTRACT_REQUESTS_FILTERS,
  WATCHTIME_BILLS: WATCHTIME_BILLS_FILTER,
  WATCHTIME_BILL_PROOFS: WATCHTIME_BILL_PROOFS_FILTER,
};

const ListOfCountries = [
  { name: "Argentina", code: "AR", enabled: false },
  { name: "Australia", code: "AU", enabled: false },
  { name: "Austria", code: "AT", enabled: false },
  { name: "Bangladesh", code: "BD", enabled: false },
  { name: "Belgium", code: "BE", enabled: false },
  { name: "Brazil", code: "BR", enabled: false },
  { name: "Canada", code: "CA", enabled: false },
  { name: "China", code: "CN", enabled: false },
  { name: "Colombia", code: "CO", enabled: false },
  { name: "Denmark", code: "DK", enabled: false },
  { name: "Egypt", code: "EG", enabled: false },
  { name: "France", code: "FR", enabled: false },
  { name: "Germany", code: "DE", enabled: false },
  { name: "Greece", code: "GR", enabled: false },
  { name: "Hong Kong", code: "HK", enabled: false },
  { name: "Hungary", code: "HU", enabled: false },
  { name: "Iceland", code: "IS", enabled: false },
  { name: "India", code: "IN", enabled: false },
  { name: "Indonesia", code: "ID", enabled: false },
  { name: "Ireland", code: "IE", enabled: false },
  { name: "Israel", code: "IL", enabled: false },
  { name: "Italy", code: "IT", enabled: false },
  { name: "Japan", code: "JP", enabled: false },
  { name: "South Korea", code: "SK", enabled: false },
  { name: "Malaysia", code: "MY", enabled: false },
  { name: "Mexico", code: "MX", enabled: false },
  { name: "Nepal", code: "NP", enabled: false },
  { name: "Netherlands", code: "NL", enabled: false },
  { name: "New Zealand", code: "NZ", enabled: false },
  { name: "Norway", code: "NO", enabled: false },
  { name: "Philippines", code: "PH", enabled: false },
  { name: "Poland", code: "PL", enabled: false },
  { name: "Portugal", code: "PT", enabled: false },
  { name: "Romania", code: "RO", enabled: false },
  { name: "Russian Federation", code: "RU", enabled: false },
  { name: "Saudi Arabia", code: "SA", enabled: false },
  { name: "Singapore", code: "SG", enabled: false },
  { name: "South Africa", code: "ZA", enabled: false },
  { name: "Spain", code: "ES", enabled: false },
  { name: "Sri Lanka", code: "LK", enabled: false },
  { name: "Sweden", code: "SE", enabled: false },
  { name: "Switzerland", code: "CH", enabled: false },
  { name: "Turkey", code: "TR", enabled: false },
  { name: "Taiwan", code: "TW", enabled: false },
  { name: "Thailand", code: "TH", enabled: false },
  { name: "Ukraine", code: "UA", enabled: false },
  { name: "United Arab Emirates", code: "AE", enabled: false },
  { name: "United Kingdom", code: "GB", enabled: false },
  { name: "United States", code: "US", enabled: false },
  { name: "Vietnam", code: "VN", enabled: false },
];

const Constants = {
  ListOfCountries,
};

const DisplayTypes = {
  ContentDetails: [
    {
      typeId: "details",
      type: "Details",
    },
    {
      typeId: "contracts",
      type: "Contracts",
    },
    {
      typeId: "suggestions",
      type: "Suggestions",
    },
  ],
};

const Arrays = {
  TypeOfUser,
  Sorting,
  Titles,
  Filters,
  Constants,
  DisplayTypes,
};

export default Arrays;
