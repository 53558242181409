// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box, Grid, IconButton } from "@mui/material";
import { Back } from "iconsax-react";

// Functional Imports
import Colors from "../../utils/Colors";
import OtpInput from "react-otp-input";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import CInputOutput from "../../atoms/CInputOutput";
import { ToastGenerator } from "../../utils/Functions";
import API from "../../api/API";

interface ConfirmChangePasswordProps {
  setAuthState?: any;
  email?: any;
}

const ConfirmChangePassword: FC<ConfirmChangePasswordProps> = (props) => {
  const [otp, setOtp] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isOtpOrPassword, setOtpOrPassword] = useState(true);

  const onConfirm = () => {
    if (newPassword === "" || confirmPassword === "") {
      ToastGenerator.info("Fill both fields!");
      return;
    }

    if (newPassword !== confirmPassword) {
      ToastGenerator.info("Passwords should match!");
      return;
    }

    if (newPassword.length < 8) {
      ToastGenerator.info("Password too short!");
      return;
    }

    const payload = {
      otp: otp,
      email: props.email,
      newPassword: newPassword,
    };

    API.verifyEmailAndChangePassword(payload).then((response) => {
      if (response.actionSuccess) {
        ToastGenerator.success(
          "Password changed successfully! You can use your new credentials to login!"
        );
        props.setAuthState("login");
      }
    });
  };

  return (
    <Grid
      item
      xs={12}
      md={5}
      paddingLeft={5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        height: "100vh",
        overflowY: "scroll",
        backgroundColor: Colors.lightBlueBackground,
      }}
    >
      <CUIPresets.AnimationWrapper>
        {isOtpOrPassword && (
          <>
            <Box width={470} height={60} />

            <Box
              sx={{
                ...Styles.SStyles.RowCenterStart,
              }}
            >
              <IconButton
                sx={{
                  ml: -3,
                }}
                onClick={() => {
                  props.setAuthState("login");
                }}
              >
                <Back size="32" color={Colors.black} />
              </IconButton>
              <CText.Jakarta.Size32.Bold.Black
                style={{
                  marginLeft: 8,
                }}
                text="Enter OTP"
              />
            </Box>

            <CText.Jakarta.Size18.Light.Black
              style={{
                marginTop: 24,
                width: 420,
              }}
              text={"We have sent an OTP to your registered email: "}
            />

            <CText.Jakarta.Size18.Light.Black
              style={{
                marginTop: 8,
                width: 420,
              }}
              text={props.email}
            />

            <Box
              sx={{
                width: 420,
                ...Styles.SStyles.RowCenterCenter,
                my: 4,
              }}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType="tel"
                containerStyle={{
                  width: 420,
                }}
                inputStyle={{
                  height: 60,
                  width: 45,
                  fontSize: 42,
                  marginRight: 24,
                  borderRadius: 8,
                  border: "none",
                }}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} />}
              />
            </Box>

            <CUIPresets.FilledButton
              disabled={otp.length !== 6}
              onClick={() => setOtpOrPassword(false)}
              title="Next"
              sx={{
                width: 430,
                mt: 3,
              }}
            />
          </>
        )}
        {!isOtpOrPassword && (
          <>
            <Box width={470} height={60} />

            <Box
              sx={{
                ...Styles.SStyles.RowCenterStart,
              }}
            >
              <IconButton
                sx={{
                  ml: -3,
                }}
                onClick={() => {
                  setOtpOrPassword(true);
                }}
              >
                <Back size="32" color={Colors.black} />
              </IconButton>
              <CText.Jakarta.Size32.Bold.Black
                style={{
                  marginLeft: 8,
                }}
                text="Change Password"
              />
            </Box>

            <CInputOutput.LabelInputFilledPassword
              sx={{ width: 420, mt: 4 }}
              label={"New Password"}
              textFieldProps={{
                placeholder: "Enter new password",
                value: newPassword,
                onChange: (e: any) => setNewPassword(e.target.value),
              }}
            />

            <CInputOutput.LabelInputFilledPassword
              label={"Confirm Password"}
              sx={{ width: 420, mt: 2 }}
              textFieldProps={{
                placeholder: "Confirm your password",
                value: confirmPassword,
                onChange: (e: any) => setConfirmPassword(e.target.value),
              }}
            />

            <CUIPresets.FilledButton
              title="Confirm"
              onClick={onConfirm}
              sx={{
                width: 430,
                mt: 3,
              }}
            />
          </>
        )}
      </CUIPresets.AnimationWrapper>
    </Grid>
  );
};

export default ConfirmChangePassword;
