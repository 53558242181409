// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import {
  AddSquare,
  BitcoinConvert,
  CardReceive,
  MinusSquare,
  VideoTime,
} from "iconsax-react";

// Functional Imports
import Colors from "../../utils/Colors";
import { useNavigate } from "react-router-dom";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import { PATHS } from "../../routes/Paths";

interface AdminPaymentsProps {}

const AdminPayments: FC<AdminPaymentsProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Payments" disabled />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<VideoTime size="60" color={Colors.blueBackground} />}
            title="Watchtime Bills"
            onClick={() => navigate(PATHS.ADMIN_CREATOR_WATCHTIME_BILLS)}
          />
          <CUIPresets.SelectFlow
            Icon={<CardReceive size="60" color={Colors.blueBackground} />}
            title="Payment Requests"
            onClick={() => navigate(PATHS.ADMIN_CREATOR_PAYMENT_REQUESTS)}
          />
          <CUIPresets.SelectFlow
            Icon={<AddSquare size="60" color={Colors.blueBackground} />}
            title="Add Funds Requests"
            onClick={() => navigate(PATHS.ADMIN_ADD_FUNDS_REQUESTS)}
          />
          <CUIPresets.SelectFlow
            Icon={<MinusSquare size="60" color={Colors.blueBackground} />}
            title="Withdraw Requests"
            onClick={() => navigate(PATHS.ADMIN_WITHDRAW_FUNDS_REQUESTS)}
          />
          <CUIPresets.SelectFlow
            Icon={<VideoTime size="60" color={Colors.blueBackground} />}
            title="Watchtime Rates"
            onClick={() => navigate(PATHS.ADMIN_WATCHTIME_RATES)}
          />
          <CUIPresets.SelectFlow
            Icon={<BitcoinConvert size="60" color={Colors.blueBackground} />}
            title="Currency Conversion"
            onClick={() => navigate(PATHS.ADMIN_CURRENCY_CONVERSION)}
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminPayments;
