// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";

const overviewInit = {
  subCount: 0,
  viewsCount: [],
  totalViewsCount: 0,
};

interface CreatorOverviewProps {}

const CreatorOverview: FC<CreatorOverviewProps> = (props) => {
  const [overviewDetails, setOverviewDetails] = useState(overviewInit);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getOverview().then((response) => {
      setDataLoading(false);
      setOverviewDetails(response.overview);
    });
  }, []);

  if (dataLoading) {
    return (
      <>
        <CUIPresets.PageHeader title="Overview" disabled />
        <CUIPresets.LoadingSpinner />
      </>
    );
  } else {
    return (
      <>
        <CUIPresets.PageHeader title="Overview" />

        <Box sx={{ ml: 1 }}>
          <CUIPresets.LabelValueHorizontal
            label={"Total Subscribers"}
            value={overviewDetails.subCount}
          />

          <CUIPresets.LabelValueHorizontal
            label={"Total Views (Last 30 Days)"}
            value={overviewDetails.totalViewsCount}
          />
        </Box>
      </>
    );
  }
};

export default CreatorOverview;
