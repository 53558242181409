// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { DateFunctions, ToastGenerator } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import moment from "moment";
import API from "../../api/API";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";
import CText from "../../atoms/CText";
import CComposite from "../../atoms/CComposite";
import Arrays from "../../utils/Arrays";

interface CreatorTransactionsDateProps {}

const CreatorTransactionsDate: FC<CreatorTransactionsDateProps> = (props) => {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<any>([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const startDateTemp = new Date();
    setStartDate(new Date(startDateTemp.getTime() - 86400000));
    apiCall(new Date(startDateTemp.getTime() - 86400000), new Date());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apiCall = (startDate: any, endDate: any) => {
    setDataLoading(true);
    API.getTransactionsByDate(
      DateFunctions.format(startDate, "YYYY-MM-DD"),
      DateFunctions.format(endDate, "YYYY-MM-DD")
    ).then((response) => {
      setDataLoading(false);
      populateData(response.transactions);
    });
  };

  const populateData = (dataArray: any) => {
    const apiData: any = [];
    let total = 0;

    dataArray?.map((item: any) => {
      apiData.push([
        String(item.from?.fullName),
        String(item.contentDetails?.contentDetails?.name),
        DateFunctions.format(item.date),
        String(item.amountInSSC),
        <CUIPresets.RightArrowAvatarWhite
          sx={{
            marginLeft: 2,
          }}
          onClick={() =>
            navigate(PATHS.CREATOR_CONTRACT_DETAILS + "/" + item.contractId)
          }
        />,
      ]);

      total = total + item.amountInSSC;

      return 0;
    });
    apiData.push(["Total", String(total)]);

    setTableData(apiData);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Transactions" />

        <Box
          sx={{
            ...Styles.SStyles.RowCenterSpace,
            mt: 2,
            width: 400,
          }}
        >
          <CText.Jakarta.Size16.Mid.Black text="Start Date" />
          <CComposite.DatePicker date={startDate} setDate={setStartDate} />
        </Box>

        <Box
          sx={{
            ...Styles.SStyles.RowCenterSpace,
            width: 400,
            mt: 2,
          }}
        >
          <CText.Jakarta.Size16.Mid.Black text="End Date" />
          <CComposite.DatePicker date={endDate} setDate={setEndDate} />
        </Box>

        <CUIPresets.OutlinedButton
          sx={{
            mt: 2,
            ml: 19,
            width: 250,
            mb: 2,
          }}
          title="Apply"
          onClick={() => {
            if (moment(startDate).isAfter(endDate)) {
              ToastGenerator.info("Start date has to be before End date!");
              return;
            }

            apiCall(startDate, endDate);
          }}
        />

        {!dataLoading && (
          <>
            <CTableRow.FlexTableTop
              sx={{
                width: 900,
              }}
              titleArray={Arrays.Titles.CreatorContractList}
            />
            {tableData.map((item: any) => (
              <CTableRow.FlexTableRow
                sx={{
                  width: 900,
                }}
                contentArray={item}
              />
            ))}
            {tableData.length === 0 && (
              <CTableRow.FlexTableRow
                sx={{
                  width: 900,
                }}
                contentArray={["No Data"]}
              />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorTransactionsDate;
