// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { DateFunctions, Information } from "../../utils/Functions";
import { useParams } from "react-router-dom";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import CTableRow from "../../atoms/CTableRow";
import Arrays from "../../utils/Arrays";

interface CreatorContractDetailsProps {}

const CreatorContractDetails: FC<CreatorContractDetailsProps> = (props) => {
  const { id } = useParams();

  const [dataLoading, setDataLoading] = useState(false);
  const [contractDetails, setContractDetails] = useState<any>();

  const [transactionsTable, setTransactionsTable] = useState([]);

  const [totalTransfered, setTotalTransfered] = useState(0);

  useEffect(() => {
    setDataLoading(true);
    API.getContractDetails(id).then((response) => {
      setDataLoading(false);
      setContractDetails(response.contract?.contractDetails);
      generateTransactionsData(
        response.contract?.transactions,
        response.contract?.contractDetails?.contractTerms?.rateOramount
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateTransactionsData = (transactions: any, rateOrAmount: any) => {
    const apiData: any = [];
    let totalTransfered = 0;

    transactions?.map((item: any) => {
      if (item.type === "ATC") {
        apiData.push([
          DateFunctions.format(item.date),
          String(item.views),
          String(rateOrAmount),
          String(item.amountInSSC),
        ]);

        totalTransfered = totalTransfered + item.amountInSSC;
      }

      return 0;
    });

    setTotalTransfered(totalTransfered);
    setTransactionsTable(apiData);
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title={"Contract Details"} />

        {dataLoading && <CUIPresets.LoadingSpinner />}

        {!dataLoading && (
          <Box
            sx={{
              ml: 1,
            }}
          >
            <CUIPresets.LabelValueHorizontal
              sx={{ mt: 1 }}
              label={"Creator Name"}
              value={contractDetails?.entityDetails?.creator?.fullName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Content Name"}
              value={
                contractDetails?.entityDetails?.content?.contentDetails?.name
              }
            />
            <CUIPresets.LabelValueHorizontal
              label={"Advertiser Name"}
              value={contractDetails?.entityDetails?.advertiser?.fullName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Start Date"}
              value={DateFunctions.format(contractDetails?.startDate)}
            />
            <CUIPresets.LabelValueHorizontal
              label={"End Date"}
              value={DateFunctions.format(contractDetails?.endDate)}
            />
            <CUIPresets.LabelStatus
              status={contractDetails?.status}
              chipSelect={"contracts"}
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Ad Slot Details"
            />

            <CUIPresets.LabelValueHorizontal
              label={"Country"}
              value={Information.retrieveCountry(
                contractDetails?.adSlotDetails?.selectedCountryCode
              )}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Ad Slot"}
              value={contractDetails?.adSlotDetails?.selectedAdSlot}
            />
            <CUIPresets.LabelIframeHorizontal
              label="Ad Preview"
              iframe={
                contractDetails?.adSlotDetails?.selectedAdFile?.bunnyStreamLink
              }
            />

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Contract Terms"
            />
            <CUIPresets.LabelValueHorizontal
              label={"Type Of Contract"}
              value={contractDetails?.contractTerms?.typeOfContract}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Amount"}
              value={contractDetails?.contractTerms?.rateOramount}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Contract Duration"}
              value={contractDetails?.contractTerms?.duration}
            />
            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Active Data"
            />
            <CUIPresets.LabelValueHorizontal
              label={"Total Transferred"}
              value={totalTransfered}
            />
            {contractDetails?.status === "active" && (
              <>
                <CUIPresets.LabelValueHorizontal
                  label={"Lapsed Duration"}
                  value={DateFunctions.differenceInDays(
                    contractDetails?.startDate,
                    new Date()
                  )}
                />
                <CUIPresets.LabelValueHorizontal
                  label={"Remaining Duration"}
                  value={DateFunctions.differenceInDays(
                    new Date(),
                    contractDetails?.endDate
                  )}
                />
              </>
            )}

            <CText.Roboto.Size28.Light.BlackUnderline
              style={{
                marginBottom: 16,
                marginTop: 32,
              }}
              text="Transactions"
            />
            <CTableRow.FlexTableTop
              titleArray={Arrays.Titles.CreatorContractDetails}
            />
            {transactionsTable.map((item) => (
              <CTableRow.FlexTableRow contentArray={item} />
            ))}
            {transactionsTable?.length === 0 && (
              <CTableRow.FlexTableRow contentArray={["No Transactions"]} />
            )}
          </Box>
        )}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorContractDetails;
