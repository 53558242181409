// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { ToastGenerator } from "../../utils/Functions";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CText from "../../atoms/CText";
import CInputOutput from "../../atoms/CInputOutput";
import CUIPresets from "../../atoms/CUIPresets";

interface ForgotPasswordProps {
  setAuthState?: any;
  email?: any;
  setEmail?: any;
}

const ForgotPassword: FC<ForgotPasswordProps> = (props) => {
  // const [email, setEmail] = useState("");
  const [disableSendOTP, setDisableSendOTP] = useState(false);

  const sendOTP = () => {
    if (props.email === "") {
      ToastGenerator.infoDark("Email cannot be empty!");
      return;
    }

    setDisableSendOTP(true);
    API.forgotViewerProfile(props.email).then((response) => {
      setDisableSendOTP(false);
      if (response.actionSuccess) {
        props.setAuthState(Constants.TYPES.VIEWER_AUTH_STATES.VERIFY_CHANGE);
      }
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: 500,
          height: 500,
        }}
      >
        <CText.Jakarta.Size24.SemiBold.White
          style={{ marginTop: 32, marginLeft: 32 }}
          text="Forgot Password"
        />

        <CInputOutput.LabelInputLight
          sx={{ width: 400, mt: 2, ml: 4 }}
          label="Email"
          textFieldProps={{
            placeholder: "",
            value: props.email,
            onChange: (e: any) => props.setEmail(e.target.value),
          }}
        />

        <CUIPresets.ViewerFilled
          disabled={disableSendOTP}
          onClick={sendOTP}
          title={"Send OTP"}
          sx={{ width: 400, height: 40, ml: 4, mt: 3 }}
        />

        <CUIPresets.ViewerOutlined
          title={"Back"}
          onClick={() =>
            props.setAuthState(Constants.TYPES.VIEWER_AUTH_STATES.LOGIN)
          }
          sx={{ width: 400, height: 40, ml: 4, mt: 2 }}
        />
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default ForgotPassword;
