// React Imports
import { FC, useState } from "react";

// MUI Imports
import {
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Box,
  TextField,
  ButtonBase,
  Avatar,
  IconButton,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DirectSend, Eye, EyeSlash } from "iconsax-react";

// Functional Imports
import { NumberTypeInputProps } from "../../utils/Functions";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";

// Local Imports
import CTools from "../CTools";
import CText from "../CText";
import ViewerCInputOutput from "./ViewerCInputOutput";

interface SelectBoxProps {
  label?: any;
  selected?: any;
  setValue?: any;
  options?: any;
  xsKey?: any;
  xsValue?: any;
  disabled?: any;
  placeholder?: any;
  sx?: any;
  selectBoxProps?: any;
  error?: any;
}

const SelectBoxFilled: FC<SelectBoxProps> = (props) => {
  return (
    <FormControl sx={{ width: "90%", mt: 2, ...props.sx }}>
      <CText.Jakarta.Size18.Mid.Grey1 text={props.label} />
      <Select
        size="small"
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
        }}
        disabled={props.disabled}
        error={props.error}
        placeholder="Select product category"
        style={{ marginTop: "8px", backgroundColor: "white" }}
        value={props.selected}
        onChange={props.setValue}
        displayEmpty
        {...props.selectBoxProps}
      >
        <MenuItem key="0" disabled value={undefined || null || ""}>
          <CText.Jakarta.Size16.Light.Grey2 text={props.placeholder} />
        </MenuItem>
        {props.options.map((item: any, index: any) => {
          return (
            <MenuItem key={index} value={item[props.xsKey]}>
              <CText.Jakarta.Size16.Light.Black text={item[props.xsValue]} />
            </MenuItem>
          );
        })}
      </Select>

      {props.error && (
        <Box
          sx={{
            ml: 1,
            mt: 2,
          }}
        >
          <CTools.HelperText.Absolute>{props.error}</CTools.HelperText.Absolute>
        </Box>
      )}
    </FormControl>
  );
};

const SelectBoxOutlined: FC<SelectBoxProps> = (props) => {
  return (
    <FormControl sx={{ width: "90%", mt: 2, ...props.sx }}>
      <CText.Jakarta.Size16.Mid.Black text={props.label} />
      <Select
        size="small"
        disabled={props.disabled}
        error={props.error}
        placeholder="Select product category"
        style={{ marginTop: "8px", backgroundColor: "white" }}
        value={props.selected}
        onChange={props.setValue}
        displayEmpty
        {...props.selectBoxProps}
      >
        <MenuItem key="0" disabled value={undefined || null || ""}>
          <CText.Jakarta.Size16.Mid.Grey1 text={props.placeholder} />
        </MenuItem>
        {props.options.map((item: any, index: any) => {
          return (
            <MenuItem key={index} value={item[props.xsKey]}>
              <CText.Jakarta.Size16.Light.Black text={item[props.xsValue]} />
            </MenuItem>
          );
        })}
      </Select>

      {props.error && (
        <CTools.HelperText.Relative>{props.error}</CTools.HelperText.Relative>
      )}
    </FormControl>
  );
};

const SelectBoxHorizontal: FC<SelectBoxProps> = (props) => {
  return (
    <FormControl
      sx={{
        ...Styles.SStyles.RowStartSpace,
        flexDirection: "row",
        width: 560,
        mt: 2,
        ...props.sx,
      }}
    >
      <CText.Jakarta.Size16.Mid.Grey3
        style={{ marginTop: 8 }}
        text={props.label}
      />
      <Box>
        <Select
          size="small"
          disabled={props.disabled}
          error={props.error}
          placeholder="Select product category"
          style={{ backgroundColor: "white", width: 240 }}
          value={props.selected}
          onChange={props.setValue}
          displayEmpty
          {...props.selectBoxProps}
        >
          <MenuItem key="0" disabled value={undefined || null || ""}>
            <CText.Jakarta.Size16.Mid.Grey1 text={props.placeholder} />
          </MenuItem>
          {props.options?.map((item: any, index: any) => {
            return (
              <MenuItem key={index} value={item[props.xsKey]}>
                <CText.Jakarta.Size16.Light.Black text={item[props.xsValue]} />
              </MenuItem>
            );
          })}
        </Select>

        {props.error && (
          <CTools.HelperText.Relative>{props.error}</CTools.HelperText.Relative>
        )}
      </Box>
    </FormControl>
  );
};

const SelectBoxFilledLight: FC<SelectBoxProps> = (props) => {
  return (
    <FormControl sx={{ width: "90%", mt: 2, ...props.sx }}>
      <CText.Jakarta.Size18.Mid.Grey1 text={props.label} />
      <Select
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
        }}
        disabled={props.disabled}
        error={props.error}
        placeholder="Select product category"
        style={{
          marginTop: "8px",
          backgroundColor: Colors.greyBackground2,
          height: 50,
        }}
        value={props.selected}
        onChange={props.setValue}
        displayEmpty
        {...props.selectBoxProps}
      >
        <MenuItem key="0" disabled value={undefined || null || ""}>
          <CText.Jakarta.Size18.Mid.Grey1 text={props.placeholder} />
        </MenuItem>
        {props.options.map((item: any, index: any) => {
          return (
            <MenuItem key={index} value={item[props.xsKey]}>
              <CText.Jakarta.Size18.Mid.Grey1 text={item[props.xsValue]} />
            </MenuItem>
          );
        })}
      </Select>

      {props.error && (
        <CTools.HelperText.Relative>{props.error}</CTools.HelperText.Relative>
      )}
    </FormControl>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface SelectMultipleProps {
  label?: any;
  sx?: any;
  options?: any;
  xsKey?: any;
  xsValue?: any;
  value?: any;
  setValue?: any;
  placeholder?: any;
}

const SelectMultiple: FC<SelectMultipleProps> = (props) => {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    props.setValue(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ ...props.sx }}>
      <CText.Jakarta.Size16.Mid.Black text={props.label} />

      <Select
        multiple
        sx={{
          boxShadow: "none",
          mt: 1,
        }}
        size="small"
        value={props.value}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {props.options?.map((item: any, index: number) => (
          <MenuItem key={item} value={item}>
            <Checkbox
              sx={{ color: Colors.blueBackground }}
              checked={props.value?.indexOf(item) > -1}
            />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export interface LabelInputProps {
  label?: any;
  textFieldProps?: any;
  sx?: any;
  textFieldSx?: any;
}

const LabelInputFilled: FC<LabelInputProps> = (props) => {
  return (
    <Box sx={{ mt: 1, width: "90%", ...props.sx }}>
      <CText.Jakarta.Size18.Mid.Grey1 text={props.label} />
      <TextField
        variant="standard"
        size="small"
        InputProps={{
          disableUnderline: true,
        }}
        onWheel={(event) => {
          event.preventDefault();
        }}
        sx={{
          width: "100%",
          height: "50px",
          justifyContent: "center",
          paddingLeft: 1,
          borderRadius: "3px",
          borderWidth: 0,
          backgroundColor: Colors.white,
          mt: 1,
          ...props.textFieldSx,
        }}
        FormHelperTextProps={{ component: CTools.HelperText.Absolute }}
        {...props.textFieldProps}
      />
    </Box>
  );
};

const LabelInputFilledPassword: FC<LabelInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box sx={{ mt: 1, width: "90%", ...props.sx }}>
      <CText.Jakarta.Size18.Mid.Grey1 text={props.label} />
      <TextField
        variant="standard"
        type={showPassword ? "text" : "password"}
        size="small"
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                sx={{
                  mr: 1,
                }}
              >
                {!showPassword && <Eye />}
                {showPassword && <EyeSlash />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onWheel={(event) => {
          event.preventDefault();
        }}
        sx={{
          width: "100%",
          height: "50px",
          justifyContent: "center",
          paddingLeft: 1,
          borderRadius: "3px",
          borderWidth: 0,
          backgroundColor: Colors.white,
          mt: 1,
          ...props.textFieldSx,
        }}
        FormHelperTextProps={{ component: CTools.HelperText.Absolute }}
        {...props.textFieldProps}
      />
    </Box>
  );
};

const LabelInputOutlined: FC<LabelInputProps> = (props) => {
  return (
    <Box sx={{ mt: 2, width: "90%", ...props.sx }}>
      <CText.Jakarta.Size16.Mid.Black
        style={{ margin: 0.5 }}
        text={props.label}
      />
      <TextField
        onWheel={(event) => {
          event.preventDefault();
        }}
        size="small"
        sx={{
          width: "100%",
          maxHeight: "70px",
          borderRadius: "3px",
          borderWidth: 0,
          backgroundColor: Colors.white,
          mt: 0.5,
          ...props.textFieldSx,
        }}
        FormHelperTextProps={{ component: CTools.HelperText.Relative }}
        {...props.textFieldProps}
      />
    </Box>
  );
};

const LabelInputHorizontal: FC<LabelInputProps> = (props) => {
  return (
    <Box
      sx={{
        mt: 2,
        width: 560,
        flexDirection: "row",
        ...Styles.SStyles.RowStartSpace,
        ...props.sx,
      }}
    >
      <CText.Jakarta.Size16.Mid.Grey3
        style={{ marginTop: 16 }}
        text={props.label}
      />
      <TextField
        onWheel={(event) => {
          event.preventDefault();
        }}
        size="small"
        sx={{
          width: 240,
          minHeight: "50px",
          borderRadius: "3px",
          borderWidth: 0,
          backgroundColor: Colors.white,
          mt: 0.5,
          ...props.textFieldSx,
        }}
        FormHelperTextProps={{ component: CTools.HelperText.Relative }}
        {...props.textFieldProps}
      />
    </Box>
  );
};

interface UploadDocumentProps {
  icon?: any;
  title?: any;
  sx?: any;
}

const UploadDocument: FC<UploadDocumentProps> = (props) => {
  return (
    <ButtonBase
      sx={{
        height: 240,
        width: 360,
        border: "2.5px solid " + Colors.blueBackground,
        borderRadius: 1,
        mt: 1,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          height: 225,
          width: 345,
          border: "2px dashed " + Colors.blueBackground,
          borderRadius: 1,
          ...Styles.SStyles.ColumnCenterCenter,
        }}
      >
        <Avatar
          sx={{
            height: 100,
            width: 100,
            backgroundColor: Colors.lightBlueBackground2,
          }}
        >
          {props.icon}
        </Avatar>

        <CText.Jakarta.Size18.Mid.Blue4
          style={{
            marginTop: 18,
          }}
          text={props.title}
        />
      </Box>
    </ButtonBase>
  );
};

interface SelectVideoProps {
  sx?: any;
  setVideo?: any;
}

const SelectVideo: FC<SelectVideoProps> = (props) => {
  return (
    <ButtonBase
      sx={{
        height: 240,
        width: 360,
        border: "2.5px solid " + Colors.blueBackground,
        borderRadius: 1,
        mt: 1,
        ...props.sx,
      }}
      component="label"
    >
      <Box
        sx={{
          height: 225,
          width: 345,
          border: "2px dashed " + Colors.blueBackground,
          borderRadius: 1,
          ...Styles.SStyles.ColumnCenterCenter,
        }}
      >
        <Avatar
          sx={{
            height: 100,
            width: 100,
            backgroundColor: Colors.lightBlueBackground2,
          }}
        >
          <DirectSend size={48} color={Colors.blueBackground} />
        </Avatar>

        <CText.Jakarta.Size18.Mid.Blue4
          style={{
            marginTop: 18,
          }}
          text={"Select Video"}
        />
      </Box>
      <input
        type="file"
        accept={"video/*"}
        hidden
        onChange={(event: any) => {
          const EventTarget = event.target.files[0];

          if (EventTarget) {
            props.setVideo(EventTarget);
          }
        }}
      />
    </ButtonBase>
  );
};

interface SelectImageProps {
  sx?: any;
  setImage?: any;
  title?: any;
  label?: any;
}

const SelectImage: FC<SelectImageProps> = (props) => {
  return (
    <Box sx={{ ...props.sx }}>
      <CText.Jakarta.Size16.Mid.Black text={props.label} />

      <ButtonBase
        sx={{
          height: 240,
          width: 360,
          border: "2.5px solid " + Colors.blueBackground,
          borderRadius: 1,
          mt: 1,
        }}
        component="label"
      >
        <Box
          sx={{
            height: 225,
            width: 345,
            border: "2px dashed " + Colors.blueBackground,
            borderRadius: 1,
            ...Styles.SStyles.ColumnCenterCenter,
          }}
        >
          <Avatar
            sx={{
              height: 100,
              width: 100,
              backgroundColor: Colors.lightBlueBackground2,
            }}
          >
            <DirectSend size={48} color={Colors.blueBackground} />
          </Avatar>

          <CText.Jakarta.Size18.Mid.Blue4
            style={{
              marginTop: 18,
            }}
            text={props.title}
          />
        </Box>
        <input
          type="file"
          accept={"image/*"}
          hidden
          onChange={(event: any) => {
            const EventTarget = event.target.files[0];

            if (EventTarget) {
              props.setImage(EventTarget);
            }
          }}
        />
      </ButtonBase>
    </Box>
  );
};
interface TimeInputProps {
  label?: any;
  sx?: any;
  time?: any;
  setTime?: any;
  onDelete?: any;
  index?: any;
}

const TimeInput: FC<TimeInputProps> = (props) => {
  return (
    <Box marginTop={1} marginBottom={1}>
      <CText.Jakarta.Size16.Mid.Black text={props.label} />
      <Box display="flex">
        <TextField
          placeholder="minutes"
          InputProps={NumberTypeInputProps}
          value={props.time?.minutes}
          onChange={(e) =>
            props.setTime({
              ...props.time,
              minutes: e.target.value,
            })
          }
          onWheel={(event) => {
            event.preventDefault();
          }}
          size="small"
          sx={{
            width: "110px",
            height: "50px",
            justifyContent: "center",
            borderRadius: "3px",
            borderWidth: 0,
            backgroundColor: Colors.white,
            mt: 0.5,
          }}
        />

        <TextField
          placeholder="seconds"
          InputProps={NumberTypeInputProps}
          onWheel={(event) => {
            event.preventDefault();
          }}
          value={props.time?.seconds}
          onChange={(e) =>
            props.setTime({
              ...props.time,
              seconds: e.target.value,
            })
          }
          size="small"
          sx={{
            width: "110px",
            height: "50px",
            justifyContent: "center",
            borderRadius: "3px",
            borderWidth: 0,
            backgroundColor: Colors.white,
            mt: 0.5,
            ml: 1,
          }}
        />

        {props.index !== 0 && (
          <IconButton
            onClick={props.onDelete}
            sx={{ height: 40, width: 40, mt: 1, ml: 1 }}
          >
            <HighlightOffIcon
              sx={{
                color: Colors.blueBackground,
                fontSize: 28,
              }}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

interface DatePickerProps {
  value?: any;
  setValue?: any;
  label?: any;
  sx?: any;
  error?: any;
  touched?: any;
  disabled?: any;
  onBlur?: any;
  innerSx?: any;
}

const DatePickerVertical: React.FC<DatePickerProps> = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        sx={{
          mt: 2,
          ...Styles.SStyles.ColumnStartStart,
          ...props.sx,
        }}
      >
        <CText.Jakarta.Size16.Mid.Black
          style={{ margin: 0.5, marginBottom: 6 }}
          text={props.label}
        />

        <MUIDatePicker
          sx={{ width: 400, ...props.innerSx }}
          disabled={props.disabled}
          format="DD/MM/YYYY"
          value={props.value}
          onChange={(newValue) => {
            props.setValue(newValue);
          }}
          slotProps={{ textField: { size: "small" } }}
        />

        {props.error && props.touched && (
          <CTools.HelperText.Relative>{props.error}</CTools.HelperText.Relative>
        )}
      </Box>
    </LocalizationProvider>
  );
};

const DatePickerHorizontal: React.FC<DatePickerProps> = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        sx={{
          mt: 2,
          ...Styles.SStyles.RowCenterCenter,
          ...props.sx,
        }}
      >
        <CText.Jakarta.Size16.Mid.Black
          style={{ margin: 0.5, marginRight: 8, marginBottom: 6 }}
          text={props.label}
        />

        <MUIDatePicker
          sx={{ width: 400, ...props.innerSx }}
          disabled={props.disabled}
          format="DD/MM/YYYY"
          value={props.value}
          onChange={(newValue) => {
            props.setValue(newValue);
          }}
          slotProps={{ textField: { size: "small" } }}
        />

        {props.error && props.touched && (
          <CTools.HelperText.Relative>{props.error}</CTools.HelperText.Relative>
        )}
      </Box>
    </LocalizationProvider>
  );
};

const DatePicker = {
  Vertical: DatePickerVertical,
  Horizontal: DatePickerHorizontal,
};

interface UpdateImageProps {
  sx?: any;
  image?: any;
  setImage?: any;
  label?: any;
}

const UpdateImage: FC<UpdateImageProps> = (props) => {
  return (
    <Box sx={{ ...props.sx }}>
      <CText.Jakarta.Size16.Mid.Black
        style={{ marginBottom: 8 }}
        text={props.label}
      />

      <CTools.ImageContainer
        height={240}
        width={360}
        src={props.image}
        imageStyles={{
          borderRadius: 0,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          resizeMethod: "cover",
        }}
      />

      <ButtonBase
        sx={{
          height: 40,
          width: 360,
          border: "2.5px solid " + Colors.blueBackground,
          borderRadius: 1,
          mt: 3,
        }}
        component="label"
      >
        <CText.Outfit.Size18.SemiBold.Blue3 text={"Update Image"} />
        <input
          type="file"
          accept={"image/*"}
          hidden
          onChange={(event: any) => {
            const EventTarget = event.target.files[0];

            if (EventTarget) {
              props.setImage(EventTarget);
            }
          }}
        />
      </ButtonBase>
    </Box>
  );
};

const CInputOutput = {
  // Creator, Advertiser and Admin
  SelectBoxFilled,
  SelectBoxOutlined,
  SelectBoxHorizontal,
  SelectBoxFilledLight,
  SelectMultiple,
  LabelInputFilled,
  LabelInputOutlined,
  LabelInputHorizontal,
  UploadDocument,
  TimeInput,
  SelectVideo,
  SelectImage,
  UpdateImage,
  DatePicker,
  LabelInputFilledPassword,

  // Viewer
  LabelInputLight: ViewerCInputOutput.LabelInputLight,
  LabelInputLightPassword: ViewerCInputOutput.LabelInputLightPassword,
};

export default CInputOutput;
