// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { LocalStorage, ToastGenerator } from "../../utils/Functions";
import API from "../../api/API";

// Local Imports
import CComposite from "../../atoms/CComposite";
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";

interface CreatorProfileInfoProps {}

const CreatorProfileInfo: FC<CreatorProfileInfoProps> = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    fullName: "",
    userName: "",
    email: "",
  });
  const [editEnabled, setEditEnabled] = useState(false);
  const [editFullName, setEditFullName] = useState("");

  useEffect(() => {
    setDataLoading(true);
    API.getClientAccountById(LocalStorage.getString("ClientID")).then(
      (response) => {
        setDataLoading(false);
        setProfileInfo(response.client);
      }
    );
  }, []);

  const onConfirm = () => {
    if (editFullName !== profileInfo.fullName) {
      API.creatorUpdate(editFullName).then((response) => {
        if (response.actionSuccess) {
          ToastGenerator.success("Update successful!");
          setEditEnabled(false);
          setProfileInfo({
            ...profileInfo,
            fullName: editFullName,
          });
        }
      });
    } else {
      setEditEnabled(false);
    }
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Profile Info" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Profile Info" />

          <Box sx={{ mt: 1, ml: 1 }}>
            <CUIPresets.LabelValueHorizontal
              label={"Email"}
              value={profileInfo.email}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Username"}
              value={profileInfo.userName}
            />
            {!editEnabled && (
              <CUIPresets.LabelValueHorizontal
                label={"Full Name"}
                value={profileInfo.fullName}
              />
            )}

            {editEnabled && (
              <CInputOutput.LabelInputHorizontal
                label="Full Name"
                textFieldProps={{
                  placeholder: "Enter full name",
                  value: editFullName,
                  onChange: (e: any) => setEditFullName(e.target.value),
                }}
                sx={{
                  mt: -2,
                }}
              />
            )}

            {!editEnabled && (
              <>
                <CUIPresets.OutlinedButton
                  sx={{
                    width: 150,
                  }}
                  title="Edit"
                  onClick={() => {
                    setEditFullName(profileInfo.fullName);
                    setEditEnabled(true);
                  }}
                />
              </>
            )}

            {editEnabled && (
              <CComposite.FooterActions.Filled
                onClick={onConfirm}
                onCancel={() => setEditEnabled(false)}
                sx={{
                  mb: 4,
                  mt: 2,
                  width: 560,
                }}
                confirmText={"Confirm"}
              />
            )}
          </Box>
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default CreatorProfileInfo;
