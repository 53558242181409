// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Styles from "../../utils/Styles";
import Colors from "../../utils/Colors";

// Local Imports
import CText from "../../atoms/CText";

interface UploadingToServerProps {
  setActive?: any;
  totalSize?: any;
  uploadedSize?: any;
  progressPercentage?: any;
  speed?: any;
  timeLeft?: any;
}

const UploadingToServer: FC<UploadingToServerProps> = (props) => {
  return (
    <Box
      sx={{
        ...Styles.SStyles.FullHeightFullWidth,
        ...Styles.SStyles.RowCenterCenter,
      }}
    >
      <Box
        sx={{
          width: 380,
          height: 460,
          ...Styles.SStyles.elevation,
          position: "relative",
          ...Styles.SStyles.ColumnCenterCenter,
        }}
      >
        <Box sx={{ width: "40%", height: "40%" }}>
          <CircularProgressbar
            value={props.progressPercentage}
            text={`${props.progressPercentage}%`}
            styles={buildStyles({
              textColor: Colors.blueBackground,
              pathColor: Colors.blueBackground,
              trailColor: Colors.greyBackground,
            })}
          />
        </Box>

        <CText.Jakarta.Size18.Mid.Blue4
          style={{
            marginBottom: 8,
          }}
          text="Uploading To Server..."
        />

        <CText.Jakarta.Size14.SemiBold.Grey1
          style={{
            marginBottom: 24,
          }}
          text={
            props.uploadedSize + " MB of " + props.totalSize + " MB Uploaded"
          }
        />
        <Box
          display="flex"
          width="95%"
          sx={{ position: "absolute", bottom: 80 }}
          justifyContent={"space-between"}
        >
          <CText.Jakarta.Size14.SemiBold.Black
            text={"Uploading Speed: " + props.speed + " MBPS"}
          />

          <CText.Jakarta.Size14.SemiBold.Black
            text={"Estimated Time: " + props.timeLeft}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            height: 60,
            borderTop: "1px solid " + Colors.greyBackground,
            width: "100%",
            ...Styles.SStyles.RowCenterCenter,
          }}
        >
          <CText.Outfit.Size18.SemiBold.Blue3 text={"Do not close this tab!"} />
        </Box>
      </Box>
    </Box>
  );
};

export default UploadingToServer;
