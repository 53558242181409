// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box, ButtonBase } from "@mui/material";

// Functional Imports
import { LocalStorage, ToastGenerator } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { PATHS } from "../../routes/Paths";
import * as yup from "yup";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CText from "../../atoms/CText";
import CInputOutput from "../../atoms/CInputOutput";
import CUIPresets from "../../atoms/CUIPresets";

const initValues = {
  email: "",
  password: "",
};

interface LoginProps {
  setAuthState?: any;
}

const Login: FC<LoginProps> = (props) => {
  const navigate = useNavigate();
  const [disableLogin, setDisableLogin] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({}),
    onSubmit: (values: any) => {
      if (values.email === "") {
        ToastGenerator.infoDark("Email is required!");
      } else if (values.password === "") {
        ToastGenerator.infoDark("Password is required!");
      } else {
        const payload = {
          email: values.email,
          password: values.password,
        };
        setDisableLogin(true);
        API.loginViewer(payload).then((response) => {
          setDisableLogin(false);
          if (
            response.message === Constants.API_RESPONSES.VIEWER_LOGIN_SUCCESS
          ) {
            ToastGenerator.successDark(
              "Welcome " + response.Viewer.fullName + "!"
            );
            LocalStorage.setString("JWT", response.token);
            LocalStorage.setString("Type", "viewer");
            LocalStorage.setString("grid", "comfortable");
            setTimeout(() => {
              window.location.reload();
            }, 500);
            setTimeout(() => {
              navigate(PATHS.VIEWER_NAVIGATE_TO);
            }, 1000);
          }
        });
      }
    },
  });

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: 500,
          height: 500,
        }}
      >
        <CText.Jakarta.Size24.SemiBold.White
          style={{ marginTop: 32, marginLeft: 32 }}
          text="Login"
        />

        <CInputOutput.LabelInputLight
          sx={{ width: 400, mt: 2, ml: 4 }}
          label="Email"
          textFieldProps={{
            placeholder: "",
            helperText: formik.touched.email && formik.errors.email,
            error: Boolean(formik.touched.email && formik.errors.email),
            onBlur: formik.handleBlur,
            onChange: formik.handleChange,
            value: formik.values.email,
            name: "email",
          }}
        />
        <CInputOutput.LabelInputLightPassword
          sx={{ width: 400, mt: 2, ml: 4 }}
          label="Password"
          textFieldProps={{
            placeholder: "",
            helperText: formik.touched.password && formik.errors.password,
            error: Boolean(formik.touched.password && formik.errors.password),
            onBlur: formik.handleBlur,
            onChange: formik.handleChange,
            value: formik.values.password,
            name: "password",
          }}
        />

        <Box
          display="flex"
          justifyContent={"end"}
          sx={{
            marginTop: 2,
            width: 430,
          }}
        >
          <ButtonBase
            onClick={() =>
              props.setAuthState(
                Constants.TYPES.VIEWER_AUTH_STATES.FORGOT_PASSWORD
              )
            }
          >
            <CText.Jakarta.Size16.SemiBold.Cyan text="Forgot Password?" />
          </ButtonBase>
        </Box>

        <CUIPresets.ViewerFilled
          disabled={disableLogin}
          onClick={() => formik.handleSubmit()}
          title={"Login"}
          sx={{ width: 400, height: 40, ml: 4, mt: 3 }}
        />

        <CUIPresets.OrBox sx={{ width: 400, ml: 4, my: 2 }} />

        <CUIPresets.ViewerOutlined
          title={"Register"}
          onClick={() =>
            props.setAuthState(Constants.TYPES.VIEWER_AUTH_STATES.REGISTER)
          }
          sx={{ width: 400, height: 40, ml: 4 }}
        />
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default Login;
