// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { NumberTypeInputProps, ToastGenerator } from "../../utils/Functions";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import API from "../../api/API";
import * as yup from "yup";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";
import CComposite from "../../atoms/CComposite";

const initValues = {
  selectedCountryCode: "",
  adFileId: "",
  selectedAdSlot: "",
  typeOfContract: "",
  rateOramount: "",
  duration: "",
};

interface AdvertiserContractRequestFormProps {}

const preRequestDataInit = {
  adFiles: [],
  countryAvailableAdSlots: [],
  advertiserDetails: {
    advertiserId: "",
    advertiserName: "",
  },
  contentDetails: {
    contentId: "",
    name: "",
    coverPhoto: "",
    countryArray: [],
    masterFile: {
      _id: "",
      masterFileName: "",
      creatorId: "",
      bunnyStreamLink: "",
      adSlots: [],
    },
  },
  creatorDetails: {
    creatorId: "",
    creatorName: "",
  },
};

const AdvertiserContractRequestForm: FC<AdvertiserContractRequestFormProps> = (
  props
) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  const [dataLoading, setDataLoading] = useState(false);
  const [preRequestData, setPreRequestData] = useState(preRequestDataInit);
  const [availableAdSlots, setAvailableAdSlots] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({
      selectedCountryCode: yup.string().required("Country is required"),
      adFileId: yup.string().required("Ad File is required"),
      selectedAdSlot: yup.string().required("Ad Slot is required"),
      typeOfContract: yup.string().required("Type of Contract is required"),
      rateOramount: yup.string().required("Amount is required"),
      duration: yup
        .number()
        .min(15, "Minimum duration is 15 days")
        .max(60, "Maximum duration is 60 days")
        .required("Duration is required"),
    }),
    onSubmit: (values: any) => {
      setModal({
        open: true,
        apiLoading: false,
      });
    },
  });

  useEffect(() => {
    setDataLoading(true);
    API.getPreRequestData(id).then((response) => {
      let countryAvailableAdSlots: any = [];

      response.preRequestData?.activeContracts?.map((item: any) => {
        let adSlots: any = [];
        item.contracts.map((itemChild: any) => {
          if (!itemChild.activeContract) {
            adSlots.push({ adSlot: itemChild.adSlot });
          }

          return 0;
        });

        countryAvailableAdSlots = [
          ...countryAvailableAdSlots,
          { countryCode: item.countryCode, adSlots: adSlots },
        ];

        return 0;
      });

      setPreRequestData({
        ...response.preRequestData,
        countryAvailableAdSlots,
      });
      setDataLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    preRequestData.countryAvailableAdSlots.map((item: any) => {
      if (item.countryCode === formik.values.selectedCountryCode) {
        setAvailableAdSlots(item.adSlots);
      }

      return 0;
    });
    formik.setFieldValue("selectedAdSlot", "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.selectedCountryCode]);

  const confirmModalCall = () => {
    setModal({
      open: true,
      apiLoading: true,
    });

    const payload = {
      advertiserId: preRequestData.advertiserDetails?.advertiserId,
      contentId: id,
      creatorId: preRequestData.creatorDetails?.creatorId,
      selectedCountryCode: formik.values.selectedCountryCode,
      adFileId: formik.values.adFileId,
      selectedAdSlot: formik.values.selectedAdSlot,
      typeOfContract: formik.values.typeOfContract,
      rateOramount: formik.values.rateOramount,
      duration: Math.floor(Number(formik.values.duration)),
    };

    API.createContractRequest(payload).then((response) => {
      if (response.message === Constants.API_RESPONSES.REQUEST_CREATE_SUCCESS) {
        ToastGenerator.success(response.message);
        setModal({
          open: false,
          apiLoading: false,
        });
        navigate(-3);
      }
    });
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Contract Request" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Contract Request" />

          <Box
            sx={{
              ml: 1,
            }}
          >
            <CUIPresets.LabelValueHorizontal
              sx={{ mt: 1 }}
              label={"Creator"}
              value={preRequestData.creatorDetails?.creatorName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Content Name"}
              value={preRequestData.contentDetails?.name}
            />
            <CUIPresets.LabelIframeHorizontal
              label={"Content Preview"}
              iframe={
                preRequestData.contentDetails?.masterFile?.bunnyStreamLink
              }
            />

            <CUIPresets.LabelValueHorizontal
              label={"Advertiser"}
              value={preRequestData.advertiserDetails?.advertiserName}
            />

            <CInputOutput.SelectBoxHorizontal
              label="Ad File"
              placeholder="Select Ad File"
              sx={{
                mt: -1,
                mb: 3,
              }}
              options={preRequestData.adFiles}
              xsKey="_id"
              xsValue="adFileName"
              error={formik.touched.adFileId && formik.errors.adFileId}
              selected={formik.values.adFileId}
              setValue={(e: any) => {
                formik.setFieldValue("adFileId", e.target.value);
              }}
            />

            <CInputOutput.SelectBoxHorizontal
              label="Country"
              placeholder="Select Country"
              options={preRequestData.contentDetails?.countryArray}
              sx={{
                mt: -1.5,
              }}
              xsKey="countryCode"
              xsValue="countryName"
              error={
                formik.touched.selectedCountryCode &&
                formik.errors.selectedCountryCode
              }
              selected={formik.values.selectedCountryCode}
              setValue={(e: any) => {
                formik.setFieldValue("selectedCountryCode", e.target.value);
              }}
            />
            {formik.values.selectedCountryCode !== "" && (
              <CInputOutput.SelectBoxHorizontal
                label="Ad Slot"
                placeholder="Select Ad Slot"
                sx={{
                  mt: 1.5,
                }}
                options={availableAdSlots}
                xsKey="adSlot"
                xsValue="adSlot"
                error={
                  formik.touched.selectedAdSlot && formik.errors.selectedAdSlot
                }
                selected={formik.values.selectedAdSlot}
                setValue={(e: any) => {
                  formik.setFieldValue("selectedAdSlot", e.target.value);
                }}
              />
            )}

            {formik.values.adFileId !== "" &&
              formik.values.selectedAdSlot !== "" &&
              formik.values.selectedCountryCode !== "" && (
                <>
                  <CInputOutput.SelectBoxHorizontal
                    label="Type of Contract"
                    placeholder="Select Type of Contract"
                    sx={{ mt: 1.5 }}
                    options={[
                      { name: "FIX", code: "FIX" },
                      { name: "CTV", code: "CTV" },
                    ]}
                    xsKey="code"
                    xsValue="name"
                    error={
                      formik.touched.typeOfContract &&
                      formik.errors.typeOfContract
                    }
                    selected={formik.values.typeOfContract}
                    setValue={(e: any) => {
                      formik.setFieldValue("typeOfContract", e.target.value);
                    }}
                  />
                  {formik.values.typeOfContract !== "" && (
                    <>
                      <CInputOutput.LabelInputHorizontal
                        label={
                          formik.values.typeOfContract === "FIX"
                            ? "Amount (In SSC)"
                            : "Rate (In SSC)"
                        }
                        textFieldProps={{
                          placeholder:
                            formik.values.typeOfContract === "FIX"
                              ? "Enter Amount"
                              : "Enter Rate",
                          InputProps: NumberTypeInputProps,
                          helperText:
                            formik.touched.rateOramount &&
                            formik.errors.rateOramount,
                          error: Boolean(
                            formik.touched.rateOramount &&
                              formik.errors.rateOramount
                          ),
                          onBlur: formik.handleBlur,
                          onChange: formik.handleChange,
                          value: String(formik.values.rateOramount),
                          name: "rateOramount",
                        }}
                        sx={{
                          mt: 1,
                        }}
                      />
                      <CInputOutput.LabelInputHorizontal
                        label="Duration (In Days)"
                        textFieldProps={{
                          placeholder: "Enter duration",
                          InputProps: NumberTypeInputProps,
                          helperText:
                            formik.touched.duration && formik.errors.duration,
                          error: Boolean(
                            formik.touched.duration && formik.errors.duration
                          ),
                          onBlur: formik.handleBlur,
                          onChange: formik.handleChange,
                          value: formik.values.duration,
                          name: "duration",
                        }}
                        sx={{
                          mt: 0,
                        }}
                        textFieldSx={{
                          justifyContent: undefined,
                          minHeight: "50px",
                        }}
                      />
                      <CComposite.FooterActions.Filled
                        onClick={() => formik.handleSubmit()}
                        sx={{
                          mt: 4,
                          mb: 4,
                          width: 760,
                        }}
                        confirmText={"Send Request"}
                      />
                    </>
                  )}
                </>
              )}
          </Box>
          <Box sx={{ height: 50 }} />
        </>

        <CUIPresets.CustomDialog
          title="Contract Request"
          content={["Are you sure about sending this contract request?"]}
          confirmText={"Confirm"}
          cancelText="Cancel"
          confirmCall={confirmModalCall}
          modalOpen={modal.open}
          cancelCall={() =>
            setModal({
              open: false,
              apiLoading: false,
            })
          }
          apiLoading={modal.apiLoading}
        />
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdvertiserContractRequestForm;
