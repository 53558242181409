// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box, Typography, Stack, FormHelperTextProps } from "@mui/material";

// Functional Imports
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Colors from "../../utils/Colors";

// Local Imports

interface ImageContainerProps {
  src?: any;
  height?: any;
  width?: any;
  sx?: any;
  imageStyles?: any;
  imageBgColor?: any;
}

const ImageContainer: FC<ImageContainerProps> = (props) => {
  return (
    <Box
      sx={{
        width: props.width,
        height: props.height,
        backgroundColor: props.imageBgColor,
        borderRadius: 1,
        ...props.sx,
      }}
    >
      <Box
        component={"img"}
        src={props.src}        
        sx={{
          height: "100%",
          width: "100%",
          borderRadius: 1,
          objectFit: "cover",
          objectPosition: "center",
          ...props.imageStyles,
        }}
      />
    </Box>
  );
};

ImageContainer.defaultProps = {
  imageBgColor: Colors.greyBorder,
};

interface HelperTextProps {}

const HelperTextRelative: FC<HelperTextProps> = ({
  children,
}: FormHelperTextProps) => (
  <Stack
    direction="row"
    spacing={0.5}
    alignItems="center"
    sx={{ marginTop: 1.25 }}
  >
    <ErrorOutlineIcon color="error" />
    <Typography component="span" variant="body2" color="error">
      {children}
    </Typography>
  </Stack>
);

const HelperTextLight: FC<HelperTextProps> = ({
  children,
}: FormHelperTextProps) => (
  <Stack
    direction="row"
    spacing={0.5}
    alignItems="center"
    sx={{ position: "absolute", bottom: -30 }}
  >
    <ErrorOutlineIcon color="error" />
    <Typography component="span" variant="body2" color="error">
      {children}
    </Typography>
  </Stack>
);

const HelperTextAbsolute: FC<HelperTextProps> = ({
  children,
}: FormHelperTextProps) => (
  <Stack
    direction="row"
    spacing={0.5}
    alignItems="center"
    sx={{ position: "absolute", bottom: -30 }}
  >
    <ErrorOutlineIcon color="error" />
    <Typography component="span" variant="body2" color="error">
      {children}
    </Typography>
  </Stack>
);

const HelperText = {
  Absolute: HelperTextAbsolute,
  Relative: HelperTextRelative,
  Light: HelperTextLight,
};

interface IFrameContainerProps {
  style?: any;
  height?: any;
  width?: any;
  src?: any;
  iframeBgColor?: any;
}

const IFrameContainer: FC<IFrameContainerProps> = (props) => {
  
  return (
    <iframe
      title="unique"
      style={{
        border: "none",
        height: props.height,
        width: props.width,
        borderRadius: 4,
        backgroundColor: props.iframeBgColor,
        ...props.style,
      }}
      src={props.src}
      allowFullScreen={true}
    />
  );
};

IFrameContainer.defaultProps = {
  iframeBgColor: Colors.greyBackground,
};

const CTools = {
  ImageContainer,
  HelperText,
  IFrameContainer,
};

export default CTools;
