// React Imports
import React, { FC } from "react";

// MUI Imports
import { Modal, Paper, IconButton, Slide, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Calendar } from "iconsax-react";
import CloseIcon from "@mui/icons-material/Close";

// Functional Imports
import {} from "../../utils/Functions";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";

// Local Imports
import CText from "../../atoms/CText";
import CUIPresets from "../../atoms/CUIPresets";

type DateModalProps = {
  modalOpen?: any;
  setModalOpen?: any;
  startDate?: any;
  setStartDate?: any;
  endDate?: any;
  setEndDate?: any;
  applyDate?: any;
};

const DateModal: FC<DateModalProps> = (props) => {
  return (
    <Modal
      open={props.modalOpen}
      onClose={() => undefined}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        ...Styles.SStyles.FullHeightFullWidth,
      }}
    >
      <Slide direction="left" in={props.modalOpen}>
        <Box sx={{ outline: "none" }}>
          <Paper
            sx={{
              height: "100vh",
              outline: "none",
              border: 0,
              width: "24vw",
              ...Styles.SStyles.ColumnStartStart,
              position: "relative",
              backgroundColor: Colors.blackBackground2 + "66",
            }}
          >
            <Paper
              elevation={5}
              sx={{
                zIndex: 10,
                width: "100%",
                minHeight: "60px",
                backgroundColor: Colors.blackBackground4,
                ...Styles.SStyles.RowCenterSpace,
                position: "absolute",
                top: 0,
              }}
            >
              <CText.Jakarta.Size16.Light.White
                style={{
                  marginLeft: 16,
                }}
                text={"Select Date Range"}
              />
              <IconButton
                onClick={() => {
                  if (props.setModalOpen) {
                    props.setModalOpen(false);
                  }
                }}
                sx={{
                  mr: 1,
                  ":hover": {
                    backgroundColor: Colors.white + "66",
                  },
                }}
              >
                <CloseIcon
                  
                  sx={{
                    color: Colors.white,
                  }}
                />
              </IconButton>
            </Paper>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <CText.Jakarta.Size14.Mid.White
                style={{
                  marginTop: 80,
                  marginBottom: 8,
                  marginLeft: 16,
                }}
                text="Start Date"
              />

              <DatePicker
                value={props.startDate}
                onChange={(e: any) => {
                  if (props.setStartDate) {
                    props.setStartDate(e);
                  }
                }}
                slots={{
                  openPickerIcon: CalenderWhite,
                }}
                slotProps={{
                  textField: {
                    InputProps: {
                      style: {
                        outline: "none",
                        color: "white",
                        marginBottom: 8,
                        marginLeft: 16,
                      },
                    },
                  },
                }}
              />

              <CText.Jakarta.Size14.Mid.White
                style={{
                  marginTop: 16,
                  marginBottom: 8,
                  marginLeft: 16,
                }}
                text="End Date"
              />

              <DatePicker
                value={props.endDate}
                onChange={(e: any) => {
                  if (props.setEndDate) {
                    props.setEndDate(e);
                  }
                }}
                slots={{
                  openPickerIcon: CalenderWhite,
                }}
                slotProps={{
                  textField: {
                    InputProps: {
                      style: {
                        outline: "none",
                        color: "white",
                        marginBottom: 8,
                        marginLeft: 16,
                      },
                    },
                  },
                }}
              />

              <CUIPresets.ViewerOutlined2
                title="Apply"
                // onClick={props.applyDate}
                onClick={() => {
                  if (props.applyDate) {
                    props.applyDate();
                  }
                }}
                color={Colors.cyan}
                sx={{
                  borderColor: Colors.cyan,
                  ml: 2,
                  mt: 2,
                  width: 250,
                }}
              />
            </LocalizationProvider>
          </Paper>
        </Box>
      </Slide>
    </Modal>
  );
};

export default DateModal;

interface CalenderWhiteProps {}

const CalenderWhite: FC<CalenderWhiteProps> = (props) => {
  return <Calendar color={Colors.white} />;
};
