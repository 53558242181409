const Colors = {
  // Text Colors
  greyText: "#8D8D8D",
  greyText2: "#949494",
  greyText3: "#666666",
  greyText4: "#767676",
  greyText5: "#A5A5A5",
  greyText6: '#787878',
  greyText7: "#AEAEAE",
  greyText8: "#B9B9B9",
  blueText: "#275AAD",
  blueText2: "#3373CC",
  greenText: "#7FA06F",
  redText: "#BA413B",
  orangeText: "#E9C508",

  // Pure Colors
  white: "#FFF",
  green: "#41B160",
  red: "#EA4E2C",
  black: "#000",
  cyan: "#04A9B6",
  lightGreen: "#ECF7E8",
  lightRed: "#F3D7D8",
  lightBlue: "#C9D6FF",
  lightOrange: "#F7F2DA",

  // Background Colors
  lightBlueBackground: "#F0F3FB",
  lightBlueBackground2: "#F2F5FF",
  lightBlueBackground3: "#E1EDFF",
  buttonBlueBackground: "#275AAD",
  blueBackground: "#3373CC",
  blueTableColor: "#3373CC1A",
  blueBorderColor: "#3373CC80",

  lightGreyTextfield: "#F3F3F3",
  greyBackground: "#E8E8E8",
  greyBackground2: "#2F2F2F",
  lightGreyBackground: "#F6F6F6",
  lightGreyBackground2: "#F9F9F9",

  // Borders
  greyBorder: "#EAEAEA",
  greyBorder2: "#EEEEEE",

  // Viewer UI
  blackBackground: "#070707",
  blackBackground2: "#141414",
  blackBackground3: "#232323",
  blackBackground4: "#1D1D1F",
  blackBackground5: '#292929',
  blackBackground6: "#313131",
  blackBackground7:"#232323",
  blackBackground8:"#2d2d2d",
};

export default Colors;
