// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { StringManipulation } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";
import Arrays from "../../utils/Arrays";
import Styles from "../../utils/Styles";

interface CreatorContentOverviewProps {}

const CreatorContentOverview: FC<CreatorContentOverviewProps> = (props) => {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("ALL");

  useEffect(() => {
    setDataLoading(true);
    API.getContentViewDetails("ALL").then((response) => {
      populateData(response.viewDetails);
      setDataLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateData = (dataArray: any) => {
    const apiData: any = [];

    dataArray?.map((item: any) => {
      apiData.push([
        StringManipulation.stringLimiter(item.contentName, 20),
        String(item.d1Views),
        String(item.d7Views),
        String(item.d30Views),
        String(item.allTimeViews),
        <CUIPresets.RightArrowAvatarWhite
          sx={{ ml: 2 }}
          onClick={() =>
            navigate(PATHS.CREATOR_DETAILED_OVERVIEW + "/" + item.contentId)
          }
        />,
      ]);

      return 0;
    });

    setTableData(apiData);
  };

  const selectCountryAction = (country: any) => {
    setSelectedCountry(country);
    setDataLoading(true);
    API.getContentViewDetails(country).then((response) => {
      populateData(response.viewDetails);
      setDataLoading(false);
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <Box
          sx={{
            ...Styles.SStyles.RowCenterSpace,
            width: 900,
          }}
        >
          <CUIPresets.PageHeader title="Content Overview" />

          <CUIPresets.SortBox
            options={[
              { name: "All", code: "ALL" },
              ...Arrays.Constants.ListOfCountries,
            ]}
            label={""}
            xsKey="code"
            xsValue="name"
            sx={{
              mt: 2,
              mr: 0
            }}
            selected={selectedCountry}
            setValue={(e: any) => selectCountryAction(e.target.value)}
          />
        </Box>

        {!dataLoading && (
          <>
            <CTableRow.FlexTableTopCustom
              flexArray={[1, 0.5, 0.5, 0.5, 0.5, 0.3]}
              sx={{
                width: 900,
              }}
              titleArray={Arrays.Titles.ContentOverview}
            />
            {tableData.map((item) => (
              <CTableRow.FlexTableRowCustom
                flexArray={[1, 0.5, 0.5, 0.5, 0.5, 0.3]}
                sx={{
                  width: 900,
                }}
                contentArray={item}
              />
            ))}
            {tableData.length === 0 && (
              <CTableRow.FlexTableRow
                sx={{
                  width: 900,
                }}
                contentArray={["No Data"]}
              />
            )}
          </>
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorContentOverview;
