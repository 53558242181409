// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  Calculations,
  NumberTypeInputProps,
  ToastGenerator,
} from "../../utils/Functions";
import * as yup from "yup";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";
import CComposite from "../../atoms/CComposite";

const initValues = {
  amountInSSC: "",
  currency: "",
  modeOfPayment: "",
};

interface RaisingStatusProps {}

const RaisingStatus: FC<RaisingStatusProps> = (props) => {
  const navigate = useNavigate();
  const [conversionFactor, setConversionFactor]: any = useState("");
  const [currencyConversions, setCurrencyConversions] = useState({
    USD: 0,
    INR: 0,
  });
  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });

  useEffect(() => {
    API.getCurrencyConversion().then((response) => {
      setCurrencyConversions({
        USD: response.currencyConversion?.[0]?.USD,
        INR: response.currencyConversion?.[0]?.INR,
      });
    });
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({
      amountInSSC: yup.string().required("Amount is required"),
      currency: yup.string().required("Currency is required"),
      modeOfPayment: yup.string().required("Mode of Payment is required"),
    }),
    onSubmit: (values: any) => {
      setModal({
        open: true,
        apiLoading: false,
      });
    },
  });

  const confirmModalCall = () => {
    setModal({
      open: true,
      apiLoading: true,
    });

    const payload = {
      amountInSSC: formik.values.amountInSSC,
      currency: formik.values.currency,
      amountInCurrency: Calculations.rounding(
        conversionFactor * Number(formik.values.amountInSSC),
        0
      ),
      modeOfPayment: formik.values.modeOfPayment,
    };

    API.addFundsRequest(payload).then((response) => {
      if (response.message === Constants.API_RESPONSES.ADD_FUNDS_SUCCESS) {
        ToastGenerator.success(response.message);
        setModal({
          open: false,
          apiLoading: false,
        });
        navigate(-1);
      }
    });
  };

  return (
    <Box width={"100%"}>
      <CInputOutput.LabelInputOutlined
        sx={{ width: 400, mt: 1 }}
        label="Amount"
        textFieldProps={{
          placeholder: "Enter Amount",
          InputProps: NumberTypeInputProps,
          helperText: formik.touched.amountInSSC && formik.errors.amountInSSC,
          error: Boolean(
            formik.touched.amountInSSC && formik.errors.amountInSSC
          ),
          onBlur: formik.handleBlur,
          onChange: formik.handleChange,
          value: formik.values.amountInSSC,
          name: "amountInSSC",
        }}
      />

      <CInputOutput.SelectBoxOutlined
        label="Currency"
        placeholder="Select Currency Of Payment"
        sx={{
          width: 400,
          mr: 18,
        }}
        options={[
          { name: "INR", code: "INR" },
          { name: "USD", code: "USD" },
        ]}
        xsKey="code"
        xsValue="name"
        error={formik.touched.currency && formik.errors.currency}
        selected={formik.values.currency}
        setValue={(e: any) => {
          formik.setFieldValue("currency", e.target.value);
          formik.setFieldValue("modeOfPayment", "");
          if (e.target.value === "INR") {
            setConversionFactor(currencyConversions.INR);
          } else if (e.target.value === "USD") {
            setConversionFactor(currencyConversions.USD);
          }
        }}
      />

      {formik.values.amountInSSC !== "" && formik.values.currency !== "" && (
        <>
          <CInputOutput.SelectBoxOutlined
            label="Mode of Payment"
            placeholder="Select Mode Of Payment"
            sx={{
              width: 400,
              mr: 18,
            }}
            options={
              formik.values.currency === "USD"
                ? [{ name: "Paypal", code: "PAYPAL" }]
                : [
                    { name: "Bank Account", code: "BANK" },
                    { name: "UPI", code: "UPI" },
                  ]
            }
            xsKey="code"
            xsValue="name"
            error={formik.touched.modeOfPayment && formik.errors.modeOfPayment}
            selected={formik.values.modeOfPayment}
            setValue={(e: any) => {
              formik.setFieldValue("modeOfPayment", e.target.value);
            }}
          />

          <CUIPresets.LabelValueVertical2
            label={"Exchange Rate"}
            value={"1 SSC = " + conversionFactor + " " + formik.values.currency}
          />

          <CUIPresets.LabelValueVertical2
            label={"Amount To Be Sent"}
            value={
              Calculations.rounding(
                conversionFactor * Number(formik.values.amountInSSC),
                0
              ) +
              " " +
              formik.values.currency
            }
          />

          <CComposite.FooterActions.Filled
            sx={{
              mt: 4,
              mb: 4,
              width: 760,
            }}
            onClick={() => formik.handleSubmit()}
            confirmText={"Raise Request"}
          />
        </>
      )}

      <CUIPresets.CustomDialog
        title="Raise Request"
        content={["Are you sure about raising this request?"]}
        confirmText={"Confirm"}
        cancelText="Cancel"
        confirmCall={confirmModalCall}
        modalOpen={modal.open}
        cancelCall={() =>
          setModal({
            open: false,
            apiLoading: false,
          })
        }
        apiLoading={modal.apiLoading}
      />
    </Box>
  );
};

export default RaisingStatus;
