// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { StringManipulation } from "../../utils/Functions";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";

// Local Imports
import CTools from "../../atoms/CTools";
import CTableRow from "../../atoms/CTableRow";
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import Colors from "../../utils/Colors";

interface AdvertiserSelectContentProps {}

const AdvertiserSelectContent: FC<AdvertiserSelectContentProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");

  const [spaceDataLoading, setSpaceDataLoading] = useState(false);
  const [contentDataLoading, setContentDataLoading] = useState(false);

  const [contentList, setContentList] = useState([]);
  const [spaceData, setSpaceData] = useState({
    name: "",
    background: "",
  });

  useEffect(() => {    
    setSpaceDataLoading(true);
    setContentDataLoading(true);
    API.getContentData(id).then((response) => {
      setSpaceDataLoading(false);
      setContentDataLoading(false);
      setContentList(response.contentList);
      setSpaceData(response.spaceConfigurationData);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchAction = (searchQuery: any) => {
    setSearchText(searchQuery);
    setContentDataLoading(true);

    API.getContentData(id, searchQuery).then((response) => {
      setContentList(response.contentList);
      setContentDataLoading(false);
    });
  };

  if (spaceDataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title={"Select Content"} />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <Box sx={{ position: "relative" }}>
            <CTools.ImageContainer
              height={300}
              width={800}
              imageBgColor={Colors.lightBlueBackground2}
              src={spaceData.background}
              sx={{
                mt: 2,
              }}
            />
            <CUIPresets.PageHeaderWhite
              sx={{
                position: "absolute",
                top: 16,
                left: 36,
                color: "white",
              }}
              title={spaceData.name}
            />
            <CText.Roboto.Size28.Light.BlackUnderline
              text="Select Content"
              style={{
                marginBottom: 16,
                marginTop: 16,
              }}
            />

            <Box
              sx={{
                width: 800,
                marginBottom: 2,
                mt: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CUIPresets.SearchBar
                placeholder={"Search Content"}
                textFieldProps={{
                  value: searchText,
                  onChange: (ev: any) => searchAction(ev.target.value),
                }}
              />
            </Box>
          </Box>

          {!contentDataLoading &&
            contentList?.map((item: any) => (
              <CTableRow.SelectContentRow
                coverPhoto={item.contentDetails?.coverPhoto}
                contentName={item.contentDetails?.name}
                duration={item.masterFile?.duration}
                adSlotInfo={StringManipulation.adSlotDisplay(item.masterFile?.adSlots)}
                onClick={() =>
                  navigate(PATHS.ADVERTISER_CONTRACT_REQUEST + "/" + item._id)
                }
              />
            ))}
          {!contentDataLoading && contentList.length === 0 && (
            <CTableRow.FlexTableRow contentArray={["No Data"]} />
          )}
          <Box sx={{ height: 50 }} />
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdvertiserSelectContent;
