// React Imports
import React, { FC } from "react";

// MUI Imports
import {} from "@mui/material";
import {} from "iconsax-react";

// Functional Imports

// Local Imports
import CInputOutput from "../../atoms/CInputOutput";
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import CTools from "../../atoms/CTools";
import CComposite from "../../atoms/CComposite";
import { ToastGenerator } from "../../utils/Functions";

interface ContentCreationProps {
  // DATA
  countries?: any;
  selectedCountries?: any;
  masterFileData?: any;
  dataLoading?: any;
  contentName?: any;
  contentImage?: any;
  modal?: any;

  // FUNCTIONS
  setSelectCountry?: any;
  setContentName?: any;
  setContentImage?: any;
  onConfirm?: any;
  setModal?: any;
}

const ContentCreation: FC<ContentCreationProps> = (props) => {
  if (props.dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Create Content" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Create Content" />

          <CUIPresets.LabelValueVertical2
            label={"Reference Name"}
            value={props.masterFileData.masterFileName}
          />

          <>
            <CText.Jakarta.Size16.Mid.Grey3
              style={{
                marginBottom: 8,
                marginTop: 16,
              }}
              text="Preview"
            />

            <CTools.IFrameContainer
              src={props.masterFileData.bunnyStreamLink}
              style={{
                marginBottom: 24,
              }}
              width={320}
              height={200}
            />
          </>

          <CInputOutput.LabelInputOutlined
            sx={{ width: 360, mt: 0, ml: 0 }}
            label="Name of the Content"
            textFieldProps={{
              placeholder: "Enter Name of the Content",
              value: props.contentName,
              onChange: (e: any) => props.setContentName(e.target.value),
            }}
          />

          <CInputOutput.SelectImage
            label={"Cover Image"}
            title={"Upload Cover Image"}
            sx={{ mt: 2 }}
            setImage={(ev: any) => {
              props.setContentImage({
                file: ev,
                name: ev.name,
                size: Number(ev.size) / (1024 * 1024),
              });
            }}
          />

          <CUIPresets.SelectedFile
            imageExists={props.contentImage.file}
            fileName={props.contentImage.name}
            fileSize={props.contentImage.size}
          />

          <CText.Jakarta.Size16.Mid.Black
            style={{ marginTop: 16, marginBottom: 8 }}
            text={"Countries"}
          />

          <CUIPresets.OutlinedButton
            sx={{ width: 360 }}
            title={"Select Countries"}
            onClick={() => props.setSelectCountry(true)}
          />

          {props.selectedCountries > 0 && (
            <>
              <CText.Jakarta.Size16.Mid.Black
                style={{ marginTop: 16, marginBottom: 0 }}
                text={"Selected Countries"}
              />

              <CUIPresets.ShowSelectedItem
                sx={{ width: 360, ml: 0, mt: 0 }}
                value={props.countries}
                xsKey={"name"}
                xsEnable={"enabled"}
                label=""
              />
            </>
          )}

          <CComposite.FooterActions.Filled
            onClick={() => {
              if (props.contentName === "") {
                ToastGenerator.info("Content needs a name!");
                return;
              } else if (props.contentImage.file === null) {
                ToastGenerator.info("Content needs a cover photo!");
                return;
              } else if (props.selectedCountries === 0) {
                ToastGenerator.info(
                  "Content needs atleast one country selected!"
                );
                return;
              }

              props.setModal({
                open: true,
                apiLoading: false,
              });
            }}
            sx={{
              mt: 4,
              mb: 4,
              width: 820,
            }}
            confirmText={"Create"}
          />
          <CUIPresets.CustomDialog
            title="Create Content"
            content={["Are you sure about creating this content?"]}
            confirmText={"Confirm"}
            cancelText="Cancel"
            confirmCall={() => {
              props.onConfirm();
              props.setModal({
                open: true,
                apiLoading: true,
              });
            }}
            apiLoading={props.modal.apiLoading}
            modalOpen={props.modal.open}
            cancelCall={() =>
              props.setModal({
                open: false,
                apiLoading: false,
              })
            }
          />
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default ContentCreation;
