// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import Images from "../../utils/Images";

// Local Imports
import CTools from "../../atoms/CTools";
import Login from "./Login";
import Register from "./Register";
import CUIPresets from "../../atoms/CUIPresets";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import Constants from "../../utils/Constants";

interface ViewerAuthProps {}

const ViewerAuth: FC<ViewerAuthProps> = (props) => {
  const [authState, setAuthState] = useState(
    Constants.TYPES.VIEWER_AUTH_STATES.REGISTER
  );
  const [email, setEmail] = useState("");

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          height: "100vh",
          backgroundColor: Colors.blackBackground,
          ...Styles.SStyles.RowCenterSpace,
        }}
      >
        <CTools.ImageContainer
          imageBgColor={Colors.blackBackground2}
          src={Images.AuthBG}
          sx={{ position: "absolute", top: 0, left: 0 }}
          imageStyles={{
            borderRadius: 0,
          }}
          height={"98vh"}
          width={"80vw"}
        />
        <CTools.ImageContainer
          src={Images.BlueEllipse}
          sx={{ position: "absolute", top: -400, left: -400, zIndex: -1 }}
          imageStyles={{
            borderRadius: 0,
          }}
          height={800}
          width={800}
        />
        <CTools.ImageContainer
          src={Images.PurpleEllipse}
          sx={{
            position: "absolute",
            zIndex: -1,
            bottom: 0,
            right: 0,
            overflow: "hidden",
          }}
          imageStyles={{
            borderRadius: 0,
          }}
          height={400}
          width={400}
        />
        {authState === Constants.TYPES.VIEWER_AUTH_STATES.LOGIN && (
          <Login setAuthState={setAuthState} />
        )}
        {authState === Constants.TYPES.VIEWER_AUTH_STATES.REGISTER && (
          <Register setAuthState={setAuthState} />
        )}
        {authState === Constants.TYPES.VIEWER_AUTH_STATES.FORGOT_PASSWORD && (
          <ForgotPassword
            email={email}
            setEmail={setEmail}
            setAuthState={setAuthState}
          />
        )}
        {authState === Constants.TYPES.VIEWER_AUTH_STATES.VERIFY_CHANGE && (
          <ChangePassword setAuthState={setAuthState} email={email} />
        )}
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default ViewerAuth;
