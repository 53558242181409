// React Imports
import React, { FC } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../CUIPresets";

interface ContentListSubspaceProps {
  grid?: any;
  contentList?: any;
  subspaceId?: any;
}

const ContentListSubspace: FC<ContentListSubspaceProps> = (props) => {
  const navigate = useNavigate();

  if (props.grid === Constants.TYPES.VIEWER_DISPLAY.COMPACT) {
    return (
      <>
        {props.contentList?.map((item: any) => (
          <CUIPresets.SmallContentWithoutTitle
            image={item.contentDetails?.coverPhoto}
            onClick={() => {
              navigate(
                PATHS.VIEWER_PLAYER + "/" + item._id + "/" + props.subspaceId
              );
            }}
          />
        ))}
      </>
    );
  } else if (props.grid === Constants.TYPES.VIEWER_DISPLAY.COMFORTABLE) {
    return (
      <>
        {props.contentList?.map((item: any) => (
          <CUIPresets.LargeContentWithoutTitle
            image={item.contentDetails?.coverPhoto}
            onClick={() => {
              navigate(
                PATHS.VIEWER_PLAYER + "/" + item._id + "/" + props.subspaceId
              );
            }}
          />
        ))}
      </>
    );
  } else return null;
};

export default ContentListSubspace;
