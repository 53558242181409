// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useParams } from "react-router-dom";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTools from "../../atoms/CTools";
import CText from "../../atoms/CText";

interface AdminAdvertiserAdFileDetailsProps {}

const AdminAdvertiserAdFileDetails: FC<AdminAdvertiserAdFileDetailsProps> = (
  props
) => {
  const { id } = useParams();

  const [displayData, setDisplayData] = useState({
    adFileName: "",
    status: "",
    spacesLink: "",
    bunnyStreamLink: "",
    duration: "",
  });
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getAdFileAdmin(id).then((response) => {
      setDataLoading(false);
      setDisplayData({
        adFileName: response.adFile.adFileName,
        status: response.adFile.status,
        spacesLink: response.adFile.spacesLink,
        bunnyStreamLink: response.adFile.bunnyStreamLink,
        duration: response.adFile.duration,
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Master File Details" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Ad File Details" />

          <Box sx={{ mt: 1, ml: 1 }}>
            <CUIPresets.LabelValueHorizontal
              label={"Ad File Name"}
              value={displayData.adFileName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Duration"}
              value={displayData.duration}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Spaces Link"}
              value={displayData.spacesLink}
            />

            <CUIPresets.LabelValueHorizontal
              label={"Status"}
              value={displayData.status}
            />

            {(displayData.status ===
              Constants.STATUS_FLAGS.MASTER_FILE.ASSIGNED ||
              displayData.status ===
                Constants.STATUS_FLAGS.MASTER_FILE.UNASSIGNED) && (
              <>
                <CText.Jakarta.Size16.Mid.Grey3
                  style={{
                    marginBottom: 8,
                    marginTop: 16,
                  }}
                  text="Preview"
                />

                <CTools.IFrameContainer
                  src={displayData.bunnyStreamLink}
                  style={{
                    marginBottom: 24,
                  }}
                  width={320}
                  height={200}
                />
              </>
            )}
          </Box>
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdminAdvertiserAdFileDetails;
