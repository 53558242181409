// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import { CardReceive, CardRemove1 } from "iconsax-react";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";

interface CreatorAdvertisementsProps {}

const CreatorAdvertisements: FC<CreatorAdvertisementsProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Advertisements" disabled />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<CardReceive size="60" color={Colors.blueBackground} />}
            title="Open Requests"
            onClick={() => navigate(PATHS.CREATOR_CREQUESTS + "/open")}
          />
          <CUIPresets.SelectFlow
            Icon={<CardRemove1 size="60" color={Colors.blueBackground} />}
            title="Other Requests"
            onClick={() => navigate(PATHS.CREATOR_CREQUESTS + "/other")}
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorAdvertisements;
