import Constants from "../../utils/Constants";
import { LocalStorage } from "../../utils/Functions";
import ACTION_TYPES from "../actions/actionTypes";

const initialState = {
  // GLOBAL
  grid: LocalStorage.getString("grid"),

  // VIEWER SEARCH
  searchedText: "",
  searched: false,
  spacesList: [],

  // VIEWER SUBSCRIPTIONS
  subscriptionsOptionSelected: Constants.TYPES.TRENDING_SUBSCRIPTION.CONTENT,

  // VIEWER TRENDING
  trendingOptionSelected: Constants.TYPES.TRENDING_SUBSCRIPTION.CONTENT,
  trendingFilter: Constants.TYPES.TRENDING_FILTER.DAY,

  // VIEWER MYSPACE
  myspaceOptionSelected: Constants.TYPES.MY_SPACE.CONTINUE_WATCHING,
  savedContentList: [],
  savedContentLoading: false,
  historyList: [],
  historyLoading: false,
};

const applicationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // GLOBAL
    case ACTION_TYPES.GRID:
      LocalStorage.setString("grid", action.payload);
      return {
        ...state,
        grid: action.payload,
      };

    // VIEWER SEARCH
    case ACTION_TYPES.SEARCHED_TEXT:
      return {
        ...state,
        searchedText: action.payload,
      };
    case ACTION_TYPES.SEARCHED:
      return {
        ...state,
        searched: action.payload,
      };
    case ACTION_TYPES.SPACES_LIST:
      return {
        ...state,
        spacesList: action.payload,
      };
    case ACTION_TYPES.SUBSCRIPTIONS_OPTION_SELECTED:
      return {
        ...state,
        subscriptionsOptionSelected: action.payload,
      };
    case ACTION_TYPES.MYSPACE_OPTION_SELECTED:
      return {
        ...state,
        myspaceOptionSelected: action.payload,
      };
    case ACTION_TYPES.SAVED_CONTENT_LIST:
      return {
        ...state,
        savedContentList: action.payload,
      };
    case ACTION_TYPES.SAVED_CONTENT_LOADING:
      return {
        ...state,
        savedContentLoading: action.payload,
      };
    case ACTION_TYPES.HISTORY_LIST:
      return {
        ...state,
        historyList: action.payload,
      };
    case ACTION_TYPES.HISTORY_LOADING:
      return {
        ...state,
        historyLoading: action.payload,
      };
    case ACTION_TYPES.TRENDING_OPTION_SELECTED:
      return {
        ...state,
        trendingOptionSelected: action.payload,
      };
    case ACTION_TYPES.TRENDING_FILTER:
      return {
        ...state,
        trendingFilter: action.payload,
      };
    default:
      return state;
  }
};

export default applicationReducer;
