// React Imports
import React, { FC } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate } from "react-router-dom";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../CUIPresets";

interface FooterActionsProps {
  confirmText?: any;
  sx?: any;
  onClick?: any;
  onCancel?: any;
  cancelText?: any;
}

const FooterActionsFilled: FC<FooterActionsProps> = (props) => {
  const navigate = useNavigate();

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    } else {
      navigate(-1);
    }
  };

  return (
    <Box
      sx={{
        width: 790,
        justifyContent: "end",
        display: "flex",
        ...props.sx,
      }}
    >
      <CUIPresets.OutlinedButton
        onClick={onCancel}
        sx={{
          textTransform: "none",
          width: "90px",
          height: "40px",
          marginRight: 1,
          borderWidth: 0,
          ":hover": {
            borderWidth: 0,
          },
        }}
        title={props.cancelText}
      />
      <CUIPresets.FilledButton
        onClick={props.onClick}
        color={Colors.blueBackground}
        sx={{
          textTransform: "none",
          width: "180px",
          height: "40px",
        }}
        title={props.confirmText}
      />
    </Box>
  );
};

FooterActionsFilled.defaultProps = {
  cancelText: "Cancel",
};

const FooterActionsOutlined: FC<FooterActionsProps> = (props) => {
  const navigate = useNavigate();

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    } else {
      navigate(-1);
    }
  };

  return (
    <Box
      sx={{
        width: 790,
        justifyContent: "end",
        display: "flex",
        ...props.sx,
      }}
    >
      <CUIPresets.OutlinedButton
        onClick={onCancel}
        sx={{
          textTransform: "none",
          width: "90px",
          height: "40px",
          marginRight: 1,
          borderWidth: 0,
          ":hover": {
            borderWidth: 0,
          },
        }}
        title={props.cancelText}
      />
      <CUIPresets.OutlinedButton
        onClick={props.onClick}
        color={Colors.blueBackground}
        sx={{
          textTransform: "none",
          width: "180px",
          height: "40px",
        }}
        title={props.confirmText}
      />
    </Box>
  );
};

FooterActionsOutlined.defaultProps = {
  cancelText: "Cancel",
};

const FooterActions = {
  Filled: FooterActionsFilled,
  Outlined: FooterActionsOutlined,
};

export default FooterActions;
