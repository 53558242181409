// React Imports
import React, { FC } from "react";

// MUI Imports
import { Grid } from "@mui/material";
import { SearchNormal1 } from "iconsax-react";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";

interface AdvertiserCreatorProps {}

const AdvertiserCreator: FC<AdvertiserCreatorProps> = (props) => {
  const navigate = useNavigate();

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <CUIPresets.PageHeader title="Creators" disabled />

        <Grid container marginTop={2}>
          <CUIPresets.SelectFlow
            Icon={<SearchNormal1 size="60" color={Colors.blueBackground} />}
            title="Search Creators"
            onClick={() => navigate(PATHS.ADVERTISER_SEARCH_CREATOR)}
          />
        </Grid>
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdvertiserCreator;
