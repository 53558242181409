// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box, Grid, ButtonBase } from "@mui/material";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Colors from "../../utils/Colors";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CInputOutput from "../../atoms/CInputOutput";
import CText from "../../atoms/CText";
import API from "../../api/API";

const initValues = {
  email: "",
  password: "",
};

interface LoginProps {
  setAuthState?: any;
  setEmail?: any;
}

const Login: FC<LoginProps> = (props) => {
  const navigate = useNavigate();

  const [disableLogin, setDisableLogin] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({
      email: yup.string().required("Email is required"),
      password: yup.string().required("Enter password"),
    }),
    onSubmit: (values: any) => {
      setDisableLogin(true);
      API.loginClientAccount(values, navigate).then((response) => {
        setDisableLogin(false)
        if (response.otpSent) {
          props.setAuthState("otp");
          props.setEmail(values.email);
        }
      });
    },
  });

  return (
    <Grid
      item
      xs={12}
      md={5}
      paddingLeft={5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        height: "100vh",
        overflowY: "scroll",
        backgroundColor: Colors.lightBlueBackground,
      }}
    >
      <CUIPresets.AnimationWrapper>
        <>
          <Box width={470} height={60} />

          <CText.Jakarta.Size32.Bold.Black
            style={{
              marginTop: 24,
              width: 420,
            }}
            text="Login"
          />

          <CInputOutput.LabelInputFilled
            sx={{ width: 420, mt: 4 }}
            label="Email"
            textFieldProps={{
              placeholder: "Enter your email",
              helperText: formik.touched.email && formik.errors.email,
              error: Boolean(formik.touched.email && formik.errors.email),
              onBlur: formik.handleBlur,
              onChange: formik.handleChange,
              value: formik.values.email,
              name: "email",
            }}
          />

          <CInputOutput.LabelInputFilledPassword
            sx={{ width: 420, mt: 4 }}
            label="Password"
            textFieldProps={{
              placeholder: "Enter your password",
              helperText: formik.touched.password && formik.errors.password,
              error: Boolean(formik.touched.password && formik.errors.password),
              onBlur: formik.handleBlur,
              onChange: formik.handleChange,
              value: formik.values.password,
              name: "password",
            }}
          />

          <Box
            display="flex"
            justifyContent={"end"}
            sx={{
              marginTop: 2,
              width: 430,
            }}
          >
            <ButtonBase onClick={() => props.setAuthState("ForgotPassword")}>
              <CText.Jakarta.Size16.SemiBold.Blue1 text="Forgot Password?" />
            </ButtonBase>
          </Box>

          <CUIPresets.FilledButton
            onClick={formik.handleSubmit}
            disabled={disableLogin}
            title="Login"
            sx={{
              width: 430,
              mt: 3,
            }}
          />

          <CUIPresets.OrBox sx={{ mt: 3, width: 430 }} />

          <CUIPresets.OutlinedButton
            onClick={() => props.setAuthState("register")}
            title="Register"
            sx={{
              width: 430,
              mt: 3,
              mb: 5,
            }}
          />
        </>
      </CUIPresets.AnimationWrapper>
    </Grid>
  );
};

export default Login;
