// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import {
  Calculations,
  LocalStorage,
  StringManipulation,
} from "../../utils/Functions";
import ENDPOINTS from "../../api/ENDPOINTS";
import axios from "axios";

// Local Imports
import UploadSuccessful from "./UploadSuccessful";
import UploadingToServer from "./UploadingToServer";
import UploadNewAd from "./UploadNewAd";

interface AdvertiserUploadNewAdProps {}

const AdvertiserUploadNewAd: FC<AdvertiserUploadNewAdProps> = (props) => {
  const [active, setActive] = useState("upload");
  const [uploadData, setUploadData] = useState({
    totalSize: 0,
    uploadedSize: 0,
    progressPercentage: 0,
  });
  const [fileData, setFileData]: any = useState({
    fileName: "",
    adSlots: [],
  });

  const [speedCalculation, setSpeedCalculation] = useState({
    previouslyUploadedSize: 0,
    time: new Date(),
    speed: 0,
    timeLeft: 0,
  });

  useEffect(() => {
    const sizeDiff =
      uploadData.uploadedSize - speedCalculation.previouslyUploadedSize;
    const timeDiff =
      (new Date().getTime() - new Date(speedCalculation.time).getTime()) / 1000;

    const speed = sizeDiff / timeDiff;

    let timeLeft;
    if (speed !== 0) {
      timeLeft = (uploadData.totalSize - uploadData.uploadedSize) / speed;
    }

    setSpeedCalculation({
      previouslyUploadedSize: uploadData.uploadedSize,
      time: new Date(),
      speed: Calculations.rounding(speed, 1),
      timeLeft: Calculations.rounding(timeLeft, 0) + 20,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadData]);

  const adFileUpload = (payload: any) => {
    const adFileName = StringManipulation.masterFileTitle(payload.adFileName);

    postAdFile(adFileName, payload.videoFile.file, payload.videoFile.size);
    setActive("uploading");
    setUploadData({
      ...uploadData,
      totalSize: payload.videoFile.size,
    });
    setFileData({
      fileName: adFileName,
    });
  };

  const postAdFile = async (adFileName: any, file: any, totalSize: any) => {
    let data = new FormData();

    data.append("adFileName", adFileName);
    data.append("file", file);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: ENDPOINTS.VIDEOS.UPLOAD_AD_FILE,
      headers: {
        Authorization: "Bearer " + LocalStorage.getString("JWT"),
        "Content-Type": "multipart/form-data",
      },
      data: data,
      onUploadProgress: (progressEvent: any) => {
        const uploadedSize = Calculations.rounding(
          progressEvent.loaded / (1024 * 1024),
          1
        );

        const totalSizePass = Calculations.rounding(totalSize, 1);

        const progressPercentage = Calculations.rounding(
          (Number(progressEvent.loaded / (1024 * 1024)) / Number(totalSize)) *
            100,
          1
        );

        let progressPercentagePass;

        if (progressPercentage < 98) {
          progressPercentagePass = progressPercentage;
        } else {
          progressPercentagePass = 98;
        }

        setUploadData({
          ...uploadData,
          uploadedSize: uploadedSize,
          totalSize: totalSizePass,
          progressPercentage: progressPercentagePass,
        });
      },
    };

    axios
      .request(config)
      .then((response: any) => {
        console.log(JSON.stringify(response.data));
        setActive("completed");
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  return (
    <>
      {active === "upload" && (
        <UploadNewAd onConfirm={(payload: any) => adFileUpload(payload)} />
      )}
      {active === "uploading" && (
        <UploadingToServer
          setActive={setActive}
          totalSize={uploadData.totalSize}
          uploadedSize={uploadData.uploadedSize}
          progressPercentage={uploadData.progressPercentage}
          speed={speedCalculation.speed}
          timeLeft={speedCalculation.timeLeft}
        />
      )}
      {active === "completed" && (
        <UploadSuccessful setActive={setActive} fileName={fileData.fileName} />
      )}
    </>
  );
};

export default AdvertiserUploadNewAd;
