// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";
import { Global, I3DCubeScan, VideoCircle } from "iconsax-react";

// Functional Imports
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";
import API from "../../api/API";

// Local Imports
import CTools from "../../atoms/CTools";
import CText from "../../atoms/CText";
import CUIPresets from "../../atoms/CUIPresets";

interface ContinueWatchingProps {}

const ContinueWatching: FC<ContinueWatchingProps> = (props) => {
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState(false);
  const [continueWatchingList, setContinueWatchingList] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    API.getContinueWatching().then((response) => {
      setDataLoading(false);
      setContinueWatchingList(response.continueWatching);
    });
  }, []);

  return (
    <Box
      sx={{
        ml: 4,
        overflowY: "scroll",
        overflowX: "hidden",
        height: window.innerHeight - 72,
      }}
    >
      {dataLoading && <CUIPresets.ViewerLoading />}
      {!dataLoading &&
        continueWatchingList.map((item: any, index: any) => (
          <ContinueWatchingTile
            key={index}
            image={item.continueWatchingContent?.contentDetails?.coverPhoto}
            contentName={item.continueWatchingContent?.contentDetails?.name}
            subspaceName={item.subSpaceDetails?.subSpaceName}
            spaceName={item.continueWatchingContent?.spaceDetails?.name}
            onClick={() => {
              navigate(
                PATHS.VIEWER_PLAYER +
                  "/" +
                  item.contentId +
                  "/" +
                  item.subSpaceId
              );
            }}
          />
        ))}
      {!dataLoading && continueWatchingList.length === 0 && (
        <CUIPresets.ViewerStatusText
          sx={{ height: "80vh" }}
          text="No Content"
        />
      )}
    </Box>
  );
};

export default ContinueWatching;

interface ContinueWatchingTileProps {
  image?: any;
  contentName?: any;
  subspaceName?: any;
  spaceName?: any;
  onClick?: any;
}

const ContinueWatchingTile: FC<ContinueWatchingTileProps> = (props) => {
  return (
    <Box
      sx={{
        width: "90vw",
        height: "15vw",
        my: 2,
        mr: "2vw",
        borderRadius: 2,
        backgroundColor: Colors.blackBackground4,
        ...Styles.SStyles.RowCenterSpace,
        px: 4,
      }}
    >
      <CTools.ImageContainer
        height={180}
        width={240}
        src={props.image}
        sx={{
          mr: 2,
        }}
      />
      <Box
        sx={{
          width: "70%",
          ml: 2,
        }}
      >
        <>
          <Box
            sx={{
              ...Styles.SStyles.RowCenterStart,
            }}
          >
            <VideoCircle size="28" color={Colors.white} />
            <CText.Jakarta.Size20.Semibold.White
              style={{ marginLeft: 12 }}
              text={props.contentName}
            />
          </Box>
          <Box
            sx={{
              ...Styles.SStyles.RowCenterStart,
              mt: 4,
            }}
          >
            <Global size="28" color={Colors.cyan} />

            <CText.Jakarta.Size18.Light.Cyan
              style={{ marginLeft: 12 }}
              text={props.spaceName}
            />
          </Box>
          <Box
            sx={{
              ...Styles.SStyles.RowCenterStart,
              mt: 4,
            }}
          >
            <I3DCubeScan size="28" color={Colors.greyText} />
            <CText.Jakarta.Size18.Light.Grey1
              style={{ marginLeft: 12 }}
              text={props.subspaceName}
            />
          </Box>
        </>
      </Box>
      <CUIPresets.RightArrowAvatarLight
        sx={{
          marginRight: 4,
        }}
        onClick={props.onClick}
        iconSize={64}
      />
    </Box>
  );
};
