// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box, Grid, IconButton } from "@mui/material";
import { Back } from "iconsax-react";

// Functional Imports
import { ToastGenerator } from "../../utils/Functions";
import API from "../../api/API";
import Colors from "../../utils/Colors";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CText from "../../atoms/CText";
import CInputOutput from "../../atoms/CInputOutput";

interface ForgotPasswordProps {
  setAuthState?: any;
  email?: any;
  setEmail?: any;
}

const ForgotPassword: FC<ForgotPasswordProps> = (props) => {
  const [disableConfirm, setDisableConfirm] = useState(false);

  const onConfirm = () => {
    if (props.email?.trim() === "") {
      ToastGenerator.info("Enter email!");
      return;
    }

    setDisableConfirm(true);
    API.forgotPassword(props.email).then((response) => {
      setDisableConfirm(false);
      if (response.actionSuccess) {
        props.setAuthState("ChangePassword");
      }
    });
  };

  return (
    <Grid
      item
      xs={12}
      md={5}
      paddingLeft={5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        height: "100vh",
        overflowY: "scroll",
        backgroundColor: Colors.lightBlueBackground,
      }}
    >
      <CUIPresets.AnimationWrapper>
        <>
          <Box width={470} height={60} />
          <Box
            sx={{
              ...Styles.SStyles.RowCenterStart,
            }}
          >
            <IconButton
              sx={{
                ml: -3,
              }}
              onClick={() => {
                props.setAuthState("login");
              }}
            >
              <Back size="32" color={Colors.black} />
            </IconButton>
            <CText.Jakarta.Size32.Bold.Black
              style={{
                marginLeft: 8,
              }}
              text="Forgot Password"
            />
          </Box>
          <CText.Jakarta.Size18.Light.Black
            style={{
              marginTop: 24,
              width: 420,
            }}
            text={"Enter your registered email: "}
          />
          <CInputOutput.LabelInputFilled
            sx={{ width: 420, my: 2 }}
            textFieldProps={{
              placeholder: "Email",
              value: props.email,
              onChange: (e: any) => props.setEmail(e.target.value),
            }}
          />
          <CUIPresets.FilledButton
            disabled={disableConfirm}
            title="Confirm"
            onClick={onConfirm}
            sx={{
              width: 430,
              mt: 11,
            }}
          />
        </>
      </CUIPresets.AnimationWrapper>
    </Grid>
  );
};

export default ForgotPassword;
