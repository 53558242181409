// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { ToastGenerator, Validate } from "../../utils/Functions";
import { useFormik } from "formik";
import * as yup from "yup";
import API from "../../api/API";
import Arrays from "../../utils/Arrays";
import Constants from "../../utils/Constants";

// Local Imports
import CInputOutput from "../../atoms/CInputOutput";
import CUIPresets from "../../atoms/CUIPresets";

const initValues = {
  fullName: "",
  email: "",
  countryCode: "",
  password: "",
};

interface RegisterProps {
  setAuthState?: any;
}

const Register: FC<RegisterProps> = (props) => {
  const [disableRegister, setDisableRegister] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: yup.object().shape({}),
    onSubmit: (values: any) => {
      if (values.fullName === "") {
        ToastGenerator.infoDark("Name is required!");
        return;
      } else if (values.email === "") {
        ToastGenerator.infoDark("Email is required!");
      } else if (values.email !== "" && !Validate.email(values.email)) {
        ToastGenerator.infoDark("Email is Invalid!");
      } else if (values.countryCode === "") {
        ToastGenerator.infoDark("Country is required!");
        return;
      } else if (values.password === "") {
        ToastGenerator.infoDark("Password is required!");
        return;
      } else {
        const payload = {
          fullName: values.fullName,
          email: values.email,
          password: values.password,
          countryCode: values.countryCode,
        };

        setDisableRegister(true);
        API.registerViewer(payload).then((response) => {
          setDisableRegister(false);
          if (
            response.message ===
            Constants.API_RESPONSES.VIEWER_ACCOUNT_CREATE_SUCCESS
          ) {
            ToastGenerator.successDark(
              "Account created successfully, you can login now!"
            );
            props.setAuthState(Constants.TYPES.VIEWER_AUTH_STATES.LOGIN);
          }
        });
      }
    },
  });

  return (
    <CUIPresets.AnimationWrapper>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: 500,
          height: "95vh",
          overflow: "scroll",
        }}
      >
        <CInputOutput.LabelInputLight
          sx={{ width: 400, mt: 2, ml: 4 }}
          label="Name"
          textFieldProps={{
            placeholder: "",
            helperText: formik.touched.fullName && formik.errors.fullName,
            error: Boolean(formik.touched.fullName && formik.errors.fullName),
            onBlur: formik.handleBlur,
            onChange: formik.handleChange,
            value: formik.values.fullName,
            name: "fullName",
          }}
        />
        <CInputOutput.LabelInputLight
          sx={{ width: 400, mt: 2, ml: 4 }}
          label="Email"
          textFieldProps={{
            placeholder: "",
            helperText: formik.touched.email && formik.errors.email,
            error: Boolean(formik.touched.email && formik.errors.email),
            onBlur: formik.handleBlur,
            onChange: formik.handleChange,
            value: formik.values.email,
            name: "email",
          }}
        />

        <CInputOutput.SelectBoxFilledLight
          label="Country"
          placeholder="Select Country"
          sx={{
            width: 410,
            mt: 2,
            ml: 4,
          }}
          options={Arrays.Constants.ListOfCountries}
          xsKey="code"
          xsValue="name"
          selected={formik.values.countryCode}
          setValue={(e: any) => {
            formik.setFieldValue("countryCode", e.target.value);
          }}
        />

        <CInputOutput.LabelInputLightPassword
          sx={{ width: 400, mt: 2, ml: 4 }}
          label="Password"
          textFieldProps={{
            placeholder: "",
            helperText: formik.touched.password && formik.errors.password,
            error: Boolean(formik.touched.password && formik.errors.password),
            onBlur: formik.handleBlur,
            onChange: formik.handleChange,
            value: formik.values.password,
            name: "password",
          }}
        />
        <CUIPresets.ViewerFilled
          disabled={disableRegister}
          onClick={() => formik.handleSubmit()}
          title={"Register"}
          sx={{ width: 400, height: 40, mt: 4, ml: 4 }}
        />

        <CUIPresets.OrBox sx={{ width: 400, ml: 4, my: 2 }} />

        <CUIPresets.ViewerOutlined
          title={"Login"}
          onClick={() =>
            props.setAuthState(Constants.TYPES.VIEWER_AUTH_STATES.LOGIN)
          }
          sx={{ width: 400, height: 40, ml: 4 }}
        />
      </Box>
    </CUIPresets.AnimationWrapper>
  );
};

export default Register;
