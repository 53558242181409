// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import API from "../../api/API";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTableRow from "../../atoms/CTableRow";

interface AdminCreatorViewContentProps {}

const AdminCreatorViewContent: FC<AdminCreatorViewContentProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState(false);
  const [contentList, setContentList] = useState([]);

  const [spaceId, setSpaceId] = useState("");

  useEffect(() => {
    apiCall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apiCall = () => {
    setDataLoading(true);
    API.getContentOfViewerAdmin(id).then((response) => {
      setDataLoading(false);
      setContentList(response.contents);
      setSpaceId(response.contents?.[0]?.spaceId);
    });
  };

  const updateAdminAllowCall = (value: any) => {
    const payload = {
      type: "space",
      id: spaceId,
      newAdminAllowValue: value,
    };

    API.updateAdminAllow(payload).then((response) => {
      apiCall();
    });
  };

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <Box
          sx={{
            ...Styles.SStyles.RowCenterSpace,
            width: 800,
          }}
        >
          <CUIPresets.PageHeader title="View Content" />

          <Box>
            <CUIPresets.OutlinedButton
              sx={{
                mt: 1,
                width: 90,
                mr: 1,
              }}
              title={"True"}
              onClick={() => updateAdminAllowCall(true)}
            />

            <CUIPresets.OutlinedButton
              sx={{
                mt: 1,
                width: 90,
              }}
              title={"False"}
              onClick={() => updateAdminAllowCall(false)}
            />
          </Box>
        </Box>

        {!dataLoading &&
          contentList?.map((item: any) => (
            <CTableRow.CreatorContentRow
              image={item.contentDetails?.coverPhoto}
              title={
                item.contentDetails?.name + " (" + String(item.adminAllow) + ")"
              }
              onClick={() =>
                navigate(PATHS.ADMIN_CREATOR_CONTENT_DETAILS + "/" + item._id)
              }
            />
          ))}
        {!dataLoading && contentList?.length === 0 && (
          <CTableRow.FlexTableRow contentArray={["No Data"]} />
        )}
        {dataLoading && <CUIPresets.LoadingSpinner />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default AdminCreatorViewContent;
