// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useNavigate, useParams } from "react-router-dom";
import { ToastGenerator } from "../../utils/Functions";
import Colors from "../../utils/Colors";
import API from "../../api/API";
import Constants from "../../utils/Constants";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import CTools from "../../atoms/CTools";
import CText from "../../atoms/CText";

interface AdvertiserAdFileDetailsProps {}

const AdvertiserAdFileDetails: FC<AdvertiserAdFileDetailsProps> = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [modal, setModal] = useState({
    open: false,
    apiLoading: false,
  });
  const [displayData, setDisplayData] = useState({
    adReferenceName: "",
    duration: "",
    status: "",
    preview: "",
  });
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getAdFileById(id).then((response) => {
      setDataLoading(false);
      setDisplayData({
        adReferenceName: response.adFileSelected.adFileName,
        duration: response.adFileSelected.duration,
        status: response.adFileSelected.status,
        preview: response.adFileSelected.bunnyStreamLink,
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAction = () => {
    setModal({
      open: true,
      apiLoading: true,
    });
    API.deleteAdFileById(id).then((response) => {
      if (response.message === Constants.API_RESPONSES.DELETE_AD_FILE) {
        ToastGenerator.success(response.message);
        setModal({
          open: false,
          apiLoading: false,
        });
        navigate(-1);
      }
    });
  };

  if (dataLoading) {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Ad File Details" />
          <CUIPresets.LoadingSpinner />
        </>
      </CUIPresets.AnimationWrapper>
    );
  } else {
    return (
      <CUIPresets.AnimationWrapper>
        <>
          <CUIPresets.PageHeader title="Ad File Details" />

          <Box sx={{ mt: 1, ml: 1 }}>
            <CUIPresets.LabelValueHorizontal
              label={"Ad Reference Name"}
              value={displayData.adReferenceName}
            />
            <CUIPresets.LabelValueHorizontal
              label={"Duration"}
              value={displayData.duration}
            />
            <CUIPresets.LabelStatus
              chipSelect={"adFile"}
              status={displayData.status}
            />

            {(displayData.status === Constants.STATUS_FLAGS.AD_FILE.ASSIGNED ||
              displayData.status ===
                Constants.STATUS_FLAGS.AD_FILE.UNASSIGNED) && (
              <>
                <CText.Jakarta.Size16.Mid.Grey3
                  style={{
                    marginBottom: 8,
                    marginTop: 16,
                  }}
                  text="Preview"
                />

                <CTools.IFrameContainer
                  src={displayData.preview}
                  style={{
                    marginBottom: 24,
                  }}
                  width={320}
                  height={200}
                />
              </>
            )}
          </Box>

          {(displayData.status === Constants.STATUS_FLAGS.AD_FILE.ERROR ||
            displayData.status ===
              Constants.STATUS_FLAGS.AD_FILE.UNASSIGNED) && (
            <CUIPresets.FilledButton
              onClick={() =>
                setModal({
                  open: true,
                  apiLoading: false,
                })
              }
              title="Delete"
              color={Colors.red}
              sx={{ ml: 1, mb: 4 }}
            />
          )}

          <CUIPresets.CustomDialog
            title="Delete Master File"
            content={["Are you sure about deleting this ad file?"]}
            confirmText={"Confirm"}
            cancelText="Cancel"
            confirmCall={() => deleteAction()}
            modalOpen={modal.open}
            apiLoading={modal.apiLoading}
            cancelCall={() =>
              setModal({
                open: false,
                apiLoading: false,
              })
            }
          />
        </>
      </CUIPresets.AnimationWrapper>
    );
  }
};

export default AdvertiserAdFileDetails;
