// React Imports
import React, { FC, useState, useEffect } from "react";

// MUI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useParams } from "react-router-dom";
import API from "../../api/API";
import Arrays from "../../utils/Arrays";
import Styles from "../../utils/Styles";

// Local Imports
import CUIPresets from "../../atoms/CUIPresets";
import ContentDetails from "./ContentDetails";
import ContentSuggestions from "./ContentSuggestions";
import ActiveContracts from "./ActiveContracts";

interface CreatorContentDetailsProps {}

const CreatorContentDetails: FC<CreatorContentDetailsProps> = (props) => {
  const { id } = useParams();

  const [optionSelected, setOptionSelected] = useState("details");

  const [contentDetails, setContentDetails] = useState({
    name: "",
    coverPhoto: "",
    countryArray: [],
  });

  const [masterFileDetails, setMasterFileDetails] = useState({
    adSlots: [],
    bunnyStreamLink: "",
    duration: "",
  });

  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    API.getContentById(id).then((response) => {
      setDataLoading(false);
      setContentDetails(response.contentData?.contentDetails);
      setMasterFileDetails(response.contentData?.masterFile);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CUIPresets.AnimationWrapper>
      <>
        <Box
          sx={{
            ...Styles.SStyles.RowCenterSpace,
            width: 800,
          }}
        >
          <CUIPresets.PageHeader title="Content Details" />
          <CUIPresets.SortBox
            options={Arrays.DisplayTypes.ContentDetails}
            label={""}
            sx={{ width: 160, mt: 2 }}
            xsKey="typeId"
            xsValue="type"
            selected={optionSelected}
            setValue={(e: any) => setOptionSelected(e.target.value)}
          />
        </Box>

        {optionSelected === "details" && (
          <ContentDetails
            contentDetails={contentDetails}
            masterFileDetails={masterFileDetails}
            dataLoading={dataLoading}
          />
        )}

        {optionSelected === "contracts" && <ActiveContracts />}

        {optionSelected === "suggestions" && <ContentSuggestions />}
      </>
    </CUIPresets.AnimationWrapper>
  );
};

export default CreatorContentDetails;
