// React Imports
import React, { FC, useState } from "react";

// MUI Imports
import { Box, ButtonBase, TextField, Typography } from "@mui/material";

// Functional Imports
import Styles from "../../utils/Styles";

// Local Imports
import CTools from "../../atoms/CTools";
import SignUpViewer from "../../assets/landing/signup-viewer.png";
import SignUpCreator from "../../assets/landing/signup-creator.png";
import SignUpAdvertiser from "../../assets/landing/signup-advertiser.png";
import FeedbackDontLike from "../../assets/landing/feedback-dont-like.png";
import FeedbackLike from "../../assets/landing/feedback-like.png";
import FeedbackNoComments from "../../assets/landing/feedback-no-comments.png";
import FeedbackSeeIssues from "../../assets/landing/feedback-see-issues.png";
import FeedbackThumbsUp from "../../assets/landing/feedback-thumbs-up.png";
import FeedbackThumbsDown from "../../assets/landing/feedback-thumbs-down.png";

const TYPE_OF_USERS = {
  CONTENT_CREATOR: "Content Creator",
  ADVERTISER: "Advertiser",
  VIEWER: "Viewer",
};

const OPINION = {
  LIKE_THE_IDEA: "Like the Idea",
  DONT_LIKE_THE_IDEA: "Don't like the Idea",
  SEE_ISSUES_IN_THIS_IDEA: "See issues in this idea",
};

const COMPARISON = {
  BETTER_THAN_YOUTUBE: "It's better than Youtube",
  WORSE_THAN_YOUTUBE: "It's worse than Youtube",
  NO_COMMENTS: "No comments",
};

interface FeedbackProps {}

const Feedback: FC<FeedbackProps> = (props) => {
  const [typeOfUser, setTypeOfUser] = useState("");
  const [opinion, setOpinion] = useState("");
  const [comparison, setComparison] = useState("");

  return (
    <Box
      sx={{
        width: "80%",
        backgroundColor: "#222222",
        borderRadius: 2,
        py: 4,
      }}
    >
      <Box sx={{ ...Styles.SStyles.RowCenterSpace, m: 3 }}>
        <Typography
          style={{
            fontFamily: "Heebo",
            fontSize: 28,
            color: "#04A9B6",
            fontWeight: 500,
          }}
        >
          I'm a
        </Typography>

        <Box
          sx={{
            ...Styles.SStyles.RowCenterCenter,
            width: '80%',            
          }}
        >
          <FeedbackTile
            icon={SignUpViewer}
            selected={typeOfUser === TYPE_OF_USERS.VIEWER}
            title={TYPE_OF_USERS.VIEWER}
            onClick={() => setTypeOfUser(TYPE_OF_USERS.VIEWER)}
          />
          <FeedbackTile
            icon={SignUpCreator}
            selected={typeOfUser === TYPE_OF_USERS.CONTENT_CREATOR}
            title={TYPE_OF_USERS.CONTENT_CREATOR}
            onClick={() => setTypeOfUser(TYPE_OF_USERS.CONTENT_CREATOR)}
          />
          <FeedbackTile
            icon={SignUpAdvertiser}
            selected={typeOfUser === TYPE_OF_USERS.ADVERTISER}
            title={TYPE_OF_USERS.ADVERTISER}
            onClick={() => setTypeOfUser(TYPE_OF_USERS.ADVERTISER)}
          />
        </Box>
      </Box>

      <Box sx={{ ...Styles.SStyles.RowCenterSpace, m: 3, mt: 4 }}>
        <Typography
          style={{
            fontFamily: "Heebo",
            fontSize: 28,
            color: "#04A9B6",
            fontWeight: 500,
          }}
        >
          I
        </Typography>

        <Box
          sx={{
            ...Styles.SStyles.RowCenterCenter,
            width: '80%',
          }}
        >
          <FeedbackTile
            icon={FeedbackLike}
            selected={opinion === OPINION.LIKE_THE_IDEA}
            title={OPINION.LIKE_THE_IDEA}
            onClick={() => setOpinion(OPINION.LIKE_THE_IDEA)}
          />
          <FeedbackTile
            icon={FeedbackDontLike}
            selected={opinion === OPINION.DONT_LIKE_THE_IDEA}
            title={OPINION.DONT_LIKE_THE_IDEA}
            onClick={() => setOpinion(OPINION.DONT_LIKE_THE_IDEA)}
          />
          <FeedbackTile
            icon={FeedbackSeeIssues}
            selected={opinion === OPINION.SEE_ISSUES_IN_THIS_IDEA}
            title={OPINION.SEE_ISSUES_IN_THIS_IDEA}
            onClick={() => setOpinion(OPINION.SEE_ISSUES_IN_THIS_IDEA)}
          />
        </Box>
      </Box>

      <Box sx={{ ...Styles.SStyles.RowCenterSpace, m: 3, mt: 4 }}>
        <Box
          style={{
            minWidth: 120,
          }}
        >
          <Typography
            style={{
              fontFamily: "Heebo",
              fontSize: 28,
              color: "#04A9B6",
              fontWeight: 500,
            }}
          >
            I think
          </Typography>
        </Box>

        <Box
          sx={{
            ...Styles.SStyles.RowCenterCenter,
            width: '80%',
          }}
        >
          <FeedbackTile
            icon={FeedbackThumbsUp}
            selected={comparison === COMPARISON.BETTER_THAN_YOUTUBE}
            title={COMPARISON.BETTER_THAN_YOUTUBE}
            onClick={() => setComparison(COMPARISON.BETTER_THAN_YOUTUBE)}
          />
          <FeedbackTile
            icon={FeedbackThumbsDown}
            selected={comparison === COMPARISON.WORSE_THAN_YOUTUBE}
            title={COMPARISON.WORSE_THAN_YOUTUBE}
            onClick={() => setComparison(COMPARISON.WORSE_THAN_YOUTUBE)}
          />
          <FeedbackTile
            icon={FeedbackNoComments}
            selected={comparison === COMPARISON.NO_COMMENTS}
            title={COMPARISON.NO_COMMENTS}
            onClick={() => setComparison(COMPARISON.NO_COMMENTS)}
          />
        </Box>
      </Box>

      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 28,
          color: "white",
          fontWeight: 500,
          marginLeft: 24,
          marginTop: 24,
        }}
      >
        I'd like to say...
      </Typography>

      <TextField
        multiline
        minRows={6}
        placeholder="Write your inputs Here..."
        inputProps={{
          style: { color: "white", fontSize: 24 },
        }}
        sx={{
          backgroundColor: "#272727",
          width: "94%",
          marginLeft: 3,
          marginTop: 2,
        }}
      />

      <Box
        sx={{
          ...Styles.SStyles.RowCenterCenter,
        }}
      >
        <ButtonBase
          onClick={() => {
            setTimeout(() => {}, 200);
          }}
          sx={{
            height: 50,
            width: 320,
            backgroundColor: "#04A9B6",
            borderRadius: 8,
            marginTop: 4,
          }}
        >
          <Typography
            style={{
              fontFamily: "Heebo",
              fontSize: 20,
              color: "white",
              fontWeight: 500,
            }}
          >
            Submit
          </Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default Feedback;

interface FeedbackTileProps {
  selected?: any;
  icon?: any;
  title?: any;
  sx?: any;
  onClick?: any;
}

const FeedbackTile: FC<FeedbackTileProps> = (props) => {
  return (
    <ButtonBase
      onClick={() => {
        if (props.onClick) {
          setTimeout(() => {
            props.onClick();
          }, 200);
        }
      }}
      sx={{
        height: 160,
        width: '30%',
        borderRadius: 2,
        mx: 2,
        border: props.selected ? "1px solid #04A9B6" : null,
        backgroundColor: props.selected ? "#292D32" : "#272727",
        ...Styles.SStyles.ColumnCenterCenter,
        ...props.sx,
      }}
    >
      <CTools.ImageContainer
        src={props.icon}
        imageBgColor={null}
        height={100}
        width={100}
      />
      <Typography
        style={{
          fontFamily: "Heebo",
          fontSize: 20,
          color: "white",
          marginTop: 8,
        }}
      >
        {props.title}
      </Typography>
    </ButtonBase>
  );
};
