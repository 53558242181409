// React Imports
import React, { FC, useEffect, useState } from "react";

// MUI Imports
import {} from "@mui/material";

// Functional Imports
import { DateFunctions } from "../../utils/Functions";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/Paths";
import Constants from "../../utils/Constants";
import moment from "moment";

// Local Imports
import CComposite from "../../atoms/CComposite";
import CText from "../../atoms/CText";

interface ListGeneratorHistoryProps {
  contentList?: any;
  setContentList?: any;
}

const ListGeneratorHistory: FC<ListGeneratorHistoryProps> = (
  props
) => {
  const navigate = useNavigate();

  const [list, setList] = useState([]);

  useEffect(() => {
    let currentMonth = moment().month();
    let currentYear = moment().year();

    let listTemp: any = [];

    props.contentList.map((item: any, index: number) => {
      if (
        moment(item.createdAt).month() === currentMonth &&
        moment(item.createdAt).year() === currentYear
      ) {
        listTemp.push(
          <CComposite.MySpaceTile
            contentId={item.contentDetails?._id}
            contentName={item.contentDetails?.contentDetails?.name}
            spaceName={item.contentDetails?.spaceDetails?.name}
            image={item.contentDetails?.contentDetails?.coverPhoto}
            date={DateFunctions.format(item.createdAt)}
            type={Constants.TYPES.MY_SPACE.HISTORY}
            onClick={() => {
              navigate(
                PATHS.VIEWER_PLAYER + "/" + item.contentDetails?._id + "/0"
              );
            }}
          />
        );
      } else {
        listTemp.push(
          <CText.Jakarta.Size16.Mid.White
            text={
              moment(item.createdAt).format("MMM") +
              " " +
              moment(item.createdAt).year()
            }
            style={{ marginBottom: 0, marginTop: 16, width: "100%" }}
          />
        );
        listTemp.push(
          <CComposite.MySpaceTile
            contentId={item.contentDetails?._id}
            contentName={item.contentDetails?.contentDetails?.name}
            spaceName={item.contentDetails?.spaceDetails?.name}
            image={item.contentDetails?.contentDetails?.coverPhoto}
            date={DateFunctions.format(item.createdAt)}
            type={Constants.TYPES.MY_SPACE.HISTORY}
            onClick={() => {
              navigate(
                PATHS.VIEWER_PLAYER + "/" + item.contentDetails?._id + "/0"
              );
            }}
          />
        );
        currentMonth = moment(item.createdAt).month();
        currentYear = moment(item.createdAt).year();
      }

      return 0;
    });

    setList(listTemp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contentList]);

  return <>{list}</>;
};

export default ListGeneratorHistory;
